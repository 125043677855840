import React, { useMemo, useEffect, useState } from "react";
import PageHeader from "components/PageHeader";
import Panel from "components/Panel";
import { Button, ButtonGroup, Col, FormGroup, Row } from "reactstrap";
import DataTable from "components/DataTable";
import Datetime from "react-datetime";
import "chartjs-adapter-date-fns";
import moment from "moment";
import { useApi } from "components/useApi3";
import FormatCurrency from "components/FormatCurrency";
import IncidentStatus from "components/IncidentStatus";
import InfoCard from "./components/InfoCard";
import Chart from "./components/Chart";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import { incidentStatusByStatusName } from "helpers/incidentStatusById";
import { useContext } from "react";
import { AppContext } from "AppContext";
import TableFiltrationButton from "components/TableFiltrationButton";

const Incidents = () => {
	var firstDay = moment().startOf("month");
	var lastDay = moment().endOf("month");
	const { t } = useTranslation();

	const appContext = useContext(AppContext);

	const [filters, setFilters] = useState({
		fromDate: firstDay,
		toDate: lastDay
	});

	// din kostnad vs trafikområde you,site
	// switchar mellan att visa "siteData" och "userData" i chart och för "site" visas ingen table
	// även infocard rutan visar olika baseat på costMode
	const [costMode, setCostMode] = useState("you");

	const [dataResult, dataApi] = useApi();

	useEffect(() => {
		const url = "api/followup/userincidentsummary"
			.concat("?fromDate=")
			.concat(filters.fromDate.toISOString())
			.concat("&toDate=")
			.concat(filters.toDate.toISOString());

		dataApi.get(url);
	}, [filters, dataApi]);
	const statusFilters =
		appContext.customer === "Tryg"
			? [
					{
						name: t("incoming", "Incoming"),
						value: "Open"
					},
					{
						name: t("new", "New"),
						value: "New"
					},
					{
						name: t("reviewed", "Reviewed"),
						value: "Recieved"
					},
					{
						name: t("tryg_damage", "Tryg damage"),
						value: "SentToTryg"
					},
					{
						name: t("internal_damage", "Internal damage"),
						value: "SavedLocally"
					},
					{
						name: t("completed", "Completed"),
						value: "Completed"
					},
					{
						name: t("removed", "Removed"),
						value: "Removed"
					}
			  ]
			: [
					{
						name: t("incoming", "Incoming"),
						value: "Open"
					},
					{
						name: t("sent", "Sent"),
						value: "Sent"
					},
					{
						name: t("completed", "Completed"),
						value: "Completed"
					}
			  ];
	const initialState =
		appContext.customer === "Tryg"
			? {
					filters: [
						{
							id: "status",
							value: [
								"New",
								"SentToTryg",
								"SavedLocally",
								"Recieved",
								"Completed"
							]
						}
					]
			  }
			: {
					filters: [
						{
							id: "status",
							value: ["Open", "Sent", "Completed"]
						}
					]
			  };
	const columns = [
		{
			enableColumnFilter: true,
			accessorKey: "incidentDate",
			header: t("incident_date"),
			cell: (cell) => {
				return moment(cell.row.original.incidentDate).format("L");
			}
		},
		{
			enableColumnFilter: true,
			accessorFn: (row) =>
				row.vehicleIdentifier
					.concat(" - ")
					.concat(row.vehicleRegistrationNumber)
					.concat(" - ")
					.concat(row.vehicleModel),
			header: t("vehicle")
		},

		{
			enableColumnFilter: true,
			accessorKey: "siteName",
			header: t("traffic_area")
		},
		{
			accessorKey: "estimatedCostForInjury",
			header: t("estimated_cost_for_injury"),
			cell: (cell) => {
				return (
					<FormatCurrency
						value={cell.row.original.estimatedCostForInjury}
					/>
				);
			}
		},
		{
			accessorKey: "compensationAmount",
			header: t("compensation_amount"),
			cell: (cell) => {
				return (
					<FormatCurrency
						value={cell.row.original.compensationAmount}
					/>
				);
			}
		},
		{
			accessorKey: "counterpartCost",
			header: t("counterpart_cost"),
			cell: (cell) => {
				return (
					<FormatCurrency value={cell.row.original.counterpartCost} />
				);
			}
		},
		{
			enableColumnFilter: true,
			accessorKey: "status",
			header: t("status"),
			cell: (cell) => {
				return (
					<IncidentStatus
						status={cell.row.original.status}
						customer={appContext.customer}
					/>
				);
			},
			Filter: TableFiltrationButton,
			hiddenHeaderFilter: true,
			filterFn: "multiFilter",
			FilterOptions: statusFilters
		},
		{
			enableColumnFilter: true,
			accessorKey: "createdDate",
			header: t("created"),
			cell: (cell) => {
				return moment(cell.row.original.createdDate).format("L");
			}
		},
		{
			enableColumnFilter: true,
			accessorKey: "createdByName",
			header: t("created_by")
		}
	];

	const tableRows =
		dataResult.data && dataResult.data.incidents
			? dataResult.data.incidents
			: [];

	return (
		<>
			<PageHeader
				title={t("follow_up")}
				subtitle={t("compare_your_costs_and_your_traffic_area")}
			/>
			<Panel>
				<Row>
					<Col xs={12} sm={6}>
						<div className="d-flex justify-content-end">
							<Row>
								<Col sm={8}>
									<ButtonGroup className="me-sm-2">
										<Button
											onClick={() => {
												setCostMode("you");
											}}
											color="white"
											className="btn-form-input"
										>
											{t("your_cost")}
										</Button>
										<Button
											onClick={() => {
												setCostMode("site");
											}}
											color="white"
											className="btn-form-input"
										>
											{t("yourtrafficarea")}
										</Button>
									</ButtonGroup>
								</Col>
								<Col sm={4} className="mt-2 mt-sm-0">
									<ButtonGroup>
										<Button
											onClick={() => {
												var firstDay =
													moment().subtract(
														1,
														"months"
													);
												var lastDay = moment();

												setFilters((f) => {
													return {
														...f,
														fromDate: firstDay,
														toDate: lastDay
													};
												});
											}}
											color="white"
											className="btn-form-input"
										>
											{t("month")}
										</Button>
										<Button
											onClick={() => {
												var firstDay =
													moment().subtract(
														3,
														"months"
													);
												var lastDay = moment();

												setFilters((f) => {
													return {
														...f,
														fromDate: firstDay,
														toDate: lastDay
													};
												});
											}}
											color="white"
											className="btn-form-input"
										>
											{t("quarter")}
										</Button>
										<Button
											onClick={() => {
												var firstDay =
													moment().subtract(
														1,
														"years"
													);
												var lastDay = moment();

												setFilters((f) => {
													return {
														...f,
														fromDate: firstDay,
														toDate: lastDay
													};
												});
											}}
											color="white"
											className="btn-form-input"
										>
											{t("year")}
										</Button>
									</ButtonGroup>
								</Col>
							</Row>
						</div>
					</Col>
					<Col xs={12} sm={6} className="mt-2 mt-sm-0">
						<div className="d-flex justify-content-end">
							<FormGroup className="me-2">
								<Datetime
									locale={i18next.resolvedLanguage}
									onChange={(e) => {
										const value = e;
										setFilters((f) => {
											return {
												...f,
												fromDate: value
											};
										});
									}}
									value={filters.fromDate}
									timeFormat={false}
									inputProps={{
										placeholder: "Från datum",
										className: "form-control"
									}}
								/>
							</FormGroup>
							<FormGroup>
								<Datetime
									locale={i18next.resolvedLanguage}
									onChange={(e) => {
										const value = e;
										setFilters((f) => {
											return {
												...f,
												toDate: value
											};
										});
									}}
									value={filters.toDate}
									timeFormat={false}
									inputProps={{
										placeholder: "Till datum",
										className: "form-control"
									}}
								/>
							</FormGroup>
						</div>
						<hr />
					</Col>
				</Row>
				<Row>
					<Col sm={9}>
						<Chart result={dataResult} costMode={costMode} />
					</Col>
					<Col sm={3}>
						<InfoCard result={dataResult} costMode={costMode} />
					</Col>
				</Row>
			</Panel>
			{costMode === "you" && (
				<Row>
					<Col xs={12} className="mt-2 mt-md-3">
						<DataTable
							columns={columns}
							result={dataResult}
							rowData={tableRows}
							initialState={initialState}
						/>
					</Col>
				</Row>
			)}
		</>
	);
};

export default Incidents;
