import { t } from "i18next";
import * as React from "react";
const SvgTrailerSideLeft = (props) => (
	<svg
		width={525}
		height={312}
		viewBox="0 0 525 312"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
		className="svg-component"
	>
		<g clipPath="url(#clip0_1811_3524)">
			<path
				d="M211.48 252.05H225.48V215.04H211.48V252.05Z"
				fill="#262626"
			/>
			<path
				d="M196.69 227.55L195.84 233.55H17.6803L15.0103 227.55H196.69Z"
				fill="#262626"
			/>
			<path
				d="M9.4502 215.05H218.48L216.69 227.55H15.0102L9.4502 215.05Z"
				fill="#767676"
			/>
			<path
				d="M221.48 249.55L237.48 217.05H383.43L399.81 247.55L390.96 260.55L318.45 254.69C318.12 258.81 314.68 262.05 310.48 262.05C306.28 262.05 302.76 258.73 302.5 254.54L228.46 259.05L221.46 249.54L221.48 249.55Z"
				fill="#262626"
			/>
			<path
				d="M310.49 260.55C314.08 260.55 316.99 257.64 316.99 254.05C316.99 250.46 314.08 247.55 310.49 247.55C306.9 247.55 303.99 250.46 303.99 254.05C303.99 257.64 306.9 260.55 310.49 260.55Z"
				fill="black"
			/>
			<path
				d="M220.73 215.05L214.48 262.9C214.4 263.52 214.88 264.06 215.5 264.06H216.24C218.05 264.06 219.63 262.79 219.99 261.02C222.03 251.06 231.33 215.27 266.48 218.06C271.61 218.47 286.82 221.31 286.82 221.31C286.82 221.31 293.86 221.06 297.17 230.56C300.48 240.06 303.48 245.21 310.48 245.21C317.48 245.21 320.48 240.06 323.79 230.56C327.1 221.06 334.14 221.31 334.14 221.31C334.14 221.31 349.35 218.47 354.48 218.06C389.62 215.27 398.92 251.06 400.97 261.02C401.33 262.8 402.91 264.06 404.72 264.06H405.46C406.08 264.06 406.56 263.51 406.48 262.9L400.23 215.05H220.71H220.73Z"
				fill="#767676"
			/>
			<path
				d="M488.681 215.05L490.521 222.05L505.431 236.91V254.41L452.721 215.05H488.681Z"
				fill="#262626"
			/>
			<path
				d="M404.94 250.91L400.25 215.05H452.72L500.74 250.91H404.94Z"
				fill="black"
			/>
			<path
				d="M437.51 242.05H447.51V237.05H437.51V242.05Z"
				fill="#FF8601"
			/>
			<path
				d="M511.521 235.05V236.55H509.021V255.55H511.521V257.05H505.521V235.05H511.521Z"
				fill="black"
			/>
			<path
				d="M509.641 254.05H511.9C512.243 254.05 512.521 253.772 512.521 253.43V238.67C512.521 238.328 512.243 238.05 511.9 238.05H509.641C509.298 238.05 509.02 238.328 509.02 238.67V253.43C509.02 253.772 509.298 254.05 509.641 254.05Z"
				fill="#FF8601"
			/>
			<path
				d="M83.6401 249.55L99.6401 217.05H151.38L167.76 247.55L158.92 260.55L90.6401 259.05L83.6401 249.55Z"
				fill="#262626"
			/>
			<path
				d="M82.9003 215.05L76.6503 262.9C76.5703 263.52 77.0503 264.06 77.6703 264.06H78.4103C80.2203 264.06 81.8003 262.79 82.1603 261.02C84.2003 251.06 93.5003 215.27 128.65 218.06C133.78 218.47 115.53 218.47 120.66 218.06C155.8 215.27 165.1 251.06 167.15 261.02C167.51 262.8 168.3 264.06 170.11 264.06H171.64C172.26 264.06 172.74 263.51 172.66 262.9L166.41 215.05"
				fill="#767676"
			/>
			<path
				d="M82.9003 215.05L76.6503 262.9C76.5703 263.52 77.0503 264.06 77.6703 264.06H78.4103C80.2203 264.06 81.8003 262.79 82.1603 261.02C84.2003 251.06 93.5003 215.27 128.65 218.06C133.78 218.47 115.53 218.47 120.66 218.06C155.8 215.27 165.1 251.06 167.15 261.02C167.51 262.8 168.3 264.06 170.11 264.06H171.64C172.26 264.06 172.74 263.51 172.66 262.9L166.41 215.05"
				stroke="#262626"
				strokeWidth={2}
				strokeMiterlimit={10}
			/>
			<path
				d="M265.5 296.02C286.272 296.02 303.11 279.181 303.11 258.41C303.11 237.639 286.272 220.8 265.5 220.8C244.729 220.8 227.89 237.639 227.89 258.41C227.89 279.181 244.729 296.02 265.5 296.02Z"
				fill="#414141"
			/>
			<path
				d="M265.5 279.91C277.374 279.91 287 270.284 287 258.41C287 246.536 277.374 236.91 265.5 236.91C253.626 236.91 244 246.536 244 258.41C244 270.284 253.626 279.91 265.5 279.91Z"
				fill="#CCCCCC"
			/>
			<path
				d="M265.5 268.41C271.023 268.41 275.5 263.933 275.5 258.41C275.5 252.887 271.023 248.41 265.5 248.41C259.977 248.41 255.5 252.887 255.5 258.41C255.5 263.933 259.977 268.41 265.5 268.41Z"
				fill="#E3E3E3"
			/>
			<path
				d="M265.9 252.41C265.62 252.41 265.4 252.19 265.4 251.91C265.4 251.63 265.62 251.41 265.9 251.41C266.18 251.41 266.4 251.63 266.4 251.91C266.4 252.19 266.18 252.41 265.9 252.41Z"
				fill="white"
			/>
			<path
				d="M265.9 250.91C265.35 250.91 264.9 251.36 264.9 251.91C264.9 252.46 265.35 252.91 265.9 252.91C266.45 252.91 266.9 252.46 266.9 251.91C266.9 251.36 266.45 250.91 265.9 250.91Z"
				fill="black"
			/>
			<path
				d="M270.38 254.6C270.25 254.6 270.12 254.55 270.03 254.45C269.94 254.36 269.88 254.23 269.88 254.1C269.88 253.97 269.93 253.84 270.03 253.75C270.12 253.66 270.25 253.6 270.38 253.6C270.51 253.6 270.64 253.65 270.73 253.75C270.82 253.84 270.88 253.97 270.88 254.1C270.88 254.23 270.83 254.36 270.73 254.45C270.64 254.54 270.51 254.6 270.38 254.6Z"
				fill="white"
			/>
			<path
				d="M270.38 253.1C270.12 253.1 269.87 253.2 269.67 253.39C269.28 253.78 269.28 254.41 269.67 254.8C269.87 255 270.12 255.09 270.38 255.09C270.64 255.09 270.89 254.99 271.09 254.8C271.48 254.41 271.48 253.78 271.09 253.39C270.89 253.19 270.64 253.1 270.38 253.1Z"
				fill="black"
			/>
			<path
				d="M272 259.31C272.276 259.31 272.5 259.086 272.5 258.81C272.5 258.534 272.276 258.31 272 258.31C271.724 258.31 271.5 258.534 271.5 258.81C271.5 259.086 271.724 259.31 272 259.31Z"
				fill="white"
			/>
			<path
				d="M272 257.81C271.45 257.81 271 258.26 271 258.81C271 259.36 271.45 259.81 272 259.81C272.55 259.81 273 259.36 273 258.81C273 258.26 272.55 257.81 272 257.81Z"
				fill="black"
			/>
			<path
				d="M269.82 263.79C269.69 263.79 269.56 263.74 269.47 263.64C269.38 263.55 269.32 263.42 269.32 263.29C269.32 263.16 269.37 263.03 269.47 262.94C269.56 262.85 269.69 262.79 269.82 262.79C269.95 262.79 270.08 262.84 270.17 262.94C270.36 263.14 270.36 263.45 270.17 263.65C270.08 263.74 269.95 263.8 269.82 263.8V263.79Z"
				fill="white"
			/>
			<path
				d="M269.82 262.29C269.56 262.29 269.31 262.39 269.11 262.58C268.72 262.97 268.72 263.6 269.11 263.99C269.31 264.19 269.56 264.28 269.82 264.28C270.08 264.28 270.33 264.18 270.53 263.99C270.92 263.6 270.92 262.97 270.53 262.58C270.33 262.38 270.08 262.29 269.82 262.29Z"
				fill="black"
			/>
			<path
				d="M265.1 265.42C265.376 265.42 265.6 265.196 265.6 264.92C265.6 264.644 265.376 264.42 265.1 264.42C264.824 264.42 264.6 264.644 264.6 264.92C264.6 265.196 264.824 265.42 265.1 265.42Z"
				fill="white"
			/>
			<path
				d="M265.1 263.92C264.55 263.92 264.1 264.37 264.1 264.92C264.1 265.47 264.55 265.92 265.1 265.92C265.65 265.92 266.1 265.47 266.1 264.92C266.1 264.37 265.65 263.92 265.1 263.92Z"
				fill="black"
			/>
			<path
				d="M260.62 263.23C260.49 263.23 260.36 263.18 260.27 263.08C260.18 262.99 260.12 262.86 260.12 262.73C260.12 262.6 260.17 262.47 260.27 262.38C260.36 262.29 260.49 262.23 260.62 262.23C260.75 262.23 260.88 262.28 260.97 262.38C261.16 262.58 261.16 262.89 260.97 263.09C260.88 263.18 260.75 263.24 260.62 263.24V263.23Z"
				fill="white"
			/>
			<path
				d="M260.62 261.73C260.36 261.73 260.11 261.83 259.91 262.02C259.52 262.41 259.52 263.04 259.91 263.43C260.11 263.63 260.36 263.72 260.62 263.72C260.88 263.72 261.13 263.62 261.33 263.43C261.72 263.04 261.72 262.41 261.33 262.02C261.13 261.82 260.88 261.73 260.62 261.73Z"
				fill="black"
			/>
			<path
				d="M259 258.51C258.72 258.51 258.5 258.29 258.5 258.01C258.5 257.73 258.72 257.51 259 257.51C259.28 257.51 259.5 257.73 259.5 258.01C259.5 258.29 259.28 258.51 259 258.51Z"
				fill="white"
			/>
			<path
				d="M259 257.01C258.45 257.01 258 257.46 258 258.01C258 258.56 258.45 259.01 259 259.01C259.55 259.01 260 258.56 260 258.01C260 257.46 259.55 257.01 259 257.01Z"
				fill="black"
			/>
			<path
				d="M261.19 254.04C261.06 254.04 260.93 253.99 260.84 253.89C260.75 253.8 260.69 253.67 260.69 253.54C260.69 253.41 260.74 253.28 260.84 253.19C260.93 253.1 261.06 253.04 261.19 253.04C261.32 253.04 261.45 253.09 261.54 253.19C261.73 253.39 261.73 253.7 261.54 253.9C261.45 253.99 261.32 254.05 261.19 254.05V254.04Z"
				fill="white"
			/>
			<path
				d="M261.19 252.53C260.93 252.53 260.68 252.63 260.48 252.82C260.09 253.21 260.09 253.84 260.48 254.23C260.68 254.43 260.93 254.52 261.19 254.52C261.45 254.52 261.7 254.42 261.9 254.23C262.29 253.84 262.29 253.21 261.9 252.82C261.7 252.62 261.45 252.53 261.19 252.53Z"
				fill="black"
			/>
			<path
				d="M265.5 262.41C267.709 262.41 269.5 260.619 269.5 258.41C269.5 256.201 267.709 254.41 265.5 254.41C263.291 254.41 261.5 256.201 261.5 258.41C261.5 260.619 263.291 262.41 265.5 262.41Z"
				fill="#808080"
			/>
			<path
				d="M355.52 296.02C376.292 296.02 393.13 279.181 393.13 258.41C393.13 237.639 376.292 220.8 355.52 220.8C334.749 220.8 317.91 237.639 317.91 258.41C317.91 279.181 334.749 296.02 355.52 296.02Z"
				fill="#414141"
			/>
			<path
				d="M355.52 279.91C367.394 279.91 377.02 270.284 377.02 258.41C377.02 246.536 367.394 236.91 355.52 236.91C343.646 236.91 334.02 246.536 334.02 258.41C334.02 270.284 343.646 279.91 355.52 279.91Z"
				fill="#CCCCCC"
			/>
			<path
				d="M355.52 268.41C361.043 268.41 365.52 263.933 365.52 258.41C365.52 252.887 361.043 248.41 355.52 248.41C349.997 248.41 345.52 252.887 345.52 258.41C345.52 263.933 349.997 268.41 355.52 268.41Z"
				fill="#E3E3E3"
			/>
			<path
				d="M355.92 252.41C355.64 252.41 355.42 252.19 355.42 251.91C355.42 251.63 355.64 251.41 355.92 251.41C356.2 251.41 356.42 251.63 356.42 251.91C356.42 252.19 356.2 252.41 355.92 252.41Z"
				fill="white"
			/>
			<path
				d="M355.92 250.91C355.37 250.91 354.92 251.36 354.92 251.91C354.92 252.46 355.37 252.91 355.92 252.91C356.47 252.91 356.92 252.46 356.92 251.91C356.92 251.36 356.47 250.91 355.92 250.91Z"
				fill="black"
			/>
			<path
				d="M360.4 254.6C360.27 254.6 360.14 254.55 360.05 254.45C359.96 254.36 359.9 254.23 359.9 254.1C359.9 253.97 359.95 253.84 360.05 253.75C360.14 253.66 360.27 253.6 360.4 253.6C360.53 253.6 360.66 253.65 360.75 253.75C360.84 253.84 360.9 253.97 360.9 254.1C360.9 254.23 360.85 254.36 360.75 254.45C360.66 254.54 360.53 254.6 360.4 254.6Z"
				fill="white"
			/>
			<path
				d="M360.4 253.1C360.14 253.1 359.89 253.2 359.69 253.39C359.3 253.78 359.3 254.41 359.69 254.8C359.89 255 360.14 255.09 360.4 255.09C360.66 255.09 360.91 254.99 361.11 254.8C361.5 254.41 361.5 253.78 361.11 253.39C360.91 253.19 360.66 253.1 360.4 253.1Z"
				fill="black"
			/>
			<path
				d="M362.02 259.31C362.296 259.31 362.52 259.086 362.52 258.81C362.52 258.534 362.296 258.31 362.02 258.31C361.744 258.31 361.52 258.534 361.52 258.81C361.52 259.086 361.744 259.31 362.02 259.31Z"
				fill="white"
			/>
			<path
				d="M362.02 257.81C361.47 257.81 361.02 258.26 361.02 258.81C361.02 259.36 361.47 259.81 362.02 259.81C362.57 259.81 363.02 259.36 363.02 258.81C363.02 258.26 362.57 257.81 362.02 257.81Z"
				fill="black"
			/>
			<path
				d="M359.83 263.79C359.7 263.79 359.57 263.74 359.48 263.64C359.29 263.44 359.29 263.13 359.48 262.93C359.57 262.84 359.7 262.78 359.83 262.78C359.96 262.78 360.09 262.83 360.18 262.93C360.27 263.02 360.33 263.15 360.33 263.28C360.33 263.41 360.28 263.54 360.18 263.63C360.09 263.72 359.96 263.78 359.83 263.78V263.79Z"
				fill="white"
			/>
			<path
				d="M359.83 262.29C359.57 262.29 359.32 262.39 359.12 262.58C358.73 262.97 358.73 263.6 359.12 263.99C359.32 264.19 359.57 264.28 359.83 264.28C360.09 264.28 360.34 264.18 360.54 263.99C360.93 263.6 360.93 262.97 360.54 262.58C360.34 262.38 360.09 262.29 359.83 262.29Z"
				fill="black"
			/>
			<path
				d="M355.12 265.42C355.396 265.42 355.62 265.196 355.62 264.92C355.62 264.644 355.396 264.42 355.12 264.42C354.844 264.42 354.62 264.644 354.62 264.92C354.62 265.196 354.844 265.42 355.12 265.42Z"
				fill="white"
			/>
			<path
				d="M355.12 263.92C354.57 263.92 354.12 264.37 354.12 264.92C354.12 265.47 354.57 265.92 355.12 265.92C355.67 265.92 356.12 265.47 356.12 264.92C356.12 264.37 355.67 263.92 355.12 263.92Z"
				fill="black"
			/>
			<path
				d="M350.64 263.23C350.51 263.23 350.38 263.18 350.29 263.08C350.2 262.99 350.14 262.86 350.14 262.73C350.14 262.6 350.19 262.47 350.29 262.38C350.38 262.29 350.51 262.23 350.64 262.23C350.77 262.23 350.9 262.28 350.99 262.38C351.18 262.58 351.18 262.89 350.99 263.09C350.9 263.18 350.77 263.24 350.64 263.24V263.23Z"
				fill="white"
			/>
			<path
				d="M350.64 261.73C350.38 261.73 350.13 261.83 349.93 262.02C349.54 262.41 349.54 263.04 349.93 263.43C350.13 263.63 350.38 263.72 350.64 263.72C350.9 263.72 351.15 263.62 351.35 263.43C351.74 263.04 351.74 262.41 351.35 262.02C351.15 261.82 350.9 261.73 350.64 261.73Z"
				fill="black"
			/>
			<path
				d="M349.01 258.51C348.73 258.51 348.51 258.29 348.51 258.01C348.51 257.73 348.73 257.51 349.01 257.51C349.29 257.51 349.51 257.73 349.51 258.01C349.51 258.29 349.29 258.51 349.01 258.51Z"
				fill="white"
			/>
			<path
				d="M349.01 257.01C348.46 257.01 348.01 257.46 348.01 258.01C348.01 258.56 348.46 259.01 349.01 259.01C349.56 259.01 350.01 258.56 350.01 258.01C350.01 257.46 349.56 257.01 349.01 257.01Z"
				fill="black"
			/>
			<path
				d="M351.2 254.04C351.07 254.04 350.94 253.99 350.85 253.89C350.65 253.69 350.65 253.38 350.85 253.18C350.94 253.09 351.07 253.03 351.2 253.03C351.33 253.03 351.46 253.08 351.55 253.18C351.64 253.27 351.7 253.4 351.7 253.53C351.7 253.66 351.65 253.79 351.55 253.88C351.46 253.97 351.33 254.03 351.2 254.03V254.04Z"
				fill="white"
			/>
			<path
				d="M351.2 252.53C350.94 252.53 350.69 252.63 350.49 252.82C350.1 253.21 350.1 253.84 350.49 254.23C350.69 254.43 350.94 254.52 351.2 254.52C351.46 254.52 351.71 254.42 351.91 254.23C352.3 253.84 352.3 253.21 351.91 252.82C351.71 252.62 351.46 252.53 351.2 252.53Z"
				fill="black"
			/>
			<path
				d="M355.52 262.41C357.729 262.41 359.52 260.619 359.52 258.41C359.52 256.201 357.729 254.41 355.52 254.41C353.311 254.41 351.52 256.201 351.52 258.41C351.52 260.619 353.311 262.41 355.52 262.41Z"
				fill="#808080"
			/>
			<path d="M509.02 19.52H28.9502V210.55H509.02V19.52Z" fill="white" />
			<path
				d="M505.52 23.02V207.05H32.4502V23.02H505.52ZM509.91 16.02H28.0602C26.6202 16.02 25.4502 17.19 25.4502 18.63V211.43C25.4502 212.87 26.6202 214.04 28.0602 214.04H509.9C511.34 214.04 512.51 212.87 512.51 211.43V18.63C512.51 17.19 511.34 16.02 509.9 16.02H509.91Z"
				fill="#E0E0E0"
			/>
			<path
				d="M124.41 296.02C145.181 296.02 162.02 279.181 162.02 258.41C162.02 237.639 145.181 220.8 124.41 220.8C103.638 220.8 86.7998 237.639 86.7998 258.41C86.7998 279.181 103.638 296.02 124.41 296.02Z"
				fill="#414141"
			/>
			<path
				d="M124.41 279.91C136.284 279.91 145.91 270.284 145.91 258.41C145.91 246.536 136.284 236.91 124.41 236.91C112.536 236.91 102.91 246.536 102.91 258.41C102.91 270.284 112.536 279.91 124.41 279.91Z"
				fill="#CCCCCC"
			/>
			<path
				d="M124.41 268.41C129.933 268.41 134.41 263.933 134.41 258.41C134.41 252.887 129.933 248.41 124.41 248.41C118.887 248.41 114.41 252.887 114.41 258.41C114.41 263.933 118.887 268.41 124.41 268.41Z"
				fill="#E3E3E3"
			/>
			<path
				d="M124.81 252.41C124.53 252.41 124.31 252.19 124.31 251.91C124.31 251.63 124.53 251.41 124.81 251.41C125.09 251.41 125.31 251.63 125.31 251.91C125.31 252.19 125.09 252.41 124.81 252.41Z"
				fill="white"
			/>
			<path
				d="M124.81 250.91C124.26 250.91 123.81 251.36 123.81 251.91C123.81 252.46 124.26 252.91 124.81 252.91C125.36 252.91 125.81 252.46 125.81 251.91C125.81 251.36 125.36 250.91 124.81 250.91Z"
				fill="black"
			/>
			<path
				d="M129.29 254.6C129.16 254.6 129.03 254.55 128.94 254.45C128.75 254.25 128.75 253.94 128.94 253.74C129.03 253.65 129.16 253.59 129.29 253.59C129.42 253.59 129.55 253.64 129.64 253.74C129.73 253.83 129.79 253.96 129.79 254.09C129.79 254.22 129.74 254.35 129.64 254.44C129.55 254.53 129.42 254.59 129.29 254.59V254.6Z"
				fill="white"
			/>
			<path
				d="M129.29 253.1C129.03 253.1 128.78 253.2 128.58 253.39C128.19 253.78 128.19 254.41 128.58 254.8C128.78 255 129.03 255.09 129.29 255.09C129.55 255.09 129.8 254.99 130 254.8C130.39 254.41 130.39 253.78 130 253.39C129.8 253.19 129.55 253.1 129.29 253.1Z"
				fill="black"
			/>
			<path
				d="M130.91 259.31C131.186 259.31 131.41 259.086 131.41 258.81C131.41 258.534 131.186 258.31 130.91 258.31C130.634 258.31 130.41 258.534 130.41 258.81C130.41 259.086 130.634 259.31 130.91 259.31Z"
				fill="white"
			/>
			<path
				d="M130.91 257.81C130.36 257.81 129.91 258.26 129.91 258.81C129.91 259.36 130.36 259.81 130.91 259.81C131.46 259.81 131.91 259.36 131.91 258.81C131.91 258.26 131.46 257.81 130.91 257.81Z"
				fill="black"
			/>
			<path
				d="M128.72 263.79C128.59 263.79 128.46 263.74 128.37 263.64C128.28 263.55 128.22 263.42 128.22 263.29C128.22 263.16 128.27 263.03 128.37 262.94C128.46 262.85 128.59 262.79 128.72 262.79C128.85 262.79 128.98 262.84 129.07 262.94C129.16 263.03 129.22 263.16 129.22 263.29C129.22 263.42 129.17 263.55 129.07 263.64C128.98 263.73 128.85 263.79 128.72 263.79Z"
				fill="white"
			/>
			<path
				d="M128.72 262.29C128.46 262.29 128.21 262.39 128.01 262.58C127.62 262.97 127.62 263.6 128.01 263.99C128.21 264.19 128.46 264.28 128.72 264.28C128.98 264.28 129.23 264.18 129.43 263.99C129.82 263.6 129.82 262.97 129.43 262.58C129.23 262.38 128.98 262.29 128.72 262.29Z"
				fill="black"
			/>
			<path
				d="M124.01 265.42C123.73 265.42 123.51 265.2 123.51 264.92C123.51 264.64 123.73 264.42 124.01 264.42C124.29 264.42 124.51 264.64 124.51 264.92C124.51 265.2 124.29 265.42 124.01 265.42Z"
				fill="white"
			/>
			<path
				d="M124.01 263.92C123.46 263.92 123.01 264.37 123.01 264.92C123.01 265.47 123.46 265.92 124.01 265.92C124.56 265.92 125.01 265.47 125.01 264.92C125.01 264.37 124.56 263.92 124.01 263.92Z"
				fill="black"
			/>
			<path
				d="M119.53 263.23C119.4 263.23 119.27 263.18 119.18 263.08C119.09 262.99 119.03 262.86 119.03 262.73C119.03 262.6 119.08 262.47 119.18 262.38C119.27 262.29 119.4 262.23 119.53 262.23C119.66 262.23 119.79 262.28 119.88 262.38C120.07 262.58 120.07 262.89 119.88 263.09C119.79 263.18 119.66 263.24 119.53 263.24V263.23Z"
				fill="white"
			/>
			<path
				d="M119.53 261.73C119.27 261.73 119.02 261.83 118.82 262.02C118.43 262.41 118.43 263.04 118.82 263.43C119.02 263.63 119.27 263.72 119.53 263.72C119.79 263.72 120.04 263.62 120.24 263.43C120.63 263.04 120.63 262.41 120.24 262.02C120.04 261.82 119.79 261.73 119.53 261.73Z"
				fill="black"
			/>
			<path
				d="M117.9 258.51C117.62 258.51 117.4 258.29 117.4 258.01C117.4 257.73 117.62 257.51 117.9 257.51C118.18 257.51 118.4 257.73 118.4 258.01C118.4 258.29 118.18 258.51 117.9 258.51Z"
				fill="white"
			/>
			<path
				d="M117.9 257.01C117.35 257.01 116.9 257.46 116.9 258.01C116.9 258.56 117.35 259.01 117.9 259.01C118.45 259.01 118.9 258.56 118.9 258.01C118.9 257.46 118.45 257.01 117.9 257.01Z"
				fill="black"
			/>
			<path
				d="M120.09 254.04C119.96 254.04 119.83 253.99 119.74 253.89C119.65 253.8 119.59 253.67 119.59 253.54C119.59 253.41 119.64 253.28 119.74 253.19C119.83 253.1 119.96 253.04 120.09 253.04C120.22 253.04 120.35 253.09 120.44 253.19C120.53 253.28 120.59 253.41 120.59 253.54C120.59 253.67 120.54 253.8 120.44 253.89C120.35 253.98 120.22 254.04 120.09 254.04Z"
				fill="white"
			/>
			<path
				d="M120.09 252.53C119.83 252.53 119.58 252.63 119.38 252.82C118.99 253.21 118.99 253.84 119.38 254.23C119.58 254.43 119.83 254.52 120.09 254.52C120.35 254.52 120.6 254.42 120.8 254.23C121.19 253.84 121.19 253.21 120.8 252.82C120.6 252.62 120.35 252.53 120.09 252.53Z"
				fill="black"
			/>
			<path
				d="M124.41 262.41C126.619 262.41 128.41 260.619 128.41 258.41C128.41 256.201 126.619 254.41 124.41 254.41C122.201 254.41 120.41 256.201 120.41 258.41C120.41 260.619 122.201 262.41 124.41 262.41Z"
				fill="#808080"
			/>
			<path
				d="M225.4 214.77H9.37012L14.9301 227.27L17.6001 233.27H80.4001L76.5701 262.61C76.4901 263.23 76.9701 263.77 77.5901 263.77H78.3301C80.1401 263.77 81.7201 262.5 82.0801 260.73C82.5401 258.5 83.3601 254.98 84.7501 250.89L86.9601 253.89C86.8001 255.28 86.7201 256.7 86.7201 258.13C86.7201 278.9 103.56 295.74 124.33 295.74C145.1 295.74 161.94 278.9 161.94 258.13C161.94 257.36 161.91 256.59 161.86 255.83L164.67 251.7C165.9 255.44 166.64 258.65 167.07 260.72C167.43 262.5 168.22 263.76 170.03 263.76H171.56C172.18 263.76 172.66 263.21 172.58 262.6L168.75 233.26H211.4V251.76H215.82L214.4 262.6C214.32 263.22 214.8 263.76 215.42 263.76H216.16C217.97 263.76 219.55 262.49 219.91 260.72C220.33 258.66 221.08 255.47 222.29 251.76H223.24L227.82 257.97C227.82 257.97 227.82 258.07 227.82 258.12C227.82 278.89 244.66 295.73 265.43 295.73C286.2 295.73 303.04 278.89 303.04 258.12C303.04 257.65 303.02 257.18 303 256.71C304.17 259.66 307.05 261.76 310.42 261.76C313.79 261.76 316.72 259.64 317.87 256.65C317.85 257.14 317.83 257.63 317.83 258.12C317.83 278.89 334.67 295.73 355.44 295.73C376.21 295.73 393.05 278.89 393.05 258.12C393.05 257.79 393.03 257.46 393.03 257.13L397.92 249.94C399.51 254.42 400.43 258.32 400.92 260.71C401.28 262.49 402.86 263.75 404.67 263.75H405.41C406.03 263.75 406.51 263.2 406.43 262.59L404.87 250.61H500.68L452.65 214.74H225.41L225.4 214.77Z"
				stroke="black"
				strokeWidth={2}
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M488.59 214.77H452.63L505.35 254.14V236.64L490.43 221.78L488.59 214.77Z"
				stroke="black"
				strokeWidth={2}
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M511.82 237.78H509.56C509.22 237.78 508.94 238.06 508.94 238.4V236.28H511.44V234.78H505.44V256.78H511.44V255.28H508.94V253.16C508.94 253.5 509.22 253.78 509.56 253.78H511.82C512.16 253.78 512.44 253.5 512.44 253.16V238.4C512.44 238.06 512.16 237.78 511.82 237.78Z"
				stroke="black"
				strokeWidth={2}
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M509.83 15.75H27.9801C26.5387 15.75 25.3701 16.9185 25.3701 18.36V211.17C25.3701 212.611 26.5387 213.78 27.9801 213.78H509.83C511.272 213.78 512.44 212.611 512.44 211.17V18.36C512.44 16.9185 511.272 15.75 509.83 15.75Z"
				stroke="black"
				strokeWidth={2}
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<rect
				width={174.803}
				height={103.937}
				fill="white"
				fillOpacity={0.01}
				className="zone"
				onClick={() =>
					props.handlePathClick &&
					props.handlePathClick(
						77,
						t("Zone77", "Forparti fører-side top")
					)
				}
			/>
			{props.clickedZones.find((path) => path.zoneId === 77) && (
				<g filter="url(#filter0_d_1811_3524)">
					<rect
						x={72.4019}
						y={36.9683}
						width={30}
						height={30}
						rx={15}
						fill="#3c7749"
					/>
					<rect
						x={71.4019}
						y={35.9683}
						width={32}
						height={32}
						rx={16}
						stroke="white"
						strokeWidth={2}
					/>
					<path
						d="M95.4019 45.9683L84.4019 56.9683L79.4019 51.9683"
						stroke="white"
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
				</g>
			)}
			<rect
				width={174.803}
				height={103.937}
				transform="translate(174.803)"
				fill="white"
				fillOpacity={0.01}
				className="zone"
				onClick={() =>
					props.handlePathClick &&
					props.handlePathClick(
						78,
						t("Zone78", "Midt fører-side top")
					)
				}
			/>
			{props.clickedZones.find((path) => path.zoneId === 78) && (
				<g filter="url(#filter1_d_1811_3524)">
					<rect
						x={247.205}
						y={36.9683}
						width={30}
						height={30}
						rx={15}
						fill="#3c7749"
					/>
					<rect
						x={246.205}
						y={35.9683}
						width={32}
						height={32}
						rx={16}
						stroke="white"
						strokeWidth={2}
					/>
					<path
						d="M270.205 45.9683L259.205 56.9683L254.205 51.9683"
						stroke="white"
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
				</g>
			)}
			<rect
				width={174.803}
				height={103.937}
				transform="translate(349.606)"
				fill="white"
				fillOpacity={0.01}
				className="zone"
				onClick={() =>
					props.handlePathClick &&
					props.handlePathClick(
						79,
						t("Zone79", "Bagparti fører-side top")
					)
				}
			/>
			{props.clickedZones.find((path) => path.zoneId === 79) && (
				<g filter="url(#filter2_d_1811_3524)">
					<rect
						x={422.008}
						y={36.9683}
						width={30}
						height={30}
						rx={15}
						fill="#3c7749"
					/>
					<rect
						x={421.008}
						y={35.9683}
						width={32}
						height={32}
						rx={16}
						stroke="white"
						strokeWidth={2}
					/>
					<path
						d="M445.008 45.9683L434.008 56.9683L429.008 51.9683"
						stroke="white"
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
				</g>
			)}
			<rect
				width={174.803}
				height={103.937}
				transform="translate(0 103.937)"
				fill="white"
				fillOpacity={0.01}
				className="zone"
				onClick={() =>
					props.handlePathClick &&
					props.handlePathClick(
						80,
						t("Zone80", "Forparti fører-side midt")
					)
				}
			/>
			{props.clickedZones.find((path) => path.zoneId === 80) && (
				<g filter="url(#filter3_d_1811_3524)">
					<rect
						x={72.4019}
						y={140.905}
						width={30}
						height={30}
						rx={15}
						fill="#3c7749"
					/>
					<rect
						x={71.4019}
						y={139.905}
						width={32}
						height={32}
						rx={16}
						stroke="white"
						strokeWidth={2}
					/>
					<path
						d="M95.4019 149.905L84.4019 160.905L79.4019 155.905"
						stroke="white"
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
				</g>
			)}
			<rect
				width={174.803}
				height={103.937}
				transform="translate(174.803 103.937)"
				fill="white"
				fillOpacity={0.01}
				className="zone"
				onClick={() =>
					props.handlePathClick &&
					props.handlePathClick(
						81,
						t("Zone81", "Midt fører-side midt")
					)
				}
			/>
			{props.clickedZones.find((path) => path.zoneId === 81) && (
				<g filter="url(#filter4_d_1811_3524)">
					<rect
						x={247.205}
						y={140.905}
						width={30}
						height={30}
						rx={15}
						fill="#3c7749"
					/>
					<rect
						x={246.205}
						y={139.905}
						width={32}
						height={32}
						rx={16}
						stroke="white"
						strokeWidth={2}
					/>
					<path
						d="M270.205 149.905L259.205 160.905L254.205 155.905"
						stroke="white"
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
				</g>
			)}
			<rect
				width={174.803}
				height={103.937}
				transform="translate(349.606 103.937)"
				fill="white"
				fillOpacity={0.01}
				className="zone"
				onClick={() =>
					props.handlePathClick &&
					props.handlePathClick(
						82,
						t("Zone82", "Bagparti fører-side midt")
					)
				}
			/>
			{props.clickedZones.find((path) => path.zoneId === 82) && (
				<g filter="url(#filter5_d_1811_3524)">
					<rect
						x={422.008}
						y={140.905}
						width={30}
						height={30}
						rx={15}
						fill="#3c7749"
					/>
					<rect
						x={421.008}
						y={139.905}
						width={32}
						height={32}
						rx={16}
						stroke="white"
						strokeWidth={2}
					/>
					<path
						d="M445.008 149.905L434.008 160.905L429.008 155.905"
						stroke="white"
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
				</g>
			)}
			<rect
				width={174.803}
				height={103.937}
				transform="translate(0 207.873)"
				fill="white"
				fillOpacity={0.01}
				className="zone"
				onClick={() =>
					props.handlePathClick &&
					props.handlePathClick(
						83,
						t("Zone83", "Forparti fører-side bund")
					)
				}
			/>
			{props.clickedZones.find((path) => path.zoneId === 83) && (
				<g filter="url(#filter6_d_1811_3524)">
					<rect
						x={72.4019}
						y={244.842}
						width={30}
						height={30}
						rx={15}
						fill="#3c7749"
					/>
					<rect
						x={71.4019}
						y={243.842}
						width={32}
						height={32}
						rx={16}
						stroke="white"
						strokeWidth={2}
					/>
					<path
						d="M95.4019 253.842L84.4019 264.842L79.4019 259.842"
						stroke="white"
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
				</g>
			)}
			<rect
				width={174.803}
				height={103.937}
				transform="translate(174.803 207.873)"
				fill="white"
				fillOpacity={0.01}
				className="zone"
				onClick={() =>
					props.handlePathClick &&
					props.handlePathClick(
						84,
						t("Zone84", "Midt fører-side bund")
					)
				}
			/>
			{props.clickedZones.find((path) => path.zoneId === 84) && (
				<g filter="url(#filter7_d_1811_3524)">
					<rect
						x={247.205}
						y={244.842}
						width={30}
						height={30}
						rx={15}
						fill="#3c7749"
					/>
					<rect
						x={246.205}
						y={243.842}
						width={32}
						height={32}
						rx={16}
						stroke="white"
						strokeWidth={2}
					/>
					<path
						d="M270.205 253.842L259.205 264.842L254.205 259.842"
						stroke="white"
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
				</g>
			)}
			<rect
				width={174.803}
				height={103.937}
				transform="translate(349.606 207.873)"
				fill="white"
				fillOpacity={0.01}
				className="zone"
				onClick={() =>
					props.handlePathClick &&
					props.handlePathClick(
						85,
						t("Zone85", "Bagparti fører-side bund")
					)
				}
			/>
			{props.clickedZones.find((path) => path.zoneId === 85) && (
				<g filter="url(#filter8_d_1811_3524)">
					<rect
						x={422.008}
						y={244.842}
						width={30}
						height={30}
						rx={15}
						fill="#3c7749"
					/>
					<rect
						x={421.008}
						y={243.842}
						width={32}
						height={32}
						rx={16}
						stroke="white"
						strokeWidth={2}
					/>
					<path
						d="M445.008 253.842L434.008 264.842L429.008 259.842"
						stroke="white"
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
				</g>
			)}
		</g>
		<defs>
			<filter
				id="filter0_d_1811_3524"
				x={50.4019}
				y={16.9683}
				width={74}
				height={74}
				filterUnits="userSpaceOnUse"
				colorInterpolationFilters="sRGB"
			>
				<feFlood floodOpacity={0} result="BackgroundImageFix" />
				<feColorMatrix
					in="SourceAlpha"
					type="matrix"
					values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
					result="hardAlpha"
				/>
				<feOffset dy={2} />
				<feGaussianBlur stdDeviation={10} />
				<feComposite in2="hardAlpha" operator="out" />
				<feColorMatrix
					type="matrix"
					values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
				/>
				<feBlend
					mode="normal"
					in2="BackgroundImageFix"
					result="effect1_dropShadow_1811_3524"
				/>
				<feBlend
					mode="normal"
					in="SourceGraphic"
					in2="effect1_dropShadow_1811_3524"
					result="shape"
				/>
			</filter>
			<filter
				id="filter1_d_1811_3524"
				x={225.205}
				y={16.9683}
				width={74}
				height={74}
				filterUnits="userSpaceOnUse"
				colorInterpolationFilters="sRGB"
			>
				<feFlood floodOpacity={0} result="BackgroundImageFix" />
				<feColorMatrix
					in="SourceAlpha"
					type="matrix"
					values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
					result="hardAlpha"
				/>
				<feOffset dy={2} />
				<feGaussianBlur stdDeviation={10} />
				<feComposite in2="hardAlpha" operator="out" />
				<feColorMatrix
					type="matrix"
					values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
				/>
				<feBlend
					mode="normal"
					in2="BackgroundImageFix"
					result="effect1_dropShadow_1811_3524"
				/>
				<feBlend
					mode="normal"
					in="SourceGraphic"
					in2="effect1_dropShadow_1811_3524"
					result="shape"
				/>
			</filter>
			<filter
				id="filter2_d_1811_3524"
				x={400.008}
				y={16.9683}
				width={74}
				height={74}
				filterUnits="userSpaceOnUse"
				colorInterpolationFilters="sRGB"
			>
				<feFlood floodOpacity={0} result="BackgroundImageFix" />
				<feColorMatrix
					in="SourceAlpha"
					type="matrix"
					values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
					result="hardAlpha"
				/>
				<feOffset dy={2} />
				<feGaussianBlur stdDeviation={10} />
				<feComposite in2="hardAlpha" operator="out" />
				<feColorMatrix
					type="matrix"
					values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
				/>
				<feBlend
					mode="normal"
					in2="BackgroundImageFix"
					result="effect1_dropShadow_1811_3524"
				/>
				<feBlend
					mode="normal"
					in="SourceGraphic"
					in2="effect1_dropShadow_1811_3524"
					result="shape"
				/>
			</filter>
			<filter
				id="filter3_d_1811_3524"
				x={50.4019}
				y={120.905}
				width={74}
				height={74}
				filterUnits="userSpaceOnUse"
				colorInterpolationFilters="sRGB"
			>
				<feFlood floodOpacity={0} result="BackgroundImageFix" />
				<feColorMatrix
					in="SourceAlpha"
					type="matrix"
					values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
					result="hardAlpha"
				/>
				<feOffset dy={2} />
				<feGaussianBlur stdDeviation={10} />
				<feComposite in2="hardAlpha" operator="out" />
				<feColorMatrix
					type="matrix"
					values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
				/>
				<feBlend
					mode="normal"
					in2="BackgroundImageFix"
					result="effect1_dropShadow_1811_3524"
				/>
				<feBlend
					mode="normal"
					in="SourceGraphic"
					in2="effect1_dropShadow_1811_3524"
					result="shape"
				/>
			</filter>
			<filter
				id="filter4_d_1811_3524"
				x={225.205}
				y={120.905}
				width={74}
				height={74}
				filterUnits="userSpaceOnUse"
				colorInterpolationFilters="sRGB"
			>
				<feFlood floodOpacity={0} result="BackgroundImageFix" />
				<feColorMatrix
					in="SourceAlpha"
					type="matrix"
					values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
					result="hardAlpha"
				/>
				<feOffset dy={2} />
				<feGaussianBlur stdDeviation={10} />
				<feComposite in2="hardAlpha" operator="out" />
				<feColorMatrix
					type="matrix"
					values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
				/>
				<feBlend
					mode="normal"
					in2="BackgroundImageFix"
					result="effect1_dropShadow_1811_3524"
				/>
				<feBlend
					mode="normal"
					in="SourceGraphic"
					in2="effect1_dropShadow_1811_3524"
					result="shape"
				/>
			</filter>
			<filter
				id="filter5_d_1811_3524"
				x={400.008}
				y={120.905}
				width={74}
				height={74}
				filterUnits="userSpaceOnUse"
				colorInterpolationFilters="sRGB"
			>
				<feFlood floodOpacity={0} result="BackgroundImageFix" />
				<feColorMatrix
					in="SourceAlpha"
					type="matrix"
					values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
					result="hardAlpha"
				/>
				<feOffset dy={2} />
				<feGaussianBlur stdDeviation={10} />
				<feComposite in2="hardAlpha" operator="out" />
				<feColorMatrix
					type="matrix"
					values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
				/>
				<feBlend
					mode="normal"
					in2="BackgroundImageFix"
					result="effect1_dropShadow_1811_3524"
				/>
				<feBlend
					mode="normal"
					in="SourceGraphic"
					in2="effect1_dropShadow_1811_3524"
					result="shape"
				/>
			</filter>
			<filter
				id="filter6_d_1811_3524"
				x={50.4019}
				y={224.842}
				width={74}
				height={74}
				filterUnits="userSpaceOnUse"
				colorInterpolationFilters="sRGB"
			>
				<feFlood floodOpacity={0} result="BackgroundImageFix" />
				<feColorMatrix
					in="SourceAlpha"
					type="matrix"
					values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
					result="hardAlpha"
				/>
				<feOffset dy={2} />
				<feGaussianBlur stdDeviation={10} />
				<feComposite in2="hardAlpha" operator="out" />
				<feColorMatrix
					type="matrix"
					values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
				/>
				<feBlend
					mode="normal"
					in2="BackgroundImageFix"
					result="effect1_dropShadow_1811_3524"
				/>
				<feBlend
					mode="normal"
					in="SourceGraphic"
					in2="effect1_dropShadow_1811_3524"
					result="shape"
				/>
			</filter>
			<filter
				id="filter7_d_1811_3524"
				x={225.205}
				y={224.842}
				width={74}
				height={74}
				filterUnits="userSpaceOnUse"
				colorInterpolationFilters="sRGB"
			>
				<feFlood floodOpacity={0} result="BackgroundImageFix" />
				<feColorMatrix
					in="SourceAlpha"
					type="matrix"
					values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
					result="hardAlpha"
				/>
				<feOffset dy={2} />
				<feGaussianBlur stdDeviation={10} />
				<feComposite in2="hardAlpha" operator="out" />
				<feColorMatrix
					type="matrix"
					values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
				/>
				<feBlend
					mode="normal"
					in2="BackgroundImageFix"
					result="effect1_dropShadow_1811_3524"
				/>
				<feBlend
					mode="normal"
					in="SourceGraphic"
					in2="effect1_dropShadow_1811_3524"
					result="shape"
				/>
			</filter>
			<filter
				id="filter8_d_1811_3524"
				x={400.008}
				y={224.842}
				width={74}
				height={74}
				filterUnits="userSpaceOnUse"
				colorInterpolationFilters="sRGB"
			>
				<feFlood floodOpacity={0} result="BackgroundImageFix" />
				<feColorMatrix
					in="SourceAlpha"
					type="matrix"
					values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
					result="hardAlpha"
				/>
				<feOffset dy={2} />
				<feGaussianBlur stdDeviation={10} />
				<feComposite in2="hardAlpha" operator="out" />
				<feColorMatrix
					type="matrix"
					values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
				/>
				<feBlend
					mode="normal"
					in2="BackgroundImageFix"
					result="effect1_dropShadow_1811_3524"
				/>
				<feBlend
					mode="normal"
					in="SourceGraphic"
					in2="effect1_dropShadow_1811_3524"
					result="shape"
				/>
			</filter>
			<clipPath id="clip0_1811_3524">
				<rect width={524.41} height={311.81} fill="white" />
			</clipPath>
		</defs>
	</svg>
);
export default SvgTrailerSideLeft;
