import React, { Suspense } from "react";
import { Switch, Route } from "react-router";
import AuthorizeRoute from "./components/api-authorization/AuthorizeRoute";
import ApiAuthorizationRoutes from "./components/api-authorization/ApiAuthorizationRoutes";
import { ApplicationPaths } from "./components/api-authorization/ApiAuthorizationConstants";
import Layout from "./components/Layout";
import "./style/app.scss";
import "../node_modules/react-datetime/css/react-datetime.css";

import { AppContextProvider } from "./AppContext";
import SuperAdminCompanies from "./pages/superadmin/companies";
import SuperAdminCompaniesSites from "./pages/superadmin/companies/sites";
import SuperAdminCompaniesVehicles from "./pages/superadmin/companies/vehicles";
import SuperAdminCompaniesUsers from "./pages/superadmin/companies/users";
import SuperAdminImport from "pages/superadmin/import";
import AdminCompany from "./pages/admin/company";
import AdminSites from "./pages/admin/sites";
import AdminUsers from "./pages/admin/users";
import AdminVehicles from "./pages/admin/vehicles";
import ReportsIncidents from "./pages/reports/incidents";
import IncidentsFollowup from "./pages/incidents/followup";
import IncidentsList from "./pages/incidents/list";
import IncidentsMine from "./pages/incidents/mine";
import Dashboard from "./pages/dashboard";
import IncidentCreate from "./pages/incidents/create";
import IncidentEdit from "./pages/incidents/edit";
import IncidentSend from "./pages/incidents/send";
import IncidentView from "./pages/incidents/view";
import Diagnostics from "pages/diagnostics";
// import * as pdfjs from "pdfjs-dist/legacy/build/pdf";

// pdfjs.GlobalWorkerOptions.workerSrc = "pdfjs-dist/legacy/build/pdf.worker";
const App = () => {
    return (
        <Suspense fallback={null}>
            <AppContextProvider>
                <Layout>
                    <Switch>
                        <AuthorizeRoute exact path="/" component={Dashboard} />
                        <AuthorizeRoute
                            exact
                            path="/incidents/edit/:id"
                            component={IncidentEdit}
                        />
                        <AuthorizeRoute
                            exact
                            path="/incidents/view/:id"
                            component={IncidentView}
                        />
                        <AuthorizeRoute
                            exact
                            path="/incidents/send/:id"
                            component={IncidentSend}
                        />
                        <AuthorizeRoute
                            exact
                            path="/incidents/create"
                            component={IncidentCreate}
                        />
                        <AuthorizeRoute
                            exact
                            path="/incidents/mine"
                            component={IncidentsMine}
                        />
                        <AuthorizeRoute
                            exact
                            path="/incidents/list"
                            component={IncidentsList}
                        />
                        <AuthorizeRoute
                            exact
                            path="/followup/list"
                            component={IncidentsFollowup}
                        />
                        <AuthorizeRoute
                            exact
                            path="/reports/incidents"
                            component={ReportsIncidents}
                        />
                        <AuthorizeRoute
                            exact
                            path="/superadmin/companies"
                            component={SuperAdminCompanies}
                        />
                        <AuthorizeRoute
                            exact
                            path="/superadmin/companies/:id/users"
                            component={SuperAdminCompaniesUsers}
                        />
                        <AuthorizeRoute
                            exact
                            path="/superadmin/companies/:id/sites"
                            component={SuperAdminCompaniesSites}
                        />
                        <AuthorizeRoute
                            exact
                            path="/superadmin/companies/:id/vehicles"
                            component={SuperAdminCompaniesVehicles}
                        />
                        <AuthorizeRoute
                            exact
                            path="/superadmin/import"
                            component={SuperAdminImport}
                        />
                        <AuthorizeRoute
                            exact
                            path="/admin/company"
                            component={AdminCompany}
                        />
                        <AuthorizeRoute
                            exact
                            path="/admin/company/sites"
                            component={AdminSites}
                        />
                        <AuthorizeRoute
                            exact
                            path="/admin/company/users"
                            component={AdminUsers}
                        />
                        <AuthorizeRoute
                            exact
                            path="/admin/company/vehicles"
                            component={AdminVehicles}
                        />
                        <AuthorizeRoute
                            exact
                            path="/diagnostics"
                            component={Diagnostics}
                        />
                        <Route
                            path={ApplicationPaths.ApiAuthorizationPrefix}
                            component={ApiAuthorizationRoutes}
                        />
                    </Switch>
                </Layout>
            </AppContextProvider>
        </Suspense>
    );
};
export default App;
