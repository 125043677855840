// Skapa, steg1 och steg2, och hantera Skadeinformation
import { FormGroup, Label, Input, Col, Row } from "reactstrap";
import crash from "assets/crash.png";
import theft from "assets/theft.png";
import fire from "assets/fire.png";
import Switch from "components/InputSwitch";
import InputSwitchMultiChoice from "components/InputSwitchMultiChoice";
import HelpDialog from "components/HelpDialog";
import SplitDatetime from "components/SplitDatetime";
import { useFormikContext } from "formik";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
export const StepOneFormItems = ({
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    setFieldValue,
    editMode
}) => {
    const { submitCount, isValid } = useFormikContext();
    const { t } = useTranslation();

    useEffect(() => {
        if (!isValid) {
            const errorElement = document.querySelector("#topOfForm");

            if (errorElement) {
                //console.log("scrolling to element", errorElement);
                errorElement.scrollIntoView({ behavior: "smooth" });
            }
        }
    }, [submitCount, isValid]);

    return (
        <>
            {editMode ? (
                <Row className="mb-3">
                    <Col xs={12}>
                        <FormGroup>
                            <Switch
                                onChange={() =>
                                    setFieldValue("insuranceCategory", "CRASH")
                                }
                                checked={values.insuranceCategory === "CRASH"}
                                label={t("crash")}
                            />
                        </FormGroup>
                        <FormGroup>
                            <Switch
                                onChange={() =>
                                    setFieldValue("insuranceCategory", "THEFT")
                                }
                                checked={values.insuranceCategory === "THEFT"}
                                label={t("theft_or_burglary")}
                            />
                        </FormGroup>
                        <FormGroup>
                            <Switch
                                onChange={() =>
                                    setFieldValue("insuranceCategory", "FIRE")
                                }
                                checked={values.insuranceCategory === "FIRE"}
                                label={t("fire")}
                            />
                        </FormGroup>
                    </Col>
                    <Col xs={12}>
                        {errors.insuranceCategory ? (
                            <div className="invalid-feedback d-block">
                                {errors.insuranceCategory}
                            </div>
                        ) : null}
                    </Col>
                </Row>
            ) : (
                <Row className="mb-3">
                    <Col xs={12} lg={4}>
                        <div
                            className={
                                values.insuranceCategory === "CRASH"
                                    ? "category-button selected"
                                    : "category-button"
                            }
                            color="white"
                            onClick={() =>
                                setFieldValue("insuranceCategory", "CRASH")
                            }
                        >
                            <img src={crash} alt="Krock" />
                            <p>
                                {t(
                                    "collision_traffic_accident_or_damage_to_vehicle"
                                )}
                            </p>
                        </div>
                    </Col>
                    <Col xs={12} lg={4}>
                        <div
                            className={
                                values.insuranceCategory === "THEFT"
                                    ? "category-button selected"
                                    : "category-button"
                            }
                            color="white"
                            onClick={() =>
                                setFieldValue("insuranceCategory", "THEFT")
                            }
                        >
                            <img src={theft} alt="Stöld/Inbrott" />
                            <p>{t("theft_or_burglary")}</p>
                        </div>
                    </Col>
                    <Col xs={12} lg={4}>
                        <div
                            className={
                                values.insuranceCategory === "FIRE"
                                    ? "category-button selected"
                                    : "category-button"
                            }
                            color="white"
                            onClick={() =>
                                setFieldValue("insuranceCategory", "FIRE")
                            }
                        >
                            <img src={fire} alt="Brand" />
                            <p> {t("fire")}</p>
                        </div>
                    </Col>
                    <Col xs={12}>
                        {errors.insuranceCategory ? (
                            <div className="invalid-feedback d-block">
                                {errors.insuranceCategory}
                            </div>
                        ) : null}
                    </Col>
                </Row>
            )}
            {values.insuranceCategory === "CRASH" && (
                <Row>
                    <Col xs={12}>
                        <label>{t("how_did_the_injury_occur")}</label>
                    </Col>
                    <Col sm={4}>
                        <FormGroup>
                            <Switch
                                onChange={() =>
                                    setFieldValue(
                                        "crashCategory",
                                        "Singelolycka"
                                    )
                                }
                                checked={
                                    values.crashCategory === "Singelolycka"
                                }
                                label={t("single_accident")}
                            />
                        </FormGroup>
                        <FormGroup>
                            <Switch
                                onChange={() =>
                                    setFieldValue(
                                        "crashCategory",
                                        "Vilt eller djurolycka"
                                    )
                                }
                                checked={
                                    values.crashCategory ===
                                    "Vilt eller djurolycka"
                                }
                                label={t("wildlife_or_animal_collision")}
                            />
                        </FormGroup>
                        <FormGroup>
                            <Switch
                                onChange={() =>
                                    setFieldValue(
                                        "crashCategory",
                                        "Parkeringsskada"
                                    )
                                }
                                checked={
                                    values.crashCategory === "Parkeringsskada"
                                }
                                label={t("parking_damage")}
                            />
                        </FormGroup>
                        <FormGroup>
                            <Switch
                                onChange={() =>
                                    setFieldValue(
                                        "crashCategory",
                                        "Smitningsolycka"
                                    )
                                }
                                checked={
                                    values.crashCategory === "Smitningsolycka"
                                }
                                label={t("contagion_accident")}
                            />
                        </FormGroup>
                        <FormGroup>
                            <Switch
                                onChange={() =>
                                    setFieldValue(
                                        "crashCategory",
                                        "Motor eller elektronik"
                                    )
                                }
                                checked={
                                    values.crashCategory ===
                                    "Motor eller elektronik"
                                }
                                label={t("engine_or_electronics")}
                            />
                        </FormGroup>
                    </Col>
                    <Col sm={4}>
                        <FormGroup>
                            <Switch
                                onChange={() =>
                                    setFieldValue("crashCategory", "Glasskada")
                                }
                                checked={values.crashCategory === "Glasskada"}
                                label={t("glass_damage")}
                            />
                        </FormGroup>
                        <FormGroup>
                            <Switch
                                onChange={() =>
                                    setFieldValue(
                                        "crashCategory",
                                        "Skadegörelse"
                                    )
                                }
                                checked={
                                    values.crashCategory === "Skadegörelse"
                                }
                                label={t("vandalism")}
                            />
                        </FormGroup>
                        <FormGroup>
                            <Switch
                                onChange={() =>
                                    setFieldValue("crashCategory", "Kollision")
                                }
                                checked={values.crashCategory === "Kollision"}
                                label={t("collision")}
                            />
                        </FormGroup>
                        <FormGroup>
                            <Switch
                                onChange={() =>
                                    setFieldValue("crashCategory", "Annat")
                                }
                                checked={values.crashCategory === "Annat"}
                                label={t("other")}
                            />
                        </FormGroup>
                    </Col>
                </Row>
            )}
            {values.crashCategory === "Kollision" && (
                <Row>
                    <Col xs={12} lg={4}>
                        <FormGroup className="mt-2">
                            <Switch
                                onChange={() =>
                                    setFieldValue(
                                        "isTrafficCollision",
                                        !values.isTrafficCollision
                                    )
                                }
                                checked={values.isTrafficCollision}
                                label={t("was_it_an_encounter_collision")}
                            />
                        </FormGroup>
                    </Col>
                </Row>
            )}
            {values.isTrafficCollision && (
                <Row>
                    <Col sm={6}>
                        <FormGroup>
                            <Label>
                                {t("the_approximate_width_of_the_road")}
                            </Label>
                            <Input
                                className={
                                    errors.estimatedRoadWidth
                                        ? "is-invalid"
                                        : ""
                                }
                                autoComplete="off"
                                name="estimatedRoadWidth"
                                value={values.estimatedRoadWidth}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            ></Input>
                        </FormGroup>
                    </Col>
                </Row>
            )}
            {values.insuranceCategory === "THEFT" && (
                <Row>
                    <Col sm={6}>
                        <FormGroup>
                            <Label>{t("what_has_been_stolen")}</Label>
                            <Input
                                className={
                                    errors.theftItems ? "is-invalid" : ""
                                }
                                autoComplete="off"
                                name="theftItems"
                                value={values.theftItems}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            ></Input>
                        </FormGroup>
                    </Col>
                </Row>
            )}
            {values.insuranceCategory === "FIRE" && (
                <Row>
                    <Col xs={6}>
                        <FormGroup>
                            <Label>{t("type_of_fire")}</Label>
                            <Input
                                className={errors.fireType ? "is-invalid" : ""}
                                autoComplete="off"
                                name="fireType"
                                value={values.fireType}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            ></Input>
                        </FormGroup>
                    </Col>
                </Row>
            )}
            <Row className="border-top border-bottom pt-3 mb-3">
                <Col xs={12} lg={4}>
                    <FormGroup>
                        <Label>{t("where_did_the_injury_occur")} </Label>
                        <InputSwitchMultiChoice
                            options={[
                                t("garage/garage_plan"),
                                t("other_parking_space"),
                                t("public_road"),
                                t("travel_center_or_equivalent"),
                                t("other")
                            ]}
                            selected={values.damageLocationCategory}
                            setSelected={(value) => {
                                setFieldValue("damageLocationCategory", value);
                            }}
                        />
                        {errors.damageLocationCategory ? (
                            <div className="invalid-feedback d-block">
                                {errors.damageLocationCategory}
                            </div>
                        ) : null}
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col xs={12} lg={4}>
                    <FormGroup>
                        <Label>{t("when_did_it_happen")}</Label>
                        <HelpDialog
                            title={t("date_of_incident")}
                            message={t(
                                "enter_the_date_and_time_when_the_incident_occurred"
                            )}
                        />
                        <SplitDatetime
                            onChange={(value) => {
                                setFieldValue("incidentDate", value);
                            }}
                            errors={errors}
                            value={values.incidentDate}
                            name="incidentDate"
                        />
                        {errors.incidentDate ? (
                            <div className="invalid-feedback d-block">
                                {errors.incidentDate}
                            </div>
                        ) : null}
                    </FormGroup>
                </Col>
            </Row>
        </>
    );
};
