import { faQuestionCircle } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { UncontrolledTooltip } from "reactstrap";

function TooltipIcon({ title, text, id }) {
	return (
		<div>
			<p className="mb-0 ms-2" style={{ cursor: "pointer" }}>
				<span id={`TooltipExample${id}`}>
					<FontAwesomeIcon
						color="secondary"
						icon={faQuestionCircle}
					/>
				</span>
			</p>
			<UncontrolledTooltip
				target={`TooltipExample${id}`}
				placement="top"
				autohide={false}
				flip={true}
			>
				<p className="fw-bold mb-1" style={{ fontSize: "14px" }}>
					{title}
				</p>
				<p className="mb-0" style={{ fontSize: "14px" }}>
					{text}
				</p>
			</UncontrolledTooltip>
		</div>
	);
}

export default TooltipIcon;
