import React, { useContext } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAlignLeft } from "@fortawesome/free-solid-svg-icons";
import { Navbar, Button, Nav, NavbarBrand } from "reactstrap";
import { LoginMenu } from "./api-authorization/LoginMenu";
import SelectLang from "./navigation/SelectLang";
import { AppContext } from "AppContext";
import { Link } from "react-router-dom";
import logo from "../assets/Skadestop.svg";

const Topnav = ({ toggleSidebar }) => {
	const appContext = useContext(AppContext);

	return (
		<Navbar
			light
			className="navbar bg-dark shadow-sm py-3 d-flex justify-content-between"
			expand="md"
		>
			<Button
				color="primary"
				className="menu-toggle py-1 px-2"
				onClick={toggleSidebar}
			>
				<FontAwesomeIcon className="m-0" icon={faAlignLeft} />
			</Button>
			<NavbarBrand
				tag={Link}
				to="/"
				className="d-sm-none d-block nav-link p-0 ms-3 me-0"
			>
				<img src={logo} alt="logo" width="125" className="img-fluid" />
			</NavbarBrand>
			<Nav className="ms-auto navbar__nav " navbar>
				<div className="d-flex align-items-center">
					<SelectLang />
					<LoginMenu profileName={appContext.profileName} />
				</div>
			</Nav>
		</Navbar>
	);
};

export default Topnav;
