import React, { useState, useEffect } from "react";
import Autosuggest from "react-autosuggest";
import "./react-autosuggest.css";
import { useTranslation } from "react-i18next";

const SelectVehicle = ({
    vehicles,
    vehicleId,
    setVehicleId,
    className,
    vehiclesStatus
}) => {
    const { t } = useTranslation();
    const [suggestions, setSuggestions] = useState([]);
    const [value, setValue] = useState("");

    useEffect(() => {
        var findVehicle = vehicles.find((item) => item.id === vehicleId);
        if (findVehicle)
            setValue(
                findVehicle.registrationNumber
                    ? findVehicle.registrationNumber
                    : findVehicle.fullName
            );
    }, [vehicleId, vehicles]);

    const vehicleNames = vehicles.map((item) =>
        item.registrationNumber ? item.registrationNumber : item.fullName
    );

    function getSuggestions(value) {
        return vehicleNames.filter(
            (searchStr) =>
                searchStr.startsWith(value.trim()) ||
                searchStr.toLowerCase().startsWith(value.toLowerCase().trim())
        );
    }
    function shouldRenderSuggestions() {
        return true;
    }
    return (
        <div>
            <Autosuggest
                suggestions={suggestions}
                onSuggestionsClearRequested={() => setSuggestions([])}
                onSuggestionsFetchRequested={({ value }) => {
                    setValue(value);
                    setSuggestions(getSuggestions(value));
                }}
                onSuggestionSelected={(_, { suggestionValue }) => {
                    // console.log("Selected: " + suggestionValue);
                    var findVehicle = vehicles.find(
                        (item) =>
                            item.registrationNumber === suggestionValue ||
                            item.fullName === suggestionValue
                    );
                    // console.log('which is', findVehicle);
                    if (findVehicle) {
                        setVehicleId(
                            findVehicle.id,
                            findVehicle.registrationNumber
                                ? findVehicle.registrationNumber
                                : findVehicle.fullName
                        );
                    } else {
                        setVehicleId(null, null);
                    }
                }}
                getSuggestionValue={(suggestion) => suggestion}
                renderSuggestion={(suggestion) => <span>{suggestion}</span>}
                inputProps={{
                    className: className,
                    placeholder:
                        vehiclesStatus === 1
                            ? t(
                                  "loading_list_with_vehicles",
                                  "Loading list with vehicles.."
                              )
                            : vehiclesStatus > 2
                            ? t(
                                  "could_not_load_list",
                                  "Something went wrong! Could not load list"
                              )
                            : t("add_vehicle", "Add vehicle"),
                    value: value,
                    disabled: vehiclesStatus !== 2,
                    onChange: (_, { newValue, method }) => {
                        setValue(newValue);
                    },
                    onBlur: (e) => {
                        var findVehicle = vehicles.find(
                            (item) =>
                                item.registrationNumber === e.target.value ||
                                item.fullName === e.target.value
                        );
                        // console.log('which is', findVehicle);
                        if (findVehicle) {
                            setVehicleId(
                                findVehicle.id,
                                findVehicle.registrationNumber
                                    ? findVehicle.registrationNumber
                                    : findVehicle.fullName
                            );
                        } else {
                            setVehicleId(null, null);
                        }
                    }
                }}
                highlightFirstSuggestion={true}
                shouldRenderSuggestions={shouldRenderSuggestions}
            />
        </div>
    );
};
export default SelectVehicle;
