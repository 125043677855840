import React, { useState } from "react";
import { Formik } from "formik";
import { StepOneFormItems } from "pages/incidents/components/IncidentForm/StepOneFormItems";
import { StepTwoFormItems } from "pages/incidents/components/IncidentForm/StepTwoFormItems";
import { HandleTryggHansaIncidentFormInputs } from "pages/incidents/components/IncidentForm/HandleIncidentFormInputs";
import classnames from "classnames";

import {
    Alert,
    Button,
    Col,
    Form,
    FormGroup,
    Nav,
    NavItem,
    Row,
    NavLink
} from "reactstrap";

import { ArrowRightIcon } from "components/Icons";
import { useTranslation } from "react-i18next";
import { allSchemasCombined } from "./schema";

const TryggHansaEdit = ({
    vehicles,
    vehiclesStatus,
    initialValues,
    onSubmit,
    onSubmitSend
}) => {
    const [tab, setTab] = useState(0);
    let submitAction = "";
    const { t } = useTranslation();
    return (
        <>
            <hr />
            <Formik
                initialValues={initialValues}
                validateOnBlur={false}
                validateOnChange={false}
                onSubmit={(values) => {
                    //console.log(submitAction);
                    if (submitAction === "complete") {
                        // sätt status till Completed
                        values.status = "Completed";
                        // sen spara
                        onSubmit(values);
                    }
                    if (submitAction === "send") {
                        // spara och sen skicka till history.push(incidents/send) vyn
                        onSubmitSend(values);
                    } else {
                        // vanlig spara
                        onSubmit(values);
                    }
                }}
                validationSchema={allSchemasCombined}
            >
                {({
                    errors,
                    values,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    setFieldValue
                }) => (
                    <Form onSubmit={handleSubmit} noValidate>
                        {Object.keys(errors).length > 0 && (
                            <Alert color="danger">
                                {t(
                                    "you_have_not_filled_in_all_required_fields._Questions_marked_with_red_text_must_be_completed"
                                )}
                            </Alert>
                        )}
                        {tab === 1 ? (
                            <>
                                <StepOneFormItems
                                    editMode
                                    values={values}
                                    errors={errors}
                                    touched={touched}
                                    handleBlur={handleBlur}
                                    handleChange={handleChange}
                                    setFieldValue={setFieldValue}
                                />
                                <StepTwoFormItems
                                    insuranceCategory={values.insuranceCategory}
                                    vehiclesStatus={vehiclesStatus}
                                    vehicles={vehicles}
                                    values={values}
                                    errors={errors}
                                    touched={touched}
                                    handleBlur={handleBlur}
                                    handleChange={handleChange}
                                    setFieldValue={setFieldValue}
                                />
                            </>
                        ) : (
                            <>
                                <HandleTryggHansaIncidentFormInputs
                                    values={values}
                                    errors={errors}
                                    touched={touched}
                                    handleBlur={handleBlur}
                                    handleChange={handleChange}
                                    setFieldValue={setFieldValue}
                                />
                            </>
                        )}

                        <FormGroup>
                            <Row>
                                <Col
                                    xs={12}
                                    className="d-flex justify-content-end"
                                >
                                    {values.status.toLowerCase() === "open" && (
                                        <Button
                                            onClick={() => {
                                                submitAction = "send";
                                                handleSubmit();
                                            }}
                                            color="primary"
                                            type="button"
                                            className="me-2"
                                        >
                                            {t("submit")} <ArrowRightIcon />
                                        </Button>
                                    )}
                                    {values.status.toLowerCase() === "sent" && (
                                        <Button
                                            onClick={() => {
                                                submitAction = "complete";
                                                handleSubmit();
                                            }}
                                            color="primary"
                                            type="button"
                                            className="me-2"
                                        >
                                            {t("complete")}
                                        </Button>
                                    )}

                                    <Button
                                        onClick={() => {
                                            submitAction = "save";
                                            handleSubmit();
                                        }}
                                        color="primary"
                                        type="button"
                                    >
                                        {t("save")} <ArrowRightIcon />
                                    </Button>
                                </Col>
                            </Row>
                        </FormGroup>
                    </Form>
                )}
            </Formik>
        </>
    );
};
export default TryggHansaEdit;
