import React, { Component, Fragment } from "react";
import { NavItem, NavLink } from "reactstrap";
import { Link } from "react-router-dom";
import authService from "./AuthorizeService";
import { ApplicationPaths } from "./ApiAuthorizationConstants";
import {
	Dropdown,
	DropdownItem,
	DropdownToggle,
	DropdownMenu
} from "reactstrap";
import { UserIcon } from "components/Icons";
import { Translation } from "react-i18next";

export class LoginMenu extends Component {
	constructor(props) {
		super(props);

		this.state = {
			isAuthenticated: false,
			userName: null,
			profileName: ""
		};
	}

	componentDidMount() {
		this._subscription = authService.subscribe(() => this.populateState());
		this.populateState();
		this.calculateInitials();
	}

	componentWillUnmount() {
		authService.unsubscribe(this._subscription);
	}

	async populateState() {
		const [isAuthenticated, user] = await Promise.all([
			authService.isAuthenticated(),
			authService.getUser()
		]);
		this.setState({
			isAuthenticated,
			userName: user && user.name
		});
	}

	render() {
		const { isAuthenticated, userName } = this.state;
		if (!isAuthenticated) {
			const registerPath = `${ApplicationPaths.Register}`;
			const loginPath = `${ApplicationPaths.Login}`;
			return this.anonymousView(registerPath, loginPath);
		} else {
			const profilePath = `${ApplicationPaths.Profile}`;
			const logoutPath = {
				pathname: `${ApplicationPaths.LogOut}`,
				state: { local: true }
			};
			return this.authenticatedView(userName, profilePath, logoutPath);
		}
	}

	calculateInitials = () => {
		if (this.props.profileName) {
			const firstInitial = this.props.profileName.charAt(0).toUpperCase();
			const lastSpaceIndex = this.props.profileName.lastIndexOf(" ");
			const lastInitial =
				lastSpaceIndex !== -1
					? this.props.profileName
							.charAt(lastSpaceIndex + 1)
							.toUpperCase()
					: "";
			const combinedInitials = firstInitial + lastInitial;
			this.setState({ profileName: combinedInitials });
		}
	};

	authenticatedView(userName, profilePath, logoutPath) {
		const toggleDropdown = () => {
			this.setState({ dropDownOpen: !this.state.dropDownOpen });
		};
		return (
			<Fragment>
				<Dropdown
					className="login-menu"
					nav
					isOpen={this.state.dropDownOpen}
					toggle={toggleDropdown}
				>
					<DropdownToggle nav className="text-white py-0">
						<div className="login-menu__icon-wrapper text-white">
							{this.state.profileName ? (
								this.state.profileName
							) : (
								<UserIcon />
							)}
						</div>
					</DropdownToggle>
					<DropdownMenu className="login-menu__dropdown-menu">
						<DropdownItem>
							<NavLink
								className="text-dark p-0 "
								tag={Link}
								to={logoutPath}
							>
								<Translation>
									{(t) => (
										<div>{t("log_out", "Sign out")}</div>
									)}
								</Translation>{" "}
							</NavLink>
						</DropdownItem>
					</DropdownMenu>
				</Dropdown>
			</Fragment>
		);
	}

	anonymousView(registerPath, loginPath) {
		return (
			<Fragment>
				<NavItem>
					<NavLink
						tag={Link}
						className="text-white p-0"
						to={loginPath}
					>
						<Translation>
							{(t) => <div>{t("login", "Sign in")}</div>}
						</Translation>{" "}
					</NavLink>
				</NavItem>
			</Fragment>
		);
	}
}
