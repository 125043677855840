import SvgCarBag from "../Svgs/SvgCarBag";
import SvgCarFront from "../Svgs/SvgCarFront";
import SvgCarSideLeft from "../Svgs/SvgCarSideLeft";
import SvgLastbilFront from "../Svgs/SvgLastbilFront";
import SvgTrailerBag from "../Svgs/SvgTrailerBag";
import SvgTrailerFront from "../Svgs/SvgTrailerFront";
import SvgCarSideRight from "../Svgs/SvgCarSideRight";
import SvgLastbilSideLeft from "../Svgs/SvgLastbilSideLeft";
import SvgLastbilSideRight from "../Svgs/SvgLastbilSideRight";
import SvgLastbilBag from "../Svgs/SvgLastbilBag";
import SvgTrailerSideLeft from "../Svgs/SvgTrailerSideLeft";
import SvgTrailerSideRight from "../Svgs/SvgTrailerSideRight";
import { getImageByZoneId } from "./getImage";

export function renderSelectedComponent(
	selectedImageId,
	clickedZones,
	handlePathClick
) {
	switch (selectedImageId) {
		case "Illustrationer_bil_side_left":
			return (
				<SvgCarSideLeft
					clickedZones={clickedZones}
					handlePathClick={handlePathClick}
				/>
			);
		case "Illustrationer_bil_front":
			return (
				<SvgCarFront
					clickedZones={clickedZones}
					handlePathClick={handlePathClick}
				/>
			);
		case "Illustrationer_bil_side_right":
			return (
				<SvgCarSideRight
					clickedZones={clickedZones}
					handlePathClick={handlePathClick}
				/>
			);
		case "Illustrationer_bil_bag":
			return (
				<SvgCarBag
					clickedZones={clickedZones}
					handlePathClick={handlePathClick}
				/>
			);
		case "Illustrationer_lastbil_side_left":
			return (
				<SvgLastbilSideLeft
					clickedZones={clickedZones}
					handlePathClick={handlePathClick}
				/>
			);
		case "Illustrationer_lastbil_front":
			return (
				<SvgLastbilFront
					clickedZones={clickedZones}
					handlePathClick={handlePathClick}
				/>
			);

		case "Illustrationer_lastbil_side_right":
			return (
				<SvgLastbilSideRight
					clickedZones={clickedZones}
					handlePathClick={handlePathClick}
				/>
			);

		case "Illustrationer_lastbil_bag":
			return (
				<SvgLastbilBag
					clickedZones={clickedZones}
					handlePathClick={handlePathClick}
				/>
			);

		case "Illustrationer_trailer_side_left":
			return (
				<SvgTrailerSideLeft
					clickedZones={clickedZones}
					handlePathClick={handlePathClick}
				/>
			);
		case "Illustrationer_trailer_front":
			return (
				<SvgTrailerFront
					clickedZones={clickedZones}
					handlePathClick={handlePathClick}
				/>
			);
		case "Illustrationer_trailer_side_right":
			return (
				<SvgTrailerSideRight
					clickedZones={clickedZones}
					handlePathClick={handlePathClick}
				/>
			);
		case "Illustrationer_trailer_bag":
			return (
				<SvgTrailerBag
					clickedZones={clickedZones}
					handlePathClick={handlePathClick}
				/>
			);
		default:
			return null;
	}
}

export function renderSelectedStaticComponent(clickedZones) {
	const selectedImageId = getImageByZoneId(clickedZones[0].zoneId);
	switch (selectedImageId) {
		case "Illustrationer_bil_side_left":
			return <SvgCarSideLeft clickedZones={clickedZones} />;
		case "Illustrationer_bil_front":
			return <SvgCarFront clickedZones={clickedZones} />;
		case "Illustrationer_bil_side_right":
			return <SvgCarSideRight clickedZones={clickedZones} />;
		case "Illustrationer_bil_bag":
			return <SvgCarBag clickedZones={clickedZones} />;
		case "Illustrationer_lastbil_side_left":
			return <SvgLastbilSideLeft clickedZones={clickedZones} />;
		case "Illustrationer_lastbil_front":
			return <SvgLastbilFront clickedZones={clickedZones} />;

		case "Illustrationer_lastbil_side_right":
			return <SvgLastbilSideRight clickedZones={clickedZones} />;

		case "Illustrationer_lastbil_bag":
			return <SvgLastbilBag clickedZones={clickedZones} />;

		case "Illustrationer_trailer_side_left":
			return <SvgTrailerSideLeft clickedZones={clickedZones} />;
		case "Illustrationer_trailer_front":
			return <SvgTrailerFront clickedZones={clickedZones} />;
		case "Illustrationer_trailer_side_right":
			return <SvgTrailerSideRight clickedZones={clickedZones} />;
		case "Illustrationer_trailer_bag":
			return <SvgTrailerBag clickedZones={clickedZones} />;
		default:
			return null;
	}
}
