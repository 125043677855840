import { DeleteIcon } from "components/Icons";
import { getCurrentLang } from "helpers/getCurrentLang";
import moment from "moment";
import React, { useState } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import { useTranslation } from "react-i18next";
import { faGlobe } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
	<a
		href=""
		ref={ref}
		onClick={(e) => {
			e.preventDefault();
			onClick(e);
		}}
	>
		{children}
	</a>
));

const SelectLang = () => {
	const { i18n } = useTranslation();

	const lngs = {
		en: { nativeName: "English", lang: "en-GB" },
		dk: { nativeName: "Dansk", lang: "da-DK" },
		sv: { nativeName: "Svenska", lang: "sv-SE" }
	};

	const [language, setLanguage] = useState(i18n.resolvedLanguage);

	const changeLanguage = (value) => {
		i18n.changeLanguage(value);

		const momentLang = moment().locale(getCurrentLang(value).moment);

		moment.locale(momentLang);

		setLanguage(value);
	};

	return (
		<Dropdown className="me-3 d-flex align-items-center language-dropdown">
			<Dropdown.Toggle
				as={CustomToggle}
				id="dropdown-custom-components"
				className="language-dropdown__toggle"
			>
				<FontAwesomeIcon
					icon={faGlobe}
					className="language-dropdown__icon"
				/>
				<span className="d-none d-sm-block">
					{getCurrentLang(language).name}
				</span>
			</Dropdown.Toggle>

			<Dropdown.Menu className="language-dropdown__menu">
				{Object.keys(lngs).map((lng, index) => (
					<Dropdown.Item
						className="language-dropdown__menu-item"
						key={index}
						eventKey={lng}
						value={lng}
						onClick={() => changeLanguage(lngs[lng].lang)}
					>
						{lngs[lng].nativeName}
					</Dropdown.Item>
				))}
			</Dropdown.Menu>
		</Dropdown>
	);
};

export default SelectLang;
