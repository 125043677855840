import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import {
    Row,
    Col,
    FormGroup,
    Label,
    Input,
    Button,
    ListGroup,
    ListGroupItem
} from "reactstrap";

const CounterPartFormList = ({ items, onAdd, onRemove }) => {
    const { t } = useTranslation();
    const [state, setState] = useState({
        name: "",
        registrationNumber: "",
        personalOrOrganisationNumber: "",
        telephoneNumber: ""
    });

    const updateField = (e) => {
        const type = e.target.type;
        const name = e.target.name;
        const value = type === "checkbox" ? e.target.checked : e.target.value;

        setState({
            ...state,
            [name]: value
        });
    };

    return (
        <FormGroup>
            <Row>
                <Col xs={12}>
                    <Label>{t("contact_details_counterparty")}</Label>
                    <table className="table table-discreet d-none d-sm-table">
                        <thead>
                            <tr>
                                <th>{t("name")}</th>
                                <th>{t("license_plate_number")}</th>
                                <th>{t("personal_or_organization_number")}</th>
                                <th>{t("phone")}</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {items.length === 0 && (
                                <tr>
                                    <td colSpan={5}>
                                        {t(
                                            "fill_in_the_name_registration_number_social_security_number_or_organization_number_and_phone_number_and_click_add"
                                        )}
                                    </td>
                                </tr>
                            )}
                            {items.map((item, key) => {
                                return (
                                    <tr>
                                        <td>{item.name}</td>
                                        <td>{item.registrationNumber}</td>
                                        <td>
                                            {item.personalOrOrganisationNumber}
                                        </td>
                                        <td>{item.telephoneNumber}</td>
                                        <td>
                                            <Button
                                                color="white"
                                                onClick={() => {
                                                    onRemove(item.name);
                                                }}
                                            >
                                                {t("delete")}
                                            </Button>
                                        </td>
                                    </tr>
                                );
                            })}
                            <tr className="table-active">
                                <td>
                                    <Input
                                        placeholder={t("name")}
                                        autoComplete="off"
                                        name="name"
                                        value={state.name}
                                        onChange={updateField}
                                    ></Input>
                                </td>
                                <td>
                                    <Input
                                        placeholder={t("license_plate_number")}
                                        autoComplete="off"
                                        name="registrationNumber"
                                        value={state.registrationNumber}
                                        onChange={updateField}
                                    ></Input>
                                </td>
                                <td>
                                    <Input
                                        autoComplete="off"
                                        placeholder={t(
                                            "personal_or_organization_number"
                                        )}
                                        name="personalOrOrganisationNumber"
                                        value={
                                            state.personalOrOrganisationNumber
                                        }
                                        onChange={updateField}
                                    ></Input>
                                </td>
                                <td>
                                    <Input
                                        placeholder={t("phone")}
                                        autoComplete="off"
                                        name="telephoneNumber"
                                        value={state.telephoneNumber}
                                        onChange={updateField}
                                    ></Input>
                                </td>
                                <td>
                                    <Button
                                        color="primary"
                                        onClick={() => {
                                            onAdd({
                                                name: state.name,
                                                personalOrOrganisationNumber:
                                                    state.personalOrOrganisationNumber,
                                                registrationNumber:
                                                    state.registrationNumber,
                                                telephoneNumber:
                                                    state.telephoneNumber
                                            });
                                            setState({
                                                name: "",
                                                registrationNumber: "",
                                                telephoneNumber: "",
                                                personalOrOrganisationNumber: ""
                                            });
                                        }}
                                        type="button"
                                    >
                                        {t("add")}
                                    </Button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div className="d-block d-sm-none">
                        <ListGroup>
                            {items.length === 0 && (
                                <ListGroupItem>
                                    {" "}
                                    {t(
                                        "fill_in_the_name_registration_number_social_security_number_or_organization_number_and_phone_number_and_click_add"
                                    )}
                                </ListGroupItem>
                            )}
                            {items.map((item, key) => {
                                return (
                                    <ListGroupItem>
                                        <div className="small-label">
                                            {t("name")}
                                        </div>
                                        <h1>{item.name}</h1>
                                        <div className="small-label">
                                            {t("license_plate_number")}
                                        </div>
                                        <div>{item.registrationNumber}</div>
                                        <div className="small-label">
                                            {t(
                                                "personal_or_organization_number"
                                            )}
                                        </div>
                                        <div>
                                            {item.personalOrOrganisationNumber}
                                        </div>
                                        <div className="small-label">
                                            {t("phone")}
                                        </div>
                                        <div>{item.telephoneNumber}</div>
                                        <div>
                                            <Button
                                                block
                                                color="white"
                                                onClick={() => {
                                                    onRemove(item.name);
                                                }}
                                            >
                                                {t("delete")}
                                            </Button>
                                        </div>
                                    </ListGroupItem>
                                );
                            })}
                            <ListGroupItem>
                                {items.length > 0 ? (
                                    <Label>{t("add_another_contact")}</Label>
                                ) : (
                                    <Label>
                                        {t("add")} {t("contact")}
                                    </Label>
                                )}

                                <FormGroup>
                                    <Input
                                        placeholder={t("name")}
                                        autoComplete="off"
                                        name="name"
                                        value={state.name}
                                        onChange={updateField}
                                    ></Input>
                                </FormGroup>
                                <FormGroup>
                                    <Input
                                        placeholder={t("license_plate_number")}
                                        autoComplete="off"
                                        name="registrationNumber"
                                        value={state.registrationNumber}
                                        onChange={updateField}
                                    ></Input>
                                </FormGroup>
                                <FormGroup>
                                    <Input
                                        autoComplete="off"
                                        placeholder={t(
                                            "personal_or_organization_number"
                                        )}
                                        name="personalOrOrganisationNumber"
                                        value={
                                            state.personalOrOrganisationNumber
                                        }
                                        onChange={updateField}
                                    ></Input>
                                </FormGroup>
                                <FormGroup>
                                    <Input
                                        placeholder={t("phone")}
                                        autoComplete="off"
                                        name="telephoneNumber"
                                        value={state.telephoneNumber}
                                        onChange={updateField}
                                    ></Input>
                                </FormGroup>
                                <FormGroup>
                                    <Button
                                        color="primary"
                                        block
                                        onClick={() => {
                                            onAdd({
                                                name: state.name,
                                                personalOrOrganisationNumber:
                                                    state.personalOrOrganisationNumber,
                                                registrationNumber:
                                                    state.registrationNumber,
                                                telephoneNumber:
                                                    state.telephoneNumber
                                            });
                                            setState({
                                                name: "",
                                                registrationNumber: "",
                                                telephoneNumber: "",
                                                personalOrOrganisationNumber: ""
                                            });
                                        }}
                                        type="button"
                                    >
                                        {t("add")}
                                    </Button>
                                </FormGroup>
                            </ListGroupItem>
                        </ListGroup>
                    </div>
                </Col>
            </Row>
        </FormGroup>
    );
};

export default CounterPartFormList;
