import React, { Fragment, useContext } from "react";
import { NavLink } from "react-router-dom";
import { ApplicationPaths } from "./ApiAuthorizationConstants";
import { NavItem } from "reactstrap";
import { AppContext } from "../../AppContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faAnalytics,
    faCogs,
    faFileSignature,
    faHomeAlt,
    faListAlt,
    faTasks
} from "@fortawesome/pro-light-svg-icons";
import { useTranslation } from "react-i18next";

const DashboardItem = ({ onItemClick }) => {
    const { t } = useTranslation();

    return (
        <NavItem>
            <NavLink
                exact
                to="/"
                activeClassName="active"
                className="nav-link"
                onClick={onItemClick}
            >
                <FontAwesomeIcon icon={faHomeAlt} className="me-2" />
                {t("dashboard")}
            </NavLink>
        </NavItem>
    );
};

const ReportDamageItem = ({ onItemClick }) => {
    const { t } = useTranslation();

    return (
        <NavItem>
            <NavLink
                to="/incidents/create"
                activeClassName="active"
                className="nav-link"
                onClick={onItemClick}
            >
                <FontAwesomeIcon icon={faFileSignature} className="me-2" />
                {t("report_damage")}
            </NavLink>
        </NavItem>
    );
};

const ReportItem = ({ onItemClick }) => {
    const { t } = useTranslation();

    return (
        <>
            {
                <NavItem>
                    <NavLink
                        to="/reports/incidents"
                        activeClassName="active"
                        className="nav-link"
                        onClick={onItemClick}
                    >
                        <FontAwesomeIcon icon={faAnalytics} className="me-2" />
                        {t("reports")}
                    </NavLink>
                </NavItem>
            }
        </>
    );
};

const ManageCaseItem = ({ onItemClick }) => {
    const { t } = useTranslation();

    return (
        <>
            <NavItem>
                <NavLink
                    to="/incidents/list"
                    activeClassName="active"
                    className="nav-link"
                    onClick={onItemClick}
                >
                    <FontAwesomeIcon icon={faFileSignature} className="me-2" />
                    {t("manage_cases")}
                </NavLink>
            </NavItem>
        </>
    );
};
const MyInsuranceItem = ({ onItemClick }) => {
    const { t } = useTranslation();
    return (
        <>
            <NavItem>
                <NavLink
                    to="/incidents/mine"
                    activeClassName="active"
                    className="nav-link"
                    onClick={onItemClick}
                >
                    <FontAwesomeIcon icon={faListAlt} className="me-2" />
                    {t("my_insurance_claims")}
                </NavLink>
            </NavItem>
        </>
    );
};

const MyInsuranceStatisticItem = ({ onItemClick }) => {
    const { t } = useTranslation();
    return (
        <NavItem>
            <NavLink
                to="/followup/list"
                activeClassName="active"
                className="nav-link"
                onClick={onItemClick}
            >
                <FontAwesomeIcon icon={faTasks} className="me-2" />
                {t("follow_up")}
            </NavLink>
        </NavItem>
    );
};

const AdminItem = ({ onItemClick }) => {
    const { t } = useTranslation();
    return (
        <NavItem>
            <NavLink
                to="/admin/company"
                activeClassName="active"
                className="nav-link"
                onClick={onItemClick}
            >
                <FontAwesomeIcon icon={faCogs} className="me-2" />
                {t("settings")}
            </NavLink>
        </NavItem>
    );
};

const SuperAdminItems = ({ onItemClick }) => {
    const { t } = useTranslation();
    const authContext = useContext(AppContext);

    return (
        <>
            <NavItem>
                <NavLink
                    to="/superadmin/companies"
                    activeClassName="active"
                    className="nav-link"
                    onClick={onItemClick}
                >
                    <FontAwesomeIcon icon={faCogs} className="me-2" />
                    {t("company", "Company")}
                </NavLink>
            </NavItem>
            {authContext.userRoles.indexOf("SuperAdmin") !== -1 && (
                <NavItem>
                    <NavLink
                        to="/superadmin/import"
                        activeClassName="active"
                        className="nav-link"
                        onClick={onItemClick}
                    >
                        <FontAwesomeIcon
                            icon={faFileSignature}
                            className="me-2"
                        />
                        {t("import", "Import")}
                    </NavLink>
                </NavItem>
            )}
        </>
    );
};

const ReporterMenu = ({ onItemClick }) => {
    return (
        <>
            <ReportDamageItem onItemClick={onItemClick} />
            <MyInsuranceItem onItemClick={onItemClick} />
        </>
    );
};

const HandlerMenu = ({ onItemClick }) => {
    return (
        <>
            <ManageCaseItem onItemClick={onItemClick} />
            <ReportItem onItemClick={onItemClick} />
            <ReportDamageItem onItemClick={onItemClick} />
            <MyInsuranceItem onItemClick={onItemClick} />
        </>
    );
};

const AdminMenu = ({ onItemClick }) => {
    return (
        <>
            <AdminItem onItemClick={onItemClick} />
        </>
    );
};

const SuperAdminMenu = ({ onItemClick }) => {
    return (
        <>
            <SuperAdminItems onItemClick={onItemClick} />
        </>
    );
};

export const AuthMenuItems = (props) => {
    const { t } = useTranslation();
    const authContext = useContext(AppContext);
    const loginPath = `${ApplicationPaths.Login}`;
    console.log(authContext.userRoles);
    return authContext.isAuth ? (
        <Fragment>
            <DashboardItem onItemClick={props.onItemClick} />
            {authContext.userRoles.indexOf("IncidentReporter") !== -1 &&
                authContext.userRoles.indexOf("IncidentHandler") === -1 && (
                    <ReporterMenu onItemClick={props.onItemClick} />
                )}
            {authContext.userRoles.indexOf("IncidentHandler") !== -1 && (
                <HandlerMenu onItemClick={props.onItemClick} />
            )}

            {authContext.userRoles.indexOf("CompanyAdmin") !== -1 && (
                <AdminMenu onItemClick={props.onItemClick} />
            )}
            {(authContext.userRoles.indexOf("SuperAdmin") !== -1 ||
                authContext.userRoles.indexOf("Partner") !== -1) && (
                <SuperAdminMenu onItemClick={props.onItemClick} />
            )}
        </Fragment>
    ) : (
        <Fragment>
            <NavItem>
                <NavLink className="text-dark" to={loginPath}>
                    {t("login", "Sign in")}
                </NavLink>
            </NavItem>
        </Fragment>
    );
};
