import React, { useState } from "react";
import classNames from "classnames";
import { Container } from "reactstrap";
import Sidebar from "./Sidebar";
import Topnav from "./Topnav";

const Layout = ({ buildNumber, children }) => {
	const [sidebarIsOpen, setSidebarOpen] = useState(false);
	const toggleSidebar = () => setSidebarOpen(!sidebarIsOpen);

	return (
		<div>
			<div className="App wrapper">
				<Sidebar toggle={toggleSidebar} isOpen={sidebarIsOpen} />
				<div
					className={classNames("content", {
						"is-open": sidebarIsOpen
					})}
				>
					<Topnav toggleSidebar={toggleSidebar} />
					<Container fluid>
						{children}{" "}
						{/* <div className="buildnumber">build: {buildNumber}</div> */}
					</Container>
				</div>
			</div>
		</div>
	);
};
export default Layout;
