import { t } from "i18next";
import * as React from "react";
const SvgCarSideRight = (props) => (
	<svg
		width={468}
		height={171}
		viewBox="0 0 468 171"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
		className="svg-component"
	>
		<g clipPath="url(#clip0_1811_2559)">
			<path
				d="M442.41 89.4602L445.41 97.4602H447.41C443.31 92.1602 445.41 89.4602 445.41 89.4602H442.41Z"
				fill="#989898"
			/>
			<path
				d="M447.41 97.4602C448.41 92.4602 445.41 89.4602 445.41 89.4602C445.41 89.4602 443.31 92.1602 447.41 97.4602Z"
				fill="white"
			/>
			<path
				d="M129.41 27.86L125.41 24.26C83.8101 34.76 67.8101 50.06 67.8101 50.06L77.4101 51.86C99.3501 35.32 129.41 27.86 129.41 27.86Z"
				fill="#6F7B93"
				stroke="black"
				strokeMiterlimit={10}
			/>
			<path
				d="M315.41 60.8601C315.41 60.8601 318.41 62.8601 322.41 61.8601C326.41 60.8601 328.81 60.0601 328.81 60.0601C297.41 42.8601 281.41 32.0601 263.21 25.3601L257.41 27.8601C257.41 27.8601 281.51 33.1301 315.41 60.8601Z"
				fill="#6F7B93"
				stroke="black"
				strokeMiterlimit={10}
			/>
			<path
				d="M33.9099 53.0601C33.6099 52.9601 33.3099 53.0601 33.1099 53.3601C34.9099 53.7601 37.3099 54.5601 40.2099 56.0601C40.4099 55.9601 40.5099 55.8601 40.5099 55.8601L77.5099 51.8601L67.9099 50.0601L33.9099 53.0601Z"
				fill="#EDEDED"
			/>
			<path
				d="M25.9102 65.0601L28.2102 65.3601L32.2102 64.8601C35.0102 59.7601 39.1102 56.7601 40.2102 56.0601C37.3102 54.6601 34.9102 53.7601 33.1102 53.3601C30.1102 55.0601 25.9102 65.0601 25.9102 65.0601Z"
				fill="white"
			/>
			<path
				d="M24.3101 81.9601V89.8601C24.3101 89.8601 28.4101 85.8601 29.4101 73.8601C29.7101 70.3601 30.7101 67.3601 32.1101 64.8601L28.1101 65.3601C28.1101 65.3601 24.4101 81.8601 24.3101 81.9601Z"
				fill="#EDEDED"
			/>
			<path
				d="M42.9103 77.26L43.4103 73.76H29.5103V73.86C29.4103 75.26 29.2103 76.56 29.0103 77.76L42.9103 77.26Z"
				fill="white"
			/>
			<path
				d="M205.41 18.8601C249.41 17.8601 257.41 27.8601 257.41 27.8601L263.21 25.3601C245.41 18.7601 241.41 16.0601 202.81 15.0601C175.11 14.2601 148.31 18.5601 125.41 24.2601L129.41 27.8601C129.41 27.8601 161.41 19.8601 205.41 18.8601Z"
				fill="#EDEDED"
			/>
			<path
				d="M18.0103 96.9602L76.5103 103.26C76.8103 102.76 77.1103 102.26 77.4103 101.86C70.6103 101.06 72.9103 99.5602 62.5103 97.7602C45.5103 94.7602 53.0103 78.9602 53.0103 78.9602C52.5103 79.3602 51.8103 79.7602 50.5103 79.7602C48.0103 79.7602 33.7103 80.4602 28.4103 80.6602C26.8103 87.3602 24.4103 89.7602 24.4103 89.7602L18.0103 96.9602Z"
				fill="#EDEDED"
			/>
			<path
				d="M76.4102 103.36L17.9102 97.0601L19.4102 111.06C21.8102 125.06 35.0102 124.46 35.0102 124.46L70.9102 131.26V117.06C72.0102 111.56 74.0102 107.06 76.4102 103.36Z"
				fill="#D1D1D1"
			/>
			<path
				d="M295.91 50.76L290.41 63.26L307.41 63.86C307.41 63.86 304.31 56.96 295.91 50.76Z"
				fill="#262626"
			/>
			<path
				d="M295.91 50.7601C280.11 38.8601 264.11 23.6601 215.91 22.8601L228.71 60.6601L290.41 63.2601L295.91 50.7601Z"
				fill="#6F7B93"
			/>
			<path
				d="M149.41 29.1602C147.11 29.6602 144.81 30.1602 142.61 30.7602L126.41 57.3602L134.61 57.6602L149.41 29.1602Z"
				fill="black"
			/>
			<path
				d="M89.0103 51.86C80.6103 55.86 87.1103 56.06 87.0103 56.06L126.31 57.36L142.51 30.76C106.91 39.26 89.0103 51.86 89.0103 51.86Z"
				fill="#6F7B93"
			/>
			<path
				d="M149.41 29.0601L134.61 57.5601L213.01 60.1601L208.61 22.8601C185.61 23.1601 165.81 25.6601 149.41 29.0601Z"
				fill="#6F7B93"
			/>
			<path
				d="M208.51 22.8601L212.91 60.1601L228.71 60.6601L215.91 22.8601H212.41C211.21 22.8601 209.91 22.8601 208.51 22.8601Z"
				fill="black"
			/>
			<path
				d="M284.31 58.2602C290.21 58.6602 294.41 59.5602 296.61 60.1602L297.51 58.2602C297.51 58.2602 297.51 50.7602 290.31 49.3602C283.01 47.8602 281.51 52.7602 281.51 52.7602V59.7602L284.31 58.2602Z"
				fill="white"
			/>
			<path
				d="M281.51 59.7603V60.3603C286.61 62.9603 295.11 63.3603 295.11 63.3603L296.61 60.1603C294.41 59.5603 290.21 58.5603 284.31 58.2603C284.31 58.3603 281.51 59.7603 281.51 59.7603Z"
				fill="#E1E1E1"
			/>
			<path
				d="M69.8102 112.06L76.8102 99.2601L69.9102 82.3601H148.91V133.36L67.9102 130.36L69.8102 112.06Z"
				fill="#262626"
			/>
			<path
				d="M400.41 82.8601H315.41V133.86H400.41V82.8601Z"
				fill="#262626"
			/>
			<path
				d="M212.41 23.8601C251.41 23.8601 265.91 31.6601 282.41 40.8601C300.31 50.8601 306.51 64.3601 306.61 64.4601L306.81 64.7601L307.11 64.8601C311.31 65.7601 312.61 71.9601 312.71 72.4601C315.71 102.86 309.81 123.86 308.61 127.86C297.51 127.66 182.61 125.76 168.41 124.86C154.31 123.96 151.41 111.16 151.31 110.66V110.56L151.21 110.46C150.41 109.06 131.11 74.3601 127.11 68.1601C126.11 66.5601 125.41 65.1601 125.01 63.9601C124.51 62.3601 124.81 61.7601 124.91 61.5601L125.11 61.4601V61.3601L127.21 57.9601L128.11 56.4601L126.41 56.3601L87.1099 55.0601C86.7099 55.0601 86.2099 54.9601 85.9099 54.8601C86.2099 54.5601 87.1099 53.8601 89.4099 52.7601H89.5099L89.6099 52.6601C89.7099 52.5601 100.11 45.3601 120.41 38.2601C139.11 31.6601 170.21 23.8601 212.41 23.8601ZM212.41 22.8601C130.41 22.8601 89.0099 51.8601 89.1099 51.8601C80.7099 55.8601 87.1099 56.0601 87.1099 56.0601L126.41 57.3601L124.31 60.7601C124.31 60.7601 123.31 61.5601 124.11 64.1601C124.41 65.2601 125.11 66.6601 126.31 68.5601C130.41 74.8601 150.41 110.86 150.41 110.86C150.41 110.86 153.41 124.86 168.41 125.86C183.41 126.86 309.41 128.86 309.41 128.86C309.41 128.86 317.21 106.66 313.81 72.2601C313.81 72.2601 312.41 64.8601 307.41 63.8601C307.41 63.8601 288.41 22.8601 212.41 22.8601Z"
				fill="black"
			/>
			<path
				d="M32.8101 63.6602L56.3101 64.7602L40.1101 55.9602C39.1101 56.6602 35.6101 59.2602 32.8101 63.6602Z"
				fill="#E1E1E1"
			/>
			<path
				d="M429.01 89.5601C431.21 89.7601 433.01 89.9601 434.51 90.2601C433.01 90.0601 431.21 89.7601 429.01 89.5601Z"
				fill="black"
			/>
			<path
				d="M449.91 128.86H434.51C430.01 128.86 426.11 132.26 424.41 133.96L438.41 134.56L449.01 129.76C449.41 129.56 449.71 129.26 449.91 128.86Z"
				fill="#989898"
			/>
			<path
				d="M56.41 64.7602C56.41 64.7602 58.41 64.8602 57.41 66.7602C57.41 66.7602 53.81 65.3602 124.11 64.0602C123.31 61.4602 124.31 60.6602 124.31 60.6602L126.41 57.2602L87.11 55.9602H40.21L56.41 64.7602Z"
				fill="#EDEDED"
			/>
			<path
				d="M402.41 81.8601C402.51 81.8601 398.51 81.8601 400.41 83.6601C401.21 84.4601 402.21 86.0601 403.21 87.7601C404.11 87.7601 405.01 87.8601 405.91 87.8601H406.61C407.51 87.8601 408.41 87.9601 409.21 87.9601H409.31C410.11 87.9601 410.91 88.0601 411.71 88.0601H412.41C413.21 88.0601 413.91 88.1601 414.71 88.1601H415.11C415.71 88.1601 416.31 88.2601 416.91 88.2601C417.11 88.3601 417.41 88.3601 417.61 88.3601C418.21 88.3601 418.91 88.3601 419.51 88.4601C419.81 88.5601 420.01 88.5601 420.21 88.5601C420.71 88.5601 421.11 88.6601 421.51 88.6601C421.81 88.6601 422.11 88.7601 422.41 88.7601C422.81 88.7601 423.11 88.8601 423.51 88.8601C424.01 88.8601 424.51 88.8601 425.01 88.9601C425.21 89.0601 425.41 89.0601 425.61 89.0601C426.91 89.2601 428.01 89.3601 429.11 89.4601C431.31 89.7601 433.11 89.9601 434.61 90.1601C434.81 90.1601 434.91 90.2601 435.11 90.2601C435.21 90.3601 435.31 90.3601 435.41 90.3601C435.71 90.3601 436.01 90.4601 436.21 90.4601C433.61 86.6601 428.11 81.8601 428.11 81.8601H402.41Z"
				fill="#F7F7F7"
			/>
			<path
				d="M429.01 89.5601C429.91 91.2601 430.71 93.0601 431.21 94.9601C433.61 95.1601 434.51 95.3601 434.51 95.3601C434.51 95.3601 437.51 96.9601 437.51 93.6601C437.61 92.8601 437.01 91.7601 436.21 90.5601C436.01 90.5601 435.71 90.4601 435.41 90.4601C435.21 90.3601 435.11 90.3601 435.01 90.3601C434.81 90.3601 434.71 90.2601 434.51 90.2601C433.01 90.0601 431.21 89.7601 429.01 89.5601Z"
				fill="#7F7F7F"
			/>
			<path
				d="M406.61 87.9602C406.41 87.9602 406.21 87.9602 406.11 88.0602C405.21 87.9602 404.31 87.9602 403.41 87.9602C405.11 90.7602 406.61 93.8602 406.61 93.8602C420.11 93.8602 427.51 94.3602 431.31 94.7602C430.91 93.1602 430.11 91.3602 429.11 89.5602C428.01 89.3602 426.81 89.2602 425.61 89.1602C425.41 89.0602 425.21 89.0602 425.01 89.0602C424.51 89.0602 424.01 88.9602 423.51 88.9602C423.11 88.9602 422.81 88.8602 422.41 88.8602C422.11 88.8602 421.81 88.7602 421.51 88.7602C421.01 88.7602 420.61 88.6602 420.21 88.6602C420.01 88.5602 419.71 88.5602 419.51 88.5602C418.91 88.5602 418.31 88.4602 417.61 88.4602C417.41 88.3602 417.11 88.3602 416.91 88.3602C416.31 88.3602 415.71 88.2602 415.11 88.2602H414.71C413.91 88.2602 413.21 88.1602 412.41 88.1602H411.71C410.91 88.1602 410.11 88.0602 409.31 88.0602H409.21C408.31 88.0602 407.51 87.9602 406.61 87.9602Z"
				fill="#989898"
			/>
			<path
				d="M446.71 114.26L412.91 112.76L413.71 115.56L414.41 115.26L441.61 116.46L447.01 116.66C446.91 115.86 446.81 115.06 446.71 114.26Z"
				fill="#E1E1E1"
			/>
			<path
				d="M441.51 116.36L414.31 115.16L413.61 115.46L414.31 117.86H424.41V121.86H415.41L415.81 123.46C415.81 123.46 416.31 125.76 422.81 126.16C426.81 126.36 434.91 126.96 440.51 127.36C441.91 126.26 441.51 124.66 441.51 124.66V116.36Z"
				fill="#767676"
			/>
			<path
				d="M424.41 117.86H414.31L415.41 121.86H424.41V117.86Z"
				fill="#989898"
			/>
			<path
				d="M441.51 124.66C441.51 124.66 442.01 126.26 440.51 127.26C444.01 127.56 446.61 127.76 446.61 127.76C446.61 127.76 447.51 122.46 446.91 116.56L441.51 116.36V124.66Z"
				fill="#989898"
			/>
			<path
				d="M225.11 119.16C225.01 119.46 225.01 119.86 224.91 120.26L223.21 127.26C268.81 128.06 309.51 128.86 309.51 128.86C309.51 128.86 310.31 126.56 311.31 122.26C298.81 122.06 270.31 121.16 225.11 119.16Z"
				fill="url(#paint0_linear_1811_2559)"
			/>
			<path
				d="M222.51 68.0601C223.01 71.7601 227.01 103.16 225.01 119.16C270.31 121.16 298.81 122.06 311.21 122.26C313.21 113.26 316.01 95.6601 313.81 72.3601L222.51 68.0601Z"
				fill="url(#paint1_linear_1811_2559)"
			/>
			<path
				d="M222.51 67.6601C222.51 67.7601 222.51 67.8601 222.61 68.0601L313.91 72.3601V72.1601C313.91 72.1601 312.51 64.7601 307.51 63.7601L219.81 60.3601L222.51 67.6601Z"
				fill="url(#paint2_linear_1811_2559)"
			/>
			<path
				d="M124.31 60.66C124.31 60.66 123.31 61.46 124.11 64.06L222.51 68.06V67.66L219.81 60.36L126.41 57.26L124.31 60.66Z"
				fill="url(#paint3_linear_1811_2559)"
			/>
			<path
				d="M224.91 120.26C225.01 119.86 225.01 119.56 225.21 119.16C198.91 118.06 172.51 116.56 151.91 114.56C153.91 118.76 158.51 125.16 168.51 125.86C174.01 126.26 196.71 126.76 223.21 127.26L224.91 120.26Z"
				fill="url(#paint4_linear_1811_2559)"
			/>
			<path
				d="M225.11 119.16C227.11 103.16 223.01 71.7599 222.51 68.1599L124.11 64.1599C124.41 65.2599 125.11 66.6599 126.31 68.5599C130.41 74.8599 150.41 110.86 150.41 110.86C150.41 110.86 150.81 112.46 151.81 114.56C172.41 116.46 198.91 118.06 225.11 119.16Z"
				fill="url(#paint5_linear_1811_2559)"
			/>
			<path
				d="M324.41 73.8601H318.41V77.8601H324.41V73.8601Z"
				fill="#FB8E36"
			/>
			<path
				d="M420.91 92.8601C421.739 92.8601 422.41 90.8454 422.41 88.3601C422.41 85.8748 421.739 83.8601 420.91 83.8601C420.082 83.8601 419.41 85.8748 419.41 88.3601C419.41 90.8454 420.082 92.8601 420.91 92.8601Z"
				fill="white"
			/>
			<path
				d="M413.91 92.8601C414.739 92.8601 415.41 90.8454 415.41 88.3601C415.41 85.8748 414.739 83.8601 413.91 83.8601C413.082 83.8601 412.41 85.8748 412.41 88.3601C412.41 90.8454 413.082 92.8601 413.91 92.8601Z"
				fill="white"
			/>
			<path
				d="M426.91 125.86C429.395 125.86 431.41 123.845 431.41 121.36C431.41 118.875 429.395 116.86 426.91 116.86C424.425 116.86 422.41 118.875 422.41 121.36C422.41 123.845 424.425 125.86 426.91 125.86Z"
				fill="#989898"
			/>
			<path
				d="M445.41 119.86H431.91V120.86H445.41V119.86Z"
				fill="#E1E1E1"
			/>
			<path
				d="M445.41 122.86H431.91V123.86H445.41V122.86Z"
				fill="#E1E1E1"
			/>
			<path
				d="M428.91 125.86C431.395 125.86 433.41 123.845 433.41 121.36C433.41 118.875 431.395 116.86 428.91 116.86C426.425 116.86 424.41 118.875 424.41 121.36C424.41 123.845 426.425 125.86 428.91 125.86Z"
				fill="#989898"
			/>
			<path
				d="M427.91 124.66C428.21 124.76 428.61 124.86 428.91 124.86C430.81 124.86 432.41 123.26 432.41 121.36C432.41 119.46 430.81 117.86 428.91 117.86C428.61 117.86 428.21 117.96 427.91 118.06C429.31 118.46 430.41 119.76 430.41 121.36C430.41 122.96 429.41 124.26 427.91 124.66Z"
				fill="#EDEDED"
			/>
			<path
				d="M427.91 124.66C429.31 124.26 430.41 122.86 430.41 121.36C430.41 119.86 429.31 118.46 427.91 118.06C426.51 118.46 425.41 119.76 425.41 121.36C425.41 122.96 426.51 124.26 427.91 124.66Z"
				fill="white"
			/>
			<path
				d="M361.91 155.86C379.859 155.86 394.41 141.309 394.41 123.36C394.41 105.411 379.859 90.8601 361.91 90.8601C343.961 90.8601 329.41 105.411 329.41 123.36C329.41 141.309 343.961 155.86 361.91 155.86Z"
				fill="#414141"
			/>
			<path
				d="M361.41 102.86C362.71 102.86 363.91 102.96 365.01 103.16C365.11 101.96 365.21 100.96 365.31 100.16C364.01 99.9601 362.71 99.8601 361.41 99.8601C359.61 99.8601 357.81 100.06 356.11 100.46L356.71 103.36C358.21 103.06 359.81 102.86 361.41 102.86Z"
				fill="#E4E4E4"
			/>
			<path
				d="M365.11 103.16C368.61 103.76 371.81 105.26 374.61 107.36L376.51 105.06C373.31 102.56 369.61 100.86 365.41 100.16C365.31 100.96 365.21 101.96 365.11 103.16Z"
				fill="#CDCDCD"
			/>
			<path
				d="M374.51 107.46C376.71 109.16 378.51 111.26 379.91 113.86C380.91 113.16 381.81 112.56 382.41 112.16C380.81 109.46 378.81 107.06 376.41 105.16L374.51 107.46Z"
				fill="#E4E4E4"
			/>
			<path
				d="M342.51 114.76C343.71 112.16 345.41 109.96 347.51 108.06C346.61 107.26 345.91 106.46 345.31 105.96C343.01 108.06 341.21 110.56 339.81 113.36L342.51 114.76Z"
				fill="#E4E4E4"
			/>
			<path
				d="M379.91 113.76C381.51 116.76 382.41 120.16 382.51 123.76H385.51C385.51 119.56 384.31 115.56 382.41 112.06C381.81 112.56 380.91 113.06 379.91 113.76Z"
				fill="#CDCDCD"
			/>
			<path
				d="M347.61 108.16C350.11 105.86 353.31 104.16 356.81 103.36L356.21 100.46C352.11 101.36 348.41 103.36 345.41 106.06C346.01 106.56 346.71 107.36 347.61 108.16Z"
				fill="#CDCDCD"
			/>
			<path
				d="M340.41 123.86C340.41 120.56 341.21 117.46 342.51 114.86L339.81 113.46C338.31 116.56 337.41 120.16 337.41 123.86C337.41 124.36 337.51 124.86 337.51 125.36C338.31 125.26 339.31 125.16 340.51 124.96C340.41 124.56 340.41 124.26 340.41 123.86Z"
				fill="#CDCDCD"
			/>
			<path
				d="M340.51 124.86C339.31 125.06 338.31 125.16 337.61 125.36C337.81 128.56 338.61 131.56 339.91 134.26L342.61 132.96C341.41 130.46 340.61 127.76 340.51 124.86Z"
				fill="#E4E4E4"
			/>
			<path
				d="M342.51 132.96L339.81 134.26C341.61 137.96 344.31 141.06 347.71 143.46C348.11 142.76 348.61 141.86 349.21 140.86C346.41 138.86 344.11 136.16 342.51 132.96Z"
				fill="#CDCDCD"
			/>
			<path
				d="M356.81 144.26C354.01 143.66 351.41 142.46 349.21 140.86C348.61 141.86 348.11 142.76 347.71 143.46C350.21 145.16 353.01 146.46 356.11 147.16L356.81 144.26Z"
				fill="#E4E4E4"
			/>
			<path
				d="M382.41 123.86C382.41 126.76 381.91 129.46 380.71 131.96C381.91 132.26 382.91 132.66 383.61 132.86C384.81 130.06 385.41 127.06 385.41 123.86H382.41Z"
				fill="#E4E4E4"
			/>
			<path
				d="M380.81 131.96C379.41 135.26 377.31 138.06 374.61 140.16L376.41 142.56C379.61 140.06 382.11 136.66 383.71 132.86C382.91 132.66 381.91 132.36 380.81 131.96Z"
				fill="#CDCDCD"
			/>
			<path
				d="M356.71 144.36L356.01 147.26C357.81 147.66 359.61 147.86 361.41 147.86C363.81 147.86 366.01 147.46 368.21 146.86C367.91 146.16 367.51 145.16 367.11 144.06C365.31 144.56 363.41 144.86 361.41 144.86C359.81 144.86 358.31 144.66 356.71 144.36Z"
				fill="#CDCDCD"
			/>
			<path
				d="M374.51 140.26C372.41 141.96 369.91 143.26 367.01 144.06C367.41 145.16 367.81 146.16 368.11 146.86C371.11 146.06 373.91 144.56 376.31 142.66L374.51 140.26Z"
				fill="#E4E4E4"
			/>
			<path
				d="M370.21 121.96C370.01 123.36 372.81 123.66 372.91 123.66L382.51 123.76C382.51 120.16 381.51 116.76 379.91 113.76C377.01 115.76 373.31 118.26 371.91 119.36C369.61 121.16 370.21 121.96 370.21 121.96Z"
				fill="#808080"
			/>
			<path
				d="M368.41 129.46C367.21 130.26 368.71 132.66 368.71 132.66L374.61 140.26C377.41 138.06 379.51 135.16 380.91 131.96C377.61 130.86 373.31 129.56 371.61 129.16C368.61 128.46 368.41 129.46 368.41 129.46Z"
				fill="#808080"
			/>
			<path
				d="M357.71 115.76C359.11 115.56 358.81 112.76 358.71 112.86L356.71 103.46C353.21 104.26 350.11 105.86 347.51 108.16C350.01 110.46 353.31 113.56 354.71 114.66C357.11 116.56 357.71 115.76 357.71 115.76Z"
				fill="#808080"
			/>
			<path
				d="M368.31 114.76L374.41 107.36C371.81 105.26 368.51 103.76 365.01 103.16C364.71 106.66 364.41 111.06 364.41 112.86C364.41 115.86 365.41 115.86 365.41 115.86C366.41 116.86 368.41 114.86 368.31 114.76Z"
				fill="#808080"
			/>
			<path
				d="M361.31 132.76C360.01 132.26 359.01 134.96 358.91 135.06L356.71 144.36C358.21 144.66 359.81 144.86 361.41 144.86C363.41 144.86 365.31 144.56 367.11 144.06C365.91 140.86 364.31 136.66 363.51 135.06C362.21 132.36 361.31 132.76 361.31 132.76Z"
				fill="#808080"
			/>
			<path
				d="M352.81 121.76C352.71 121.76 353.01 122.66 350.41 123.36C348.61 123.76 344.21 124.36 340.81 124.86C341.01 127.76 341.71 130.46 342.91 132.96L351.61 128.86C351.61 128.86 354.21 127.96 354.71 129.26C354.71 129.26 355.61 129.66 354.31 132.36C353.51 133.86 351.21 137.76 349.51 140.76C351.81 142.36 354.31 143.56 357.11 144.16L359.31 134.86C359.31 134.86 360.31 132.16 361.61 132.66C361.61 132.66 362.51 132.26 363.81 134.96C364.51 136.56 366.21 140.76 367.41 143.96C370.11 143.16 372.61 141.86 374.81 140.16L368.91 132.56C368.91 132.56 367.41 130.26 368.61 129.46C368.61 129.46 368.91 128.46 371.81 129.16C373.51 129.56 377.81 130.96 381.11 131.96C382.11 129.46 382.71 126.76 382.71 123.86L373.11 123.76C373.11 123.76 370.21 123.46 370.41 122.06C370.41 122.06 369.81 121.36 372.11 119.46C373.51 118.26 377.21 115.76 380.11 113.86C378.71 111.36 376.91 109.16 374.71 107.46L368.61 114.86C368.61 114.86 366.61 116.86 365.61 115.86C365.61 115.86 364.61 115.86 364.61 112.86C364.61 111.06 364.91 106.56 365.21 103.16C364.01 102.96 362.81 102.86 361.61 102.86C360.01 102.86 358.41 103.06 356.91 103.36L358.91 112.76C358.91 112.76 359.21 115.56 357.81 115.76C357.81 115.76 357.11 116.56 354.81 114.66C353.41 113.56 350.11 110.56 347.61 108.16C345.51 109.96 343.81 112.26 342.61 114.76L351.21 119.06C351.21 119.06 353.61 120.56 352.81 121.76Z"
				fill="#CDCDCD"
			/>
			<path
				d="M342.51 114.76C341.21 117.46 340.41 120.56 340.31 123.76C340.31 124.16 340.41 124.46 340.41 124.86C343.91 124.46 348.31 123.76 350.01 123.36C352.91 122.76 352.71 121.76 352.71 121.76C353.51 120.56 351.11 119.06 351.11 119.06L342.51 114.76Z"
				fill="#808080"
			/>
			<path
				d="M351.21 128.76L342.51 132.86C344.11 135.96 346.41 138.76 349.21 140.76C351.01 137.86 353.21 133.96 354.01 132.36C355.31 129.66 354.41 129.26 354.41 129.26C353.91 127.96 351.21 128.86 351.21 128.76Z"
				fill="#808080"
			/>
			<path
				d="M361.91 118.86C362.739 118.86 363.41 118.189 363.41 117.36C363.41 116.532 362.739 115.86 361.91 115.86C361.082 115.86 360.41 116.532 360.41 117.36C360.41 118.189 361.082 118.86 361.91 118.86Z"
				fill="#767676"
			/>
			<path
				d="M367.41 123.36C368.239 123.36 368.91 122.689 368.91 121.86C368.91 121.032 368.239 120.36 367.41 120.36C366.582 120.36 365.91 121.032 365.91 121.86C365.91 122.689 366.582 123.36 367.41 123.36Z"
				fill="#767676"
			/>
			<path
				d="M365.41 129.36C366.239 129.36 366.91 128.689 366.91 127.86C366.91 127.032 366.239 126.36 365.41 126.36C364.582 126.36 363.91 127.032 363.91 127.86C363.91 128.689 364.582 129.36 365.41 129.36Z"
				fill="#767676"
			/>
			<path
				d="M358.41 129.36C359.239 129.36 359.91 128.689 359.91 127.86C359.91 127.032 359.239 126.36 358.41 126.36C357.582 126.36 356.91 127.032 356.91 127.86C356.91 128.689 357.582 129.36 358.41 129.36Z"
				fill="#767676"
			/>
			<path
				d="M356.11 123.76C356.939 123.76 357.61 123.088 357.61 122.26C357.61 121.432 356.939 120.76 356.11 120.76C355.282 120.76 354.61 121.432 354.61 122.26C354.61 123.088 355.282 123.76 356.11 123.76Z"
				fill="#767676"
			/>
			<path
				d="M361.91 126.86C363.843 126.86 365.41 125.293 365.41 123.36C365.41 121.427 363.843 119.86 361.91 119.86C359.977 119.86 358.41 121.427 358.41 123.36C358.41 125.293 359.977 126.86 361.91 126.86Z"
				fill="#767676"
			/>
			<path
				d="M107.91 155.86C125.859 155.86 140.41 141.309 140.41 123.36C140.41 105.411 125.859 90.8601 107.91 90.8601C89.9609 90.8601 75.4102 105.411 75.4102 123.36C75.4102 141.309 89.9609 155.86 107.91 155.86Z"
				fill="#414141"
			/>
			<path
				d="M107.41 102.86C108.71 102.86 109.91 102.96 111.01 103.16C111.11 101.96 111.21 100.96 111.31 100.16C110.01 99.9601 108.71 99.8601 107.41 99.8601C105.61 99.8601 103.81 100.06 102.11 100.46L102.71 103.36C104.21 103.06 105.81 102.86 107.41 102.86Z"
				fill="#E4E4E4"
			/>
			<path
				d="M111.11 103.16C114.61 103.76 117.81 105.26 120.61 107.36L122.51 105.06C119.41 102.56 115.51 100.86 111.41 100.16C111.31 100.96 111.21 101.96 111.11 103.16Z"
				fill="#CDCDCD"
			/>
			<path
				d="M120.51 107.46C122.71 109.16 124.51 111.26 125.91 113.86C126.91 113.16 127.81 112.56 128.41 112.16C126.81 109.46 124.81 107.06 122.41 105.16L120.51 107.46Z"
				fill="#E4E4E4"
			/>
			<path
				d="M88.5101 114.76C89.7101 112.16 91.4101 109.96 93.5101 108.06C92.6101 107.26 91.9101 106.46 91.3101 105.96C89.0101 108.06 87.2101 110.56 85.8101 113.36L88.5101 114.76Z"
				fill="#E4E4E4"
			/>
			<path
				d="M125.91 113.76C127.51 116.76 128.41 120.16 128.51 123.76H131.51C131.51 119.56 130.41 115.56 128.41 112.06C127.81 112.56 126.91 113.06 125.91 113.76Z"
				fill="#CDCDCD"
			/>
			<path
				d="M93.6102 108.16C96.1102 105.86 99.3102 104.16 102.81 103.36L102.21 100.46C98.1102 101.36 94.4102 103.36 91.4102 106.06C92.0102 106.56 92.7102 107.36 93.6102 108.16Z"
				fill="#CDCDCD"
			/>
			<path
				d="M86.4102 123.86C86.4102 120.56 87.2102 117.46 88.5102 114.86L85.8102 113.46C84.3102 116.56 83.4102 120.16 83.4102 123.86V125.36C84.2102 125.26 85.2102 125.16 86.4102 124.96V123.86Z"
				fill="#CDCDCD"
			/>
			<path
				d="M86.5103 124.86C85.3103 125.06 84.3103 125.16 83.5103 125.36C83.7103 128.56 84.5102 131.56 85.8102 134.26L88.5103 132.96C87.4103 130.46 86.6103 127.76 86.5103 124.86Z"
				fill="#E4E4E4"
			/>
			<path
				d="M88.5101 132.96L85.8101 134.26C87.6101 137.96 90.3101 141.06 93.7101 143.46C94.1101 142.76 94.6101 141.86 95.2101 140.86C92.4101 138.86 90.1101 136.16 88.5101 132.96Z"
				fill="#CDCDCD"
			/>
			<path
				d="M102.81 144.26C100.01 143.66 97.41 142.46 95.21 140.86C94.61 141.86 94.11 142.76 93.71 143.46C96.21 145.16 99.01 146.46 102.11 147.16L102.81 144.26Z"
				fill="#E4E4E4"
			/>
			<path
				d="M128.41 123.86C128.51 126.76 127.91 129.46 126.71 131.96C127.91 132.26 128.91 132.66 129.61 132.86C130.81 130.06 131.41 127.06 131.41 123.86H128.41Z"
				fill="#E4E4E4"
			/>
			<path
				d="M126.81 131.96C125.41 135.26 123.31 138.06 120.51 140.16L122.41 142.56C125.61 140.06 128.11 136.66 129.71 132.86C128.91 132.66 127.91 132.36 126.81 131.96Z"
				fill="#CDCDCD"
			/>
			<path
				d="M102.71 144.36L102.01 147.26C103.81 147.66 105.61 147.86 107.41 147.86C109.81 147.86 112.11 147.46 114.21 146.86C113.91 146.16 113.51 145.16 113.11 144.06C111.31 144.56 109.41 144.86 107.41 144.86C105.81 144.86 104.31 144.66 102.71 144.36Z"
				fill="#CDCDCD"
			/>
			<path
				d="M120.51 140.26C118.41 141.96 115.91 143.26 113.11 144.06C113.61 145.16 113.91 146.16 114.21 146.86C117.21 146.06 120.01 144.56 122.41 142.66L120.51 140.26Z"
				fill="#E4E4E4"
			/>
			<path
				d="M116.21 121.96C116.01 123.36 118.81 123.66 118.91 123.66L128.51 123.76C128.51 120.16 127.51 116.76 125.91 113.76C123.01 115.76 119.31 118.26 117.91 119.36C115.61 121.16 116.21 121.96 116.21 121.96Z"
				fill="#808080"
			/>
			<path
				d="M114.31 129.46C113.21 130.26 114.71 132.66 114.51 132.66L120.41 140.26C123.21 138.06 125.31 135.16 126.71 131.96C123.41 130.86 119.11 129.56 117.41 129.16C114.51 128.46 114.31 129.46 114.31 129.46Z"
				fill="#808080"
			/>
			<path
				d="M103.71 115.76C105.11 115.56 104.81 112.76 104.71 112.86L102.71 103.46C99.2103 104.26 96.1103 105.86 93.5103 108.16C96.0103 110.46 99.3103 113.56 100.71 114.66C103.11 116.56 103.71 115.76 103.71 115.76Z"
				fill="#808080"
			/>
			<path
				d="M114.31 114.76L120.41 107.36C117.81 105.26 114.51 103.76 111.01 103.16C110.71 106.66 110.41 111.06 110.41 112.86C110.41 115.86 111.41 115.86 111.41 115.86C112.41 116.86 114.41 114.86 114.31 114.76Z"
				fill="#808080"
			/>
			<path
				d="M107.31 132.76C106.01 132.26 105.01 134.96 105.01 135.06L102.81 144.36C104.31 144.66 105.81 144.86 107.41 144.86C109.41 144.86 111.31 144.56 113.11 144.06C111.91 140.86 110.31 136.66 109.51 135.06C108.21 132.36 107.31 132.76 107.31 132.76Z"
				fill="#808080"
			/>
			<path
				d="M98.7101 121.76C98.7101 121.76 99.0101 122.66 96.3101 123.36C94.7101 123.76 90.2101 124.36 86.8101 124.86C86.9101 127.76 87.6101 130.46 88.8101 132.96L97.5101 128.86C97.5101 128.86 100.11 127.96 100.61 129.26C100.61 129.26 101.51 129.66 100.21 132.36C99.4101 133.86 97.1101 137.76 95.4101 140.76C97.7101 142.36 100.21 143.56 103.01 144.16L105.21 134.86C105.21 134.86 106.21 132.16 107.51 132.66C107.51 132.66 108.41 132.26 109.71 134.96C110.41 136.56 112.11 140.76 113.31 143.96C116.01 143.16 118.51 141.86 120.71 140.16L114.81 132.56C114.81 132.56 113.31 130.26 114.51 129.46C114.51 129.46 114.71 128.46 117.61 129.16C119.31 129.56 123.61 130.96 126.91 131.96C127.91 129.46 128.51 126.76 128.51 123.86L118.91 123.76C118.91 123.76 116.11 123.46 116.31 122.06C116.31 122.06 115.61 121.36 118.01 119.46C119.41 118.26 123.11 115.76 126.01 113.86C124.61 111.36 122.81 109.16 120.61 107.46L114.51 114.86C114.51 114.86 112.51 116.86 111.51 115.86C111.51 115.86 110.51 115.86 110.51 112.86C110.51 111.06 110.81 106.56 111.11 103.16C110.01 102.96 108.71 102.86 107.51 102.86C105.91 102.86 104.31 103.06 102.81 103.36L104.81 112.76C104.81 112.76 105.11 115.56 103.71 115.76C103.71 115.76 103.11 116.56 100.71 114.66C99.3101 113.56 96.0101 110.56 93.5101 108.16C91.4101 109.96 89.7101 112.26 88.5101 114.76L97.1101 119.06C97.1101 119.06 99.5101 120.56 98.7101 121.76Z"
				fill="#CDCDCD"
			/>
			<path
				d="M88.5104 114.76C87.2104 117.46 86.4104 120.56 86.5104 123.76V124.86C89.9104 124.46 94.3104 123.76 96.0104 123.36C98.9104 122.76 98.7104 121.76 98.7104 121.76C99.5104 120.56 97.1104 119.06 97.1104 119.06L88.5104 114.76Z"
				fill="#808080"
			/>
			<path
				d="M97.2103 128.76L88.5103 132.86C90.1103 135.96 92.4103 138.76 95.2103 140.76C97.0103 137.86 99.2103 133.96 100.01 132.36C101.31 129.66 100.41 129.26 100.41 129.26C99.9103 127.96 97.2103 128.86 97.2103 128.76Z"
				fill="#808080"
			/>
			<path
				d="M107.91 118.86C108.739 118.86 109.41 118.189 109.41 117.36C109.41 116.532 108.739 115.86 107.91 115.86C107.082 115.86 106.41 116.532 106.41 117.36C106.41 118.189 107.082 118.86 107.91 118.86Z"
				fill="#767676"
			/>
			<path
				d="M113.41 123.36C114.239 123.36 114.91 122.689 114.91 121.86C114.91 121.032 114.239 120.36 113.41 120.36C112.582 120.36 111.91 121.032 111.91 121.86C111.91 122.689 112.582 123.36 113.41 123.36Z"
				fill="#767676"
			/>
			<path
				d="M111.41 129.36C112.239 129.36 112.91 128.689 112.91 127.86C112.91 127.032 112.239 126.36 111.41 126.36C110.582 126.36 109.91 127.032 109.91 127.86C109.91 128.689 110.582 129.36 111.41 129.36Z"
				fill="#767676"
			/>
			<path
				d="M104.41 129.36C105.239 129.36 105.91 128.689 105.91 127.86C105.91 127.032 105.239 126.36 104.41 126.36C103.582 126.36 102.91 127.032 102.91 127.86C102.91 128.689 103.582 129.36 104.41 129.36Z"
				fill="#767676"
			/>
			<path
				d="M102.11 123.76C102.939 123.76 103.61 123.088 103.61 122.26C103.61 121.432 102.939 120.76 102.11 120.76C101.282 120.76 100.61 121.432 100.61 122.26C100.61 123.088 101.282 123.76 102.11 123.76Z"
				fill="#767676"
			/>
			<path
				d="M107.91 126.86C109.843 126.86 111.41 125.293 111.41 123.36C111.41 121.427 109.843 119.86 107.91 119.86C105.977 119.86 104.41 121.427 104.41 123.36C104.41 125.293 105.977 126.86 107.91 126.86Z"
				fill="#767676"
			/>
			<path
				d="M244.91 71.8601H228.91V74.6601H244.91V71.8601Z"
				fill="white"
			/>
			<path
				d="M244.91 75.3602V74.6602H228.91V74.7602C228.91 74.7602 229.91 78.3602 233.91 78.3602H240.91C240.91 78.3602 244.91 78.3602 244.91 75.3602Z"
				fill="#767676"
			/>
			<path
				d="M228.91 71.8601H244.91V71.3601C244.91 71.3601 244.91 70.3601 236.91 70.3601C228.91 70.3601 228.91 71.3601 228.91 71.3601V71.8601Z"
				fill="#767676"
			/>
			<path
				d="M148.91 68.8601H132.91V71.6601H148.91V68.8601Z"
				fill="white"
			/>
			<path
				d="M148.91 71.6602H132.91V71.7602C132.91 71.7602 133.91 75.3602 137.91 75.3602H144.91C144.91 75.3602 148.91 75.3602 148.91 72.3602V71.6602Z"
				fill="#767676"
			/>
			<path
				d="M132.91 68.8601H148.91V68.3601C148.91 68.3601 148.91 67.3601 140.91 67.3601C132.91 67.3601 132.91 68.3601 132.91 68.3601V68.8601Z"
				fill="#767676"
			/>
			<path
				d="M398.71 105.86C401.81 112.46 402.41 117.96 402.51 118.16V135.96L423.71 133.96H424.31C426.01 132.26 429.91 128.86 434.41 128.86H449.91C449.91 128.76 450.01 128.56 450.01 128.36L450.31 124.56H448.31L449.31 115.56C450.21 108.26 447.51 102.26 447.51 102.26L445.31 97.5601L442.31 89.5601L437.81 82.0601C424.81 67.0601 336.81 60.0601 336.81 60.0601H328.81C328.81 60.0601 326.41 60.8601 322.41 61.8601L322.91 62.3601C322.91 62.3601 395.01 73.0601 414.31 78.7601C423.11 80.5601 428.11 81.8601 428.11 81.8601C428.11 81.8601 432.01 85.3601 434.81 88.7601C441.51 94.0601 444.41 102.06 445.71 107.66C446.21 107.76 446.41 107.76 446.41 107.76C446.41 107.76 446.21 107.66 445.71 107.66C446.61 111.46 446.71 114.26 446.71 114.26C446.81 115.06 446.91 115.86 447.01 116.66C447.71 122.56 446.71 127.86 446.71 127.86C446.71 127.86 444.11 127.66 440.61 127.36C435.01 126.96 426.91 126.36 422.91 126.16C416.41 125.76 415.91 123.46 415.91 123.46L415.51 121.86L414.41 117.86L413.71 115.46L412.91 112.66L446.71 114.16C446.71 114.16 446.61 111.46 445.71 107.56C440.01 107.26 425.91 106.06 398.71 105.86Z"
				fill="#EDEDED"
			/>
			<path
				d="M428.11 81.8602C428.01 81.7602 423.01 80.5602 414.31 78.7602C393.21 74.3602 357.21 66.5602 310.41 65.4602C313.01 67.9602 313.81 72.2602 313.81 72.2602C316.11 95.6602 313.31 113.36 311.21 122.36H323.31V118.06C326.11 104.16 333.51 96.8602 333.51 96.8602C333.51 96.8602 349.51 77.8602 374.51 84.8602C388.11 88.6602 395.11 98.0602 398.81 105.86C426.01 105.96 440.21 107.16 445.81 107.56C444.51 101.86 441.61 93.9602 434.91 88.6602C435.41 89.2602 435.81 89.7602 436.21 90.3602C437.01 91.5602 437.51 92.6602 437.51 93.4602C437.51 96.7602 434.51 95.1602 434.51 95.1602C434.51 95.1602 433.61 94.9602 431.21 94.7602C427.41 94.3602 420.01 93.8602 406.51 93.8602C406.51 93.8602 405.01 90.7602 403.31 87.9602C402.31 86.2602 401.31 84.6602 400.51 83.8602C398.51 81.8602 402.51 81.8602 402.51 81.8602H428.11Z"
				fill="white"
			/>
			<path
				d="M168.41 125.86C159.11 125.26 154.41 119.46 152.21 115.36H144.01L145.61 133.96L323.21 136.06V122.36H311.21C310.21 126.56 309.41 128.86 309.41 128.86C309.41 128.86 183.41 126.86 168.41 125.86Z"
				fill="#D1D1D1"
			/>
			<path
				d="M53.4101 77.8602C53.4101 77.8602 53.4101 78.4602 52.8101 79.1602C52.8101 79.1602 45.3101 94.9602 62.3101 97.9602C72.7101 99.7602 70.4101 101.16 77.2101 102.06C87.9101 87.2601 106.01 86.0602 106.01 86.0602C138.21 87.0602 143.81 114.06 143.81 114.06L143.91 115.36H152.11C150.81 112.86 150.41 110.86 150.41 110.86C150.41 110.86 130.41 74.8602 126.31 68.5602C125.11 66.7602 124.51 65.2602 124.11 64.1602C53.8101 65.4602 57.4101 66.8602 57.4101 66.8602C56.4101 68.8602 53.4101 77.8602 53.4101 77.8602Z"
				fill="white"
			/>
			<path
				d="M324.41 122.26L323.21 122.16V135.86H325.81V118.86C330.81 86.8601 361.81 85.8601 361.81 85.8601C381.71 86.9601 391.21 96.9601 395.71 105.66C399.41 112.76 399.81 118.96 399.81 118.96V135.86L402.41 135.66V117.86C402.41 117.86 401.81 112.36 398.71 105.76C395.01 97.9601 387.91 88.5601 374.41 84.7601C349.41 77.7601 333.41 96.7601 333.41 96.7601C333.41 96.7601 326.01 104.16 323.21 117.96V122.26H324.41Z"
				fill="#989898"
			/>
			<path
				d="M414.31 78.7601C395.01 73.0601 322.91 62.3601 323.01 62.3601L322.51 61.8601C318.51 62.8601 315.51 60.8601 315.51 60.8601C284.81 35.5601 257.51 27.8601 257.51 27.8601C257.51 27.8601 249.51 17.8601 205.51 18.8601C161.51 19.8601 129.51 27.8601 129.51 27.8601C129.51 27.8601 97.11 36.3601 77.51 51.8601L40.51 55.8601C40.51 55.8601 40.41 55.9601 40.21 56.0601H87.11C87.11 56.0601 80.71 55.8601 89.11 51.8601C89.11 51.8601 130.51 22.8601 212.51 22.8601C288.51 22.8601 307.51 63.8601 307.51 63.8601C308.61 64.1601 309.61 64.6601 310.41 65.4601C357.21 66.5601 393.21 74.3601 414.31 78.7601Z"
				fill="#E1E1E1"
			/>
			<path
				d="M67.5103 121.36V130.96H70.1103V117.96C75.1103 85.9602 106.11 84.9602 106.11 84.9602C126.01 86.0602 135.51 96.0602 140.01 104.76C143.71 111.86 144.11 118.06 144.11 118.06V134.96L146.71 134.76V116.96C146.71 116.96 146.11 111.46 143.01 104.86C139.31 97.0602 132.21 87.6602 118.71 83.8602C93.7103 76.8602 77.7103 95.8602 77.7103 95.8602C77.7103 95.8602 70.3103 103.26 67.5103 117.06V121.36Z"
				fill="#989898"
			/>
			<path
				d="M56.4102 64.7601L32.8102 63.5601C31.1102 66.1601 29.8102 69.5601 29.4102 73.5601H43.3102L42.9102 77.1601L29.0102 77.6601C28.8102 78.7601 28.6102 79.7601 28.4102 80.6601C33.7102 80.4601 48.1102 79.7601 50.5102 79.7601C53.5102 79.7601 53.5102 77.7601 53.5102 77.7601C53.5102 77.7601 56.5102 68.7601 57.5102 66.7601C58.5102 64.7601 56.4102 64.7601 56.4102 64.7601Z"
				fill="#DC0000"
				stroke="black"
				strokeWidth={0.5}
				strokeMiterlimit={10}
			/>
			<path
				d="M402.41 81.26H428.11C428.11 81.26 433.61 86.06 436.21 89.86C436.18 89.86 437.61 92.26 437.51 93.06C437.51 96.36 434.51 94.76 434.51 94.76C434.51 94.76 433.61 94.56 431.21 94.36C431.19 94.29 419.92 93.26 406.61 93.26C406.61 93.26 405.81 91.6 404.71 89.62C404.46 89.46 404.29 89.05 404.14 88.61C403.9 88.19 403.66 87.77 403.4 87.36C403.46 87.36 403.35 87.16 403.2 87.16C402.2 85.46 401.2 83.86 400.4 83.06C398.5 81.26 402.5 81.26 402.4 81.26H402.41Z"
				stroke="#231F20"
				strokeWidth={0.5}
				strokeMiterlimit={10}
			/>
			<path
				d="M313.81 71.76C316.01 95.06 313.21 112.66 311.21 121.66C311.24 121.66 311.28 121.66 311.31 121.66C310.31 125.96 309.51 128.26 309.51 128.26C309.51 128.26 268.81 127.46 223.21 126.66L224.91 119.66C225.01 119.26 225.01 118.86 225.11 118.56C225.08 118.56 225.04 118.56 225.01 118.56C227.01 102.56 223.01 71.16 222.51 67.46L313.81 71.76Z"
				stroke="#231F20"
				strokeWidth={0.5}
				strokeMiterlimit={10}
			/>
			<path
				d="M307.51 63.16C312.51 64.16 313.91 71.56 313.91 71.56V71.76L222.61 67.46C222.51 67.26 222.51 67.16 222.51 67.06L219.81 59.76L307.51 63.16Z"
				stroke="#231F20"
				strokeWidth={0.5}
				strokeMiterlimit={10}
			/>
			<path
				d="M124.31 60.0599L126.41 56.6599L219.81 59.7599L222.51 67.0599V67.4599L124.11 63.4599C123.31 60.8599 124.31 60.0599 124.31 60.0599Z"
				stroke="#231F20"
				strokeWidth={0.5}
				strokeMiterlimit={10}
			/>
			<path
				d="M222.51 67.5598L124.11 63.5598C124.41 64.6598 125.11 66.0598 126.31 67.9598C130.41 74.2598 150.41 110.26 150.41 110.26C150.41 110.26 150.81 111.86 151.81 113.96C151.84 113.96 151.88 113.96 151.91 113.96C153.91 118.16 158.51 124.55 168.51 125.25C174.01 125.65 196.71 126.15 223.21 126.65L224.91 119.65C225.01 119.25 225.01 118.95 225.21 118.55C225.18 118.55 225.14 118.55 225.11 118.55C227.11 102.55 223.01 71.1498 222.51 67.5498V67.5598Z"
				stroke="#231F20"
				strokeWidth={0.5}
				strokeMiterlimit={10}
			/>
			<path
				d="M444.91 96.7202L441.91 88.7202H444.91C444.91 88.7202 447.91 91.7202 446.91 96.7202H444.91Z"
				fill="white"
				stroke="black"
				strokeMiterlimit={10}
			/>
			<path
				d="M17.4102 96.3201L18.9102 110.32C21.3102 124.32 34.5102 123.72 34.5102 123.72L67.0102 129.88V130.22H68.8302L70.4102 130.52V129.73L75.7502 129.93C79.0702 144.36 91.9802 155.12 107.41 155.12C122.01 155.12 134.35 145.5 138.46 132.25L143.61 132.44V134.22L146.21 134.02V133.23L322.71 135.32V135.12H325.31V133.12H330.65C335.02 145.91 347.13 155.12 361.41 155.12C375.69 155.12 387.8 145.91 392.17 133.12H399.31V135.12L401.91 134.92L402.01 135.22L423.21 133.22H423.91L437.91 133.82L448.51 129.02C448.91 128.82 449.21 128.52 449.41 128.12C449.41 128.02 449.51 127.82 449.51 127.62L449.81 123.82H447.81L448.81 114.82C449.71 107.52 447.01 101.52 447.01 101.52L444.81 96.8201L441.81 88.8201L437.31 81.3201C424.31 66.3201 336.31 59.3201 336.31 59.3201H328.31C296.91 42.1201 280.91 31.3201 262.71 24.6201C244.91 18.0201 240.91 15.3201 202.31 14.3201C174.61 13.5201 147.81 17.8201 124.91 23.5201C83.3102 34.0201 67.3102 49.3201 67.3102 49.3201H67.3402L33.4102 52.3201C33.1102 52.2201 32.8102 52.3201 32.6102 52.6201M32.6102 52.6201C33.7902 52.8801 33.7902 52.8801 32.6102 52.6201ZM32.6102 52.6201C29.6102 54.3201 25.4102 64.3201 25.4102 64.3201L27.6602 64.6101H27.6102C27.6102 64.6101 23.9102 81.1201 23.8102 81.2201V89.1201L23.9102 89.0201L17.5102 96.2201"
				stroke="black"
				strokeWidth={2}
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M32.7601 53.3601C32.9601 53.0601 33.2601 52.9601 33.5601 53.0601L67.5601 50.0601L77.1601 51.8601L40.1901 55.8601C38.6701 56.9001 34.4301 60.1801 31.8601 64.8601L27.8601 65.3601L25.5601 65.0601C25.5601 65.0601 29.7601 55.0601 32.7601 53.3601Z"
				fill="white"
				stroke="black"
				strokeWidth={0.5}
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<rect
				width={144.133}
				height={56.6933}
				fill="white"
				fillOpacity={0.01}
				className="zone"
				onClick={() =>
					props.handlePathClick &&
					props.handlePathClick(
						28,
						t("Zone28", "Bagparti passager-side top")
					)
				}
			/>
			{props.clickedZones.find((path) => path.zoneId === 28) && (
				<g filter="url(#filter0_d_1811_2559)">
					<rect
						x={57.0669}
						y={13.3467}
						width={30}
						height={30}
						rx={15}
						fill="#3c7749"
					/>
					<rect
						x={56.0669}
						y={12.3467}
						width={32}
						height={32}
						rx={16}
						stroke="white"
						strokeWidth={2}
					/>
					<path
						d="M80.0669 22.3467L69.0669 33.3467L64.0669 28.3467"
						stroke="white"
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
				</g>
			)}
			<rect
				width={144.133}
				height={56.6933}
				transform="translate(144.133)"
				fill="white"
				fillOpacity={0.01}
				className="zone"
				onClick={() =>
					props.handlePathClick &&
					props.handlePathClick(
						29,
						t("Zone29", "Midt passager-side top")
					)
				}
			/>
			{props.clickedZones.find((path) => path.zoneId === 29) && (
				<g filter="url(#filter1_d_1811_2559)">
					<rect
						x={201.2}
						y={13.3467}
						width={30}
						height={30}
						rx={15}
						fill="#3c7749"
					/>
					<rect
						x={200.2}
						y={12.3467}
						width={32}
						height={32}
						rx={16}
						stroke="white"
						strokeWidth={2}
					/>
					<path
						d="M224.2 22.3467L213.2 33.3467L208.2 28.3467"
						stroke="white"
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
				</g>
			)}
			<rect
				width={144.133}
				height={56.6933}
				transform="translate(288.267)"
				fill="white"
				fillOpacity={0.01}
				className="zone"
				onClick={() =>
					props.handlePathClick &&
					props.handlePathClick(
						30,
						t("Zone30", "Front passager-side top")
					)
				}
			/>
			{props.clickedZones.find((path) => path.zoneId === 30) && (
				<g filter="url(#filter2_d_1811_2559)">
					<rect
						x={345.333}
						y={13.3467}
						width={30}
						height={30}
						rx={15}
						fill="#3c7749"
					/>
					<rect
						x={344.333}
						y={12.3467}
						width={32}
						height={32}
						rx={16}
						stroke="white"
						strokeWidth={2}
					/>
					<path
						d="M368.333 22.3467L357.333 33.3467L352.333 28.3467"
						stroke="white"
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
				</g>
			)}
			<rect
				width={144.133}
				height={56.6933}
				transform="translate(0 56.6934)"
				fill="white"
				fillOpacity={0.01}
				className="zone"
				onClick={() =>
					props.handlePathClick &&
					props.handlePathClick(
						31,
						t("Zone31", "Bagparti passager-side midt")
					)
				}
			/>
			{props.clickedZones.find((path) => path.zoneId === 31) && (
				<g filter="url(#filter3_d_1811_2559)">
					<rect
						x={57.0669}
						y={70.04}
						width={30}
						height={30}
						rx={15}
						fill="#3c7749"
					/>
					<rect
						x={56.0669}
						y={69.04}
						width={32}
						height={32}
						rx={16}
						stroke="white"
						strokeWidth={2}
					/>
					<path
						d="M80.0669 79.04L69.0669 90.04L64.0669 85.04"
						stroke="white"
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
				</g>
			)}
			<rect
				width={144.133}
				height={56.6933}
				transform="translate(144.133 56.6934)"
				fill="white"
				fillOpacity={0.01}
				className="zone"
				onClick={() =>
					props.handlePathClick &&
					props.handlePathClick(
						32,
						t("Zone32", "Midt passager-side midt")
					)
				}
			/>
			{props.clickedZones.find((path) => path.zoneId === 32) && (
				<g filter="url(#filter4_d_1811_2559)">
					<rect
						x={201.2}
						y={70.04}
						width={30}
						height={30}
						rx={15}
						fill="#3c7749"
					/>
					<rect
						x={200.2}
						y={69.04}
						width={32}
						height={32}
						rx={16}
						stroke="white"
						strokeWidth={2}
					/>
					<path
						d="M224.2 79.04L213.2 90.04L208.2 85.04"
						stroke="white"
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
				</g>
			)}
			<rect
				width={144.133}
				height={56.6933}
				transform="translate(288.267 56.6934)"
				fill="white"
				fillOpacity={0.01}
				className="zone"
				onClick={() =>
					props.handlePathClick &&
					props.handlePathClick(
						33,
						t("Zone33", "Front passager-side midt")
					)
				}
			/>
			{props.clickedZones.find((path) => path.zoneId === 33) && (
				<g filter="url(#filter5_d_1811_2559)">
					<rect
						x={345.333}
						y={70.04}
						width={30}
						height={30}
						rx={15}
						fill="#3c7749"
					/>
					<rect
						x={344.333}
						y={69.04}
						width={32}
						height={32}
						rx={16}
						stroke="white"
						strokeWidth={2}
					/>
					<path
						d="M368.333 79.04L357.333 90.04L352.333 85.04"
						stroke="white"
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
				</g>
			)}
			<rect
				width={144.133}
				height={56.6933}
				transform="translate(0 113.387)"
				fill="white"
				fillOpacity={0.01}
				className="zone"
				onClick={() =>
					props.handlePathClick &&
					props.handlePathClick(
						34,
						t("Zone34", "Bagparti passager-side bund")
					)
				}
			/>
			{props.clickedZones.find((path) => path.zoneId === 34) && (
				<g filter="url(#filter6_d_1811_2559)">
					<rect
						x={57.0669}
						y={126.733}
						width={30}
						height={30}
						rx={15}
						fill="#3c7749"
					/>
					<rect
						x={56.0669}
						y={125.733}
						width={32}
						height={32}
						rx={16}
						stroke="white"
						strokeWidth={2}
					/>
					<path
						d="M80.0669 135.733L69.0669 146.733L64.0669 141.733"
						stroke="white"
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
				</g>
			)}
			<rect
				width={144.133}
				height={56.6933}
				transform="translate(144.133 113.387)"
				fill="white"
				fillOpacity={0.01}
				className="zone"
				onClick={() =>
					props.handlePathClick &&
					props.handlePathClick(
						35,
						t("Zone35", "Midt passager-side bund")
					)
				}
			/>
			{props.clickedZones.find((path) => path.zoneId === 35) && (
				<g filter="url(#filter7_d_1811_2559)">
					<rect
						x={201.2}
						y={126.733}
						width={30}
						height={30}
						rx={15}
						fill="#3c7749"
					/>
					<rect
						x={200.2}
						y={125.733}
						width={32}
						height={32}
						rx={16}
						stroke="white"
						strokeWidth={2}
					/>
					<path
						d="M224.2 135.733L213.2 146.733L208.2 141.733"
						stroke="white"
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
				</g>
			)}
			<rect
				width={144.133}
				height={56.6933}
				transform="translate(288.267 113.387)"
				fill="white"
				fillOpacity={0.01}
				className="zone"
				onClick={() =>
					props.handlePathClick &&
					props.handlePathClick(
						36,
						t("Zone36", "Front passager-side bund")
					)
				}
			/>
			{props.clickedZones.find((path) => path.zoneId === 36) && (
				<g filter="url(#filter8_d_1811_2559)">
					<rect
						x={345.333}
						y={126.733}
						width={30}
						height={30}
						rx={15}
						fill="#3c7749"
					/>
					<rect
						x={344.333}
						y={125.733}
						width={32}
						height={32}
						rx={16}
						stroke="white"
						strokeWidth={2}
					/>
					<path
						d="M368.333 135.733L357.333 146.733L352.333 141.733"
						stroke="white"
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
				</g>
			)}
		</g>
		<defs>
			<filter
				id="filter0_d_1811_2559"
				x={35.0669}
				y={-6.65332}
				width={74}
				height={74}
				filterUnits="userSpaceOnUse"
				colorInterpolationFilters="sRGB"
			>
				<feFlood floodOpacity={0} result="BackgroundImageFix" />
				<feColorMatrix
					in="SourceAlpha"
					type="matrix"
					values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
					result="hardAlpha"
				/>
				<feOffset dy={2} />
				<feGaussianBlur stdDeviation={10} />
				<feComposite in2="hardAlpha" operator="out" />
				<feColorMatrix
					type="matrix"
					values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
				/>
				<feBlend
					mode="normal"
					in2="BackgroundImageFix"
					result="effect1_dropShadow_1811_2559"
				/>
				<feBlend
					mode="normal"
					in="SourceGraphic"
					in2="effect1_dropShadow_1811_2559"
					result="shape"
				/>
			</filter>
			<filter
				id="filter1_d_1811_2559"
				x={179.2}
				y={-6.65332}
				width={74}
				height={74}
				filterUnits="userSpaceOnUse"
				colorInterpolationFilters="sRGB"
			>
				<feFlood floodOpacity={0} result="BackgroundImageFix" />
				<feColorMatrix
					in="SourceAlpha"
					type="matrix"
					values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
					result="hardAlpha"
				/>
				<feOffset dy={2} />
				<feGaussianBlur stdDeviation={10} />
				<feComposite in2="hardAlpha" operator="out" />
				<feColorMatrix
					type="matrix"
					values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
				/>
				<feBlend
					mode="normal"
					in2="BackgroundImageFix"
					result="effect1_dropShadow_1811_2559"
				/>
				<feBlend
					mode="normal"
					in="SourceGraphic"
					in2="effect1_dropShadow_1811_2559"
					result="shape"
				/>
			</filter>
			<filter
				id="filter2_d_1811_2559"
				x={323.333}
				y={-6.65332}
				width={74}
				height={74}
				filterUnits="userSpaceOnUse"
				colorInterpolationFilters="sRGB"
			>
				<feFlood floodOpacity={0} result="BackgroundImageFix" />
				<feColorMatrix
					in="SourceAlpha"
					type="matrix"
					values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
					result="hardAlpha"
				/>
				<feOffset dy={2} />
				<feGaussianBlur stdDeviation={10} />
				<feComposite in2="hardAlpha" operator="out" />
				<feColorMatrix
					type="matrix"
					values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
				/>
				<feBlend
					mode="normal"
					in2="BackgroundImageFix"
					result="effect1_dropShadow_1811_2559"
				/>
				<feBlend
					mode="normal"
					in="SourceGraphic"
					in2="effect1_dropShadow_1811_2559"
					result="shape"
				/>
			</filter>
			<filter
				id="filter3_d_1811_2559"
				x={35.0669}
				y={50.04}
				width={74}
				height={74}
				filterUnits="userSpaceOnUse"
				colorInterpolationFilters="sRGB"
			>
				<feFlood floodOpacity={0} result="BackgroundImageFix" />
				<feColorMatrix
					in="SourceAlpha"
					type="matrix"
					values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
					result="hardAlpha"
				/>
				<feOffset dy={2} />
				<feGaussianBlur stdDeviation={10} />
				<feComposite in2="hardAlpha" operator="out" />
				<feColorMatrix
					type="matrix"
					values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
				/>
				<feBlend
					mode="normal"
					in2="BackgroundImageFix"
					result="effect1_dropShadow_1811_2559"
				/>
				<feBlend
					mode="normal"
					in="SourceGraphic"
					in2="effect1_dropShadow_1811_2559"
					result="shape"
				/>
			</filter>
			<filter
				id="filter4_d_1811_2559"
				x={179.2}
				y={50.04}
				width={74}
				height={74}
				filterUnits="userSpaceOnUse"
				colorInterpolationFilters="sRGB"
			>
				<feFlood floodOpacity={0} result="BackgroundImageFix" />
				<feColorMatrix
					in="SourceAlpha"
					type="matrix"
					values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
					result="hardAlpha"
				/>
				<feOffset dy={2} />
				<feGaussianBlur stdDeviation={10} />
				<feComposite in2="hardAlpha" operator="out" />
				<feColorMatrix
					type="matrix"
					values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
				/>
				<feBlend
					mode="normal"
					in2="BackgroundImageFix"
					result="effect1_dropShadow_1811_2559"
				/>
				<feBlend
					mode="normal"
					in="SourceGraphic"
					in2="effect1_dropShadow_1811_2559"
					result="shape"
				/>
			</filter>
			<filter
				id="filter5_d_1811_2559"
				x={323.333}
				y={50.04}
				width={74}
				height={74}
				filterUnits="userSpaceOnUse"
				colorInterpolationFilters="sRGB"
			>
				<feFlood floodOpacity={0} result="BackgroundImageFix" />
				<feColorMatrix
					in="SourceAlpha"
					type="matrix"
					values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
					result="hardAlpha"
				/>
				<feOffset dy={2} />
				<feGaussianBlur stdDeviation={10} />
				<feComposite in2="hardAlpha" operator="out" />
				<feColorMatrix
					type="matrix"
					values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
				/>
				<feBlend
					mode="normal"
					in2="BackgroundImageFix"
					result="effect1_dropShadow_1811_2559"
				/>
				<feBlend
					mode="normal"
					in="SourceGraphic"
					in2="effect1_dropShadow_1811_2559"
					result="shape"
				/>
			</filter>
			<filter
				id="filter6_d_1811_2559"
				x={35.0669}
				y={106.733}
				width={74}
				height={74}
				filterUnits="userSpaceOnUse"
				colorInterpolationFilters="sRGB"
			>
				<feFlood floodOpacity={0} result="BackgroundImageFix" />
				<feColorMatrix
					in="SourceAlpha"
					type="matrix"
					values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
					result="hardAlpha"
				/>
				<feOffset dy={2} />
				<feGaussianBlur stdDeviation={10} />
				<feComposite in2="hardAlpha" operator="out" />
				<feColorMatrix
					type="matrix"
					values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
				/>
				<feBlend
					mode="normal"
					in2="BackgroundImageFix"
					result="effect1_dropShadow_1811_2559"
				/>
				<feBlend
					mode="normal"
					in="SourceGraphic"
					in2="effect1_dropShadow_1811_2559"
					result="shape"
				/>
			</filter>
			<filter
				id="filter7_d_1811_2559"
				x={179.2}
				y={106.733}
				width={74}
				height={74}
				filterUnits="userSpaceOnUse"
				colorInterpolationFilters="sRGB"
			>
				<feFlood floodOpacity={0} result="BackgroundImageFix" />
				<feColorMatrix
					in="SourceAlpha"
					type="matrix"
					values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
					result="hardAlpha"
				/>
				<feOffset dy={2} />
				<feGaussianBlur stdDeviation={10} />
				<feComposite in2="hardAlpha" operator="out" />
				<feColorMatrix
					type="matrix"
					values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
				/>
				<feBlend
					mode="normal"
					in2="BackgroundImageFix"
					result="effect1_dropShadow_1811_2559"
				/>
				<feBlend
					mode="normal"
					in="SourceGraphic"
					in2="effect1_dropShadow_1811_2559"
					result="shape"
				/>
			</filter>
			<filter
				id="filter8_d_1811_2559"
				x={323.333}
				y={106.733}
				width={74}
				height={74}
				filterUnits="userSpaceOnUse"
				colorInterpolationFilters="sRGB"
			>
				<feFlood floodOpacity={0} result="BackgroundImageFix" />
				<feColorMatrix
					in="SourceAlpha"
					type="matrix"
					values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
					result="hardAlpha"
				/>
				<feOffset dy={2} />
				<feGaussianBlur stdDeviation={10} />
				<feComposite in2="hardAlpha" operator="out" />
				<feColorMatrix
					type="matrix"
					values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
				/>
				<feBlend
					mode="normal"
					in2="BackgroundImageFix"
					result="effect1_dropShadow_1811_2559"
				/>
				<feBlend
					mode="normal"
					in="SourceGraphic"
					in2="effect1_dropShadow_1811_2559"
					result="shape"
				/>
			</filter>
			<linearGradient
				id="paint0_linear_1811_2559"
				x1={311.31}
				y1={124}
				x2={223.21}
				y2={124}
				gradientUnits="userSpaceOnUse"
			>
				<stop offset={0.76} stopColor="#EDEDED" />
				<stop offset={1} stopColor="#CCCCCC" />
			</linearGradient>
			<linearGradient
				id="paint1_linear_1811_2559"
				x1={314.62}
				y1={95.1501}
				x2={222.51}
				y2={95.1501}
				gradientUnits="userSpaceOnUse"
			>
				<stop offset={0.76} stopColor="white" />
				<stop offset={1} stopColor="#E1E1E1" />
			</linearGradient>
			<linearGradient
				id="paint2_linear_1811_2559"
				x1={313.91}
				y1={66.3501}
				x2={219.81}
				y2={66.3501}
				gradientUnits="userSpaceOnUse"
			>
				<stop offset={0.76} stopColor="#EDEDED" />
				<stop offset={1} stopColor="#CCCCCC" />
			</linearGradient>
			<linearGradient
				id="paint3_linear_1811_2559"
				x1={222.51}
				y1={62.65}
				x2={123.8}
				y2={62.65}
				gradientUnits="userSpaceOnUse"
			>
				<stop offset={0.76} stopColor="#EDEDED" />
				<stop offset={1} stopColor="#CCCCCC" />
			</linearGradient>
			<linearGradient
				id="paint4_linear_1811_2559"
				x1={225.06}
				y1={120.9}
				x2={151.76}
				y2={120.9}
				gradientUnits="userSpaceOnUse"
			>
				<stop offset={0.76} stopColor="#EDEDED" />
				<stop offset={1} stopColor="#CCCCCC" />
			</linearGradient>
			<linearGradient
				id="paint5_linear_1811_2559"
				x1={225.66}
				y1={91.6499}
				x2={124.11}
				y2={91.6499}
				gradientUnits="userSpaceOnUse"
			>
				<stop offset={0.76} stopColor="white" />
				<stop offset={1} stopColor="#E1E1E1" />
			</linearGradient>
			<clipPath id="clip0_1811_2559">
				<rect width={467.72} height={170.08} fill="white" />
			</clipPath>
		</defs>
	</svg>
);
export default SvgCarSideRight;
