import { CheckIcon } from "components/Icons";
import React, { useState } from "react";
import {
    Card,
    CardImg,
    Col,
    Row,
    ListGroup,
    ListGroupItem,
    Button
} from "reactstrap";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import { renderSelectedComponent } from "./helpers/renderSelectedComponent";
import { getImage } from "./helpers/getImage";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";

const ImageSelector = ({
    setClickedZones,
    clickedZones,
    options,
    setFieldValue,
    field,
    errors
}) => {
    useEffect(() => {
        const parsedValues = field.value ? JSON.parse(field.value) : [];

        setClickedZones(parsedValues);
    }, []);

    const [selectedImageId, setSelectedImageId] = useState(options[0].labelKey);
    const { t } = useTranslation();

    const handleImageClick = (labelKey) => {
        setSelectedImageId(labelKey);
    };

    const handlePathClick = (zoneId, zoneText) => {
        const existingPath = clickedZones?.find(
            (path) => path.zoneId === zoneId
        );

        if (!existingPath) {
            setClickedZones([
                ...clickedZones,
                {
                    zoneId: zoneId,
                    text: zoneText
                }
            ]);
        } else if (!existingPath) {
            const updatedPaths = clickedZones?.map((path) =>
                path.zoneId === zoneId ? { ...path } : path
            );
            setClickedZones(updatedPaths);
        }
    };

    const handleDeleteZone = (zoneId) => {
        setClickedZones(clickedZones?.filter((path) => path.zoneId !== zoneId));
    };

    useEffect(() => {
        if (clickedZones === null) {
            setFieldValue(null);
        } else {
            setFieldValue(field.id, JSON.stringify(clickedZones));
        }
    }, [clickedZones]);

    return (
        <>
            <div className="incident-image-clicker radius-5 p-3 ">
                <Row className="">
                    {options &&
                        options.map((image) => {
                            return (
                                <Col
                                    md={options.length > 3 ? 3 : 4}
                                    xs={options.length > 3 ? 3 : 4}
                                    key={image.labelKey}
                                    className="px-1"
                                >
                                    <Card
                                        color="white"
                                        className={`mb-4 incident-image-clicker__card`}
                                        onClick={() => {
                                            handleImageClick(
                                                image.labelKey,
                                                image.nextQuestionId
                                            );
                                        }}
                                    >
                                        <CardImg
                                            src={getImage(image.labelKey)}
                                            alt={`Thumbnail ${image.labelKey}`}
                                            className={`thumbnail incident-image-clicker__card-image ${
                                                selectedImageId ===
                                                image.labelKey
                                                    ? "incident-image-clicker__active-card"
                                                    : ""
                                            }`}
                                        />
                                    </Card>
                                </Col>
                            );
                        })}
                </Row>
                <Row className="large-image-container d-flex justify-content-center">
                    <Col
                        md={12}
                        xs={12}
                        className="d-flex flex-column align-items-center justify-content-center"
                    >
                        {renderSelectedComponent(
                            selectedImageId,
                            clickedZones,
                            handlePathClick
                        )}
                    </Col>
                </Row>
            </div>
            <ListGroup className="m-0 incident-image-clicker-list">
                {clickedZones?.map((zone) => {
                    const inputString = zone.text;
                    const modifiedString = inputString
                        .toLowerCase()
                        .replace(/[-\s]/g, "_");

                    return (
                        <ListGroupItem
                            className="bg-white border-1 d-flex justify-content-between incident-image-clicker-list__item d-flex align-items-center mt-2 mt-md-3 rounded-3"
                            key={zone.zoneId}
                        >
                            <div className="d-flex align-items-center">
                                <span className="incident-image-clicker-list__check">
                                    <FontAwesomeIcon
                                        color="#3c7749"
                                        icon={faCheckCircle}
                                        size="sm"
                                    />
                                </span>
                                <span className="ms-2">
                                    {t(modifiedString, zone.text)}
                                </span>
                            </div>
                            <Button
                                color={"$red"}
                                className="p-0"
                                onClick={() => handleDeleteZone(zone.zoneId)}
                            >
                                <FontAwesomeIcon
                                    icon={faTrashAlt}
                                    color="#dc0000"
                                />
                            </Button>
                        </ListGroupItem>
                    );
                })}
            </ListGroup>
        </>
    );
};
export default ImageSelector;
