import { faTimes } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "reactstrap";

const TableFiltrationButton = ({ row, column }) => {
	const handleClick = (option) => {
		column.setFilterValue((old) => {
			let newArray = old !== null ? old : [];
			if (old !== undefined && old.length > 0) {
				const removeFilterIndex = newArray?.findIndex(
					(x) => x === option.value
				);
				if (removeFilterIndex !== -1) {
					newArray.splice(removeFilterIndex, 1);
				} else {
					newArray = [...newArray, option.value];
				}
			} else {
				newArray = [option.value];
			}
			return newArray !== null && newArray.length > 0 ? newArray : "";
		});
	};

	const activeOption = (column, option) => {
		return column.getFilterValue() !== undefined
			? column.getFilterValue().findIndex((x) => x === option.value) ===
					-1
			: true;
	};
	return (
		<div className="d-flex flex-wrap flex-md-row gap-2">
			{column.columnDef.FilterOptions?.map((option, index) => {
				return (
					<div key={index} className="table-filtration-button ">
						<Button
							size="sm"
							color="black"
							className={`d-flex flex-row align-items-center table-filtration-button__button ${
								!activeOption(column, option) &&
								"table-button-active"
							}`}
							outline
							onClick={() => {
								handleClick(option);
							}}
						>
							<p className="m-0">{option.name}</p>{" "}
							{!activeOption(column, option) && (
								<FontAwesomeIcon
									className={`ms-2 table-filtration-button__icon ${
										!activeOption(column, option) &&
										"table-icon-active"
									}`}
									icon={faTimes}
								/>
							)}
						</Button>
					</div>
				);
			})}
		</div>
	);
};
export default TableFiltrationButton;
