import React, { useState, useRef, useEffect } from "react";
import {
    Button,
    ButtonGroup,
    Alert,
    ListGroup,
    ListGroupItem
} from "reactstrap";
import { useApi } from "components/useApi3";
import { useTranslation } from "react-i18next";

const SingleImageUpload = ({
    attachment,
    onSuccessUpload,
    onRemoveImageItem,
    apiEndpoint,
    imageApiEndpoint
}) => {
    const [uploadResult, uploadApi] = useApi();
    const [imageResult, imageApi] = useApi();
    const { t } = useTranslation();
    const [file, setFile] = useState(null);
    const [image, setImage] = useState(null);
    const fileInput = useRef();

    const onFileUploadChange = (e) => {
        // console.log(e);
        e.preventDefault();
        setFile(e.target.files[0]);
    };

    const triggerInputFile = (e) => {
        fileInput.current.click();
    };

    useEffect(() => {
        if (file) {
            uploadApi.file(apiEndpoint, file);
        }
    }, [file, uploadApi]);

    useEffect(() => {
        if (uploadResult.status === 2) {
            onSuccessUpload(uploadResult.data);
            setFile(null);
            uploadApi.reset();
        }
        if (uploadResult.status > 2) {
            setFile(null);
        }
    }, [uploadResult, onSuccessUpload, uploadApi]);

    useEffect(() => {
        if (attachment) {
            imageApi.get(imageApiEndpoint.concat(attachment), attachment);
        }
    }, [attachment]);

    useEffect(() => {
        if (attachment !== null && imageResult.status === 2) {
            setImage(imageResult.data);
        } else {
            setImage(null);
        }
    }, [attachment, imageResult]);

    return (
        <ListGroup>
            <ListGroupItem>
                <input
                    className="d-none"
                    type="file"
                    name="file"
                    id="exampleFile"
                    ref={fileInput}
                    onChange={onFileUploadChange}
                ></input>
                <ButtonGroup className="mb-2">
                    <Button
                        color="white"
                        onClick={triggerInputFile}
                        disabled={uploadResult.status === 1}
                    >
                        {t("choose")}
                        {file ? (
                            <span>
                                {" "}
                                {uploadResult.status === 1 ? (
                                    <span>
                                        ({t("adds")} {file.name})
                                    </span>
                                ) : null}
                            </span>
                        ) : null}
                    </Button>
                    {attachment !== null && (
                        <Button
                            color="white"
                            onClick={() => {
                                onRemoveImageItem();
                            }}
                        >
                            {t("delete")}
                        </Button>
                    )}
                </ButtonGroup>
                {uploadResult.status > 2 ? (
                    <Alert color="warning">
                        {t("the_file_could_not_be_added")}
                    </Alert>
                ) : null}
            </ListGroupItem>
            {image !== null && (
                <>
                    <img
                        className="rounded-2 mw-100 mt-2 mt-md-0 img-fluid"
                        src={`data:image/png;base64,${image}`}
                        alt="vehicle"
                    />
                </>
            )}
        </ListGroup>
    );
};

export default SingleImageUpload;
