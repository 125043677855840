import { useState, useMemo } from "react";
import { GoogleMap, useLoadScript, Marker } from "@react-google-maps/api";
import usePlacesAutocomplete, {
	getDetails,
	getGeocode,
	getLatLng
} from "use-places-autocomplete";
import {
	Combobox,
	ComboboxInput,
	ComboboxPopover,
	ComboboxList,
	ComboboxOption
} from "@reach/combobox";
import { Col, Row } from "react-bootstrap";
import { useCallback } from "react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import LoadingSpinner from "components/LoadingSpinner";

const mapStyles = [];
const libraries = ["places"];

const IncidentMap = ({
	name,
	formGroup,
	setFieldValue,
	value,
	field,
	values
}) => {
	const [userLocation, setUserLocation] = useState(null);
	const [selected, setSelected] = useState(null);
	const [selectedAddress, setSelectedAddress] = useState(null);
	const [map, setMap] = useState(null);
	const [selectedMapClick, setSelectedMapClick] = useState("");

	const { isLoaded } = useLoadScript({
		id: "google-map-script",
		googleMapsApiKey: "AIzaSyC7x_PUYwT2eqhGzaCe0OuymwFr5_Kvo8s",
		libraries: libraries
	});

	const onLoad = useCallback((map) => setMap(map), []);

	useEffect(() => {
		let selectedValue;
		if (formGroup && formGroup.location) {
			selectedValue = JSON.parse(formGroup.location);
		} else if (field && field.value) {
			selectedValue = JSON.parse(field.value).value;
		} else {
			selectedValue = null;
		}

		if (selectedValue) {
			let parsedValue;
			try {
				parsedValue = selectedValue;
			} catch (error) {
				console.error(
					"Error parsing LatLng or LatLngLiteral object:",
					error
				);
			}

			if (
				parsedValue &&
				typeof parsedValue.lat === "number" &&
				typeof parsedValue.lng === "number"
			) {
				setSelected(parsedValue);
			} else {
				console.error(
					"Invalid LatLng or LatLngLiteral object:",
					parsedValue
				);
			}
		} else {
			setSelected(null);
		}
	}, []);

	useEffect(() => {
		if (navigator.geolocation) {
			navigator.geolocation.getCurrentPosition(
				(position) => {
					const userLat = position.coords.latitude;
					const userLng = position.coords.longitude;
					setUserLocation({ lat: userLat, lng: userLng });
				},
				(error) => {
					console.error("Error getting geolocation:", error);
				},
				{ enableHighAccuracy: true }
			);
		} else {
			console.error("Geolocation is not supported by your browser");
		}
	}, []);

	const center = useMemo(
		() => ({
			lat: userLocation ? userLocation.lat : 55.67685415159305,
			lng: userLocation ? userLocation.lng : 12.559832679900058
		}),
		[userLocation]
	);

	useEffect(() => {
		if (selected !== null) {
			setFieldValue(name, JSON.stringify(selected));
		}
	}, [selected]);

	const handleMapClick = async (event) => {
		const lat = event.latLng.lat();
		const lng = event.latLng.lng();
		setSelected({ lat, lng });

		try {
			const location = { lat, lng };
			const results = await getGeocode({ location });
			const address = results[0]?.formatted_address;

			if (address) {
				setSelectedMapClick(address);
			}
		} catch (error) {
			console.error("Error fetching address: ", error);
		}
	};

	useEffect(() => {
		if (selected !== null && map !== null) {
			map.panTo(selected);
			if (map.zoom < 14) {
				map.setZoom(14);
			}
		}
	}, [selected, map]);

	return isLoaded ? (
		<>
			<div className="places-container mb-2">
				<PlacesAutocomplete
					setFieldValue={setFieldValue}
					name={name}
					fieldValue={value}
					setSelected={setSelected}
					selected={selected}
					selectedMapClick={selectedMapClick}
					map={map}
				/>
			</div>
			<GoogleMap
				zoom={10}
				center={center}
				mapContainerClassName="map-container"
				onLoad={onLoad}
				onClick={handleMapClick}
				options={{ styles: mapStyles }}
			>
				{selected && <Marker position={selected} />}
			</GoogleMap>
		</>
	) : (
		<></>
	);
};

const PlacesAutocomplete = ({
	setSelected,
	selected,
	selectedMapClick,
	setFieldValue,
	name
}) => {
	const {
		ready,
		value,
		setValue,
		suggestions: { status, data },
		clearSuggestions
	} = usePlacesAutocomplete();

	const { t } = useTranslation();

	useEffect(() => {
		if (selected !== null) {
			const getAddressFromLatLng = async () => {
				let lat = selected?.lat;
				let lng = selected?.lng;
				const location = { lat, lng };
				const results = await getGeocode({ location });
				const address = results[0]?.formatted_address;

				if (address) {
					setValue(address, false);
					const newValue = JSON.stringify({
						interpretedValue: address,
						value: selected
					});
					setFieldValue(name, newValue);
				}
			};
			getAddressFromLatLng();
		}
	}, [selected]);

	useEffect(() => {
		if (selectedMapClick) {
			setValue(selectedMapClick, false);
		}
	}, [selectedMapClick]);

	const handleSelect = async (address) => {
		setValue(address, false);
		clearSuggestions();

		const results = await getGeocode({ address });

		const { lat, lng } = await getLatLng(results[0]);
		setSelected({ lat, lng });
	};

	return (
		<Row>
			<Col md={12} xs={12} className="">
				<Combobox onSelect={handleSelect} className="">
					<ComboboxInput
						value={value}
						onChange={(e) => setValue(e.target.value)}
						disabled={!ready}
						className="combobox-input form-control form-input"
						placeholder={t("search_address", "Search an address")}
					/>
					<ComboboxPopover className="">
						<ComboboxList className="list-group">
							{status === "OK" &&
								data.map(({ place_id, description }) => (
									<ComboboxOption
										className="list-group-item custom-list-item"
										key={place_id}
										value={description}
									/>
								))}
						</ComboboxList>
					</ComboboxPopover>
				</Combobox>
			</Col>
		</Row>
	);
};

export default IncidentMap;
