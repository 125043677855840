import Illustrationer_bil_bag from "assets/Illustrationer_bil_bag.png";
import Illustrationer_bil_front from "assets/Illustrationer_bil_front.png";
import Illustrationer_bil_side_left from "assets/Illustrationer_bil_side_left.png";
import Illustrationer_bil_side_right from "assets/Illustrationer_bil side_right.png";
import Illustrationer_lastbil_bag from "assets/Illustrationer_lastbil_bag.png";
import Illustrationer_lastbil_front from "assets/Illustrationer_lastbil_front.png";
import Illustrationer_lastbil_side_left from "assets/Illustrationer_lastbil_side_left.png";
import Illustrationer_lastbil_side_right from "assets/Illustrationer_lastbil_side_right.png";
import Illustrationer_trailer_bag from "assets/Illustrationer_trailer_bag.png";
import Illustrationer_trailer_front from "assets/Illustrationer_trailer_front.png";
import Illustrationer_trailer_side_left from "assets/Illustrationer_trailer_side_left.png";
import Illustrationer_trailer_side_right from "assets/Illustrationer_trailer_side_right.png";

export const getImage = (value) => {
    switch (value) {
        case "Illustrationer_bil_side_left":
            return Illustrationer_bil_side_left;
        case "Illustrationer_bil_front":
            return Illustrationer_bil_front;
        case "Illustrationer_bil_side_right":
            return Illustrationer_bil_side_right;
        case "Illustrationer_bil_bag":
            return Illustrationer_bil_bag;
        case "Illustrationer_lastbil_side_left":
            return Illustrationer_lastbil_side_left;
        case "Illustrationer_lastbil_front":
            return Illustrationer_lastbil_front;
        case "Illustrationer_lastbil_side_right":
            return Illustrationer_lastbil_side_right;
        case "Illustrationer_lastbil_bag":
            return Illustrationer_lastbil_bag;
        case "Illustrationer_trailer_side_left":
            return Illustrationer_trailer_side_left;
        case "Illustrationer_trailer_front":
            return Illustrationer_trailer_front;
        case "Illustrationer_trailer_side_right":
            return Illustrationer_trailer_side_right;
        case "Illustrationer_trailer_bag":
            return Illustrationer_trailer_bag;
        default:
            return null;
    }
};

export const getImageByZoneId = (zoneId) => {
    if (zoneId >= 1 && zoneId <= 9) return "Illustrationer_bil_bag";
    else if (zoneId >= 10 && zoneId <= 18)
        return "Illustrationer_bil_side_left";
    else if (zoneId >= 19 && zoneId <= 27) return "Illustrationer_bil_front";
    else if (zoneId >= 28 && zoneId <= 36)
        return "Illustrationer_bil_side_right";
    else if (zoneId >= 37 && zoneId <= 45) return "Illustrationer_bil_bag";
    else if (zoneId >= 46 && zoneId <= 54)
        return "Illustrationer_lastbil_side_left";
    else if (zoneId >= 55 && zoneId <= 63)
        return "Illustrationer_lastbil_front";
    else if (zoneId >= 64 && zoneId <= 72)
        return "Illustrationer_lastbil_side_right";
    else if (zoneId >= 77 && zoneId <= 85)
        return "Illustrationer_trailer_side_left";
    else if (zoneId >= 86 && zoneId <= 94)
        return "Illustrationer_trailer_front";
    else if (zoneId >= 95 && zoneId <= 103)
        return "Illustrationer_trailer_side_right";
};
