import React from "react";
import { Formik } from "formik";

import { Alert, Form } from "reactstrap";

import { t } from "i18next";
import { HandleTrygIncidentFormInputs } from "./HandleTrygIncidentFormInputs";
import { useContext } from "react";
import { AppContext } from "AppContext";
import { useState } from "react";

const TrygEdit = ({
    initialValues,
    onSubmit,
    adminQuestionsMissing,
    loading
}) => {
    const appContextObj = useContext(AppContext);
    const [submitEndpoint, setSubmitEndpoint] = useState();
    return (
        <>
            <Formik
                initialValues={initialValues ? initialValues : {}}
                validateOnBlur={false}
                validateOnChange={false}
                onSubmit={(values) => {
                    onSubmit(values, submitEndpoint);
                }}
            >
                {({
                    errors,
                    values,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    setFieldValue
                }) => (
                    <Form onSubmit={handleSubmit} noValidate>
                        {Object.keys(errors).length > 0 && (
                            <Alert color="danger">
                                {t(
                                    "you_have_not_filled_in_all_required_fields._Questions_marked_with_red_text_must_be_completed"
                                )}
                            </Alert>
                        )}

                        <>
                            <HandleTrygIncidentFormInputs
                                appContextObj={appContextObj}
                                values={values}
                                errors={errors}
                                touched={touched}
                                handleBlur={handleBlur}
                                handleChange={handleChange}
                                setFieldValue={setFieldValue}
                                handleSubmit={handleSubmit}
                                setSubmitEndpoint={setSubmitEndpoint}
                                adminQuestionsMissing={adminQuestionsMissing}
                                loading={loading}
                            />
                        </>
                    </Form>
                )}
            </Formik>
        </>
    );
};
export default TrygEdit;
