import Panel from "components/Panel";
import PageHeader from "components/PageHeader";
import { useApi } from "components/useApi3";
import moment from "moment";
import { useEffect } from "react";
import LoadingSpinner from "components/LoadingSpinner";
import { Alert, Button, Col, Row } from "reactstrap";
import { useHistory } from "react-router-dom";
import { ArrowLeftIcon, EmailIcon } from "components/Icons";
// two tabbar, en för "hantera ärende" och en för "skadeinformation"
// det man hämtar och redigerar är en IncidentHandleFormDto som är step1+step2+handle

const PageLabel = ({ result }) => {
    const data =
        result.status === 2
            ? result.data
            : {
                  insuranceCategory: "",
                  location: "",
                  crashCategory: "",
                  theftItems: "",
                  fireType: "",
                  vehicleDisplayName: "",
                  incidentDate: ""
              };
    const title =
        data.insuranceCategory === "CRASH"
            ? data.crashCategory
            : data.insuranceCategory === "THEFT"
            ? data.theftItems
            : data.insuranceCategory === "FIRE"
            ? data.fireType
            : "".concat(" - ").concat(data.vehicleDisplayName);
    const subtitle = data.location
        .concat(" | ")
        .concat(data.incidentDate ? moment(data.incidentDate).format("L") : "");

    return (
        <PageHeader
            title={"Skicka ärende: ".concat(title)}
            subtitle={subtitle}
        />
    );
};

const IncidentEdit = ({ match }) => {
    const [itemResult, itemApi] = useApi();
    const [textResult, textApi] = useApi();
    const [sendResult, sendApi] = useApi();

    const history = useHistory();

    useEffect(() => {
        itemApi.get("api/incident/incidents/".concat(match.params.id));
        textApi.get(
            "api/incident/incidents/".concat(match.params.id).concat("/astext")
        );
    }, [itemApi, textApi, match]);

    useEffect(() => {
        if (sendResult.status === 2) {
            history.push("/incidents/list");
        }
    }, [sendResult, history]);

    return (
        <>
            <PageLabel result={itemResult} />
            <Panel>
                <>
                    <h1>Mailets innehåll:</h1>
                    {(itemResult.status === 1 || itemResult.status === 1) && (
                        <LoadingSpinner />
                    )}
                    {textResult.status === 2 && <pre>{textResult.data}</pre>}
                    <Row>
                        <Col xs={12} className="d-flex justify-content-between">
                            <Button
                                onClick={() => {
                                    history.goBack();
                                }}
                                color="white"
                            >
                                <ArrowLeftIcon /> Tillbaka
                            </Button>

                            <Button
                                disabled={sendApi.result === 1}
                                color="warning"
                                onClick={() => {
                                    sendApi.post(
                                        "api/incident/incidents/"
                                            .concat(match.params.id)
                                            .concat("/send")
                                    );
                                }}
                            >
                                <EmailIcon /> Skicka
                            </Button>
                            {sendApi.status > 2 && (
                                <Alert color="danger">
                                    Ett fel inträffade. Kunde inte skicka
                                    ärendet.
                                </Alert>
                            )}
                            {sendApi.status === 1 && (
                                <Alert color="info">Skickar ärendet..</Alert>
                            )}
                            {sendApi.status === 2 && (
                                <Alert color="info">Ärendet skickat.</Alert>
                            )}
                        </Col>
                    </Row>
                </>
            </Panel>
        </>
    );
};
export default IncidentEdit;
