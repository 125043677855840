import {
	faCheckCircle,
	faPaperPlane,
	faPenSquare,
	faSave,
	faTrash
} from "@fortawesome/pro-light-svg-icons";

export const incidentStatusByStatusName = (status, t, customer) => {
	const statusLowered = status !== null ? status?.toLowerCase() : "";

	if (customer === "Tryg") {
		return statusLowered === "open"
			? {
					label: t("incoming", "Incoming"),
					color: "#EDEDED",
					textColor: "#000000",
					backgroundColor: "#FAFAFA",
					icon: faPenSquare
			  }
			: statusLowered === "new"
			? {
					label: t("new", "New"),
					color: "#DC0000",
					backgroundColor: "#F8E0E0",
					icon: faSave
			  }
			: statusLowered === "recieved"
			? {
					label: t("reviewed", "Reviewed"),
					color: "#E36D00",
					backgroundColor: "#F6EADE",
					icon: faSave
			  }
			: statusLowered === "senttotryg"
			? {
					label: t("tryg_damage", "Tryg damage"),
					color: "#5276D5",
					backgroundColor: "#E5EDFF",
					icon: faPaperPlane
			  }
			: statusLowered === "completed"
			? {
					label: t("completed", "Completed"),
					color: "#00BC50",
					backgroundColor: "#DFF8E9",
					icon: faCheckCircle
			  }
			: statusLowered === "removed"
			? {
					label: t("removed", "Borttagen"),
					color: "#ededed",
					textColor: "#000",
					backgroundColor: "#FAFAFA",
					icon: faTrash
			  }
			: statusLowered === "savedlocally"
			? {
					label: t("internal_damage", "Internal damage"),
					color: "#5276D5",
					backgroundColor: "#E5EDFF",
					icon: faSave
			  }
			: null;
	} else {
		return statusLowered === "open"
			? { label: t("new", "Ny"), color: "info" }
			: statusLowered === "sent"
			? { label: t("sent", "Skickad"), color: "primary" }
			: statusLowered === "completed"
			? { label: t("closed", "Avslutad"), color: "success" }
			: null;
	}
};

export const incidentStatusById = (status, t, customer) => {
	if (customer === "Tryg") {
		return status === 0
			? {
					label: t("incoming", "Incoming"),
					color: "#EDEDED",
					textColor: "#000000",
					backgroundColor: "#FAFAFA",
					icon: faPenSquare
			  }
			: status === 1
			? {
					label: t("new", "New"),
					color: "#DC0000",
					backgroundColor: "#F8E0E0",
					icon: faSave
			  }
			: status === 2
			? {
					label: t("reviewed", "Reviewed"),
					color: "#E36D00",
					backgroundColor: "#F6EADE",
					icon: faSave
			  }
			: status === 3
			? {
					label: t("tryg_damage", "Tryg damage"),
					color: "#5276D5",
					backgroundColor: "#E5EDFF",
					icon: faPaperPlane
			  }
			: status === 4
			? {
					label: t("completed", "Completed"),
					color: "#00BC50",
					backgroundColor: "#DFF8E9",
					icon: faCheckCircle
			  }
			: status === 5
			? {
					label: t("removed", "Borttagen"),
					color: "#ededed",
					textColor: "#000",
					backgroundColor: "#FAFAFA",
					icon: faTrash
			  }
			: status === 6
			? {
					label: t("internal_damage", "Internal damage"),
					color: "#5276D5",
					backgroundColor: "#E5EDFF",
					icon: faSave
			  }
			: null;
	}
};
