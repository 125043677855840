import React, { useEffect, useMemo, useState } from "react";
import { useApi } from "components/useApi3";
import DataTable from "components/DataTable";

import PageHeader from "components/PageHeader";
import Panel from "components/Panel";
import {
    Button,
    ButtonGroup,
    Col,
    Form,
    FormGroup,
    Modal,
    ModalBody,
    ModalHeader,
    Row
} from "reactstrap";
import { Formik } from "formik";
import * as Yup from "yup";
import AlertResultFeedback from "components/AlertResultFeedback";
import ItemForm from "./Form";
import LoadingSpinner from "components/LoadingSpinner";
import { useTranslation } from "react-i18next";

const validationSchema = Yup.object().shape({
    name: Yup.string().required("Namn är obligatoriskt")
});

const CrudPage = ({ match }) => {
    const apiBaseUrl = "api/superadmin/companies/"
        .concat(match.params.id)
        .concat("/sites/");
    const { t } = useTranslation();

    const [listResult, listApi] = useApi();
    const [createResult, createApi] = useApi();
    const [editItemResult, editItemApi] = useApi();
    const [editResult, editApi] = useApi();
    const [deleteResult, deleteApi] = useApi();

    const [showCreate, setShowCreate] = useState(false);
    const [showEdit, setShowEdit] = useState(false);
    const [showDelete, setShowDelete] = useState(false);
    const [deleteId, setDeleteId] = useState(null);

    useEffect(() => {
        listApi.get(apiBaseUrl);
    }, [listApi, apiBaseUrl]);

    // post skapa success
    useEffect(() => {
        if (createResult.status === 2) {
            listApi.get(apiBaseUrl);
            createApi.reset();
            setShowCreate(false);
        }
    }, [createResult.status, listApi, createApi, apiBaseUrl]);

    // post delete success
    useEffect(() => {
        if (deleteResult.status === 2) {
            listApi.get(apiBaseUrl);
            deleteApi.reset();
            setShowDelete(false);
            setShowEdit(false);
            setDeleteId(null);
        }
    }, [deleteResult.status, listApi, deleteApi, apiBaseUrl]);

    // post edit success
    useEffect(() => {
        if (editResult.status === 2) {
            listApi.get(apiBaseUrl);
            editApi.reset();
            setShowEdit(false);
        }
    }, [editResult.status, listApi, editApi, apiBaseUrl]);

    const columns = useMemo(
        () => [
            {
                accessorKey: "name",
                header: t("name_department")
            },
            {
                accessorKey: "commands",
                header: "",
                cell: (cell) => {
                    return (
                        <ButtonGroup className="float-end">
                            <Button
                                color="white"
                                onClick={() => {
                                    editItemApi.get(
                                        apiBaseUrl.concat(cell.row.original.id)
                                    );
                                    setShowEdit(true);
                                }}
                            >
                                {t("edit")}
                            </Button>
                        </ButtonGroup>
                    );
                }
            }
        ],
        [editItemApi, apiBaseUrl]
    );

    return (
        <>
            <PageHeader
                title={t("places", "Cities")}
                commands={
                    <Button
                        onClick={() => {
                            setShowCreate(true);
                        }}
                        color="primary"
                    >
                        {t("add")} {t("city")}
                    </Button>
                }
            />
            <Panel>
                <DataTable
                    hideFilterButton={true}
                    columns={columns}
                    result={listResult}
                />
            </Panel>
            <Modal
                isOpen={showCreate}
                toggle={() => {
                    setShowCreate(!showCreate);
                }}
                onClosed={() => {
                    createApi.reset();
                }}
            >
                <ModalHeader>
                    {t("add")} {t("city")}
                </ModalHeader>
                <ModalBody>
                    <Row>
                        <Col xs={12}>
                            <Formik
                                initialValues={{
                                    name: "",
                                    companyId: match.params.id
                                }}
                                onSubmit={(values) => {
                                    // same shape as initial values

                                    createApi.post(apiBaseUrl, values);
                                }}
                                validationSchema={validationSchema}
                            >
                                {({
                                    errors,
                                    values,
                                    touched,
                                    handleChange,
                                    handleBlur,
                                    handleSubmit,
                                    setFieldValue
                                }) => (
                                    <Form onSubmit={handleSubmit} noValidate>
                                        <ItemForm
                                            values={values}
                                            errors={errors}
                                            touched={touched}
                                            handleBlur={handleBlur}
                                            handleChange={handleChange}
                                            setFieldValue={setFieldValue}
                                        />
                                        <FormGroup>
                                            <Row>
                                                <Col xs={6}>
                                                    <Button
                                                        block
                                                        color="white"
                                                        onClick={() => {
                                                            setShowCreate(
                                                                false
                                                            );
                                                        }}
                                                    >
                                                        Avbryt
                                                    </Button>
                                                </Col>
                                                <Col xs={6}>
                                                    <Button
                                                        block
                                                        disabled={
                                                            createResult.status ===
                                                            1
                                                        }
                                                        color="primary"
                                                        type="submit"
                                                    >
                                                        Lägg till
                                                    </Button>
                                                </Col>
                                            </Row>
                                        </FormGroup>
                                    </Form>
                                )}
                            </Formik>
                        </Col>
                    </Row>

                    <Row>
                        <Col xs={12}>
                            <AlertResultFeedback result={createResult} />
                        </Col>
                    </Row>
                </ModalBody>
            </Modal>
            <Modal
                isOpen={showEdit}
                toggle={() => {
                    setShowEdit(!showEdit);
                }}
                onClosed={() => {
                    editApi.reset();
                }}
            >
                <ModalHeader>Redigera bolag</ModalHeader>
                {editItemResult.status === 1 && (
                    <ModalBody>
                        <LoadingSpinner />
                    </ModalBody>
                )}
                {editItemResult.status === 2 && (
                    <ModalBody>
                        <Row>
                            <Col xs={12}>
                                <Formik
                                    initialValues={{
                                        ...editItemResult.data
                                    }}
                                    onSubmit={(values) => {
                                        // same shape as initial values

                                        editApi.put(apiBaseUrl, values);
                                    }}
                                    validationSchema={validationSchema}
                                >
                                    {({
                                        errors,
                                        values,
                                        touched,
                                        handleChange,
                                        handleBlur,
                                        handleSubmit,
                                        setFieldValue
                                    }) => (
                                        <Form
                                            onSubmit={handleSubmit}
                                            noValidate
                                        >
                                            <FormGroup>
                                                <Row>
                                                    <Col>
                                                        <Button
                                                            disabled={
                                                                editResult.status ===
                                                                1
                                                            }
                                                            onClick={() => {
                                                                setDeleteId(
                                                                    values.id
                                                                );
                                                                setShowDelete(
                                                                    true
                                                                );
                                                            }}
                                                            className="float-end mt-2"
                                                            color="danger"
                                                            type="button"
                                                        >
                                                            {t("delete")}
                                                        </Button>
                                                    </Col>
                                                </Row>
                                            </FormGroup>
                                            <ItemForm
                                                handleSubmit={handleSubmit}
                                                handleBlur={handleBlur}
                                                values={values}
                                                errors={errors}
                                                touched={touched}
                                                handleChange={handleChange}
                                                setFieldValue={setFieldValue}
                                            />
                                            <FormGroup>
                                                <Row>
                                                    <Col xs={6}>
                                                        <Button
                                                            block
                                                            color="white"
                                                            onClick={() => {
                                                                setShowEdit(
                                                                    false
                                                                );
                                                            }}
                                                        >
                                                            {t("cancel")}
                                                        </Button>
                                                    </Col>
                                                    <Col xs={6}>
                                                        <Button
                                                            block
                                                            disabled={
                                                                editResult.status ===
                                                                1
                                                            }
                                                            color="primary"
                                                            type="submit"
                                                        >
                                                            {t("save")}
                                                        </Button>
                                                    </Col>
                                                </Row>
                                            </FormGroup>
                                        </Form>
                                    )}
                                </Formik>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12}>
                                <AlertResultFeedback result={editResult} />
                            </Col>
                        </Row>
                    </ModalBody>
                )}
            </Modal>
            <Modal
                isOpen={showDelete}
                toggle={() => {
                    setShowDelete(!showDelete);
                }}
                onClosed={() => {
                    deleteApi.reset();
                }}
            >
                <ModalHeader>
                    {" "}
                    {t("delete")} {t("city")}
                </ModalHeader>
                {deleteId && (
                    <ModalBody>
                        <h4>
                            {" "}
                            {t("are_you_sure_you_want_to_delete")} {t("city")}?
                        </h4>
                        <Row>
                            <Col xs={6}>
                                <Button
                                    block
                                    color="white"
                                    onClick={() => {
                                        setShowDelete(false);
                                        setDeleteId(null);
                                    }}
                                >
                                    {t("cancel")}
                                </Button>
                            </Col>
                            <Col xs={6}>
                                <Button
                                    disabled={deleteResult.status === 1}
                                    block
                                    color="danger"
                                    onClick={() => {
                                        deleteApi.del(
                                            apiBaseUrl.concat(deleteId)
                                        );
                                    }}
                                >
                                    {t("delete")}
                                </Button>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12}>
                                <AlertResultFeedback result={deleteResult} />
                            </Col>
                        </Row>
                    </ModalBody>
                )}
            </Modal>
        </>
    );
};
export default CrudPage;
