import React, { useEffect, useState } from "react";
import moment from "moment";
import { useApi } from "components/useApi3";
import { FileIcon, LoadingIcon } from "components/Icons";
import { useTranslation } from "react-i18next";
import { Col, Input, Label, Row } from "reactstrap";
import { isJson, renderJson } from "./convertIncidentValues";
import { useJsApiLoader } from "@react-google-maps/api";
import { getGeocode } from "use-places-autocomplete";
import ImageSelectorStatic from "./imageSelectorStatic";
import FileUpload from "pages/incidents/components/FileUpload";
import TooltipIcon from "components/Tooltip";
import { t } from "i18next";

const LocationPicker = ({ fetchedAddress, formGroup }) => {
    return (
        <p className="m-0">
            {fetchedAddress ? fetchedAddress : <LoadingIcon />}
        </p>
    );
};

const AutoSelectLocationButton = ({ field, formGroup }) => {
    const { t } = useTranslation();

    return <p className="m-0">{t(field.labelKey)}</p>;
};

const ImageSelector = ({ interpretedValue, options, formGroup }) => (
    <ImageSelectorStatic
        clickedZones={
            isJson(interpretedValue) ? JSON.parse(interpretedValue) : []
        }
        options={options}
    />
);

const ImageCard = ({ field, formGroup }) => {
    const { t } = useTranslation();

    const idToNumber = Number(field.interpretedValue);
    const filteredImageCards = field.options?.filter(
        (option) => option.id === idToNumber
    );

    return (
        filteredImageCards.length > 0 &&
        filteredImageCards.map((image) => {
            return (
                <Col md={6} xs={12} key={image.id}>
                    <p>{t(image.labelKey, image.translateDK)}</p>
                    <img
                        className="rounded-2 mw-100 mt-2 mt-md-0 img-fluid"
                        alt={t(image.labelKey, image.translateDK)}
                        src={`data:image/png;base64,${image.value}`}
                    />
                </Col>
            );
        })
    );
};

const Upload = ({ field, incidentId, formGroup }) => {
    const [uploadArray, setUploadArray] = useState(
        isJson(field.value) ? JSON.parse(field.value) : []
    );

    console.log(field);

    const [updateAnswerResult, answerDataApi] = useApi();

    useEffect(() => {
        if (updateAnswerResult.status === 2) {
            setUploadArray(
                isJson(updateAnswerResult.data.value)
                    ? JSON.parse(updateAnswerResult.data.value)
                    : []
            );
        }
    }, [updateAnswerResult]);

    const updateAnswer = (fileList) => {
        const values = {
            incidentId: incidentId,
            value: JSON.stringify(fileList),
            interpretedValue: JSON.stringify(fileList),
            fieldId: field.id,
            formGroupId: field.selectedFormGroupId
        };
        answerDataApi.put("api/incident/incidents/updateanswer/", values);
    };

    return (
        <div>
            <FileUpload
                attachments={uploadArray}
                onSuccessUpload={(uploadedImage) => {
                    const newArray =
                        uploadArray && uploadArray.length > 0
                            ? [...uploadArray, uploadedImage]
                            : [uploadedImage];
                    updateAnswer(newArray);
                }}
                onRemoveImageItem={(name) => {
                    const newArray = uploadArray.filter(
                        (item) => item.name !== name
                    );
                    updateAnswer(newArray);
                }}
                apiEndpoint="api/incident/uploadtrygimage"
                downloadApiEndpoint="api/incident/downloadtrygimage?name="
            />
        </div>
    );
};

const IncidentDateTimePicker = ({ field, component, formGroup }) => {
    return (
        <p className="m-0">
            {moment(field.value).format("dddd, YYYY-MM-DD HH:mm")}
        </p>
    );
};

const Select = ({ field, formGroup }) => (
    <ul className="m-0 ps-3">
        <li>{field.interpretedValue}</li>
    </ul>
);

const InputField = ({ field, incidentId, formGroup }) => {
    const { t } = useTranslation();
    const [value, setValue] = useState(field.value);
    const [updateAnswerResult, answerDataApi] = useApi();
    const updateAnswer = () => {
        const values = {
            incidentId: incidentId,
            value: value,
            interpretedValue: value,
            fieldId: field.id,
            formGroupId: field.selectedFormGroupId
        };
        answerDataApi.put("api/incident/incidents/updateanswer/", values);
    };
    return (
        <>
            {field.labelKey !== "" || field.translateDK !== "" ? (
                <Label>{t(field.labelKey, field.translateDK)}</Label>
            ) : null}
            <Input
                value={value}
                onBlur={() => updateAnswer(field)}
                onChange={(newValue) => setValue(newValue.target.value)}
            />
        </>
    );
};

const libraries = ["places"];

export const IncidentDataTypeRender = (
    component,
    field,
    t,
    incidentId,
    formGroup
) => {
    const [downloadResult, downloadApi] = useApi();

    const downloadFile = (name) => {
        downloadApi.getBlob(
            "api/incident/downloadimage?name=".concat(name),
            name
        );
    };

    switch (component) {
        case "LocationPicker":
            return (
                <LocationPicker
                    formGroup={formGroup}
                    fetchedAddress={JSON.parse(field.value).interpretedValue}
                />
            );
        case "ImageSelector":
            return (
                <ImageSelector
                    interpretedValue={field.interpretedValue}
                    options={field.options}
                    formGroup={formGroup}
                />
            );
        case "Upload":
            return (
                <Upload
                    field={field}
                    downloadFile={downloadFile}
                    incidentId={incidentId}
                    formGroup={formGroup}
                />
            );
        case "IncidentDateTimePicker":
            return (
                <IncidentDateTimePicker
                    formGroup={formGroup}
                    field={field}
                    component={component}
                />
            );
        case "Select":
            return <Select formGroup={formGroup} field={field} />;
        case "Checkbox":
            return <div> {field.value === "true" ? t("yes") : t("no")}</div>;

        case "DatePicker":
            return (
                <div>
                    {field.value !== null &&
                        moment(field.value).format("dddd, YYYY-MM-DD")}
                </div>
            );
        case "Input":
            return (
                <InputField
                    formGroup={formGroup}
                    field={field}
                    incidentId={incidentId}
                />
            );
        case "ImageCard":
            return <ImageCard formGroup={formGroup} field={field} />;
        case "AutoSelectLocationButton":
            return (
                <AutoSelectLocationButton formGroup={formGroup} field={field} />
            );
        case "MultiSelect":
            return (
                <div className="m-0">
                    {isJson(field.interpretedValue) ? (
                        <JsonCardMultiSelect
                            formGroup={formGroup}
                            values={renderJson(field.interpretedValue)}
                        />
                    ) : (
                        t(field.value, field.value)
                    )}
                </div>
            );
        case "FormTemplateButton":
            return <div>{field.translateDK}</div>;
        default:
            return (
                <div className="m-0">
                    {isJson(field.interpretedValue) ? (
                        <JsonCard
                            values={renderJson(field.interpretedValue)}
                            field={field}
                            formGroup={formGroup}
                        />
                    ) : (
                        <div>{field.interpretedValue}</div>
                    )}
                </div>
            );
    }
};

const JsonCardMultiSelect = ({ values, formGroup }) => {
    const { t } = useTranslation();
    return (
        <>
            {values.length > 0 ? (
                <Col
                    xs={12}
                    style={{
                        backgroundColor: "#E5F2F6"
                    }}
                    className="p-3 rounded d-flex flex-column"
                >
                    {values
                        ?.filter((x) => x.labelKey === "LabelKey")
                        .map((x) => {
                            return (
                                <Row>
                                    <Col md={8}>{t(x.value)}</Col>
                                </Row>
                            );
                        })}
                </Col>
            ) : (
                <Col md={6} xs={12}>
                    -
                </Col>
            )}
        </>
    );
};

const JsonCard = ({ values, field, formGroup }) => {
    const { t } = useTranslation();
    const [value, setValue] = useState(() =>
        field.interpretedValue ? JSON.parse(field.interpretedValue) : []
    );

    const [updateAnswerResult, answerDataApi] = useApi();
    const updateAnswer = () => {
        const values = {
            value: JSON.stringify(value),
            interpretedValue: JSON.stringify(value),
            id: field.answerId,
            selectedFormGroupId: field.selectedFormGroupId
        };
        answerDataApi.put("api/incident/incidents/updateanswer/", values);
    };

    return (
        <>
            {value.length > 0 ? (
                <Col
                    xs={12}
                    style={{
                        backgroundColor: "#E5F2F6"
                    }}
                    className="p-3 rounded d-flex flex-column"
                >
                    {value?.map((x, index) => {
                        return Object.keys(x)?.map((y, keyIndex) => {
                            return (
                                <Row
                                    className="mb-2"
                                    key={`${index}-${keyIndex}`}
                                >
                                    <Col md={6}>{t(y)}</Col>
                                    <Col md={6}>
                                        <Input
                                            size="sm"
                                            className="bg-white"
                                            value={x[y]}
                                            onBlur={() => updateAnswer(field)}
                                            onChange={(e) => {
                                                const newValues = [...value];
                                                newValues[index][y] =
                                                    e.target.value;
                                                setValue(newValues);
                                            }}
                                        />
                                    </Col>
                                </Row>
                            );
                        });
                    })}
                </Col>
            ) : (
                <>-</>
            )}
        </>
    );
};
