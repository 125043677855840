import { Alert, Button, Col, FormGroup, Row } from "reactstrap";
import moment from "moment";
import { useEffect } from "react";
import { useApi } from "components/useApi3";
import { useTranslation } from "react-i18next";

const ReviewAndSubmit = ({
    vehicles,
    values,
    onSubmit,
    onBack,
    createResult
}) => {
    const [duplicateResult, duplicateApi] = useApi();
    const { t } = useTranslation();
    //console.log(values);
    useEffect(() => {
        if (createResult.status === 0) {
            const saveMe = {
                ...values,
                numberOfPassengers: parseInt(values.numberOfPassengers),
                mileage: parseInt(values.mileage),
                incidentDate: values.incidentDate.toISOString()
                // followUpTalkDate: .followUpTalkDate !== "" ? state.followUpTalkDate.toISOString() : null,
                // handlingDoneDate: state.handlingDoneDate !== "" ? state.handlingDoneDate.toISOString() : null,
            };

            duplicateApi.post("api/incident/incidents/hasduplicates", saveMe);
        }
    }, [duplicateApi, values, createResult]);

    return (
        <>
            <Row>
                <Col sm={4}>
                    <FormGroup>
                        <label>Ärende</label>
                        <div>
                            {values.insuranceCategory === "CRASH"
                                ? "Krock, trafikolycka eller skada på fordon"
                                : values.insuranceCategory === "THEFT"
                                ? "Stöld eller inbrott"
                                : values.insuranceCategory === "FIRE"
                                ? "Brand"
                                : null}
                        </div>
                    </FormGroup>
                </Col>
                <Col sm={4}>
                    <FormGroup>
                        <label>Plats</label>
                        <div>{values.location}</div>
                    </FormGroup>
                </Col>
                <Col sm={4}>
                    <FormGroup>
                        <label>Händelsedatum</label>
                        <div>{moment(values.incidentDate).format("L")}</div>
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col sm={4}>
                    <FormGroup>
                        <label>Typ av skada</label>
                        <div>
                            {values.insuranceCategory === "CRASH"
                                ? values.crashCategory
                                : values.insuranceCategory === "THEFT"
                                ? values.theftItems
                                : values.insuranceCategory === "FIRE"
                                ? values.fireType
                                : null}
                        </div>
                    </FormGroup>
                </Col>
                <Col sm={4}>
                    <FormGroup>
                        <label>Fordon</label>
                        <div>{values.vehicleFullName}</div>
                    </FormGroup>
                </Col>
                <Col sm={4}>
                    <FormGroup>
                        <label>Mätarställning</label>
                        <div>{values.mileage}</div>
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col sm={4}>
                    <FormGroup>
                        <label>Skadan inträffade</label>
                        <div>{values.damageLocationCategory.join(", ")}</div>
                    </FormGroup>
                </Col>
            </Row>
            <hr />
            <Row>
                <Col sm={4}>
                    <FormGroup>
                        <label>Händelsebeskrivning</label>
                        <dv>{values.descriptionOfEvents}</dv>
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col xs={12}>
                    <FormGroup>
                        <label>Anlitades ersättningsfordon?</label>
                        <div>
                            {values.replacementVehicleHired
                                ? t("yes")
                                : t("no")}
                        </div>
                    </FormGroup>
                </Col>
            </Row>
            {values.crashCategory === "Kollision" && (
                <>
                    <Row>
                        <Col xs={12}>
                            <FormGroup>
                                <label>Var det en möteskollision?</label>
                                <div>
                                    {values.isTrafficCollision
                                        ? t("yes")
                                        : t("no")}
                                </div>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12}>
                            <FormGroup>
                                <label>Vägens bredd</label>
                                <div>{values.estimatedRoadWidth}</div>
                            </FormGroup>
                        </Col>
                    </Row>
                </>
            )}
            {(values.insuranceCategory === "CRASH" ||
                values.insuranceCategory === "FIRE") && (
                <>
                    <Row>
                        <Col sm={12}>
                            <FormGroup>
                                <label>
                                    Synliga skador på fordon eller andra
                                    föremål?
                                </label>
                                <div>
                                    {values.visibleDamages ? t("yes") : t("no")}
                                </div>
                            </FormGroup>
                        </Col>
                    </Row>
                    {values.visibleDamages ? (
                        <Row>
                            <Col xs={12}>
                                <FormGroup>
                                    <label>Beskrivning av synliga skador</label>
                                    <div>
                                        {values.visibleDamagesDescription}
                                    </div>
                                </FormGroup>
                            </Col>
                        </Row>
                    ) : null}
                    <Row>
                        <Col sm={12}>
                            <FormGroup>
                                <label>Personskada? </label>
                                <div>
                                    {values.personalDamages
                                        ? t("yes")
                                        : t("no")}
                                    <br />
                                    {values.personalDamagesDescription}
                                </div>
                            </FormGroup>
                        </Col>
                    </Row>
                </>
            )}
            <Row>
                <Col sm={4}>
                    <FormGroup>
                        <label>Antal passagerare</label>
                        <div>{values.numberOfPassengers}</div>
                    </FormGroup>
                </Col>
            </Row>
            {(values.insuranceCategory === "CRASH" ||
                values.insuranceCategory === "THEFT") && (
                <Row>
                    <Col sm={4}>
                        <FormGroup>
                            <label>Kontaktuppgifter motpart</label>
                            {values.counterPartContactInformations.map(
                                (item) => {
                                    return (
                                        <Row>
                                            <Col xs={12} lg={6}>
                                                {item.name}
                                            </Col>
                                            <Col xs={12} lg={2}>
                                                {item.registrationNumber}
                                            </Col>
                                            <Col xs={12} lg={2}>
                                                {
                                                    item.personalOrOrganisationNumber
                                                }
                                            </Col>
                                            <Col xs={12} lg={2}>
                                                {item.telephoneNumber}
                                            </Col>
                                        </Row>
                                    );
                                }
                            )}
                        </FormGroup>
                    </Col>
                </Row>
            )}
            <Row>
                <Col sm={4}>
                    <FormGroup>
                        <label>Vittnen</label>
                        <div>{values.witnesses}</div>
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col xs={12}>
                    <label>Övrigt</label>
                    {values.policeContacted ? (
                        <p className="value">Polis inkopplad</p>
                    ) : null}
                    {values.emergencyServiceContacted ? (
                        <p className="value">Räddningstjänst inkopplad</p>
                    ) : null}
                    {values.bloodTest ? (
                        <p className="value">Blodprov har tagits</p>
                    ) : null}
                    {values.breathTest ? (
                        <p className="value">Utandningsprov har tagits</p>
                    ) : null}
                    {values.isVehicleTowed ? (
                        <p className="value">
                            Fordonet är bärgat till{" "}
                            {values.vehicleTowedToLocation}
                        </p>
                    ) : null}
                </Col>
            </Row>
            <Row>
                <Col xs={12}>
                    {duplicateResult.status === 2 &&
                        (duplicateResult.data ? (
                            <>
                                <Row>
                                    <Col
                                        xs={12}
                                        className="d-flex justify-content-between"
                                    >
                                        <Button
                                            onClick={() => {
                                                onBack();
                                            }}
                                            color="white"
                                            type="button"
                                        >
                                            Ändra
                                        </Button>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={12}>
                                        <Alert color="danger" className="mt-2">
                                            <Row>
                                                <Col>
                                                    Det finns redan ett ärende
                                                    rapporterat för detta
                                                    fordon, datum och ärendetyp.
                                                    Kontakta din ärendehanterare
                                                    för mer information. Vill du
                                                    skapa ärendet ändå?
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col className="d-flex justify-content-end">
                                                    <Button
                                                        onClick={() => {
                                                            onSubmit();
                                                        }}
                                                        color="primary"
                                                        type="button"
                                                    >
                                                        Ja, Skicka in
                                                    </Button>
                                                </Col>
                                            </Row>
                                        </Alert>
                                    </Col>
                                </Row>
                            </>
                        ) : (
                            <Row>
                                <Col
                                    xs={12}
                                    className="d-flex justify-content-between"
                                >
                                    <Button
                                        onClick={() => {
                                            onBack();
                                        }}
                                        color="white"
                                        type="button"
                                    >
                                        Ändra
                                    </Button>
                                    <Button
                                        onClick={() => {
                                            onSubmit();
                                        }}
                                        color="primary"
                                        type="button"
                                    >
                                        Skicka In
                                    </Button>
                                </Col>
                            </Row>
                        ))}
                    {duplicateResult.status === 1 && (
                        <Row>
                            <Col
                                xs={12}
                                className="d-flex justify-content-between"
                            >
                                <Button
                                    onClick={() => {
                                        onBack();
                                    }}
                                    color="white"
                                    type="button"
                                >
                                    Ändra
                                </Button>
                                <Button
                                    disabled={true}
                                    color="primary"
                                    type="button"
                                >
                                    Kontrollerar status...
                                </Button>
                            </Col>
                        </Row>
                    )}
                </Col>
            </Row>
        </>
    );
};
export default ReviewAndSubmit;
