import FormatCurrency from "components/FormatCurrency";
import HilightCard from "components/HilightCard";
import { ArrowRightIcon } from "components/Icons";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Row, Col } from "reactstrap";

const InfoCardUser = ({ result }) => {
	const finished = result.status === 2;
	const data = result.data;
	const { t } = useTranslation();
	return (
		<HilightCard>
			<Row>
				<Col xs={12} className="mb-4">
					<div className="d-flex justify-content-center">
						<label>{t("your_total_damage_cost")}</label>
					</div>
				</Col>
			</Row>
			<Row>
				<Col xs={6}>
					<div className="d-flex justify-content-center">
						<div className="large-value">
							{!finished ? (
								"-"
							) : (
								<FormatCurrency value={data.userTotalCost} />
							)}
						</div>
					</div>
					<div className="d-flex justify-content-center">
						{!finished ? (
							"-"
						) : (
							<div className="medium-value">
								{Math.abs(data.userComparsionPercentage)}
								{data.userComparsionPercentage <= 0
									? `% ${t(
											"lower_than_the_average_in_your_company"
									  )}`
									: `% ${t("higher_than_average")}`}
							</div>
						)}
					</div>
				</Col>
				<Col xs={6}>
					{data?.userCategoriesSummary?.map((category) => (
						<div className="d-flex justify-content-between mb-2">
							<div>
								<label>
									{t(category.labelKey, category.translateDK)}
								</label>
							</div>
							<div className="d-flex justify-content-end">
								<span className="small-value">
									{!finished ? "-" : category.cost}
								</span>
							</div>
						</div>
					))}
				</Col>
			</Row>
			<Row>
				<Col xs={12} className="d-flex justify-content-center mt-4">
					<Link className="large-link" to="followup/list">
						{t("show_more")} <ArrowRightIcon />
					</Link>
				</Col>
			</Row>
		</HilightCard>
	);
};

export default InfoCardUser;
