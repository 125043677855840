import React from "react";
import {
    Button,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Spinner
} from "reactstrap";

const IncidentModal = ({
    setModalState,
    modalState,
    body,
    title,
    cancelBtnText,
    submitBtnText,
    submitFunction,
    disabled,
    loading
}) => {
    return (
        <Modal isOpen={modalState} toggle={() => setModalState(false)}>
            <ModalHeader>{title}</ModalHeader>
            <ModalBody>{body}</ModalBody>
            <ModalFooter className="justify-content-between">
                <Button
                    color="secondary"
                    outline
                    onClick={() => setModalState(false)}
                >
                    {cancelBtnText}
                </Button>
                <Button
                    disabled={disabled}
                    color="primary"
                    onClick={() => submitFunction()}
                >
                    {loading && (
                        <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                            className="me-2"
                        />
                    )}
                    {submitBtnText}
                </Button>
            </ModalFooter>
        </Modal>
    );
};

export default IncidentModal;
