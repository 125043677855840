import SingleImageUpload from "components/SingleImageUpload";
import { useTranslation } from "react-i18next";
import { FormGroup, Label, Input } from "reactstrap";

const Form = ({
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    setFieldValue,
    handleSubmit
}) => {
    const { t } = useTranslation();
    return (
        <>
            <FormGroup>
                <Label for="name">{t("license_plate_number")}</Label>
                <Input
                    className={errors.registrationNumber ? "is-invalid" : ""}
                    autoComplete="off"
                    name="registrationNumber"
                    value={values.registrationNumber}
                    onChange={handleChange}
                    onBlur={handleBlur}
                ></Input>
                {errors.registrationNumber && touched.registrationNumber && (
                    <span className="text-danger">
                        {errors.registrationNumber}
                    </span>
                )}
            </FormGroup>
            <FormGroup>
                <Label for="name">{t("type")}</Label>
                <Input
                    className={errors.model ? "is-invalid" : ""}
                    autoComplete="off"
                    name="model"
                    value={values.model}
                    onChange={handleChange}
                    onBlur={handleBlur}
                ></Input>
                {errors.model && touched.model && (
                    <span className="text-danger">{errors.model}</span>
                )}
            </FormGroup>
            <FormGroup>
                <Label for="name">{t("id")}</Label>
                <Input
                    className={errors.identifier ? "is-invalid" : ""}
                    autoComplete="off"
                    name="identifier"
                    value={values.identifier}
                    onChange={handleChange}
                    onBlur={handleBlur}
                ></Input>
                {errors.identifier && touched.identifier && (
                    <span className="text-danger">{errors.identifier}</span>
                )}
            </FormGroup>
            <FormGroup>
                <FormGroup check>
                    <Label check>
                        <Input
                            type="checkbox"
                            name="taxRegistrated"
                            checked={values.taxRegistrated}
                            onChange={(e) => {
                                setFieldValue(
                                    "taxRegistrated",
                                    e.target.checked
                                );
                            }}
                        />
                        {t("tax_registrated")}
                    </Label>
                </FormGroup>
            </FormGroup>
            <FormGroup>
                <FormGroup check>
                    <Label check>
                        <Input
                            type="checkbox"
                            name="comprehensivelyInsured"
                            checked={values.comprehensivelyInsured}
                            onChange={(e) => {
                                setFieldValue(
                                    "comprehensivelyInsured",
                                    e.target.checked
                                );
                            }}
                        />
                        {t("comprehensively_insured")}
                    </Label>
                </FormGroup>
            </FormGroup>
            <FormGroup>
                <SingleImageUpload
                    attachment={values.image}
                    onSuccessUpload={(uploadedImage) => {
                        setFieldValue("image", uploadedImage.name);
                    }}
                    onRemoveImageItem={() => {
                        setFieldValue("image", null);
                        handleSubmit();
                    }}
                    apiEndpoint="api/organisation/uploadvehicleimage"
                    imageApiEndpoint="api/organisation/getvehicleimagebase64?name="
                />
            </FormGroup>
        </>
    );
};
export default Form;
