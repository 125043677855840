import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { Button } from "reactstrap";
import { ArrowRightIcon } from "../components/Icons";
import { useApi } from "components/useApi3";
import { useTranslation } from "react-i18next";

const Hero = ({ isIncidentReporter }) => {
	const [result, resultApi] = useApi();
	const { t, i18n } = useTranslation();
	useEffect(() => {
		resultApi.get("api/system/users/minename");
	}, [resultApi]);

	return (
		<div className="hero p-4 p-md-0">
			<div className="text-center text-md-start">
				<h2>
					{t("welcome")},
					<br />
					<small className="text-muted">
						{result.status === 2 ? result.data : "-"}
					</small>
				</h2>
			</div>
			{isIncidentReporter ? (
				<div className="text-center reporter-section mt-3">
					<Link to="/incidents/create">
						<Button color="primary" type="button">
							{t("reportincident")}
							<ArrowRightIcon />
						</Button>
					</Link>
				</div>
			) : null}
		</div>
	);
};

export default Hero;
