import TrygIncident from "components/form/Tryg/TrygIncident";
import TryggHansaIncidentForm from "components/form/TryggHansa/TryggHansaIncidentForm";
import { AppContext } from "AppContext";
import { useContext } from "react";

const IncidentEdit = ({ match }) => {
    const appContext = useContext(AppContext);

    return appContext.customer === "Tryg" ? (
        <TrygIncident match={match} editable={true} />
    ) : (
        <TryggHansaIncidentForm match={match} editable={true} />
    );
};
export default IncidentEdit;
