import { useTranslation } from "react-i18next";
import { FormGroup, Label, Input } from "reactstrap";

const Form = ({ values, errors, touched, handleBlur, handleChange }) => {
    const { t } = useTranslation();
    return (
        <>
            <FormGroup>
                <Label for="name">{t("license_plate_number")}</Label>
                <Input
                    className={errors.registrationNumber ? "is-invalid" : ""}
                    autoComplete="off"
                    name="registrationNumber"
                    value={values.registrationNumber}
                    onChange={handleChange}
                    onBlur={handleBlur}
                ></Input>
                {errors.registrationNumber && touched.registrationNumber && (
                    <span className="text-danger">
                        {errors.registrationNumber}
                    </span>
                )}
            </FormGroup>
            <FormGroup>
                <Label for="name">{t("type")}</Label>
                <Input
                    className={errors.model ? "is-invalid" : ""}
                    autoComplete="off"
                    name="model"
                    value={values.model}
                    onChange={handleChange}
                    onBlur={handleBlur}
                ></Input>
                {errors.model && touched.model && (
                    <span className="text-danger">{errors.model}</span>
                )}
            </FormGroup>
            <FormGroup>
                <Label for="name">{t("id")}</Label>
                <Input
                    className={errors.identifier ? "is-invalid" : ""}
                    autoComplete="off"
                    name="identifier"
                    value={values.identifier}
                    onChange={handleChange}
                    onBlur={handleBlur}
                ></Input>
                {errors.identifier && touched.identifier && (
                    <span className="text-danger">{errors.identifier}</span>
                )}
            </FormGroup>
        </>
    );
};
export default Form;
