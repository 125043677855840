import { createContext, useState, useEffect } from "react";
import authService from "./components/api-authorization/AuthorizeService";

export const AppContext = createContext();

export const AppContextProvider = ({ children }) => {
    const [formSettings, setFormSettings] = useState({
        nextQuestionId: 0,
        currentAnswerId: 0
    });

    const [init, setInit] = useState({
        ready: false,
        userRoles: [],
        isAuth: false
    });

    useEffect(() => {
        console.log("start");

        async function initUserManager() {
            // all auth kod spammar ensureUserManagerInitialized om det inte finns en userManager (vilket det inte gör vid laddning av sida)
            // vänta på den en gång innan alla andra kollar
            await authService.ensureUserManagerInitialized();
            var isAuthenticated = await authService.isAuthenticated();
            var user = null;
            console.log("isAuth", isAuthenticated);
            if (isAuthenticated) {
                user = await authService.getUser();
            }
            setInit((prevState) => {
                return {
                    ...prevState,
                    ready: true,
                    isAuth: isAuthenticated,
                    customer: user ? user.customer : null,
                    userRoles: user ? user.role : [],
                    profileName: user ? user.profilename : null
                };
            });
            // setInit({
            //     ready: true,
            //     isAuth: isAuthenticated,
            //     userRoles: user ? user.role : [],
            //     formSettings: formSettings,
            //     setFormSettings: setFormSettings
            //     // formSettings: formSettings,
            //     // setFormSettings: setFormSettings
            // });
        }

        initUserManager();
    }, []);

    return (
        init.ready && (
            <AppContext.Provider
                value={{
                    ...init,
                    setFormSettings: setFormSettings,
                    formSettings: formSettings
                }}
            >
                {children}
            </AppContext.Provider>
        )
    );
};
