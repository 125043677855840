import DateFormat from "components/DateFormat";
import React from "react";
import { Col, Row } from "reactstrap";
import { useTranslation } from "react-i18next";

const TrygIncidentHeader = ({ item }) => {
    const { t } = useTranslation();
    console.log(item);
    return (
        <Row>
            <Col xs={12} md={12} className="tryg-incident-header">
                <h1 className="tryg-incident-header__title">
                    {t(item?.title)}
                </h1>
                {item?.vehicle && (
                    <div className="d-flex align-items-center tryg-incident-header__wrapper">
                        <p className="tryg-incident-header__border-right tryg-incident-header__text">
                            {item?.vehicle?.registrationNumber}
                        </p>
                        <p className="tryg-incident-header__border-right tryg-incident-header__text">
                            {item?.vehicle?.identifier}
                        </p>
                        <p className="tryg-incident-header__border-right tryg-incident-header__text">
                            {item?.vehicle?.model}
                        </p>
                        <p className="tryg-incident-header__text">
                            <DateFormat>{item?.incidentDate}</DateFormat>
                        </p>
                    </div>
                )}
            </Col>
        </Row>
    );
};

export default TrygIncidentHeader;
