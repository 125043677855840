export const IncidentFormStatusText = (status, t, customer) => {
	if (customer === "Tryg") {
		return status === 0 ? (
			<p>
				{t(
					"this_claim_has_not_yet_been_submitted_to_your_company",
					"This claim has not yet been submitted to your company"
				)}
			</p>
		) : status === 1 ? (
			<p>
				{t(
					"this_claim_is_ready_for_review",
					"This claim is ready for review"
				)}
			</p>
		) : status === 2 ? (
			<p>
				{t(
					"this_injury_is_still_a_draft_and_under_review",
					"This injury is still a draft and under review"
				)}
			</p>
		) : status === 3 ? (
			<p>
				{t(
					"this_damage_has_been_reported_to_tryg",
					"This damage has been reported to Tryg"
				)}
			</p>
		) : status === 4 ? (
			<p>
				{t(
					"this_damage_has_been_completed",
					"This damage has been completed"
				)}
			</p>
		) : status === 5 ? (
			<p>
				{t(
					"this_damage_has_been_removed",
					"This damage has been removed"
				)}
			</p>
		) : status === 6 ? (
			<p>
				{t(
					"this_damage_is_stored_internally_and_has_not_been_reported_to_tryg",
					"This damage is stored internally and has not been reported to Tryg"
				)}
			</p>
		) : null;
	}
};
