import React, { useState, useRef, useEffect } from "react";
import {
	Button,
	ButtonGroup,
	Alert,
	ListGroup,
	ListGroupItem
} from "reactstrap";
import { useApi } from "components/useApi3";
import { useTranslation } from "react-i18next";

const FileUpload = ({ onSuccessUpload }) => {
	const { t } = useTranslation();
	const [uploadResult, uploadApi] = useApi();

	const [file, setFile] = useState(null);

	const fileInput = useRef();

	const onFileUploadChange = (e) => {
		// console.log(e);
		e.preventDefault();
		setFile(e.target.files[0]);
	};

	const triggerInputFile = (e) => {
		fileInput.current.click();
	};

	useEffect(() => {
		if (file) {
			uploadApi.file("api/superadmin/import/uploadfile", file);
		}
	}, [file, uploadApi]);

	useEffect(() => {
		if (uploadResult.status === 2) {
			console.log(uploadResult.data);
			onSuccessUpload(uploadResult.data);
			setFile(null);
			uploadApi.reset();
		}
		if (uploadResult.status > 2) {
			setFile(null);
		}
	}, [uploadResult, onSuccessUpload, uploadApi]);

	return (
		<ListGroup>
			<ListGroupItem>
				<input
					className="d-none"
					type="file"
					name="file"
					id="exampleFile"
					ref={fileInput}
					onChange={onFileUploadChange}
				></input>
				<ButtonGroup className="mb-2">
					<Button
						color="white"
						onClick={triggerInputFile}
						disabled={uploadResult.status === 1}
					>
						{t("choose_file", "Choose file")}{" "}
						{file ? (
							<span>
								{" "}
								{uploadResult.status === 1 ? (
									<span>
										({t("uploading", "Uploading")}{" "}
										{file.name})
									</span>
								) : null}
							</span>
						) : null}
					</Button>
				</ButtonGroup>
				{uploadResult.status > 2 ? (
					<Alert color="warning">
						{t(
							"the_file_could_not_be_uploaded",
							"The file could not be uploaded"
						)}
					</Alert>
				) : null}
			</ListGroupItem>
		</ListGroup>
	);
};

export default FileUpload;
