import React, { useState } from "react";
import { Col, Row } from "reactstrap";
import { useTranslation } from "react-i18next";

import { renderSelectedStaticComponent } from "pages/incidents/components/ImageSelector/helpers/renderSelectedComponent";

const ImageSelectorStatic = ({ clickedZones }) => {
    return (
        <>
            <div className="radius-5 p-3 ">
                <Row className="d-flex">
                    {clickedZones.map((x) => {
                        return (
                            <Col
                                md={6}
                                xs={12}
                                className="d-flex flex-column justify-content-center"
                            >
                                {renderSelectedStaticComponent([x])}
                                {x.text}
                            </Col>
                        );
                    })}
                </Row>
            </div>
        </>
    );
};
export default ImageSelectorStatic;
