import { t } from "i18next";
import {
    Button,
    Col,
    Form,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Row
} from "reactstrap";

import { AppContext } from "AppContext";
import { useContext } from "react";
import TrygEdit from "components/form/Tryg/TrygEditForm";
import TrygInformation from "components/form/Tryg/TrygInformation";
import { Component } from "./Component";
import { FieldArray } from "formik";

export const FormGroupContainer = (props) => {
    const {
        questionResult,
        errors,
        values,
        handleSubmit,
        clickedZones,
        setClickedZones,
        setFieldValue,
        handleChange,
        handleBlur,
        setShowValidationModal,
        showValidationModal,
        validateContent,
        setValidateContent,
        addAnswerResult,
        isHandler
    } = props;

    return !questionResult.isReview ? (
        <Row className="d-flex justify-content-center form-questions p-2 p-md-3 form-group-container">
            <Col xs={12} md={12} lg={6} className="bg-white rounded-3 p-0">
                <Col
                    md={12}
                    xs={12}
                    className="p-3 p-md-4 form-questions__header"
                >
                    <h1 className="m-0">
                        {t(
                            questionResult?.labelKey,
                            questionResult?.translateDK
                        )}
                    </h1>
                </Col>
                <Col className="p-2 p-md-4 pb-0 pb-md-0 form-questions-component-wrapper">
                    {!questionResult?.isFieldArray ? (
                        questionResult?.fields
                            ?.sort((a, b) => a.sort - b.sort)
                            .map((x, index) => (
                                <>
                                    <Component
                                        handleSubmit={handleSubmit}
                                        addAnswerResult={addAnswerResult}
                                        validateContent={validateContent}
                                        setValidateContent={setValidateContent}
                                        setShowValidationModal={
                                            setShowValidationModal
                                        }
                                        showValidationModal={
                                            showValidationModal
                                        }
                                        clickedZones={clickedZones}
                                        setClickedZones={setClickedZones}
                                        index={index}
                                        setFieldValue={setFieldValue}
                                        handleChange={handleChange}
                                        handleBlur={handleBlur}
                                        errors={errors}
                                        options={x.options}
                                        field={x}
                                        values={values}
                                        formGroup={questionResult}
                                    />
                                </>
                            ))
                    ) : (
                        <FieldArray
                            name={questionResult.id}
                            render={(arrayHelpers) => {
                                const arrayValues = Array.isArray(
                                    values[questionResult.id]
                                )
                                    ? values[questionResult.id]
                                    : null;
                                return (
                                    <>
                                        {arrayValues !== null &&
                                            arrayValues?.length > 0 &&
                                            arrayValues.map(
                                                (arrayValue, index) => {
                                                    return (
                                                        <div className="border-bottom mb-3">
                                                            {questionResult.labelKey ===
                                                                "FG29" && (
                                                                <p>
                                                                    {t(
                                                                        "witness",
                                                                        "Witness"
                                                                    ) +
                                                                        " " +
                                                                        (index +
                                                                            1)}
                                                                </p>
                                                            )}
                                                            {questionResult.fields
                                                                .sort(
                                                                    (a, b) =>
                                                                        a.sort -
                                                                        b.sort
                                                                )
                                                                ?.filter(
                                                                    (x) =>
                                                                        x.component !==
                                                                            "AddButton" &&
                                                                        x.component !==
                                                                            "SkipButton"
                                                                )
                                                                .map(
                                                                    (
                                                                        x,
                                                                        fieldIndex
                                                                    ) => (
                                                                        <>
                                                                            <Component
                                                                                arrayValue={
                                                                                    arrayValue
                                                                                }
                                                                                handleSubmit={
                                                                                    handleSubmit
                                                                                }
                                                                                addAnswerResult={
                                                                                    addAnswerResult
                                                                                }
                                                                                validateContent={
                                                                                    validateContent
                                                                                }
                                                                                setValidateContent={
                                                                                    setValidateContent
                                                                                }
                                                                                setShowValidationModal={
                                                                                    setShowValidationModal
                                                                                }
                                                                                showValidationModal={
                                                                                    showValidationModal
                                                                                }
                                                                                clickedZones={
                                                                                    clickedZones
                                                                                }
                                                                                setClickedZones={
                                                                                    setClickedZones
                                                                                }
                                                                                index={
                                                                                    index
                                                                                }
                                                                                setFieldValue={
                                                                                    setFieldValue
                                                                                }
                                                                                handleChange={
                                                                                    handleChange
                                                                                }
                                                                                handleBlur={
                                                                                    handleBlur
                                                                                }
                                                                                errors={
                                                                                    errors
                                                                                }
                                                                                options={
                                                                                    x.options
                                                                                }
                                                                                field={
                                                                                    x
                                                                                }
                                                                                values={
                                                                                    values
                                                                                }
                                                                                formGroup={
                                                                                    questionResult
                                                                                }
                                                                                arrayHelpers={
                                                                                    arrayHelpers
                                                                                }
                                                                            />{" "}
                                                                        </>
                                                                    )
                                                                )}
                                                        </div>
                                                    );
                                                }
                                            )}
                                        {questionResult.fields
                                            .sort((a, b) => a.sort - b.sort)
                                            ?.filter(
                                                (x) =>
                                                    x.component ===
                                                        "AddButton" ||
                                                    x.component === "SkipButton"
                                            )
                                            .map((x, fieldIndex) => (
                                                <>
                                                    <Component
                                                        handleSubmit={
                                                            handleSubmit
                                                        }
                                                        addAnswerResult={
                                                            addAnswerResult
                                                        }
                                                        validateContent={
                                                            validateContent
                                                        }
                                                        setValidateContent={
                                                            setValidateContent
                                                        }
                                                        setShowValidationModal={
                                                            setShowValidationModal
                                                        }
                                                        showValidationModal={
                                                            showValidationModal
                                                        }
                                                        clickedZones={
                                                            clickedZones
                                                        }
                                                        setClickedZones={
                                                            setClickedZones
                                                        }
                                                        index={fieldIndex}
                                                        setFieldValue={
                                                            setFieldValue
                                                        }
                                                        handleChange={
                                                            handleChange
                                                        }
                                                        handleBlur={handleBlur}
                                                        errors={errors}
                                                        options={x.options}
                                                        field={x}
                                                        values={values}
                                                        formGroup={
                                                            questionResult
                                                        }
                                                        arrayHelpers={
                                                            arrayHelpers
                                                        }
                                                    />
                                                </>
                                            ))}
                                    </>
                                );
                            }}
                        />
                    )}
                    {errors && errors[questionResult.id] ? (
                        <div className="invalid-feedback d-block">
                            {errors[questionResult.id]}
                        </div>
                    ) : null}{" "}
                </Col>
            </Col>
        </Row>
    ) : (
        <Row className="d-flex flex-column-reverse flex-md-row">
            <Col md={isHandler ? 12 : 8} xs={12} className="p-2 p-md-3">
                <div className="bg-white p-4 shadow-sm rounded-3">
                    <TrygInformation item={questionResult} />{" "}
                </div>
            </Col>{" "}
            <Col md={4} xs={12} className="p-2 p-md-3">
                {!isHandler && (
                    <TrygEdit
                        onSubmit={handleSubmit}
                        initialValues={questionResult}
                    />
                )}
            </Col>
        </Row>
    );
};
