import Panel from "components/Panel";
import PageHeader from "components/PageHeader";
import { useApi } from "components/useApi3";
import moment from "moment";
import { useEffect } from "react";
import LoadingSpinner from "components/LoadingSpinner";
import DateFormat from "components/DateFormat";
import FormatCurrency from "components/FormatCurrency";
import { useTranslation } from "react-i18next";
import TryggHansaDetails from "./TryggHansaDetails";
import TryggHansaEdit from "./TryggHansaEdit";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import {
    Col,
    FormGroup,
    Row,
    Button,
    Form,
    Nav,
    NavItem,
    NavLink,
    Alert
} from "reactstrap";
import { useState } from "react";
import classnames from "classnames";
import { t } from "i18next";

// two tabbar, en för "hantera ärende" och en för "skadeinformation"
// det man hämtar och redigerar är en IncidentHandleFormDto som är step1+step2+handle

const PageLabel = ({ result }) => {
    const data =
        result.status === 2
            ? result.data
            : {
                  insuranceCategory: "",
                  location: "",
                  crashCategory: "",
                  theftItems: "",
                  fireType: "",
                  vehicleDisplayName: "",
                  incidentDate: ""
              };
    const title =
        data.insuranceCategory === "CRASH"
            ? data.crashCategory
            : data.insuranceCategory === "THEFT"
            ? data.theftItems
            : data.insuranceCategory === "FIRE"
            ? data.fireType
            : "".concat(" - ").concat(data.vehicleDisplayName);
    const subtitle = data.location
        .concat(" | ")
        .concat(data.incidentDate ? moment(data.incidentDate).format("L") : "");

    return <PageHeader title={title} subtitle={subtitle} />;
};

const TryggHansaIncidentForm = ({ match, editable }) => {
    const [itemResult, itemApi] = useApi();

    useEffect(() => {
        itemApi.get("api/incident/incidents/".concat(match.params.id));
    }, [itemApi, match]);

    const [saveResult, saveApi] = useApi();
    const [sendResult, sendApi] = useApi();
    const [vehiclesResult, vehiclesApi] = useApi();
    const history = useHistory();
    const [tab, setTab] = useState(editable ? 0 : 1);
    useEffect(() => {
        vehiclesApi.get("api/system/vehicles/select");
    }, [vehiclesApi]);

    useEffect(() => {
        if (sendResult.status === 2) {
            history.push("/incidents/send/".concat(match.params.id));
        }
    }, [sendResult, history, match]);

    const vehicles = vehiclesResult.status === 2 ? vehiclesResult.data : [];

    const editItem =
        itemResult.status === 2
            ? {
                  ...itemResult.data,
                  incidentDate: moment(itemResult.data.incidentDate),
                  followUpTalkDate: itemResult.data.followUpTalkDate
                      ? moment(itemResult.data.followUpTalkDate)
                      : "",
                  handlingDoneDate: itemResult.data.handlingDoneDate
                      ? moment(itemResult.data.handlingDoneDate)
                      : "",
                  mileage: itemResult.data.mileage
                      ? itemResult.data.mileage
                      : 0,
                  numberOfPassengers: itemResult.data.numberOfPassengers
                      ? itemResult.data.numberOfPassengers
                      : 0
              }
            : null;

    let submitAction = "";
    return (
        <>
            <PageLabel result={itemResult} />
            <Panel>
                <Nav tabs>
                    {editable && (
                        <NavItem>
                            <NavLink
                                className={classnames({ active: tab === 0 })}
                                onClick={() => {
                                    setTab(0);
                                }}
                            >
                                {t("manage_case")}
                            </NavLink>
                        </NavItem>
                    )}

                    <NavItem>
                        <NavLink
                            className={classnames({ active: tab === 1 })}
                            onClick={() => {
                                setTab(1);
                            }}
                        >
                            {t("damage_information")}
                        </NavLink>
                    </NavItem>
                </Nav>
                {itemResult.status === 1 ? (
                    <LoadingSpinner />
                ) : itemResult.status === 2 ? (
                    <>
                        {tab === 1 && (
                            <TryggHansaDetails item={itemResult.data} />
                        )}
                        {tab === 0 && editable && (
                            <>
                                <TryggHansaEdit
                                    onSubmit={(values) => {
                                        const saveMe = {
                                            ...values,
                                            numberOfPassengers: parseInt(
                                                values.numberOfPassengers
                                            ),
                                            mileage: parseInt(values.mileage),
                                            incidentDate:
                                                values.incidentDate.toISOString(),
                                            followUpTalkDate:
                                                values.followUpTalkDate !== ""
                                                    ? values.followUpTalkDate.toISOString()
                                                    : null,
                                            handlingDoneDate:
                                                values.handlingDoneDate !== ""
                                                    ? values.handlingDoneDate.toISOString()
                                                    : null
                                        };

                                        saveApi.put(
                                            "api/incident/incidents",
                                            saveMe
                                        );
                                    }}
                                    onSubmitSend={(values) => {
                                        const saveMe = {
                                            ...values,
                                            numberOfPassengers: parseInt(
                                                values.numberOfPassengers
                                            ),
                                            mileage: parseInt(values.mileage),
                                            incidentDate:
                                                values.incidentDate.toISOString(),
                                            followUpTalkDate:
                                                values.followUpTalkDate !== ""
                                                    ? values.followUpTalkDate.toISOString()
                                                    : null,
                                            handlingDoneDate:
                                                values.handlingDoneDate !== ""
                                                    ? values.handlingDoneDate.toISOString()
                                                    : null
                                        };

                                        sendApi.put(
                                            "api/incident/incidents",
                                            saveMe
                                        );
                                    }}
                                    vehicles={vehicles}
                                    vehiclesStatus={vehiclesResult.status}
                                    initialValues={editItem}
                                />
                                {saveResult.status > 2 && (
                                    <Alert color="danger">
                                        Ett fel inträffade. Kunde inte spara
                                        ärendet.
                                    </Alert>
                                )}
                                {saveResult.status === 1 && (
                                    <Alert color="info">Sparar ärendet..</Alert>
                                )}
                                {saveResult.status === 2 && (
                                    <Alert color="info">Ärendet sparat.</Alert>
                                )}
                                {sendResult.status > 2 && (
                                    <Alert color="danger">
                                        Ett fel inträffade. Kunde inte spara
                                        ärendet.
                                    </Alert>
                                )}
                                {sendResult.status === 1 && (
                                    <Alert color="info">Sparar ärendet..</Alert>
                                )}
                            </>
                        )}
                    </>
                ) : null}
            </Panel>
        </>
    );
};
export default TryggHansaIncidentForm;
