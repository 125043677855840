import { t } from "i18next";
import * as React from "react";
const SvgTrailerSideRight = (props) => (
	<svg
		width={525}
		height={312}
		viewBox="0 0 525 312"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
		className="svg-component"
	>
		<g clipPath="url(#clip0_1811_3646)">
			<path
				d="M311.67 215.05H297.67V252.06H311.67V215.05Z"
				fill="#262626"
			/>
			<path
				d="M326.46 227.55L327.31 233.55H505.47L508.14 227.55H326.46Z"
				fill="#262626"
			/>
			<path
				d="M513.7 215.05H304.67L306.46 227.55H508.14L513.7 215.05Z"
				fill="#767676"
			/>
			<path
				d="M301.67 249.55L285.67 217.05H139.72L123.34 247.55L132.19 260.55L204.7 254.69C205.03 258.81 208.47 262.05 212.67 262.05C216.92 262.05 220.39 258.73 220.65 254.54L294.69 259.05L301.69 249.54L301.67 249.55Z"
				fill="#262626"
			/>
			<path
				d="M212.66 260.55C216.25 260.55 219.16 257.64 219.16 254.05C219.16 250.46 216.25 247.55 212.66 247.55C209.07 247.55 206.16 250.46 206.16 254.05C206.16 257.64 209.07 260.55 212.66 260.55Z"
				fill="black"
			/>
			<path
				d="M302.41 215.05L308.66 262.9C308.74 263.52 308.26 264.06 307.64 264.06H306.9C305.09 264.06 303.51 262.79 303.15 261.02C301.11 251.06 291.81 215.27 256.66 218.06C251.53 218.47 236.32 221.31 236.32 221.31C236.32 221.31 229.28 221.06 225.97 230.56C222.66 240.06 219.66 245.21 212.66 245.21C205.66 245.21 202.66 240.06 199.35 230.56C196.04 221.06 189 221.31 189 221.31C189 221.31 173.79 218.47 168.66 218.06C133.52 215.27 124.22 251.06 122.17 261.02C121.81 262.8 120.23 264.06 118.42 264.06H117.68C117.06 264.06 116.58 263.51 116.66 262.9L122.91 215.05H302.43H302.41Z"
				fill="#767676"
			/>
			<path
				d="M34.4697 215.05L32.6297 222.05L17.7197 236.91V254.41L70.4297 215.05H34.4697Z"
				fill="#262626"
			/>
			<path
				d="M118.21 250.91L122.9 215.05H70.4297L22.4097 250.91H118.21Z"
				fill="black"
			/>
			<path
				d="M85.6396 237.05H75.6396V242.05H85.6396V237.05Z"
				fill="#FF8601"
			/>
			<path
				d="M11.6299 235.05V236.55H14.1299V255.55H11.6299V257.05H17.6299V235.05H11.6299Z"
				fill="black"
			/>
			<path
				d="M13.5099 238.05H11.2499C10.9075 238.05 10.6299 238.328 10.6299 238.67V253.43C10.6299 253.772 10.9075 254.05 11.2499 254.05H13.5099C13.8523 254.05 14.1299 253.772 14.1299 253.43V238.67C14.1299 238.328 13.8523 238.05 13.5099 238.05Z"
				fill="#FF8601"
			/>
			<path
				d="M439.51 249.55L423.51 217.05H371.76L355.39 247.55L364.23 260.55L432.51 259.05L439.51 249.55Z"
				fill="#262626"
			/>
			<path
				d="M440.25 215.05L446.5 262.9C446.58 263.52 446.1 264.06 445.48 264.06H444.74C442.93 264.06 441.35 262.79 440.99 261.02C438.95 251.06 429.65 215.27 394.5 218.06C389.37 218.47 407.62 218.47 402.49 218.06C367.35 215.27 358.05 251.06 356 261.02C355.64 262.8 354.85 264.06 353.04 264.06H351.51C350.89 264.06 350.41 263.51 350.49 262.9L356.74 215.05"
				fill="#767676"
			/>
			<path
				d="M440.25 215.05L446.5 262.9C446.58 263.52 446.1 264.06 445.48 264.06H444.74C442.93 264.06 441.35 262.79 440.99 261.02C438.95 251.06 429.65 215.27 394.5 218.06C389.37 218.47 407.62 218.47 402.49 218.06C367.35 215.27 358.05 251.06 356 261.02C355.64 262.8 354.85 264.06 353.04 264.06H351.51C350.89 264.06 350.41 263.51 350.49 262.9L356.74 215.05"
				stroke="#262626"
				strokeWidth={2}
				strokeMiterlimit={10}
			/>
			<path
				d="M257.65 296.02C278.421 296.02 295.26 279.181 295.26 258.41C295.26 237.639 278.421 220.8 257.65 220.8C236.879 220.8 220.04 237.639 220.04 258.41C220.04 279.181 236.879 296.02 257.65 296.02Z"
				fill="#414141"
			/>
			<path
				d="M257.65 279.91C269.524 279.91 279.15 270.284 279.15 258.41C279.15 246.536 269.524 236.91 257.65 236.91C245.776 236.91 236.15 246.536 236.15 258.41C236.15 270.284 245.776 279.91 257.65 279.91Z"
				fill="#CCCCCC"
			/>
			<path
				d="M257.65 268.41C263.173 268.41 267.65 263.933 267.65 258.41C267.65 252.887 263.173 248.41 257.65 248.41C252.127 248.41 247.65 252.887 247.65 258.41C247.65 263.933 252.127 268.41 257.65 268.41Z"
				fill="#E3E3E3"
			/>
			<path
				d="M257.25 252.41C257.526 252.41 257.75 252.186 257.75 251.91C257.75 251.634 257.526 251.41 257.25 251.41C256.974 251.41 256.75 251.634 256.75 251.91C256.75 252.186 256.974 252.41 257.25 252.41Z"
				fill="white"
			/>
			<path
				d="M257.25 250.91C256.7 250.91 256.25 251.36 256.25 251.91C256.25 252.46 256.7 252.91 257.25 252.91C257.8 252.91 258.25 252.46 258.25 251.91C258.25 251.36 257.8 250.91 257.25 250.91Z"
				fill="black"
			/>
			<path
				d="M252.77 254.6C252.64 254.6 252.51 254.55 252.42 254.45C252.33 254.35 252.27 254.23 252.27 254.1C252.27 253.97 252.32 253.84 252.42 253.75C252.52 253.66 252.64 253.6 252.77 253.6C252.9 253.6 253.03 253.65 253.12 253.75C253.32 253.95 253.32 254.26 253.12 254.46C253.03 254.55 252.9 254.61 252.77 254.61V254.6Z"
				fill="white"
			/>
			<path
				d="M252.77 253.1C252.51 253.1 252.26 253.2 252.06 253.39C251.67 253.78 251.67 254.41 252.06 254.8C252.26 255 252.51 255.09 252.77 255.09C253.03 255.09 253.28 254.99 253.48 254.8C253.87 254.41 253.87 253.78 253.48 253.39C253.28 253.19 253.03 253.1 252.77 253.1Z"
				fill="black"
			/>
			<path
				d="M251.15 259.31C250.87 259.31 250.65 259.09 250.65 258.81C250.65 258.53 250.87 258.31 251.15 258.31C251.43 258.31 251.65 258.53 251.65 258.81C251.65 259.09 251.43 259.31 251.15 259.31Z"
				fill="white"
			/>
			<path
				d="M251.15 257.81C250.6 257.81 250.15 258.26 250.15 258.81C250.15 259.36 250.6 259.81 251.15 259.81C251.7 259.81 252.15 259.36 252.15 258.81C252.15 258.26 251.7 257.81 251.15 257.81Z"
				fill="black"
			/>
			<path
				d="M253.33 263.79C253.2 263.79 253.07 263.74 252.98 263.64C252.89 263.54 252.83 263.42 252.83 263.29C252.83 263.16 252.88 263.03 252.98 262.94C253.08 262.85 253.2 262.79 253.33 262.79C253.46 262.79 253.59 262.84 253.68 262.94C253.77 263.04 253.83 263.16 253.83 263.29C253.83 263.42 253.78 263.55 253.68 263.64C253.58 263.73 253.46 263.79 253.33 263.79Z"
				fill="white"
			/>
			<path
				d="M253.33 262.29C253.07 262.29 252.82 262.39 252.62 262.58C252.23 262.97 252.23 263.6 252.62 263.99C252.82 264.19 253.07 264.28 253.33 264.28C253.59 264.28 253.84 264.18 254.04 263.99C254.43 263.6 254.43 262.97 254.04 262.58C253.84 262.38 253.59 262.29 253.33 262.29Z"
				fill="black"
			/>
			<path
				d="M258.05 265.42C258.326 265.42 258.55 265.196 258.55 264.92C258.55 264.644 258.326 264.42 258.05 264.42C257.774 264.42 257.55 264.644 257.55 264.92C257.55 265.196 257.774 265.42 258.05 265.42Z"
				fill="white"
			/>
			<path
				d="M258.05 263.92C257.5 263.92 257.05 264.37 257.05 264.92C257.05 265.47 257.5 265.92 258.05 265.92C258.6 265.92 259.05 265.47 259.05 264.92C259.05 264.37 258.6 263.92 258.05 263.92Z"
				fill="black"
			/>
			<path
				d="M262.53 263.23C262.4 263.23 262.27 263.18 262.18 263.08C262.09 262.98 262.03 262.86 262.03 262.73C262.03 262.6 262.08 262.47 262.18 262.38C262.28 262.29 262.4 262.23 262.53 262.23C262.66 262.23 262.79 262.28 262.88 262.38C262.97 262.48 263.03 262.6 263.03 262.73C263.03 262.86 262.98 262.99 262.88 263.08C262.78 263.17 262.66 263.23 262.53 263.23Z"
				fill="white"
			/>
			<path
				d="M262.53 261.73C262.27 261.73 262.02 261.83 261.82 262.02C261.43 262.41 261.43 263.04 261.82 263.43C262.02 263.63 262.27 263.72 262.53 263.72C262.79 263.72 263.04 263.62 263.24 263.43C263.63 263.04 263.63 262.41 263.24 262.02C263.04 261.82 262.79 261.73 262.53 261.73Z"
				fill="black"
			/>
			<path
				d="M264.15 258.51C263.87 258.51 263.65 258.29 263.65 258.01C263.65 257.73 263.87 257.51 264.15 257.51C264.43 257.51 264.65 257.73 264.65 258.01C264.65 258.29 264.43 258.51 264.15 258.51Z"
				fill="white"
			/>
			<path
				d="M264.15 257.01C263.6 257.01 263.15 257.46 263.15 258.01C263.15 258.56 263.6 259.01 264.15 259.01C264.7 259.01 265.15 258.56 265.15 258.01C265.15 257.46 264.7 257.01 264.15 257.01Z"
				fill="black"
			/>
			<path
				d="M261.96 254.04C261.83 254.04 261.7 253.99 261.61 253.89C261.52 253.79 261.46 253.67 261.46 253.54C261.46 253.41 261.51 253.28 261.61 253.19C261.71 253.1 261.83 253.04 261.96 253.04C262.09 253.04 262.22 253.09 262.31 253.19C262.4 253.29 262.46 253.41 262.46 253.54C262.46 253.67 262.41 253.8 262.31 253.89C262.21 253.98 262.09 254.04 261.96 254.04Z"
				fill="white"
			/>
			<path
				d="M261.96 252.53C261.7 252.53 261.45 252.63 261.25 252.82C260.86 253.21 260.86 253.84 261.25 254.23C261.45 254.43 261.7 254.52 261.96 254.52C262.22 254.52 262.47 254.42 262.67 254.23C263.06 253.84 263.06 253.21 262.67 252.82C262.47 252.62 262.22 252.53 261.96 252.53Z"
				fill="black"
			/>
			<path
				d="M257.65 262.41C259.859 262.41 261.65 260.619 261.65 258.41C261.65 256.201 259.859 254.41 257.65 254.41C255.441 254.41 253.65 256.201 253.65 258.41C253.65 260.619 255.441 262.41 257.65 262.41Z"
				fill="#808080"
			/>
			<path
				d="M167.63 296.02C188.401 296.02 205.24 279.181 205.24 258.41C205.24 237.639 188.401 220.8 167.63 220.8C146.859 220.8 130.02 237.639 130.02 258.41C130.02 279.181 146.859 296.02 167.63 296.02Z"
				fill="#414141"
			/>
			<path
				d="M167.63 279.91C179.504 279.91 189.13 270.284 189.13 258.41C189.13 246.536 179.504 236.91 167.63 236.91C155.756 236.91 146.13 246.536 146.13 258.41C146.13 270.284 155.756 279.91 167.63 279.91Z"
				fill="#CCCCCC"
			/>
			<path
				d="M177.64 258.41C177.64 263.93 173.16 268.41 167.64 268.41C162.12 268.41 157.64 263.93 157.64 258.41C157.64 252.89 162.12 248.41 167.64 248.41C173.16 248.41 177.64 252.89 177.64 258.41Z"
				fill="#E3E3E3"
			/>
			<path
				d="M167.23 252.41C167.506 252.41 167.73 252.186 167.73 251.91C167.73 251.634 167.506 251.41 167.23 251.41C166.954 251.41 166.73 251.634 166.73 251.91C166.73 252.186 166.954 252.41 167.23 252.41Z"
				fill="white"
			/>
			<path
				d="M167.23 250.91C166.68 250.91 166.23 251.36 166.23 251.91C166.23 252.46 166.68 252.91 167.23 252.91C167.78 252.91 168.23 252.46 168.23 251.91C168.23 251.36 167.78 250.91 167.23 250.91Z"
				fill="black"
			/>
			<path
				d="M162.75 254.6C162.62 254.6 162.49 254.55 162.4 254.45C162.2 254.25 162.2 253.94 162.4 253.74C162.49 253.65 162.62 253.59 162.75 253.59C162.88 253.59 163.01 253.64 163.1 253.74C163.19 253.84 163.25 253.96 163.25 254.09C163.25 254.22 163.2 254.35 163.1 254.44C163 254.53 162.88 254.59 162.75 254.59V254.6Z"
				fill="white"
			/>
			<path
				d="M162.75 253.1C162.49 253.1 162.24 253.2 162.04 253.39C161.65 253.78 161.65 254.41 162.04 254.8C162.24 255 162.49 255.09 162.75 255.09C163.01 255.09 163.26 254.99 163.46 254.8C163.85 254.41 163.85 253.78 163.46 253.39C163.26 253.19 163.01 253.1 162.75 253.1Z"
				fill="black"
			/>
			<path
				d="M161.13 259.31C160.85 259.31 160.63 259.09 160.63 258.81C160.63 258.53 160.85 258.31 161.13 258.31C161.41 258.31 161.63 258.53 161.63 258.81C161.63 259.09 161.41 259.31 161.13 259.31Z"
				fill="white"
			/>
			<path
				d="M161.13 257.81C160.58 257.81 160.13 258.26 160.13 258.81C160.13 259.36 160.58 259.81 161.13 259.81C161.68 259.81 162.13 259.36 162.13 258.81C162.13 258.26 161.68 257.81 161.13 257.81Z"
				fill="black"
			/>
			<path
				d="M163.32 263.79C163.19 263.79 163.06 263.74 162.97 263.64C162.88 263.54 162.82 263.42 162.82 263.29C162.82 263.16 162.87 263.03 162.97 262.94C163.07 262.85 163.19 262.79 163.32 262.79C163.45 262.79 163.58 262.84 163.67 262.94C163.76 263.04 163.82 263.16 163.82 263.29C163.82 263.42 163.77 263.55 163.67 263.64C163.57 263.73 163.45 263.79 163.32 263.79Z"
				fill="white"
			/>
			<path
				d="M163.32 262.29C163.06 262.29 162.81 262.39 162.61 262.58C162.22 262.97 162.22 263.6 162.61 263.99C162.81 264.19 163.06 264.28 163.32 264.28C163.58 264.28 163.83 264.18 164.03 263.99C164.42 263.6 164.42 262.97 164.03 262.58C163.83 262.38 163.58 262.29 163.32 262.29Z"
				fill="black"
			/>
			<path
				d="M168.03 265.42C168.306 265.42 168.53 265.196 168.53 264.92C168.53 264.644 168.306 264.42 168.03 264.42C167.754 264.42 167.53 264.644 167.53 264.92C167.53 265.196 167.754 265.42 168.03 265.42Z"
				fill="white"
			/>
			<path
				d="M168.03 263.92C167.48 263.92 167.03 264.37 167.03 264.92C167.03 265.47 167.48 265.92 168.03 265.92C168.58 265.92 169.03 265.47 169.03 264.92C169.03 264.37 168.58 263.92 168.03 263.92Z"
				fill="black"
			/>
			<path
				d="M172.51 263.23C172.38 263.23 172.25 263.18 172.16 263.08C172.07 262.98 172.01 262.86 172.01 262.73C172.01 262.6 172.06 262.47 172.16 262.38C172.26 262.29 172.38 262.23 172.51 262.23C172.64 262.23 172.77 262.28 172.86 262.38C172.95 262.48 173.01 262.6 173.01 262.73C173.01 262.86 172.96 262.99 172.86 263.08C172.76 263.17 172.64 263.23 172.51 263.23Z"
				fill="white"
			/>
			<path
				d="M172.51 261.73C172.25 261.73 172 261.83 171.8 262.02C171.41 262.41 171.41 263.04 171.8 263.43C172 263.63 172.25 263.72 172.51 263.72C172.77 263.72 173.02 263.62 173.22 263.43C173.61 263.04 173.61 262.41 173.22 262.02C173.02 261.82 172.77 261.73 172.51 261.73Z"
				fill="black"
			/>
			<path
				d="M174.13 258.51C173.85 258.51 173.63 258.29 173.63 258.01C173.63 257.73 173.85 257.51 174.13 257.51C174.41 257.51 174.63 257.73 174.63 258.01C174.63 258.29 174.41 258.51 174.13 258.51Z"
				fill="white"
			/>
			<path
				d="M174.13 257.01C173.58 257.01 173.13 257.46 173.13 258.01C173.13 258.56 173.58 259.01 174.13 259.01C174.68 259.01 175.13 258.56 175.13 258.01C175.13 257.46 174.68 257.01 174.13 257.01Z"
				fill="black"
			/>
			<path
				d="M171.95 254.04C171.82 254.04 171.69 253.99 171.6 253.89C171.51 253.79 171.45 253.67 171.45 253.54C171.45 253.41 171.5 253.28 171.6 253.19C171.7 253.1 171.82 253.04 171.95 253.04C172.08 253.04 172.21 253.09 172.3 253.19C172.39 253.29 172.45 253.41 172.45 253.54C172.45 253.67 172.4 253.8 172.3 253.89C172.2 253.98 172.08 254.04 171.95 254.04Z"
				fill="white"
			/>
			<path
				d="M171.95 252.53C171.69 252.53 171.44 252.63 171.24 252.82C170.85 253.21 170.85 253.84 171.24 254.23C171.44 254.43 171.69 254.52 171.95 254.52C172.21 254.52 172.46 254.42 172.66 254.23C173.05 253.84 173.05 253.21 172.66 252.82C172.46 252.62 172.21 252.53 171.95 252.53Z"
				fill="black"
			/>
			<path
				d="M171.63 258.41C171.63 260.62 169.84 262.41 167.63 262.41C165.42 262.41 163.63 260.62 163.63 258.41C163.63 256.2 165.42 254.41 167.63 254.41C169.84 254.41 171.63 256.2 171.63 258.41Z"
				fill="#808080"
			/>
			<path d="M494.2 19.52H14.1299V210.55H494.2V19.52Z" fill="white" />
			<path
				d="M490.7 23.02V207.05H17.6299V23.02H490.7ZM495.08 16.02H13.2399C11.7999 16.02 10.6299 17.19 10.6299 18.63V211.43C10.6299 212.87 11.7999 214.04 13.2399 214.04H495.08C496.52 214.04 497.69 212.87 497.69 211.43V18.63C497.69 17.19 496.52 16.02 495.08 16.02Z"
				fill="#E0E0E0"
			/>
			<path
				d="M436.35 258.41C436.35 279.18 419.51 296.02 398.74 296.02C377.97 296.02 361.13 279.18 361.13 258.41C361.13 237.64 377.97 220.8 398.74 220.8C419.51 220.8 436.35 237.64 436.35 258.41Z"
				fill="#414141"
			/>
			<path
				d="M398.74 236.91C386.86 236.91 377.24 246.54 377.24 258.41C377.24 270.29 386.87 279.91 398.74 279.91C410.62 279.91 420.24 270.28 420.24 258.41C420.24 246.53 410.61 236.91 398.74 236.91Z"
				fill="#CCCCCC"
			/>
			<path
				d="M398.74 268.41C404.263 268.41 408.74 263.933 408.74 258.41C408.74 252.887 404.263 248.41 398.74 248.41C393.217 248.41 388.74 252.887 388.74 258.41C388.74 263.933 393.217 268.41 398.74 268.41Z"
				fill="#E3E3E3"
			/>
			<path
				d="M398.34 252.41C398.616 252.41 398.84 252.186 398.84 251.91C398.84 251.634 398.616 251.41 398.34 251.41C398.064 251.41 397.84 251.634 397.84 251.91C397.84 252.186 398.064 252.41 398.34 252.41Z"
				fill="white"
			/>
			<path
				d="M398.34 250.91C397.79 250.91 397.34 251.36 397.34 251.91C397.34 252.46 397.79 252.91 398.34 252.91C398.89 252.91 399.34 252.46 399.34 251.91C399.34 251.36 398.89 250.91 398.34 250.91Z"
				fill="black"
			/>
			<path
				d="M393.86 254.6C393.73 254.6 393.6 254.55 393.51 254.45C393.42 254.35 393.36 254.23 393.36 254.1C393.36 253.97 393.41 253.84 393.51 253.75C393.61 253.66 393.73 253.6 393.86 253.6C393.99 253.6 394.12 253.65 394.21 253.75C394.41 253.95 394.41 254.26 394.21 254.46C394.12 254.55 393.99 254.61 393.86 254.61V254.6Z"
				fill="white"
			/>
			<path
				d="M393.86 253.1C393.6 253.1 393.35 253.2 393.15 253.39C392.76 253.78 392.76 254.41 393.15 254.8C393.35 255 393.6 255.09 393.86 255.09C394.12 255.09 394.37 254.99 394.57 254.8C394.96 254.41 394.96 253.78 394.57 253.39C394.37 253.19 394.12 253.1 393.86 253.1Z"
				fill="black"
			/>
			<path
				d="M392.24 259.31C391.96 259.31 391.74 259.09 391.74 258.81C391.74 258.53 391.96 258.31 392.24 258.31C392.52 258.31 392.74 258.53 392.74 258.81C392.74 259.09 392.52 259.31 392.24 259.31Z"
				fill="white"
			/>
			<path
				d="M392.24 257.81C391.69 257.81 391.24 258.26 391.24 258.81C391.24 259.36 391.69 259.81 392.24 259.81C392.79 259.81 393.24 259.36 393.24 258.81C393.24 258.26 392.79 257.81 392.24 257.81Z"
				fill="black"
			/>
			<path
				d="M394.43 263.79C394.3 263.79 394.17 263.74 394.08 263.64C393.99 263.54 393.93 263.42 393.93 263.29C393.93 263.16 393.98 263.03 394.08 262.94C394.18 262.85 394.3 262.79 394.43 262.79C394.56 262.79 394.69 262.84 394.78 262.94C394.87 263.04 394.93 263.16 394.93 263.29C394.93 263.42 394.88 263.55 394.78 263.64C394.68 263.73 394.56 263.79 394.43 263.79Z"
				fill="white"
			/>
			<path
				d="M394.43 262.29C394.17 262.29 393.92 262.39 393.72 262.58C393.33 262.97 393.33 263.6 393.72 263.99C393.92 264.19 394.17 264.28 394.43 264.28C394.69 264.28 394.94 264.18 395.14 263.99C395.53 263.6 395.53 262.97 395.14 262.58C394.94 262.38 394.69 262.29 394.43 262.29Z"
				fill="black"
			/>
			<path
				d="M399.14 265.42C399.416 265.42 399.64 265.196 399.64 264.92C399.64 264.644 399.416 264.42 399.14 264.42C398.864 264.42 398.64 264.644 398.64 264.92C398.64 265.196 398.864 265.42 399.14 265.42Z"
				fill="white"
			/>
			<path
				d="M399.14 263.92C398.59 263.92 398.14 264.37 398.14 264.92C398.14 265.47 398.59 265.92 399.14 265.92C399.69 265.92 400.14 265.47 400.14 264.92C400.14 264.37 399.69 263.92 399.14 263.92Z"
				fill="black"
			/>
			<path
				d="M403.62 263.23C403.49 263.23 403.36 263.18 403.27 263.08C403.18 262.98 403.12 262.86 403.12 262.73C403.12 262.6 403.17 262.47 403.27 262.38C403.37 262.29 403.49 262.23 403.62 262.23C403.75 262.23 403.88 262.28 403.97 262.38C404.06 262.48 404.12 262.6 404.12 262.73C404.12 262.86 404.07 262.99 403.97 263.08C403.87 263.17 403.75 263.23 403.62 263.23Z"
				fill="white"
			/>
			<path
				d="M403.62 261.73C403.36 261.73 403.11 261.83 402.91 262.02C402.52 262.41 402.52 263.04 402.91 263.43C403.11 263.63 403.36 263.72 403.62 263.72C403.88 263.72 404.13 263.62 404.33 263.43C404.72 263.04 404.72 262.41 404.33 262.02C404.13 261.82 403.88 261.73 403.62 261.73Z"
				fill="black"
			/>
			<path
				d="M405.24 258.51C404.96 258.51 404.74 258.29 404.74 258.01C404.74 257.73 404.96 257.51 405.24 257.51C405.52 257.51 405.74 257.73 405.74 258.01C405.74 258.29 405.52 258.51 405.24 258.51Z"
				fill="white"
			/>
			<path
				d="M405.25 257.01C404.7 257.01 404.25 257.46 404.25 258.01C404.25 258.56 404.7 259.01 405.25 259.01C405.8 259.01 406.25 258.56 406.25 258.01C406.25 257.46 405.8 257.01 405.25 257.01Z"
				fill="black"
			/>
			<path
				d="M403.06 254.04C402.93 254.04 402.8 253.99 402.71 253.89C402.62 253.79 402.56 253.67 402.56 253.54C402.56 253.41 402.61 253.28 402.71 253.19C402.81 253.1 402.93 253.04 403.06 253.04C403.19 253.04 403.32 253.09 403.41 253.19C403.5 253.29 403.56 253.41 403.56 253.54C403.56 253.67 403.51 253.8 403.41 253.89C403.31 253.98 403.19 254.04 403.06 254.04Z"
				fill="white"
			/>
			<path
				d="M403.06 252.53C402.8 252.53 402.55 252.63 402.35 252.82C401.96 253.21 401.96 253.84 402.35 254.23C402.55 254.43 402.8 254.52 403.06 254.52C403.32 254.52 403.57 254.42 403.77 254.23C404.16 253.84 404.16 253.21 403.77 252.82C403.57 252.62 403.32 252.53 403.06 252.53Z"
				fill="black"
			/>
			<path
				d="M398.74 262.41C400.949 262.41 402.74 260.619 402.74 258.41C402.74 256.201 400.949 254.41 398.74 254.41C396.531 254.41 394.74 256.201 394.74 258.41C394.74 260.619 396.531 262.41 398.74 262.41Z"
				fill="#808080"
			/>
			<path
				d="M297.75 214.77H302.5H304.75H311.75H356.82H440.34H513.78L508.22 227.27L505.55 233.27H442.75L446.58 262.61C446.66 263.23 446.18 263.77 445.56 263.77H444.82C443.01 263.77 441.43 262.5 441.07 260.73C440.61 258.5 439.79 254.98 438.4 250.89L436.19 253.89C436.35 255.28 436.43 256.7 436.43 258.13C436.43 278.9 419.59 295.74 398.82 295.74C378.05 295.74 361.21 278.9 361.21 258.13C361.21 257.36 361.24 256.59 361.29 255.83L358.48 251.7C357.25 255.44 356.51 258.65 356.08 260.72C355.72 262.5 354.93 263.76 353.12 263.76H351.59C350.97 263.76 350.49 263.21 350.57 262.6L354.4 233.26H311.75V251.76H307.33L308.75 262.6C308.83 263.22 308.35 263.76 307.73 263.76H306.99C305.18 263.76 303.6 262.49 303.24 260.72C302.82 258.66 302.07 255.47 300.86 251.76H299.91L295.33 257.97C295.33 258.02 295.33 258.07 295.33 258.12C295.33 278.89 278.49 295.73 257.72 295.73C236.95 295.73 220.11 278.89 220.11 258.12C220.11 257.65 220.13 257.18 220.15 256.71C218.98 259.66 216.1 261.76 212.73 261.76C209.36 261.76 206.43 259.64 205.28 256.65C205.3 257.14 205.32 257.63 205.32 258.12C205.32 278.89 188.48 295.73 167.71 295.73C146.94 295.73 130.1 278.89 130.1 258.12C130.1 257.79 130.12 257.46 130.12 257.13L125.23 249.94C123.64 254.42 122.72 258.32 122.23 260.71C121.87 262.49 120.29 263.75 118.48 263.75H117.74C117.12 263.75 116.64 263.2 116.72 262.59L118.28 250.61H22.4897L70.5197 214.74H122.99H297.76L297.75 214.77Z"
				stroke="black"
				strokeWidth={2}
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M34.5598 214.77H70.5198L17.7998 254.14V236.64L32.7098 221.78L34.5598 214.77Z"
				stroke="black"
				strokeWidth={2}
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M11.33 237.78H13.59C13.93 237.78 14.21 238.06 14.21 238.4V236.28H11.71V234.78H17.71V256.78H11.71V255.28H14.21V253.16C14.21 253.5 13.93 253.78 13.59 253.78H11.33C10.99 253.78 10.71 253.5 10.71 253.16V238.4C10.71 238.06 10.99 237.78 11.33 237.78Z"
				stroke="black"
				strokeWidth={2}
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M495.17 15.75H13.32C11.8785 15.75 10.71 16.9185 10.71 18.36V211.17C10.71 212.611 11.8785 213.78 13.32 213.78H495.17C496.611 213.78 497.78 212.611 497.78 211.17V18.36C497.78 16.9185 496.611 15.75 495.17 15.75Z"
				stroke="black"
				strokeWidth={2}
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<rect
				width={174.803}
				height={103.937}
				fill="white"
				fillOpacity={0.01}
				className="zone"
				onClick={() =>
					props.handlePathClick &&
					props.handlePathClick(
						95,
						t("Zone95", "Bagparti fører-side top")
					)
				}
			/>
			{props.clickedZones.find((path) => path.zoneId === 95) && (
				<g filter="url(#filter0_d_1811_3646)">
					<rect
						x={72.4019}
						y={36.9683}
						width={30}
						height={30}
						rx={15}
						fill="#3c7749"
					/>
					<rect
						x={71.4019}
						y={35.9683}
						width={32}
						height={32}
						rx={16}
						stroke="white"
						strokeWidth={2}
					/>
					<path
						d="M95.4019 45.9683L84.4019 56.9683L79.4019 51.9683"
						stroke="white"
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
				</g>
			)}
			<rect
				width={174.803}
				height={103.937}
				transform="translate(174.803)"
				fill="white"
				fillOpacity={0.01}
				className="zone"
				onClick={() =>
					props.handlePathClick &&
					props.handlePathClick(
						96,
						t("Zone96", "Midt fører-side top")
					)
				}
			/>
			{props.clickedZones.find((path) => path.zoneId === 96) && (
				<g filter="url(#filter1_d_1811_3646)">
					<rect
						x={247.205}
						y={36.9683}
						width={30}
						height={30}
						rx={15}
						fill="#3c7749"
					/>
					<rect
						x={246.205}
						y={35.9683}
						width={32}
						height={32}
						rx={16}
						stroke="white"
						strokeWidth={2}
					/>
					<path
						d="M270.205 45.9683L259.205 56.9683L254.205 51.9683"
						stroke="white"
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
				</g>
			)}
			<rect
				width={174.803}
				height={103.937}
				transform="translate(349.606)"
				fill="white"
				fillOpacity={0.01}
				className="zone"
				onClick={() =>
					props.handlePathClick &&
					props.handlePathClick(
						97,
						t("Zone97", "Forparti fører-side top")
					)
				}
			/>
			{props.clickedZones.find((path) => path.zoneId === 97) && (
				<g filter="url(#filter2_d_1811_3646)">
					<rect
						x={422.008}
						y={36.9683}
						width={30}
						height={30}
						rx={15}
						fill="#3c7749"
					/>
					<rect
						x={421.008}
						y={35.9683}
						width={32}
						height={32}
						rx={16}
						stroke="white"
						strokeWidth={2}
					/>
					<path
						d="M445.008 45.9683L434.008 56.9683L429.008 51.9683"
						stroke="white"
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
				</g>
			)}
			<rect
				width={174.803}
				height={103.937}
				transform="translate(0 103.937)"
				fill="white"
				fillOpacity={0.01}
				className="zone"
				onClick={() =>
					props.handlePathClick &&
					props.handlePathClick(
						98,
						t("Zone98", "Bagparti fører-side midt")
					)
				}
			/>
			{props.clickedZones.find((path) => path.zoneId === 98) && (
				<g filter="url(#filter3_d_1811_3646)">
					<rect
						x={72.4019}
						y={140.905}
						width={30}
						height={30}
						rx={15}
						fill="#3c7749"
					/>
					<rect
						x={71.4019}
						y={139.905}
						width={32}
						height={32}
						rx={16}
						stroke="white"
						strokeWidth={2}
					/>
					<path
						d="M95.4019 149.905L84.4019 160.905L79.4019 155.905"
						stroke="white"
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
				</g>
			)}
			<rect
				width={174.803}
				height={103.937}
				transform="translate(174.803 103.937)"
				fill="white"
				fillOpacity={0.01}
				className="zone"
				onClick={() =>
					props.handlePathClick &&
					props.handlePathClick(
						99,
						t("Zone99", "Midt fører-side midt")
					)
				}
			/>
			{props.clickedZones.find((path) => path.zoneId === 99) && (
				<g filter="url(#filter4_d_1811_3646)">
					<rect
						x={247.205}
						y={140.905}
						width={30}
						height={30}
						rx={15}
						fill="#3c7749"
					/>
					<rect
						x={246.205}
						y={139.905}
						width={32}
						height={32}
						rx={16}
						stroke="white"
						strokeWidth={2}
					/>
					<path
						d="M270.205 149.905L259.205 160.905L254.205 155.905"
						stroke="white"
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
				</g>
			)}
			<rect
				width={174.803}
				height={103.937}
				transform="translate(349.606 103.937)"
				fill="white"
				fillOpacity={0.01}
				className="zone"
				onClick={() =>
					props.handlePathClick &&
					props.handlePathClick(
						100,
						t("Zone100", "Forparti fører-side midt")
					)
				}
			/>
			{props.clickedZones.find((path) => path.zoneId === 100) && (
				<g filter="url(#filter5_d_1811_3646)">
					<rect
						x={422.008}
						y={140.905}
						width={30}
						height={30}
						rx={15}
						fill="#3c7749"
					/>
					<rect
						x={421.008}
						y={139.905}
						width={32}
						height={32}
						rx={16}
						stroke="white"
						strokeWidth={2}
					/>
					<path
						d="M445.008 149.905L434.008 160.905L429.008 155.905"
						stroke="white"
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
				</g>
			)}
			<rect
				width={174.803}
				height={103.937}
				transform="translate(0 207.873)"
				fill="white"
				fillOpacity={0.01}
				className="zone"
				onClick={() =>
					props.handlePathClick &&
					props.handlePathClick(
						101,
						t("Zone101", "Bagparti fører-side bund")
					)
				}
			/>
			{props.clickedZones.find((path) => path.zoneId === 101) && (
				<g filter="url(#filter6_d_1811_3646)">
					<rect
						x={72.4019}
						y={244.842}
						width={30}
						height={30}
						rx={15}
						fill="#3c7749"
					/>
					<rect
						x={71.4019}
						y={243.842}
						width={32}
						height={32}
						rx={16}
						stroke="white"
						strokeWidth={2}
					/>
					<path
						d="M95.4019 253.842L84.4019 264.842L79.4019 259.842"
						stroke="white"
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
				</g>
			)}
			<rect
				width={174.803}
				height={103.937}
				transform="translate(174.803 207.873)"
				fill="white"
				fillOpacity={0.01}
				className="zone"
				onClick={() =>
					props.handlePathClick &&
					props.handlePathClick(
						102,
						t("Zone102", "Midt fører-side bund")
					)
				}
			/>
			{props.clickedZones.find((path) => path.zoneId === 102) && (
				<g filter="url(#filter7_d_1811_3646)">
					<rect
						x={247.205}
						y={244.842}
						width={30}
						height={30}
						rx={15}
						fill="#3c7749"
					/>
					<rect
						x={246.205}
						y={243.842}
						width={32}
						height={32}
						rx={16}
						stroke="white"
						strokeWidth={2}
					/>
					<path
						d="M270.205 253.842L259.205 264.842L254.205 259.842"
						stroke="white"
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
				</g>
			)}
			<rect
				width={174.803}
				height={103.937}
				transform="translate(349.606 207.873)"
				fill="white"
				fillOpacity={0.01}
				className="zone"
				onClick={() =>
					props.handlePathClick &&
					props.handlePathClick(
						103,
						t("Zone103", "Bagparti fører-side bund")
					)
				}
			/>
			{props.clickedZones.find((path) => path.zoneId === 103) && (
				<g filter="url(#filter8_d_1811_3646)">
					<rect
						x={422.008}
						y={244.842}
						width={30}
						height={30}
						rx={15}
						fill="#3c7749"
					/>
					<rect
						x={421.008}
						y={243.842}
						width={32}
						height={32}
						rx={16}
						stroke="white"
						strokeWidth={2}
					/>
					<path
						d="M445.008 253.842L434.008 264.842L429.008 259.842"
						stroke="white"
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
				</g>
			)}
		</g>
		<defs>
			<filter
				id="filter0_d_1811_3646"
				x={50.4019}
				y={16.9683}
				width={74}
				height={74}
				filterUnits="userSpaceOnUse"
				colorInterpolationFilters="sRGB"
			>
				<feFlood floodOpacity={0} result="BackgroundImageFix" />
				<feColorMatrix
					in="SourceAlpha"
					type="matrix"
					values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
					result="hardAlpha"
				/>
				<feOffset dy={2} />
				<feGaussianBlur stdDeviation={10} />
				<feComposite in2="hardAlpha" operator="out" />
				<feColorMatrix
					type="matrix"
					values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
				/>
				<feBlend
					mode="normal"
					in2="BackgroundImageFix"
					result="effect1_dropShadow_1811_3646"
				/>
				<feBlend
					mode="normal"
					in="SourceGraphic"
					in2="effect1_dropShadow_1811_3646"
					result="shape"
				/>
			</filter>
			<filter
				id="filter1_d_1811_3646"
				x={225.205}
				y={16.9683}
				width={74}
				height={74}
				filterUnits="userSpaceOnUse"
				colorInterpolationFilters="sRGB"
			>
				<feFlood floodOpacity={0} result="BackgroundImageFix" />
				<feColorMatrix
					in="SourceAlpha"
					type="matrix"
					values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
					result="hardAlpha"
				/>
				<feOffset dy={2} />
				<feGaussianBlur stdDeviation={10} />
				<feComposite in2="hardAlpha" operator="out" />
				<feColorMatrix
					type="matrix"
					values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
				/>
				<feBlend
					mode="normal"
					in2="BackgroundImageFix"
					result="effect1_dropShadow_1811_3646"
				/>
				<feBlend
					mode="normal"
					in="SourceGraphic"
					in2="effect1_dropShadow_1811_3646"
					result="shape"
				/>
			</filter>
			<filter
				id="filter2_d_1811_3646"
				x={400.008}
				y={16.9683}
				width={74}
				height={74}
				filterUnits="userSpaceOnUse"
				colorInterpolationFilters="sRGB"
			>
				<feFlood floodOpacity={0} result="BackgroundImageFix" />
				<feColorMatrix
					in="SourceAlpha"
					type="matrix"
					values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
					result="hardAlpha"
				/>
				<feOffset dy={2} />
				<feGaussianBlur stdDeviation={10} />
				<feComposite in2="hardAlpha" operator="out" />
				<feColorMatrix
					type="matrix"
					values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
				/>
				<feBlend
					mode="normal"
					in2="BackgroundImageFix"
					result="effect1_dropShadow_1811_3646"
				/>
				<feBlend
					mode="normal"
					in="SourceGraphic"
					in2="effect1_dropShadow_1811_3646"
					result="shape"
				/>
			</filter>
			<filter
				id="filter3_d_1811_3646"
				x={50.4019}
				y={120.905}
				width={74}
				height={74}
				filterUnits="userSpaceOnUse"
				colorInterpolationFilters="sRGB"
			>
				<feFlood floodOpacity={0} result="BackgroundImageFix" />
				<feColorMatrix
					in="SourceAlpha"
					type="matrix"
					values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
					result="hardAlpha"
				/>
				<feOffset dy={2} />
				<feGaussianBlur stdDeviation={10} />
				<feComposite in2="hardAlpha" operator="out" />
				<feColorMatrix
					type="matrix"
					values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
				/>
				<feBlend
					mode="normal"
					in2="BackgroundImageFix"
					result="effect1_dropShadow_1811_3646"
				/>
				<feBlend
					mode="normal"
					in="SourceGraphic"
					in2="effect1_dropShadow_1811_3646"
					result="shape"
				/>
			</filter>
			<filter
				id="filter4_d_1811_3646"
				x={225.205}
				y={120.905}
				width={74}
				height={74}
				filterUnits="userSpaceOnUse"
				colorInterpolationFilters="sRGB"
			>
				<feFlood floodOpacity={0} result="BackgroundImageFix" />
				<feColorMatrix
					in="SourceAlpha"
					type="matrix"
					values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
					result="hardAlpha"
				/>
				<feOffset dy={2} />
				<feGaussianBlur stdDeviation={10} />
				<feComposite in2="hardAlpha" operator="out" />
				<feColorMatrix
					type="matrix"
					values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
				/>
				<feBlend
					mode="normal"
					in2="BackgroundImageFix"
					result="effect1_dropShadow_1811_3646"
				/>
				<feBlend
					mode="normal"
					in="SourceGraphic"
					in2="effect1_dropShadow_1811_3646"
					result="shape"
				/>
			</filter>
			<filter
				id="filter5_d_1811_3646"
				x={400.008}
				y={120.905}
				width={74}
				height={74}
				filterUnits="userSpaceOnUse"
				colorInterpolationFilters="sRGB"
			>
				<feFlood floodOpacity={0} result="BackgroundImageFix" />
				<feColorMatrix
					in="SourceAlpha"
					type="matrix"
					values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
					result="hardAlpha"
				/>
				<feOffset dy={2} />
				<feGaussianBlur stdDeviation={10} />
				<feComposite in2="hardAlpha" operator="out" />
				<feColorMatrix
					type="matrix"
					values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
				/>
				<feBlend
					mode="normal"
					in2="BackgroundImageFix"
					result="effect1_dropShadow_1811_3646"
				/>
				<feBlend
					mode="normal"
					in="SourceGraphic"
					in2="effect1_dropShadow_1811_3646"
					result="shape"
				/>
			</filter>
			<filter
				id="filter6_d_1811_3646"
				x={50.4019}
				y={224.842}
				width={74}
				height={74}
				filterUnits="userSpaceOnUse"
				colorInterpolationFilters="sRGB"
			>
				<feFlood floodOpacity={0} result="BackgroundImageFix" />
				<feColorMatrix
					in="SourceAlpha"
					type="matrix"
					values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
					result="hardAlpha"
				/>
				<feOffset dy={2} />
				<feGaussianBlur stdDeviation={10} />
				<feComposite in2="hardAlpha" operator="out" />
				<feColorMatrix
					type="matrix"
					values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
				/>
				<feBlend
					mode="normal"
					in2="BackgroundImageFix"
					result="effect1_dropShadow_1811_3646"
				/>
				<feBlend
					mode="normal"
					in="SourceGraphic"
					in2="effect1_dropShadow_1811_3646"
					result="shape"
				/>
			</filter>
			<filter
				id="filter7_d_1811_3646"
				x={225.205}
				y={224.842}
				width={74}
				height={74}
				filterUnits="userSpaceOnUse"
				colorInterpolationFilters="sRGB"
			>
				<feFlood floodOpacity={0} result="BackgroundImageFix" />
				<feColorMatrix
					in="SourceAlpha"
					type="matrix"
					values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
					result="hardAlpha"
				/>
				<feOffset dy={2} />
				<feGaussianBlur stdDeviation={10} />
				<feComposite in2="hardAlpha" operator="out" />
				<feColorMatrix
					type="matrix"
					values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
				/>
				<feBlend
					mode="normal"
					in2="BackgroundImageFix"
					result="effect1_dropShadow_1811_3646"
				/>
				<feBlend
					mode="normal"
					in="SourceGraphic"
					in2="effect1_dropShadow_1811_3646"
					result="shape"
				/>
			</filter>
			<filter
				id="filter8_d_1811_3646"
				x={400.008}
				y={224.842}
				width={74}
				height={74}
				filterUnits="userSpaceOnUse"
				colorInterpolationFilters="sRGB"
			>
				<feFlood floodOpacity={0} result="BackgroundImageFix" />
				<feColorMatrix
					in="SourceAlpha"
					type="matrix"
					values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
					result="hardAlpha"
				/>
				<feOffset dy={2} />
				<feGaussianBlur stdDeviation={10} />
				<feComposite in2="hardAlpha" operator="out" />
				<feColorMatrix
					type="matrix"
					values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
				/>
				<feBlend
					mode="normal"
					in2="BackgroundImageFix"
					result="effect1_dropShadow_1811_3646"
				/>
				<feBlend
					mode="normal"
					in="SourceGraphic"
					in2="effect1_dropShadow_1811_3646"
					result="shape"
				/>
			</filter>
			<clipPath id="clip0_1811_3646">
				<rect width={524.41} height={311.81} fill="white" />
			</clipPath>
		</defs>
	</svg>
);
export default SvgTrailerSideRight;
