const Switch = ({ onChange, checked, label }) => {
	return (
		<div className="form-check form-switch focus-ring">
			<label className="form-check-label">
				<input
					onChange={onChange}
					className="form-check-input form-check-switch"
					type="checkbox"
					checked={checked}
				/>
				{label}
			</label>
		</div>
	);
};
export default Switch;
