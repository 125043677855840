import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faBan,
	faBuilding,
	faBus,
	faCarCrash,
	faCheckCircle,
	faDownload,
	faUpload,
	faEdit,
	faEnvelope,
	faEnvelopeOpen,
	faFire,
	faGavel,
	faImage,
	faPlus,
	faTrash,
	faUser,
	faUsers,
	faChevronRight,
	faChevronLeft,
	faQuestionCircle,
	faSpinner,
	faCheck,
	faSave,
	faPaperPlane,
	faFile,
	faTimes,
	faArrowUp,
	faArrowDown,
	faChevronDoubleRight,
	faChevronDoubleLeft
} from "@fortawesome/pro-light-svg-icons";
export const CheckIcon = () => {
	return <FontAwesomeIcon icon={faCheck} />;
};
export const TimesIcon = () => {
	return <FontAwesomeIcon icon={faTimes} />;
};
export const DeleteIcon = () => {
	return <FontAwesomeIcon icon={faTrash} />;
};
export const EditIcon = () => {
	return <FontAwesomeIcon icon={faEdit} />;
};
export const CreateIcon = () => {
	return <FontAwesomeIcon icon={faPlus} />;
};
export const CancelIcon = () => {
	return <FontAwesomeIcon icon={faBan} />;
};
export const EmailIcon = () => {
	return <FontAwesomeIcon icon={faEnvelope} />;
};
export const SaveIcon = () => {
	return <FontAwesomeIcon icon={faSave} />;
};
export const SubmitIcon = () => {
	return <FontAwesomeIcon icon={faCheckCircle} />;
};
export const HelpIcon = () => {
	return <FontAwesomeIcon icon={faQuestionCircle} />;
};

export const IncidentStatusIcon = ({ status }) => {
	const loweredStatus = status ? status.toLowerCase() : "";

	return loweredStatus === "open" ? (
		<FontAwesomeIcon icon={faEnvelopeOpen} />
	) : loweredStatus === "sent" ? (
		<FontAwesomeIcon icon={faEnvelope} />
	) : loweredStatus === "completed" ? (
		<FontAwesomeIcon icon={faBan} />
	) : null;
};

export const IncidentCategoryIcon = ({ category }) => {
	const loweredCategory = category ? category.toLowerCase() : "";

	return loweredCategory === "crash" ? (
		<FontAwesomeIcon icon={faCarCrash} />
	) : loweredCategory === "theft" ? (
		<FontAwesomeIcon icon={faGavel} />
	) : loweredCategory === "fire" ? (
		<FontAwesomeIcon icon={faFire} />
	) : null;
};

export const UserIcon = () => {
	return <FontAwesomeIcon icon={faUser} />;
};
export const UsersIcon = () => {
	return <FontAwesomeIcon icon={faUsers} />;
};

export const LoadingIcon = () => {
	return <FontAwesomeIcon icon={faSpinner} spin />;
};

export const DownloadIcon = () => {
	return <FontAwesomeIcon icon={faDownload} />;
};

export const UploadIcon = () => {
	return <FontAwesomeIcon icon={faUpload} />;
};

export const ImageIcon = () => {
	return <FontAwesomeIcon icon={faImage} />;
};

export const SiteIcon = () => {
	return <FontAwesomeIcon icon={faBuilding} />;
};

export const VehicleIcon = () => {
	return <FontAwesomeIcon icon={faBus} />;
};

export const ArrowRightIcon = () => {
	return <FontAwesomeIcon icon={faChevronRight} />;
};

export const ArrowLeftIcon = () => {
	return <FontAwesomeIcon icon={faChevronLeft} />;
};

export const DoubleArrowLeftIcon = () => {
	return <FontAwesomeIcon icon={faChevronDoubleLeft} />;
};

export const DoubleArrowRightIcon = () => {
	return <FontAwesomeIcon icon={faChevronDoubleRight} />;
};

export const ArrowDownIcon = () => {
	return <FontAwesomeIcon icon={faArrowDown} />;
};
export const ArrowUpIcon = () => {
	return <FontAwesomeIcon icon={faArrowUp} />;
};

export const PaperPlaneIcon = () => {
	return <FontAwesomeIcon icon={faPaperPlane} />;
};

export const FileIcon = () => {
	return <FontAwesomeIcon icon={faFile} />;
};
