import FormatCurrency from "components/FormatCurrency";
import HilightCard from "components/HilightCard";
import { useTranslation } from "react-i18next";
import { Col, Row } from "reactstrap";

const InfoCard = ({ result }) => {
    const finished = result.status === 2;
    const data = result.data;
    const { t } = useTranslation();
    return (
        <HilightCard>
            <Row>
                <Col>
                    <div className="d-flex justify-content-center mb-2">
                        <label>{t("preliminary_cost")}</label>
                    </div>
                    <div className="d-flex justify-content-center large-value mb-2">
                        {!finished ? (
                            "-"
                        ) : (
                            <FormatCurrency value={data.preliminaryCost} />
                        )}
                    </div>
                    {data?.categoriesSummary?.map((category) => (
                        <div className="d-flex justify-content-between mb-2">
                            <div>
                                <label>
                                    {t(category.labelKey, category.translateDK)}
                                </label>
                            </div>
                            <div className="d-flex justify-content-end">
                                <span className="small-value">
                                    {!finished ? "-" : category.cost}
                                </span>
                            </div>
                        </div>
                    ))}
                    <div className="d-flex justify-content-between">
                        <div>
                            <label>{t("total_damage_cost")}</label>
                        </div>
                        <div className="d-flex justify-content-end">
                            <span className="small-value">
                                {!finished ? (
                                    "-"
                                ) : (
                                    <FormatCurrency value={data.totalCost} />
                                )}
                            </span>
                        </div>
                    </div>
                </Col>
            </Row>
        </HilightCard>
    );
};
export default InfoCard;
