import * as Yup from "yup";

export const getValidation = (datatype, optional, label) => {
    switch (datatype) {
        case 0:
            return optional
                ? Yup.string().nullable()
                : Yup.string().nullable().required(`${label} är obligatoriskt`);
        case 1:
            return optional
                ? Yup.number().typeError(`${label} must be a number`)
                : Yup.number()
                      .typeError(`${label} must be a number`)
                      .required(`${label} är obligatoriskt`);
        case 2:
            return optional
                ? Yup.boolean().typeError(`${label} must be a boolean`)
                : Yup.boolean().required(`${label} är obligatoriskt`);
        case 3:
            return optional
                ? Yup.date().typeError(`${label} must be a date`)
                : Yup.date()
                      .typeError(`${label} must be a date`)
                      .required(`${label} är obligatoriskt`);
        case 4:
            return optional
                ? Yup.date().typeError(`${label} must be a date`)
                : Yup.date()
                      .typeError(`${label} must be a date`)
                      .required(`${label} är obligatoriskt`);
        case 5:
            return optional
                ? Yup.string()
                : Yup.string().required(`${label} är obligatoriskt`);
        case 6:
            return optional
                ? Yup.string()
                : Yup.string().required(`${label} är obligatoriskt`);
        default:
            return null;
    }
};
