import React, { useMemo, useEffect, useState } from "react";
import PageHeader from "components/PageHeader";
import Panel from "components/Panel";
import {
    Alert,
    Button,
    ButtonGroup,
    Col,
    FormGroup,
    List,
    Row
} from "reactstrap";
import DataTable from "components/DataTable";
import { useApi } from "components/useApi3";
import FileUpload from "./components/FileUpload";
import { useTranslation } from "react-i18next";

const Import = () => {
    const [importItemsResult, importItemsApi] = useApi();
    const [processResult, processApi] = useApi();
    const [triggerRefresh, setTriggerRefresh] = useState();
    const [deleteProcessedResult, deleteProcessedApi] = useApi();
    const [deleteUnprocessedResult, deleteUnprocessedApi] = useApi();
    const { t } = useTranslation();
    useEffect(() => {
        importItemsApi.get("api/superadmin/import/getall");
    }, [importItemsApi, triggerRefresh]);

    useEffect(() => {
        if (processResult.status === 2)
            importItemsApi.get("api/superadmin/import/getall");
    }, [processResult, importItemsApi]);

    useEffect(() => {
        if (deleteProcessedResult.status === 2)
            importItemsApi.get("api/superadmin/import/getall");
    }, [deleteProcessedResult, importItemsApi]);

    useEffect(() => {
        if (deleteUnprocessedResult.status === 2)
            importItemsApi.get("api/superadmin/import/getall");
    }, [deleteUnprocessedResult, importItemsApi]);

    const columns = useMemo(
        () => [
            {
                accessorKey: "id",
                header: "ID"
            },
            {
                accessorKey: "type",
                header: "Typ"
            },
            {
                accessorKey: "info",
                header: "Info"
            },
            {
                accessorKey: "isProcessed",
                header: "Behandlad",
                cell: (cell) => {
                    return cell.row.original.isProcessed ? t("yes") : t("no");
                }
            },
            {
                accessorKey: "processResult",
                header: "Resultat",
                cell: (cell) => {
                    return cell.row.original.processResult &&
                        cell.row.original.isProcessed
                        ? "OK"
                        : "";
                }
            },
            {
                accessorKey: "resultMessage",
                header: "Meddelande"
            }
        ],
        []
    );

    const tableRows = importItemsResult.data ? importItemsResult.data : [];

    return (
        <>
            <PageHeader
                title={t("import")}
                subtitle={t(
                    "as_superadmin_you_can_import_users_locations_and_vehicles ",
                    "Here, as superadmin, you can import users, locations and vehicles for a company"
                )}
            />
            <Panel>
                <Row>
                    <Col className="mb-3">
                        {t(
                            "import_is_done_by_uploading",
                            "Import is done by uploading a semicolon-separated (';') CSV file. Each line contains a company name, which must be created before you start importing."
                        )}
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <h2 className="mb-3">
                            {t(
                                "the_format_of_the_file_must_be_as_follows",
                                "The format of the file must be as follows:"
                            )}
                        </h2>

                        <ul>
                            <li className="mb-2">
                                <strong>
                                    {t(
                                        "the_first_line_should_only_be_vehicle",
                                        "The first line should only be Vehicle, Site or User depending on the type of import you can make"
                                    )}
                                </strong>
                            </li>
                            <li className="mb-2">
                                {t(
                                    "for_site_the_rows_must_contain_these_columns",
                                    "For Site, the rows must contain these columns"
                                )}
                                <ul>
                                    <li>{t("company_name", "Company name")}</li>
                                    <li>{t("name", "Name")}</li>
                                </ul>
                            </li>
                            <li className="mb-2">
                                {t(
                                    "for_vehicle_the_rows_must_contain_these_columns"
                                )}
                                <ul>
                                    <li>{t("company_name", "Company name")}</li>
                                    <li>{t("reg_number", "Reg number")}</li>
                                    <li>{t("id_number", "Id nummber")}</li>
                                    <li>{t("model", "Model")}</li>
                                </ul>
                            </li>
                            <li className="mb-2">
                                {t(
                                    "for_user_the_lines_must_contain_these_columns",
                                    "For User, the lines must contain these columns"
                                )}
                                <ul>
                                    <li>{t("company_name", "Company name")}</li>
                                    <li>{t("name", "Name")}</li>
                                    <li>{t("email", "Email")}</li>
                                    <li>
                                        {t(
                                            "social_security_number",
                                            "Social security number"
                                        )}
                                    </li>
                                    <li>{t("phone_number", "Phone number")}</li>
                                    <li>{t("cities_name", "City name")}</li>
                                    <li>
                                        {t(
                                            "case_manager_name",
                                            "Case manager name"
                                        )}
                                    </li>
                                    <li>
                                        {t(
                                            "is_company_admin_must_contain",
                                            "IsCompanyAdmin (must contain at least one character for True, otherwise empty for False)"
                                        )}
                                    </li>
                                    <li>
                                        {t(
                                            "is_incident_handler_must_contain",
                                            "IsIncidentHandler (must contain at least a character for True, otherwise empty for false)"
                                        )}
                                    </li>
                                    <li>
                                        {t(
                                            "is_incident_reporter_must_contain",
                                            "IsIncidentReporter (must contain at least a character for True, otherwise empty for false)"
                                        )}
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        {t("upload_file", "Upload file")} <br />
                        <FileUpload
                            onSuccessUpload={(uploadedImage) => {
                                processApi.reset();

                                setTriggerRefresh((prev) => prev + 1);
                            }}
                        />
                        {t(
                            "when_file_is_uploaded",
                            "When the file is uploaded, import lines are created based on the data in the file. They are shown in the table below, and then you can choose to process these import rows, then the data is entered into the database."
                        )}
                        <br />
                        {t(
                            "by_some_reason_you_cannot_upload",
                            "Note, for some reason you cannot upload a file with the same name twice in a row (e.g. if it has gone wrong) so you have to reload the page before uploading the file again"
                        )}
                        <br />
                        <Button
                            onClick={() => {
                                processApi.post(
                                    "api/superadmin/import/importunprocessed"
                                );
                            }}
                            color="primary"
                            type="button"
                        >
                            {t(
                                "import_all_unimported",
                                "Import All Unimported (Processed = No)"
                            )}
                        </Button>
                        {processResult.status === 1 ? (
                            <Alert color="info">
                                {t(
                                    "porocesses_all_raw_import_rows",
                                    "Processes all raw import rows"
                                )}
                            </Alert>
                        ) : processResult.status === 2 ? (
                            <Alert color="success">
                                {t("import_done", "Import klar")}
                            </Alert>
                        ) : processResult.status > 2 ? (
                            <Alert color="danger">
                                {t(
                                    "something_went_wrong",
                                    "Something went wrong"
                                )}
                            </Alert>
                        ) : null}
                    </Col>
                </Row>
            </Panel>
            <div className="mt-2 mt-md-3 rounded-2 bg-white">
                <Row>
                    <Col xs={12}>
                        <DataTable
                            columns={columns}
                            result={importItemsResult}
                            rowData={tableRows}
                        />
                    </Col>
                </Row>
                <Row className="mt-4 p-2 p-md-3">
                    <Col>
                        <Button
                            disabled={deleteProcessedResult.status === 1}
                            onClick={() => {
                                deleteProcessedApi.post(
                                    "api/superadmin/import/deleteprocessed"
                                );
                            }}
                            color="warning"
                            type="button"
                        >
                            {t(
                                "delete_all_processed",
                                "Delete All Processed (Processed = Yes)"
                            )}
                        </Button>
                    </Col>
                    <Col>
                        <Button
                            disabled={deleteUnprocessedResult.status === 1}
                            onClick={() => {
                                deleteUnprocessedApi.post(
                                    "api/superadmin/import/deleteunprocessed"
                                );
                            }}
                            color="danger"
                            type="button"
                        >
                            {t(
                                "delete_all_unprocessed",
                                "Delete All Unprocessed (Processed = No)"
                            )}
                        </Button>
                    </Col>
                </Row>
            </div>
        </>
    );
};
export default Import;
