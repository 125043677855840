import {
    incidentStatusById,
    incidentStatusByStatusName
} from "helpers/incidentStatusById";
import React from "react";
import { useTranslation } from "react-i18next";

const IncidentStatus = ({ status, customer }) => {
    const { t } = useTranslation();
    const statusObj =
        typeof status === "string"
            ? incidentStatusByStatusName(status, t, customer)
            : incidentStatusById(status, t, customer);

    return (
        statusObj !== null && (
            <span
                style={{
                    backgroundColor: statusObj.color,
                    color: `${
                        statusObj.textColor ? statusObj.textColor : "#FFFFFF"
                    }`,
                    borderRadius: "100vh"
                }}
                className="badge badge-pill m-0"
            >
                {statusObj?.label}
            </span>
        )
    );
};

export default IncidentStatus;
