import React, { useEffect } from "react";
import { useApi } from "components/useApi3";

import PageHeader from "components/PageHeader";
import Panel from "components/Panel";
import { Button, Col, Form, FormGroup, Row } from "reactstrap";
import { Formik } from "formik";
import * as Yup from "yup";
import AlertResultFeedback from "components/AlertResultFeedback";
import ItemForm from "./Form";
import LoadingSpinner from "components/LoadingSpinner";
import AdminNavigation from "../components/AdminNavigation";
import { useTranslation } from "react-i18next";

const validationSchema = Yup.object().shape({
	name: Yup.string().required("Namn är obligatoriskt"),
	replyEmail: Yup.string()
		.email("Ogiltig epostadress")
		.required("Svarsadress är obligatoriskt")
});

const CrudPage = () => {
	const apiBaseUrl = "api/organisation/companies/mine";
	const { t } = useTranslation();

	const [editItemResult, editItemApi] = useApi();
	const [editResult, editApi] = useApi();

	useEffect(() => {
		editItemApi.get(apiBaseUrl);
		editApi.reset();
	}, [editItemApi, editApi]);

	return (
		<>
			<PageHeader
				title={t("company_settings")}
				subtitle={t("settings_for_your_company")}
			/>
			<AdminNavigation />
			<Row>
				<Col sm={12} lg={6}>
					<Panel>
						{editItemResult.status === 1 && <LoadingSpinner />}
						{editItemResult.status === 2 && (
							<Formik
								initialValues={{
									...editItemResult.data
								}}
								onSubmit={(values) => {
									// same shape as initial values

									editApi.put(apiBaseUrl, values);
								}}
								validationSchema={validationSchema}
							>
								{({
									errors,
									values,
									touched,
									handleChange,
									handleBlur,
									handleSubmit
								}) => (
									<Form onSubmit={handleSubmit} noValidate>
										<ItemForm
											handleSubmit={handleSubmit}
											handleBlur={handleBlur}
											values={values}
											errors={errors}
											touched={touched}
											handleChange={handleChange}
										/>
										<FormGroup>
											<Row>
												<Col xs={8}></Col>
												<Col xs={4}>
													<Button
														block
														disabled={
															editResult.status ===
															1
														}
														color="primary"
														type="submit"
													>
														{t("save")}
													</Button>
												</Col>
											</Row>
										</FormGroup>
									</Form>
								)}
							</Formik>
						)}
						<Row>
							<Col xs={12}>
								<AlertResultFeedback
									result={editResult}
									loadingMessage={t("saves")}
									doneMessage={t("saved")}
								/>
							</Col>
						</Row>
					</Panel>
				</Col>
			</Row>
		</>
	);
};
export default CrudPage;
