import { Col, FormGroup, Row } from "reactstrap";
import { useTranslation } from "react-i18next";
import TrygIncidentHeader from "./components/TrygIncidentHeader";
import { IncidentDataTypeRender } from "./components/helpers/IncidentDataTypeRender";
import { useContext } from "react";
import { AppContext } from "AppContext";
import TooltipIcon from "components/Tooltip";
import { isJson } from "./components/helpers/convertIncidentValues";

const TrygInformation = ({ item }) => {
    const appContextObj = useContext(AppContext);

    const { t } = useTranslation();

    return (
        <Row>
            <div
                className="d-flex flex-column align-content-end "
                style={{ minHeight: 400 }}
            >
                <TrygIncidentHeader item={item} />

                <Col md={12} xs={12} className="border-bottom mt-5 pb-3 p-0">
                    <h2 className="px-0">
                        {t("case_information", "Case information")}
                    </h2>
                </Col>

                {item.formGroups &&
                    item.formGroups.length > 0 &&
                    item.formGroups
                        .filter((x) => x.fields[0].value !== null)
                        .map((formGroup) => (
                            <div className="customer-information border-bottom">
                                <Row>
                                    <Col md={6} xs={12} className="d-flex ">
                                        <p className="customer-information__cell customer-information__bold mb-0">
                                            {t(
                                                formGroup.labelKey,
                                                formGroup.translateDK
                                            )}
                                        </p>

                                        {formGroup.fields.find(
                                            (x) =>
                                                x.component === "Input" ||
                                                x.component === "Upload" ||
                                                (isJson(x?.interpretedValue) &&
                                                    x.component !==
                                                        "ImageSelector" &&
                                                    x.component !==
                                                        "LocationPicker" &&
                                                    x.component !==
                                                        "MultiSelect")
                                        ) === undefined && (
                                            <TooltipIcon
                                                id={formGroup.labelKey}
                                                text={t(
                                                    "cant_edit_field_text",
                                                    "Unfortunately, this field cannot be edited because the previous selections are dependent on each other"
                                                )}
                                                title={t(
                                                    "cant_edit_field",
                                                    "You cannot edit this field"
                                                )}
                                            />
                                        )}
                                    </Col>
                                    <Col md={6} xs={12}>
                                        {formGroup.fields &&
                                            formGroup.fields.length > 0 &&
                                            formGroup.fields
                                                .filter((x) => x.value !== null)
                                                .map((field) => {
                                                    return (
                                                        <Col md={12} xs={12}>
                                                            <div className="mb-0 p-0">
                                                                {field.interpretedValue !==
                                                                null
                                                                    ? IncidentDataTypeRender(
                                                                          field.component,
                                                                          field,
                                                                          t,
                                                                          item.id,
                                                                          formGroup
                                                                      )
                                                                    : t(
                                                                          field.labelKey,
                                                                          field.translateDK
                                                                      )}
                                                            </div>
                                                        </Col>
                                                    );
                                                })}
                                    </Col>
                                </Row>
                            </div>
                        ))}
            </div>
        </Row>
    );
};

export default TrygInformation;
