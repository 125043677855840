import { AppContext } from "AppContext";
import { t } from "i18next";
import React, { useContext } from "react";
import { Button, Col, Row } from "reactstrap";

export const TrygFormButtons = (props) => {
	const { questionResult, setErrors, goToPreviousPage, addAnswerResult } =
		props;
	const appContext = useContext(AppContext);

	return (
		<Row className="d-flex justify-content-center ">
			<Col md={6} className="tryg-form-buttons">
				<div
					className={`d-flex flex-row  ${
						questionResult.status === 2 &&
						questionResult.data.previousFormGroupId !== null
							? "justify-content-between"
							: "justify-content-end"
					}`}
				>
					{questionResult.status === 2 &&
						questionResult.data.previousFormGroupId !== null && (
							<Button
								color="secondary"
								type="button"
								className="my-2 my-md-3"
								outline
								onClick={() => {
									setErrors({});
									goToPreviousPage();
								}}
							>
								{t("previous_step")}
							</Button>
						)}

					{(questionResult.data.fields?.findIndex(
						(x) => x.component === "Review"
					) !== -1 ||
						(addAnswerResult.status !== 1 &&
							(questionResult.data?.nextFormGroupId > 0 ||
								appContext.formSettings.nextQuestionId >
									0))) && (
						<Button
							color="primary"
							type="submit"
							className="my-2 my-md-3"
						>
							{questionResult.data.fields?.findIndex(
								(x) => x.component === "Review"
							) !== -1
								? t("send")
								: t("next_step")}
						</Button>
					)}

					{questionResult.data?.options &&
						questionResult.data.options.length < 1 && (
							<Button
								color="primary"
								type="submit"
								className="my-2 my-md-3"
							>
								{t("save")}
							</Button>
						)}
				</div>
			</Col>
		</Row>
	);
};
