import { de } from "date-fns/locale";
import { getCurrentLang } from "helpers/getCurrentLang";
import i18next, { t } from "i18next";
import { Line } from "react-chartjs-2";

const Chart = ({ result, costMode }) => {
    const chartData =
        result.status === 2
            ? costMode === "site"
                ? result.data.siteChartData
                : result.data.userChartData
            : null;

    let chDatasets = [];

    if (chartData != null && chartData.length > 0) {
        chDatasets = chartData.map((item) => {
            const color =
                "rgb(" +
                Math.floor(Math.random() * 255) +
                ", " +
                Math.floor(Math.random() * 255) +
                ", " +
                Math.floor(Math.random() * 255) +
                ")";

            return {
                label: t(item.name),
                data: item.values.map((item) => {
                    return {
                        x: item.date,
                        y: item.incidentCost
                    };
                }),
                fill: false,
                backgroundColor: color,
                borderColor: color
            };
        });
    }

    const options = {
        scales: {
            x: {
                type: "time",
                time: {
                    unit: "month"
                },
                adapters: {
                    date: {
                        locale: getCurrentLang(i18next.resolvedLanguage).fns
                    }
                }
            },
            y: {
                suggestedMax: 100000,
                min: 0,
                ticks: {
                    stepSize: 100000
                }
            }
        }
    };

    const chData = { datasets: chDatasets };

    return <Line data={chData} options={options} />;
};
export default Chart;
