import { AppContext } from "AppContext";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { FormGroup, Label, Input, Alert } from "reactstrap";

const Form = ({
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    setFieldValue,
    isEdit,
    managerList,
    sitesList
}) => {
    const { t } = useTranslation();
    const appContext = useContext(AppContext);
    return (
        <>
            <FormGroup>
                <Label for="name">{t("name")}</Label>
                <Input
                    className={errors.name ? "is-invalid" : ""}
                    autoComplete="off"
                    name="name"
                    value={values.name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                ></Input>
                {errors.name && touched.name && (
                    <span className="text-danger">{errors.name}</span>
                )}
            </FormGroup>
            <FormGroup>
                <Label for="employeeId">{t("employeeId")}</Label>
                <Input
                    className={errors.employeeId ? "is-invalid" : ""}
                    autoComplete="off"
                    name="employeeId"
                    value={values.employeeId}
                    onChange={handleChange}
                    onBlur={handleBlur}
                ></Input>
                {errors.employeeId && touched.employeeId && (
                    <span className="text-danger">{errors.employeeId}</span>
                )}
            </FormGroup>{" "}
            {appContext.customer !== "Tryg" && (
                <FormGroup>
                    <Label for="name">{t("social_security_number")}</Label>
                    <Input
                        className={
                            errors.personalIdentityNumber ? "is-invalid" : ""
                        }
                        autoComplete="off"
                        name="personalIdentityNumber"
                        value={values.personalIdentityNumber}
                        onChange={handleChange}
                        onBlur={handleBlur}
                    ></Input>
                    {errors.personalIdentityNumber &&
                        touched.personalIdentityNumber && (
                            <span className="text-danger">
                                {errors.personalIdentityNumber}
                            </span>
                        )}
                </FormGroup>
            )}
            <FormGroup>
                <Label for="name">{t("email")}</Label>
                <Input
                    className={errors.email ? "is-invalid" : ""}
                    autoComplete="off"
                    name="email"
                    value={values.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                ></Input>
                {errors.email && touched.email && (
                    <span className="text-danger">{errors.email}</span>
                )}
            </FormGroup>
            <FormGroup>
                <Label for="name">{t("phone")}</Label>
                <Input
                    className={errors.mobilePhone ? "is-invalid" : ""}
                    autoComplete="off"
                    name="mobilePhone"
                    value={values.mobilePhone}
                    onChange={handleChange}
                    onBlur={handleBlur}
                ></Input>
                {errors.mobilePhone && touched.mobilePhone && (
                    <span className="text-danger">{errors.mobilePhone}</span>
                )}
            </FormGroup>
            <FormGroup>
                <Label>
                    {t(
                        "associate_the_insurance_manager",
                        "Associate insurance manager"
                    )}
                </Label>
                <Input
                    type="select"
                    name="managerId"
                    id="managerId"
                    disabled={managerList.length === 0}
                    value={values.managerId}
                    onChange={handleChange}
                    onBlur={handleBlur}
                >
                    <option value="">-- {t("choose")} --</option>
                    {managerList.map((item) => {
                        return (
                            <option key={item.id} value={item.id}>
                                {item.name}
                            </option>
                        );
                    })}
                </Input>
            </FormGroup>
            <FormGroup>
                <Label>{t("department", "Department")}</Label>
                <Input
                    type="select"
                    name="siteId"
                    id="siteId"
                    disabled={sitesList.length === 0}
                    value={values.siteId}
                    onChange={handleChange}
                    onBlur={handleBlur}
                >
                    <option value="">-- {t("choose")} --</option>
                    {sitesList.map((item) => {
                        return (
                            <option key={item.id} value={item.id}>
                                {item.name}
                            </option>
                        );
                    })}
                </Input>
                {errors.siteId && touched.siteId && (
                    <span className="text-danger">{errors.siteId}</span>
                )}
            </FormGroup>
            <FormGroup>
                <Label>{t("roles")}</Label>
                <FormGroup check>
                    <Label check>
                        <Input
                            type="checkbox"
                            name="incidentReporter"
                            checked={
                                values.roles.indexOf("IncidentReporter") !== -1
                            }
                            onChange={(e) => {
                                const isChecked = e.target.checked;
                                const newRoles = values.roles.filter(
                                    (x) => x !== "IncidentReporter"
                                );
                                if (isChecked) {
                                    newRoles.push("IncidentReporter");
                                }

                                setFieldValue("roles", newRoles);
                            }}
                        />
                        {t("claims_report")}
                    </Label>
                </FormGroup>
                <FormGroup check>
                    <Label check>
                        <Input
                            type="checkbox"
                            name="incidentHandler"
                            checked={
                                values.roles.indexOf("IncidentHandler") !== -1
                            }
                            onChange={(e) => {
                                const isChecked = e.target.checked;
                                const newRoles = values.roles.filter(
                                    (x) => x !== "IncidentHandler"
                                );
                                if (isChecked) {
                                    newRoles.push("IncidentHandler");
                                }

                                setFieldValue("roles", newRoles);
                            }}
                        />
                        {t("claims_handler")}
                    </Label>
                </FormGroup>
                <FormGroup check>
                    <Label check>
                        <Input
                            type="checkbox"
                            name="companyAdmin"
                            checked={
                                values.roles.indexOf("CompanyAdmin") !== -1
                            }
                            onChange={(e) => {
                                const isChecked = e.target.checked;
                                const newRoles = values.roles.filter(
                                    (x) => x !== "CompanyAdmin"
                                );
                                if (isChecked) {
                                    newRoles.push("CompanyAdmin");
                                }

                                setFieldValue("roles", newRoles);
                            }}
                        />
                        {t("company_admin")}
                    </Label>
                </FormGroup>
                {errors.roles && touched.roles && (
                    <span className="text-danger">{errors.roles}</span>
                )}
            </FormGroup>
            {!isEdit && (
                <FormGroup>
                    <Alert color="info">
                        {t("temporary_password")}:{" "}
                        <strong>{values.tempPassword}</strong>,{" "}
                        {t("only_visible_here_when_creating_the_user")}
                    </Alert>
                </FormGroup>
            )}
        </>
    );
};
export default Form;
