// hantera ärende formulär-inputs
import Switch from "components/InputSwitch";
import NumberField from "components/NumberField";
import {
    FormGroup,
    Label,
    Input,
    Col,
    Row,
    Button,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Spinner
} from "reactstrap";
import Datetime from "react-datetime";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import IncidentStatus from "components/IncidentStatus";
import { incidentStatusById } from "helpers/incidentStatusById";
import { CheckIcon, PaperPlaneIcon, SaveIcon } from "components/Icons";
import { IncidentFormStatusText } from "./components/helpers/incidentFormStatusText";
import { useEffect, useState } from "react";
import IncidentModal from "./components/IncidentModal";

export const HandleTrygIncidentFormInputs = ({
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    setFieldValue,
    appContextObj,
    handleSubmit,
    setSubmitEndpoint,
    adminQuestionsMissing,
    loading
}) => {
    const { t } = useTranslation();
    const [showModal, setShowModal] = useState(false);
    const [submitValuesObj, setSubmitValuesObj] = useState({
        endpoint: "",
        submitValues: null,
        translationTitle: "",
        translationText: ""
    });

    const handleShowModal = (
        endpoint,
        submitValues,
        translationTitle,
        translationText
    ) => {
        setSubmitValuesObj({
            endpoint,
            submitValues,
            translationTitle,
            translationText
        });
        setShowModal(true);
    };

    const submitValues = (endpoint, submitValues) => {
        if (submitValuesObj) {
            setSubmitEndpoint(submitValuesObj.endpoint);
            handleSubmit(submitValuesObj.submitValues);
        }
    };

    useEffect(() => {
        !showModal && setSubmitValuesObj({ endpoint: "", submitValues: null });
    }, [showModal]);

    return (
        <>
            {showModal && (
                <IncidentModal
                    setModalState={setShowModal}
                    modalState={showModal}
                    title={submitValuesObj.translationTitle}
                    body={submitValuesObj.translationText}
                    cancelBtnText={t("cancel", "Cancel")}
                    submitBtnText={t("submit", "Submit")}
                    submitFunction={submitValues}
                    disabled={
                        submitValuesObj.endpoint === "sendincidenttotryg" &&
                        adminQuestionsMissing
                    }
                    loading={loading}
                />
            )}

            {values.status === 0 || values.status === 5 ? (
                <div className="tryg-incident-form bg-white rounded-3 shadow-sm">
                    <Col
                        md={12}
                        xs={12}
                        style={{
                            backgroundColor: incidentStatusById(
                                values.status,
                                t,
                                appContextObj.customer
                            ).backgroundColor,
                            borderRadius:
                                values.status !== 5 ? "4px 4px 0px 0px" : "4px"
                        }}
                        className={`tryg-incident-form__header p-4 d-flex align-items-start justify-content-between`}
                    >
                        <div className="">
                            <h2>
                                {t(
                                    "insurance_information",
                                    "Insurance information"
                                )}
                            </h2>
                            {IncidentFormStatusText(
                                values.status,
                                t,
                                appContextObj.customer
                            )}
                        </div>
                        <IncidentStatus
                            status={values.status}
                            customer={appContextObj.customer}
                        />
                    </Col>
                    {values.status === 0 && (
                        <div className="mb-0 p-3">
                            <div className="d-flex justify-content-end">
                                <Button
                                    onClick={() => {
                                        setSubmitEndpoint(
                                            "incidents/"
                                                .concat(values.id)
                                                .concat("/sendtryg")
                                        );
                                        handleSubmit(values);
                                    }}
                                    color="success"
                                    type="button"
                                    className="d-flex align-items-center"
                                >
                                    <span className="me-2">
                                        {t("send_in", "Indsende")}
                                    </span>
                                    <PaperPlaneIcon />
                                </Button>
                            </div>
                        </div>
                    )}
                </div>
            ) : (
                <div className="tryg-incident-form bg-white rounded-3 shadow-sm">
                    <Col
                        md={12}
                        xs={12}
                        style={{
                            backgroundColor: incidentStatusById(
                                values.status,
                                t,
                                appContextObj.customer
                            ).backgroundColor
                        }}
                        className={`tryg-incident-form__header p-4 d-flex align-items-start justify-content-between`}
                    >
                        <div className="">
                            <h2>
                                {t(
                                    "insurance_information",
                                    "Insurance information"
                                )}
                            </h2>
                            <p>
                                {IncidentFormStatusText(
                                    values.status,
                                    t,
                                    appContextObj.customer
                                )}
                            </p>
                        </div>
                        <IncidentStatus
                            status={values.status}
                            customer={appContextObj.customer}
                        />
                    </Col>

                    <div className=" p-4">
                        <Row>
                            <Col xs={12}>
                                <FormGroup>
                                    <Switch
                                        onChange={() =>
                                            setFieldValue(
                                                "inscuranceCase",
                                                !values.inscuranceCase
                                            )
                                        }
                                        checked={values.inscuranceCase}
                                        label={t("insurance_case")}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                        {values.inscuranceCase && (
                            <>
                                <Row>
                                    <Col xs={12}>
                                        <FormGroup>
                                            <Label>
                                                {t(
                                                    "estimated_cost_of_damage_caused"
                                                )}
                                            </Label>
                                            <NumberField
                                                value={
                                                    values.estimatedCostForInjury
                                                }
                                                onChange={handleChange}
                                                name="estimatedCostForInjury"
                                                onBlur={handleBlur}
                                                className={
                                                    errors.estimatedCostForInjury
                                                        ? "is-invalid"
                                                        : ""
                                                }
                                            />
                                            {errors.estimatedCostForInjury ? (
                                                <div className="invalid-feedback d-block">
                                                    {
                                                        errors.estimatedCostForInjury
                                                    }
                                                </div>
                                            ) : null}
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={12}>
                                        <FormGroup>
                                            <Label>
                                                {" "}
                                                {t("counterpart_cost")}
                                            </Label>
                                            <NumberField
                                                value={values.counterpartCost}
                                                onChange={handleChange}
                                                name="counterpartCost"
                                                onBlur={handleBlur}
                                                className={
                                                    errors.counterpartCost
                                                        ? "is-invalid"
                                                        : ""
                                                }
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col xs={12}>
                                        <FormGroup>
                                            <Label> {t("other_notes")}</Label>
                                            <Input
                                                className={
                                                    errors.notes
                                                        ? "is-invalid"
                                                        : ""
                                                }
                                                autoComplete="off"
                                                name="notes"
                                                value={values.notes}
                                                type="textarea"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                            ></Input>
                                        </FormGroup>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col xs={12}>
                                        <FormGroup>
                                            <Label> {t("damage_number")}</Label>
                                            <Input
                                                className={
                                                    errors.damageNumber
                                                        ? "is-invalid"
                                                        : ""
                                                }
                                                autoComplete="off"
                                                name="damageNumber"
                                                value={values.damageNumber}
                                                type="text"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                            ></Input>
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </>
                        )}
                        <Row>
                            <Col xs={12}>
                                <FormGroup>
                                    <Switch
                                        onChange={() =>
                                            setFieldValue(
                                                "completedForInsuranceCompanies",
                                                !values.completedForInsuranceCompanies
                                            )
                                        }
                                        checked={
                                            values.completedForInsuranceCompanies
                                        }
                                        label={t(
                                            "completed_for_insurance_companies"
                                        )}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                        {values.completedForInsuranceCompanies && (
                            <>
                                <Row>
                                    <Col xs={12}>
                                        <FormGroup>
                                            <Label>
                                                {" "}
                                                {t(
                                                    "coverage_clarification",
                                                    "Coverage clarification"
                                                )}
                                            </Label>
                                            <Input
                                                className={
                                                    errors.result
                                                        ? "is-invalid"
                                                        : ""
                                                }
                                                autoComplete="off"
                                                name="result"
                                                value={values.result}
                                                type="textarea"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                            ></Input>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={12}>
                                        <FormGroup>
                                            <Label>
                                                {" "}
                                                {t(
                                                    "date_of_decision_tryg",
                                                    "Decision date, Tryg"
                                                )}
                                            </Label>
                                            <Datetime
                                                locale={
                                                    i18next.resolvedLanguage
                                                }
                                                onChange={(e) => {
                                                    setFieldValue(
                                                        "regulationReady",
                                                        e
                                                    );
                                                }}
                                                timeFormat={false}
                                                inputProps={{
                                                    placeholder: t(
                                                        "date_of_decision_tryg",
                                                        "Decision date, Tryg"
                                                    ),
                                                    className: "form-control"
                                                }}
                                                value={values.regulationReady}
                                            />
                                            {errors.regulationReady ? (
                                                <div className="invalid-feedback d-block">
                                                    {errors.regulationReady}
                                                </div>
                                            ) : null}
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={12}>
                                        <div className="mb-0">
                                            <Label>
                                                {" "}
                                                {t("compensation_amount")}
                                            </Label>
                                            <NumberField
                                                value={
                                                    values.compensationAmount
                                                }
                                                onChange={handleChange}
                                                name="compensationAmount"
                                                onBlur={handleBlur}
                                                className={
                                                    errors.compensationAmount
                                                        ? "is-invalid"
                                                        : ""
                                                }
                                            />
                                        </div>
                                    </Col>
                                </Row>
                            </>
                        )}

                        {values.inscuranceCase && (
                            <Col className="d-flex justify-content-end mt-4">
                                {(values.status === 1 ||
                                    values.status === 2) && (
                                    <Button
                                        onClick={() => {
                                            setSubmitEndpoint(
                                                "saveincidentlocal"
                                            );
                                            handleSubmit(values);
                                        }}
                                        type="button"
                                        color="secondary"
                                        outline
                                        className="me-2"
                                    >
                                        {loading && (
                                            <Spinner
                                                as="span"
                                                animation="border"
                                                size="sm"
                                                role="status"
                                                aria-hidden="true"
                                                className="me-2"
                                            />
                                        )}
                                        {t("save")}
                                    </Button>
                                )}

                                {values.status === 3 && (
                                    <Button
                                        onClick={() =>
                                            handleShowModal(
                                                "closeandcompleteincident",
                                                values,
                                                t("close_and_complete"),
                                                t("close_and_complete")
                                            )
                                        }
                                        color="success"
                                        type="button"
                                        className=""
                                    >
                                        <CheckIcon />

                                        <span className="ms-2">
                                            {t(
                                                "close_and_complete",
                                                "Close and Complete"
                                            )}{" "}
                                        </span>
                                    </Button>
                                )}

                                {values.status !== 3 && (
                                    <UncontrolledDropdown group>
                                        {(values.status === 6 ||
                                            values.status === 1 ||
                                            values.status === 2) && (
                                            <Button
                                                onClick={() =>
                                                    handleShowModal(
                                                        "closeandcompleteincident",
                                                        values,
                                                        t("close_and_complete"),
                                                        t("close_and_complete")
                                                    )
                                                }
                                                color="primary"
                                                type="button"
                                                className=""
                                            >
                                                {/* <CheckIcon /> */}

                                                <span className="">
                                                    {t(
                                                        "close_and_complete",
                                                        "Close and Complete"
                                                    )}{" "}
                                                </span>
                                            </Button>
                                        )}
                                        <DropdownToggle caret color="primary" />
                                        <DropdownMenu>
                                            {(values.status === 1 ||
                                                values.status === 2 ||
                                                values.status === 6) && (
                                                <DropdownItem
                                                    onClick={() =>
                                                        handleShowModal(
                                                            "sendincidenttotryg",
                                                            values,
                                                            t("report_to_tryg"),
                                                            adminQuestionsMissing
                                                                ? t(
                                                                      "missing_admin_question_send_to_tryg"
                                                                  )
                                                                : ""
                                                        )
                                                    }
                                                    color="primary"
                                                    type="button"
                                                    className="d-flex align-items-center"
                                                >
                                                    <PaperPlaneIcon />

                                                    <span className="ms-2">
                                                        {" "}
                                                        {t(
                                                            "report_to_tryg",
                                                            "Report to Tryg"
                                                        )}
                                                    </span>
                                                </DropdownItem>
                                            )}
                                            {(values.status === 1 ||
                                                values.status === 2) && (
                                                <DropdownItem
                                                    onClick={() =>
                                                        handleShowModal(
                                                            "savedlocally",
                                                            values,
                                                            t(
                                                                "save_internally"
                                                            ),
                                                            t("save_internally")
                                                        )
                                                    }
                                                    color="primary"
                                                    outline
                                                    type="button"
                                                    className=""
                                                >
                                                    <SaveIcon />
                                                    <span className="ms-2">
                                                        {t(
                                                            "internal_damage",
                                                            "Internal damage"
                                                        )}
                                                    </span>
                                                </DropdownItem>
                                            )}

                                            {(values.status === 6 ||
                                                values.status === 1 ||
                                                values.status === 2) && (
                                                <DropdownItem
                                                    onClick={() =>
                                                        handleShowModal(
                                                            "closeandcompleteincident",
                                                            values,
                                                            t(
                                                                "close_and_complete"
                                                            ),
                                                            t(
                                                                "close_and_complete"
                                                            )
                                                        )
                                                    }
                                                    color="success"
                                                    type="button"
                                                    className=""
                                                >
                                                    <CheckIcon />

                                                    <span className="ms-2">
                                                        {t(
                                                            "close_and_complete",
                                                            "Close and Complete"
                                                        )}{" "}
                                                    </span>
                                                </DropdownItem>
                                            )}
                                        </DropdownMenu>
                                    </UncontrolledDropdown>
                                )}
                            </Col>
                        )}
                    </div>
                </div>
            )}
        </>
    );
};
