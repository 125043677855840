import { t } from "i18next";
import moment from "moment";

export function isJson(str) {
	try {
		var json = JSON.parse(str);
		return typeof json === "object";
	} catch (e) {
		return false;
	}
}

export const renderJson = (json) => {
	let data = [];
	for (let i = 0; i < JSON.parse(json)?.length; i++) {
		let obj = JSON.parse(json)[i];
		obj !== null &&
			Object.keys(obj)
				.sort()
				.map((x) => {
					const value = {
						labelKey: x,
						value: obj[x]
					};
					return data.push(value);
				});
	}
	return data;
};

const imageCard = (field) => {
	const idToNumber = Number(field.interpretedValue);
	const filteredImageCards = field.options.filter(
		(option) => option.id === idToNumber
	);

	return filteredImageCards.map((image) => {
		return image.value;
	});
};

export const getConvertIncidentValues = (field) => {
	switch (field.component) {
		case "LocationPicker":
			return field.interpretedValue;
		case "AutoSelectLocationButton":
			return t(field.labelKey);
		case "ImageSelector":
			return isJson(field.interpretedValue)
				? renderJson(field.interpretedValue)
				: field.interpretedValue;
		case "Upload":
			return field.base64Files;
		case "ImageCard":
			return imageCard(field);
		case "IncidentDateTimePicker":
			return moment(field.interpretedValue).format(
				"dddd Do MMMM YYYY, h:mm"
			);
		case "Select":
			return field.interpretedValue;
		default:
			return isJson(field.interpretedValue)
				? renderJson(field.interpretedValue)
				: field.interpretedValue;
	}
};
