import LanguageDetector from "i18next-browser-languagedetector";

import i18next from "i18next";
import Locize from "i18next-locize-backend";
import { initReactI18next } from "react-i18next";

const locizeOptions = {
    projectId: "bcd73eea-6500-4426-bb14-ac3a6f2239a5",
    apiKey: "49435c2d-8b1f-4d7d-84e8-3b5114590b6c", // YOU should not expose your apps API key to production!!!
    referenceLng: "sv-SE",
    loadPath:
        "https://api.locize.app/bcd73eea-6500-4426-bb14-ac3a6f2239a5/latest/{{lng}}/dev"
};

i18next.use(Locize).use(LanguageDetector).use(initReactI18next).init({
    debug: true,
    fallbackLng: "en-GB",
    saveMissing: true,
    backend: locizeOptions,
    locizeLastUsed: locizeOptions
});

export default i18next;
