import { useTranslation } from "react-i18next";
import { FormGroup, Label, Input } from "reactstrap";

const Form = ({ values, errors, touched, handleBlur, handleChange }) => {
	const { t } = useTranslation();
	return (
		<>
			<FormGroup>
				<Label for="name"> {t("name_on_department")}</Label>
				<Input
					className={errors.name ? "is-invalid" : ""}
					autoComplete="off"
					name="name"
					value={values.name}
					onChange={handleChange}
					onBlur={handleBlur}
				></Input>
				{errors.name && touched.name && (
					<span className="text-danger">{errors.name}</span>
				)}
			</FormGroup>
		</>
	);
};
export default Form;
