import React, { useContext } from "react";
import { Nav, NavbarBrand } from "reactstrap";
import classNames from "classnames";
import { Link } from "react-router-dom";
import { AuthMenuItems } from "./api-authorization/MenuItem";
import logo from "../assets/Skadestop.svg";
import Thlogo from "../assets/Trygg-Hansa_Logo.svg";
import Tryglogo from "../assets/Tryg_logo.svg";
import { AppContext } from "AppContext";
import { t } from "i18next";

const Sidebar = ({ isOpen, toggle }) => {
	const appContext = useContext(AppContext);
	return (
		<div
			className={classNames(
				"sidebar",
				"d-flex justify-content-between flex-column",
				"bg-dark",
				{ "is-open": isOpen }
			)}
		>
			<div>
				<div className="sidebar-header d-flex justify-content-between">
					<NavbarBrand tag={Link} to="/" className="nav-link p-4">
						<img
							src={logo}
							alt="logo"
							width="250"
							className="img-fluid"
						/>
					</NavbarBrand>
					<span
						color="info"
						onClick={toggle}
						style={{ color: "#fff" }}
						className="d-sm-none"
					>
						&times;
					</span>
				</div>
				<div className="side-menu">
					<Nav vertical className="list-unstyled pb-3">
						<AuthMenuItems onItemClick={toggle} />
					</Nav>
				</div>
			</div>
			{appContext.customer === "Tryg" ? (
				<div className="d-flex flex-column align-items-center justify-content-center">
					<img
						src={Tryglogo}
						alt="logo"
						width="100"
						className="img-fluid mx-auto d-block mb-4"
					/>
					<p className="m-0 text-white weight-bolder">
						{t("provided_by", "Provided by")}
					</p>
					<p className="mb-4 text-white weight-bolder">
						Cloudplanning
					</p>
				</div>
			) : (
				<div className="d-flex flex-column align-items-center justify-content-center">
					<img
						src={Thlogo}
						alt="logo"
						width="100"
						className="img-fluid mx-auto d-block mb-4"
					/>
					<p className="m-0 text-white weight-bolder">
						{t("provided_by", "Provided by")}
					</p>
					<p className="mb-4 text-white weight-bolder">
						Cloudplanning
					</p>
				</div>
			)}
		</div>
	);
};

export default Sidebar;
