import Panel from "components/Panel";
import React, { useContext, useEffect, useState } from "react";
import ReviewAndSubmit from "./components/ReviewAndSubmit";
import StepOne from "./components/StepOne";
import StepTwo from "./components/StepTwo";
import {
    initialValuesStep1,
    initialValuesStep2
} from "../../../components/form/TryggHansa/schema";
import { useApi } from "components/useApi3";
import { useHistory } from "react-router-dom";
import {
    Alert,
    Button,
    Col,
    Container,
    Form,
    FormGroup,
    Input,
    Label,
    Row
} from "reactstrap";
import { useTranslation } from "react-i18next";
import { FAQTree } from "../components/IncidentForm/Form";
import Switch from "components/InputSwitch";
import SplitDatetime from "components/SplitDatetime";
import { AppContext } from "AppContext";

const CreateIncident = () => {
    // tre tabbar med två olika formulär som har varsin validering, sen en granskasida
    // när det är dags att spara, kombinera dessa två formulärs state
    // man sparar en IncidentFormDto, som är step1 och 2 kombinerat
    const { t } = useTranslation();
    const [step, setStep] = useState(1);
    const [createResult, createApi] = useApi();
    // when a form is submitted save the values to this state, and if the form is revisited it gets repopulated with this state
    const [step1Values, setStep1Values] = useState(initialValuesStep1);
    const [step2Values, setStep2Values] = useState(initialValuesStep2);
    const appContext = useContext(AppContext);
    const history = useHistory();

    useEffect(() => {
        if (createResult.status === 2) {
            history.push("/incidents/mine");
        }
    }, [createResult, history]);

    return appContext.customer === "Tryg" ? (
        <div>
            <FAQTree />
        </div>
    ) : (
        <>
            {/* 
                    <Row>
                        <Col xs={12}>
                            <FormGroup>
                                <Label> {t("status")}</Label>
                                <Input type="select" name="status" id="status">
                                    <option value="Open"> {t("open")}</option>
                                    <option value="Sent"> {t("sent")}</option>
                                    <option value="Completed">
                                        {" "}
                                        {t("completed")}
                                    </option>
                                </Input>
                            </FormGroup>
                        </Col>
                        <Col xs={12}>
                            <FormGroup>
                                <Label> {t("status")}</Label>
                                <Input name="status" id="status">
                                    <option value="Open"> {t("open")}</option>
                                    <option value="Sent"> {t("sent")}</option>
                                    <option value="Completed">
                                        {" "}
                                        {t("completed")}
                                    </option>
                                </Input>
                            </FormGroup>
                        </Col>
                        <Col xs={12}>
                            <FormGroup>
                                <Label> {t("status")}</Label>
                                <Input
                                    type="textarea"
                                    name="status"
                                    id="status"
                                >
                                    <option value="Open"> {t("open")}</option>
                                    <option value="Sent"> {t("sent")}</option>
                                    <option value="Completed">
                                        {" "}
                                        {t("completed")}
                                    </option>
                                </Input>
                            </FormGroup>
                        </Col>
                        <Col xs={12}>
                            <FormGroup>
                                <Switch
                                    label={t(
                                        "completed_for_insurance_companies"
                                    )}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                </div>
            </div> */}
            <div className="d-flex justify-content-center m-2">
                <div className="bg-white p-4 col-12 col-md-6">
                    {step === 1 && (
                        <div className="d-flex justify-content-center m-2">
                            <strong>1. {t("claims_case")} &gt;</strong>&nbsp;2.{" "}
                            {t("description")} &gt; 3. {t("review")}
                        </div>
                    )}
                    {step === 2 && (
                        <div className="d-flex justify-content-center m-2">
                            1. {t("claims_case")} &gt;&nbsp;
                            <strong>
                                2. {t("description")} &gt;{" "}
                            </strong>&nbsp;3. {t("review")}
                        </div>
                    )}
                    {step === 3 && (
                        <div className="d-flex justify-content-center m-2">
                            1. {t("claims_case")} &gt;&nbsp;2.{" "}
                            {t("description")} &gt;&nbsp;
                            <strong>3. {t("review")} </strong>
                        </div>
                    )}
                    <Panel>
                        <div className="incident-form" id="topOfForm">
                            {step === 1 && (
                                <StepOne
                                    initialValues={step1Values}
                                    onSubmit={(values) => {
                                        setStep1Values(values);
                                        setStep(2);
                                    }}
                                />
                            )}
                            {step === 2 && (
                                <StepTwo
                                    insuranceCategory={
                                        step1Values.insuranceCategory
                                    }
                                    initialValues={step2Values}
                                    onBack={() => {
                                        setStep(1);
                                    }}
                                    onSubmit={(values) => {
                                        setStep2Values(values);
                                        setStep(3);
                                    }}
                                />
                            )}
                            {step === 3 && (
                                <ReviewAndSubmit
                                    values={{ ...step1Values, ...step2Values }}
                                    onSubmit={() => {
                                        // post data to api and wait for the error message
                                        const saveMe = {
                                            ...step1Values,
                                            ...step2Values,
                                            numberOfPassengers: parseInt(
                                                step2Values.numberOfPassengers
                                            ),
                                            mileage: parseInt(
                                                step2Values.mileage
                                            ),
                                            incidentDate:
                                                step1Values.incidentDate.toISOString()
                                            // followUpTalkDate: .followUpTalkDate !== "" ? state.followUpTalkDate.toISOString() : null,
                                            // handlingDoneDate: state.handlingDoneDate !== "" ? state.handlingDoneDate.toISOString() : null,
                                        };

                                        createApi.post(
                                            "api/incident/incidents",
                                            saveMe
                                        );
                                    }}
                                    createResult={createResult}
                                    onBack={() => {
                                        setStep(2);
                                    }}
                                />
                            )}
                        </div>
                        {createResult.status > 2 && (
                            <Alert color="danger">
                                {t("an_error_occured_could_not_save_the_case")}
                            </Alert>
                        )}
                        {createResult.status === 1 && (
                            <Alert color="info"> {t("saves_the_case")}</Alert>
                        )}
                    </Panel>
                </div>
            </div>
        </>
    );
};

export default CreateIncident;
