import React, { useContext } from "react";
import { AppContext } from "AppContext";
import { Col, Row } from "reactstrap";
import Panel from "components/Panel";
import Hero from "components/Hero";
import IncidentsSummary from "./components/IncidentsSummary";
import HandlerIncidentList from "./components/HandlerIncidentList";
import { useTranslation } from "react-i18next";

const Dashboard = () => {
	const authContext = useContext(AppContext);
	const { t, i18n } = useTranslation();
	const isReporter =
		authContext.userRoles &&
		authContext.userRoles.indexOf("IncidentReporter") !== -1;
	const isHandler =
		authContext.userRoles &&
		authContext.userRoles.indexOf("IncidentHandler") !== -1;

	return (
		<>
			<Row>
				<Col md={12} xs={12} xl={6}>
					<Panel>
						<Hero isIncidentReporter={isReporter} />
					</Panel>
					{isHandler && (
						<div className="p-0 bg-white mt-2 mt-md-3 rounded-2">
							<HandlerIncidentList />
						</div>
					)}
				</Col>
				<Col md={12} xs={12} xl={6}>
					{(isHandler || isReporter) && (
						<Panel>
							<div className="p-2 p-md-0">
								{isReporter ? (
									<h1>{t("yourtrafficarea")}</h1>
								) : (
									<h1>{t("trafficareas")}</h1>
								)}
							</div>

							<IncidentsSummary
								isHandler={isHandler}
								isReporter={isReporter}
							/>
						</Panel>
					)}
				</Col>
			</Row>
		</>
	);
};

export default Dashboard;
