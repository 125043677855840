import React, { useEffect, useMemo, useState } from "react";
import { useApi } from "components/useApi3";
import DataTable from "components/DataTable";

import PageHeader from "components/PageHeader";
import Panel from "components/Panel";
import {
	Button,
	ButtonGroup,
	Col,
	FormGroup,
	Nav,
	NavItem,
	NavLink
} from "reactstrap";
import { useHistory } from "react-router-dom";
import moment from "moment";
import incidentCategory from "components/IncidentCatogory";
import IncidentStatus from "components/IncidentStatus";
import IncidentProgress from "components/IncidentProgress";
import Switch from "components/InputSwitch";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import { getCurrentLang } from "helpers/getCurrentLang";
import { incidentStatusByStatusName } from "helpers/incidentStatusById";
import { useContext } from "react";
import { AppContext } from "AppContext";
import TableFiltrationButton from "components/TableFiltrationButton";
import classnames from "classnames";
import FollowUp from "./followup/FollowUp";
const MyIncidents = () => {
	const history = useHistory();
	const { t } = useTranslation();
	const [listResult, listApi] = useApi();
	const appContext = useContext(AppContext);
	const [tab, setTab] = useState(0);
	const [filters, setFilters] = useState({
		statusOpen: true,
		statusSent: true,
		statusCompleted: true
	});

	function multiSelectFilter(rows, columnIds, filterValue) {
		return filterValue.length === 0
			? rows
			: rows.filter((row) =>
					filterValue.includes(String(row.original[columnIds]))
			  );
	}

	useEffect(() => {
		listApi.get("api/incident/mine");
	}, [listApi, filters]);

	const statusFilters =
		appContext.customer === "Tryg"
			? [
					{
						name: t("incoming", "Incoming"),
						value: "Open"
					},
					{
						name: t("new", "New"),
						value: "New"
					},
					{
						name: t("reviewed", "reviewed"),
						value: "Recieved"
					},
					{
						name: t("tryg_damage", "Tryg damage"),
						value: "SentToTryg"
					},
					{
						name: t("internal_damage", "Internal damage"),
						value: "SavedLocally"
					},
					{
						name: t("completed", "Completed"),
						value: "Completed"
					},
					{
						name: t("removed", "Removed"),
						value: "Removed"
					}
			  ]
			: [
					{
						name: t("incoming", "Incoming"),
						value: "Open"
					},
					{
						name: t("sent", "Sent"),
						value: "Sent"
					},
					{
						name: t("completed", "Completed"),
						value: "Completed"
					}
			  ];
	const initialState =
		appContext.customer === "Tryg"
			? {
					filters: [
						{
							id: "status",
							value: [
								"New",
								"SentToTryg",
								"SavedLocally",
								"Recieved",
								"Completed"
							]
						}
					]
			  }
			: {
					filters: [
						{
							id: "status",
							value: ["Open", "Sent", "Completed"]
						}
					]
			  };
	const columns = [
		{
			enableColumnFilter: true,
			accessorKey: "category",
			header: t("incident"),
			hideHeaderMobile: true,
			orderMobile: 0,
			fullWidthMobile: true,
			cell: (cell) => {
				return t(
					cell.row.original.category,
					cell.row.original.category
				);
			}
		},
		{
			enableColumnFilter: true,
			accessorKey: "status",
			header: t("status"),
			hideHeaderMobile: true,
			orderMobile: 1,
			cell: (cell) => {
				return (
					<IncidentStatus
						status={cell.row.original.status}
						customer={appContext.customer}
					/>
				);
			},
			Filter: TableFiltrationButton,
			hiddenHeaderFilter: true,
			filterFn: "multiFilter",
			FilterOptions: statusFilters
		},
		{
			enableColumnFilter: true,
			accessorKey: "vehicleRegistrationNumber",
			header: t("registration_number", "Regnumber")
		},
		{
			enableColumnFilter: true,
			accessorKey: "vehicleIdentifier",
			header: t("vehicle_id", "Vehicle")
		},
		{
			enableColumnFilter: true,

			accessorKey: "vehicleModel",
			header: t("model"),
			hideMobile: true
		},
		{
			enableColumnFilter: true,

			accessorKey: "incidentDate",
			header: t("incident_date"),
			cell: (cell) => {
				return moment(cell.row.original.incidentDate).format("L HH:mm");
			}
		},

		{
			enableColumnFilter: true,

			accessorKey: "createdDate",
			header: t("created"),
			cell: (cell) => {
				return moment(cell.row.original.createdDate).format("L HH:mm");
			}
		},

		{
			accessorKey: "commands",
			header: "",
			align: "right",
			enableColumnFilter: false,
			cell: (cell) => {
				return (
					<ButtonGroup className="">
						<Button
							size="sm"
							color="white"
							onClick={() => {
								history.push(
									"/incidents/view/".concat(
										cell.row.original.id
									)
								);
							}}
						>
							{t("details")}
						</Button>
					</ButtonGroup>
				);
			}
		}
	];

	return (
		<>
			<PageHeader
				title={t("my_insurance_claims")}
				subtitle={t(
					"here_you_can_track_your_submitted_insurance_claims"
				)}
			/>
			<Nav className="nav-tabs">
				<NavItem>
					<NavLink
						className={classnames({ active: tab === 0 })}
						onClick={() => {
							setTab(0);
						}}
					>
						{t("my_insurance_claims", "Mina ärenden")}
					</NavLink>
				</NavItem>
				<NavItem>
					<NavLink
						className={classnames({ active: tab === 1 })}
						onClick={() => {
							setTab(1);
						}}
					>
						{t("follow_up", "Uppföljning")}
					</NavLink>
				</NavItem>
			</Nav>
			<div className="my-2 my-md-3">
				{tab === 0 && (
					<DataTable
						columns={columns}
						result={listResult}
						initialState={initialState}
					/>
				)}
				{tab === 1 && <FollowUp />}
			</div>
		</>
	);
};
export default MyIncidents;
