import { useApi } from "components/useApi3";
import { useEffect } from "react";
import LoadingSpinner from "components/LoadingSpinner";
import { Nav, NavItem, NavLink, Alert, Row, Col } from "reactstrap";
import TrygEdit from "./TrygEditForm";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useState } from "react";
import classnames from "classnames";
import { t } from "i18next";
import TrygInformation from "./TrygInformation";
import { getConvertIncidentValues } from "./components/helpers/convertIncidentValues";
import moment from "moment";
import { PDFViewer, pdf } from "@react-pdf/renderer";
import PdfDocument from "components/PDF/PdfDocument";
import { pdfjs } from "react-pdf";

import pdfjsWorker from "pdfjs-dist/build/pdf.worker.entry";
import { FAQTree } from "pages/incidents/components/IncidentForm/Form";

pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;

const TrygIncident = ({ match, editable }) => {
    const [itemResult, itemApi] = useApi();
    const [hasAdminQuestion, setHasAdminQuestions] = useState(false);
    const [hideAdminQuestion, setHideAdminQuestion] = useState(true);
    const [getIncident, setGetIncident] = useState(false);
    useEffect(() => {
        itemApi.get("api/incident/incident/tryg/".concat(match.params.id));
    }, [itemApi, match]);

    useEffect(() => {
        if (getIncident) {
            itemApi.get("api/incident/incident/tryg/".concat(match.params.id));
        }
    }, [getIncident]);

    useEffect(() => {
        if (itemResult.status === 2) {
            setHasAdminQuestions(
                itemResult.data?.adminFormGroups
                    .find((x) => x.id === 140)
                    ?.fields.findIndex((x) => x.value === "true") !== 0
            );

            if (
                itemResult.data?.adminFormGroups === null ||
                itemResult.data?.adminFormGroups?.length < 1
            ) {
                setHasAdminQuestions(true);
            } else {
                setHideAdminQuestion(false);
            }
        }
    }, [itemResult]);

    const [saveResult, saveApi] = useApi();
    const [sendResult, sendApi] = useApi();
    const [uploadedPdfResult, pdfApi] = useApi();
    const [uploadPdfResult, uploadApi] = useApi();
    const [vehiclesResult, vehiclesApi] = useApi();
    const [updateIncidentResult, updateIncidentApi] = useApi();
    const [tab, setTab] = useState(0);
    const [viewform, setViewform] = useState(true);
    const [pdfFile, setPdfFile] = useState();
    const [formValues, setFormValues] = useState();
    const [pdfContent, setPdfContent] = useState({
        header: {
            title: null,
            subtitle: null,
            rightColumn: {
                label: null,
                value: null
            }
        },
        formGroups: []
    });

    const history = useHistory();

    useEffect(() => {
        if (itemResult.status === 2) {
            if (
                itemResult.data.status === 3 &&
                itemResult.data.pdfBlobStorageName !== null
            ) {
                pdfApi.get(
                    "api/incident/gettrygpdf?name=".concat(
                        itemResult.data.incident.pdfBlobStorageName
                    )
                );
            } else if (
                itemResult.status === 2 &&
                (itemResult.data.incident.status === 5 ||
                    itemResult.data.incident.status === 0)
            ) {
                setViewform(false);
            }
            setPdfFields();
        }
    }, [itemResult.status]);

    useEffect(() => {
        vehiclesApi.get("api/system/vehicles/select");
    }, [vehiclesApi]);

    useEffect(() => {
        if (sendResult.status === 2) {
            history.push("/incidents/send/".concat(match.params.id));
        }
    }, [sendResult, history, match]);

    const vehicles = vehiclesResult.status === 2 ? vehiclesResult.data : [];

    const handleSubmit = (values, endpoint) => {
        setFormValues(values);
        if (endpoint === "sendincidenttotryg") {
            uploadPdf();
        } else {
            updateIncident(endpoint);
        }
    };

    const uploadPdf = () => {
        uploadApi.file("api/incident/uploadtrygpdf", pdfFile);
    };

    const updateIncident = (endpoint, pdfName) => {
        let values = formValues;
        if (endpoint === "sendincidenttotryg") {
            values = { ...formValues, pdfBlobStorageName: pdfName };
        }
        updateIncidentApi.put("api/incident/incidents/" + endpoint, values);
    };

    useEffect(() => {
        if (uploadPdfResult.status === 2) {
            updateIncident("sendincidenttotryg", uploadPdfResult.data.name);
        }
    }, [uploadPdfResult]);
    useEffect(() => {
        if (pdfContent.formGroups.length > 0) {
            getPdfBlob();
        }
    }, [pdfContent]);

    const getPdfBlob = async () => {
        try {
            let blobPDF = await pdf(
                <PdfDocument content={pdfContent} />
            ).toBlob();

            if (blobPDF !== null) {
                const myRenamedFile = new File(
                    [blobPDF],
                    `${
                        itemResult.data?.incident.vehicle?.registrationNumber
                    }-${moment(itemResult.data?.incident.incidentDate).format(
                        "YYYY-MM-DD"
                    )}.pdf`
                );
                setPdfFile(myRenamedFile);
            }
        } catch (error) {
            console.error(error);
            console.log("Error generating PDF");
        }
    };

    useEffect(() => {
        if (updateIncidentResult.status === 2) {
            itemApi.get("api/incident/incident/tryg/".concat(match.params.id));
        }
    }, [updateIncidentResult]);

    const setPdfFields = () => {
        setPdfContent((prev) => ({
            ...prev,
            header: {
                title: t(
                    itemResult.data.incident.title,
                    itemResult.data.incident.title
                ),
                subtitle: `Nummerplade: ${itemResult?.data?.incident.vehicle?.registrationNumber}`,
                rightColumn: {
                    label: "Hændelsesdato",
                    value: moment(itemResult.data.incident.incidentDate).format(
                        "L HH:mm"
                    )
                }
            }
        }));
        itemResult.data.incident.formGroups
            .filter((x) => x.fields[0].value !== null)
            .map((formgroup) => {
                let fields = [];
                formgroup.fields?.length > 0 &&
                    formgroup.fields
                        .filter((x) => x.value !== null)
                        .map((field) => {
                            return field.interpretedValue !== null
                                ? fields.push({
                                      value: getConvertIncidentValues(field),
                                      component: field.component,
                                      translateDK: field.translateDK
                                  })
                                : fields.push({
                                      value: t(
                                          field.labelKey,
                                          field.translateDK
                                      ),
                                      component: field.components,
                                      translateDK: field.translateDK
                                  });
                        });
                return setPdfContent((prev) => ({
                    ...prev,
                    formGroups: [
                        ...prev.formGroups,
                        {
                            label: t(formgroup.labelKey, formgroup.translateDK),
                            fields: fields
                        }
                    ]
                }));
            });
    };

    return (
        <>
            {itemResult.status === 1 ? (
                <LoadingSpinner />
            ) : itemResult.status === 2 ? (
                <>
                    <Nav className="nav-tabs">
                        <NavItem>
                            <NavLink
                                className={classnames({ active: tab === 0 })}
                                onClick={() => {
                                    setTab(0);
                                }}
                            >
                                {t("information", "Information")}
                            </NavLink>
                        </NavItem>
                        {!hideAdminQuestion && (
                            <NavItem>
                                <NavLink
                                    className={classnames({
                                        active: tab === 1
                                    })}
                                    onClick={() => {
                                        setTab(1);
                                    }}
                                >
                                    {t("admin_questions", "Admin questions")}
                                    {hasAdminQuestion && (
                                        <span
                                            style={{
                                                backgroundColor: "#DC0000",
                                                color: "#FFFFFF",
                                                borderRadius: "100vh"
                                            }}
                                            className="badge badge-pill m-0 ms-2"
                                        >
                                            1
                                        </span>
                                    )}
                                </NavLink>
                            </NavItem>
                        )}
                        <NavItem>
                            <NavLink
                                className={classnames({ active: tab === 2 })}
                                onClick={() => {
                                    setTab(2);
                                }}
                            >
                                {t("PDF")}
                            </NavLink>
                        </NavItem>
                    </Nav>
                    <Row className="pt-3 pb-3 flex-xl-row flex-column-reverse g-3">
                        <Col md={12} xl={8} xs={12} sm={12}>
                            <div className="bg-white p-4 shadow-sm rounded-3">
                                <div
                                    className={tab === 0 ? "d-block" : "d-none"}
                                >
                                    <TrygInformation
                                        item={itemResult.data.incident}
                                    />
                                </div>
                                <div
                                    className={tab === 1 ? "d-block" : "d-none"}
                                >
                                    {!hasAdminQuestion ? (
                                        <TrygInformation
                                            item={{
                                                ...itemResult.data.incident,
                                                formGroups:
                                                    itemResult.data
                                                        .adminFormGroups
                                            }}
                                        />
                                    ) : (
                                        <FAQTree
                                            isHandler={true}
                                            defaultIncidentId={
                                                itemResult.data.incident.id
                                            }
                                            setHideAdminQuestion={
                                                setHideAdminQuestion
                                            }
                                            setGetIncident={setGetIncident}
                                        />
                                    )}
                                </div>

                                <div
                                    style={{ width: "100%" }}
                                    className={tab === 2 ? "d-block" : "d-none"}
                                >
                                    <PDFViewer
                                        style={{
                                            height: "1200px",
                                            width: "100%"
                                        }}
                                    >
                                        {pdfContent.header && (
                                            <PdfDocument
                                                content={pdfContent}
                                            ></PdfDocument>
                                        )}
                                    </PDFViewer>
                                </div>
                            </div>
                        </Col>
                        <Col xl={4} md={12} xs={12}>
                            {editable && (
                                <>
                                    <TrygEdit
                                        onSubmit={handleSubmit}
                                        vehicles={vehicles}
                                        vehiclesStatus={vehiclesResult.status}
                                        initialValues={itemResult.data.incident}
                                        adminQuestionsMissing={
                                            hasAdminQuestion &&
                                            !hideAdminQuestion
                                        }
                                        loading={
                                            saveResult.status === 1 ||
                                            updateIncidentResult.status === 1
                                        }
                                    />
                                    {saveResult.status > 2 && (
                                        <Alert color="danger">
                                            Ett fel inträffade. Kunde inte spara
                                            ärendet.
                                        </Alert>
                                    )}
                                    {saveResult.status === 1 && (
                                        <Alert color="info">
                                            Sparar ärendet..
                                        </Alert>
                                    )}
                                    {saveResult.status === 2 && (
                                        <Alert color="info">
                                            Ärendet sparat.
                                        </Alert>
                                    )}
                                    {sendResult.status > 2 && (
                                        <Alert color="danger">
                                            Ett fel inträffade. Kunde inte spara
                                            ärendet.
                                        </Alert>
                                    )}
                                    {sendResult.status === 1 && (
                                        <Alert color="info">
                                            Sparar ärendet..
                                        </Alert>
                                    )}
                                </>
                            )}
                        </Col>
                    </Row>
                </>
            ) : null}
        </>
    );
};

export default TrygIncident;
