import { da, enGB, sv } from "date-fns/locale";

export const getCurrentLang = (defaultLang) => {
    switch (defaultLang) {
        case "sv-SE":
            return {
                name: "Svenska",
                fns: sv,
                moment: "sv"
            };
        case "en-GB":
            return {
                name: "English",
                fns: enGB,
                moment: "en-gb"
            };
        case "da-DK":
            return {
                name: "Dansk",
                fns: da,
                moment: "da"
            };
        default:
            return {
                name: "English",
                fns: enGB,
                moment: "en-gb"
            };
    }
};
