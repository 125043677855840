import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/pro-light-svg-icons";

const LoadingSpinner = () => {
	return (
		<div className="d-flex justify-content-center position-absolute top-50 start-50 translate-middle">
			<FontAwesomeIcon icon={faSpinner} spin size="4x" color="#ce0303" />
		</div>
	);
};
export default LoadingSpinner;
