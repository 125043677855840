import React, { useEffect, useMemo, useState } from "react";
import { useApi } from "components/useApi3";
import DataTable from "components/DataTable";

import PageHeader from "components/PageHeader";
import Panel from "components/Panel";
import {
    Button,
    ButtonGroup,
    Col,
    Form,
    FormGroup,
    Modal,
    ModalBody,
    ModalHeader,
    Row
} from "reactstrap";
import { Formik } from "formik";
import * as Yup from "yup";
import AlertResultFeedback from "components/AlertResultFeedback";
import ItemForm from "./Form";
import LoadingSpinner from "components/LoadingSpinner";
import AdminNavigation from "../components/AdminNavigation";
import { useTranslation } from "react-i18next";

function randPassword(uppercase, lowercase, numbers, nonalpha) {
    var chars = [
        "ABCDEFGHIJKLMNOPQRSTUVWXYZ",
        "abcdefghijklmnopqrstuvwxyz", // letters
        "0123456789", // numbers
        "!_@-+#%?" // either
    ];

    return [uppercase, lowercase, numbers, nonalpha]
        .map(function (len, i) {
            return Array(len)
                .fill(chars[i])
                .map(function (x) {
                    return x[Math.floor(Math.random() * x.length)];
                })
                .join("");
        })
        .concat()
        .join("")
        .split("")
        .sort(function () {
            return 0.5 - Math.random();
        })
        .join("");
}

const CrudPage = ({ match }) => {
    const apiBaseUrl = "api/organisation/users/";
    const { t } = useTranslation();
    const [listResult, listApi] = useApi();
    const [createResult, createApi] = useApi();
    const [editItemResult, editItemApi] = useApi();
    const [editResult, editApi] = useApi();
    const [deleteResult, deleteApi] = useApi();
    const [managerListResult, managerListApi] = useApi();
    const [sitesListResult, sitesListApi] = useApi();
    const [showCreate, setShowCreate] = useState(false);
    const [showEdit, setShowEdit] = useState(false);
    const [showDelete, setShowDelete] = useState(false);
    const [deleteId, setDeleteId] = useState(null);

    const validationSchema = Yup.object().shape({
        name: Yup.string().required(
            t("please_enter_the_employees_name", "Enter the employee's name")
        ),
        email: Yup.string()
            .email(t("invalid_email_address", "Invalid e-mail address"))
            .required(
                t(
                    "please_enter_the_employees_email",
                    "Enter the employee's email address"
                )
            ),
        mobilePhone: Yup.string().required(
            t("please_enter_the_employees_phone", "Enter the employee's phone")
        ),
        roles: Yup.array().min(
            1,
            t("choose_at_least_one_role", "Choose at least one role")
        ),
        siteId: Yup.number()
            .nullable()
            .required(
                t("location_must_be_selected", "Location must be selected")
            )
    });

    useEffect(() => {
        listApi.get(apiBaseUrl);
    }, [listApi, apiBaseUrl]);

    // post skapa success
    useEffect(() => {
        if (createResult.status === 2) {
            listApi.get(apiBaseUrl);
            createApi.reset();
            setShowCreate(false);
        }
    }, [createResult.status, listApi, createApi, apiBaseUrl]);

    // post delete success
    useEffect(() => {
        if (deleteResult.status === 2) {
            listApi.get(apiBaseUrl);
            deleteApi.reset();
            setShowDelete(false);
            setShowEdit(false);
            setDeleteId(null);
        }
    }, [deleteResult.status, listApi, deleteApi, apiBaseUrl]);

    // post edit success
    useEffect(() => {
        if (editResult.status === 2) {
            listApi.get(apiBaseUrl);
            editApi.reset();
            setShowEdit(false);
        }
    }, [editResult.status, listApi, editApi, apiBaseUrl]);

    const columns = [
        {
            accessorKey: "name",
            header: t("name")
        },
        {
            accessorKey: "employeeId",
            header: t("employeeId")
        },
        {
            accessorKey: "email",
            header: t("email")
        },
        {
            accessorKey: "mobilePhone",
            header: t("phone")
        },
        {
            accessorKey: "managerName",
            header: t("case_manager")
        },
        {
            accessorKey: "commands",
            header: "",
            cell: (cell) => {
                return (
                    <ButtonGroup className="float-end">
                        <Button
                            color="white"
                            onClick={() => {
                                managerListApi.get(
                                    "api/organisation/users/handlers"
                                );
                                sitesListApi.get("api/organisation/sites");
                                editItemApi.get(
                                    apiBaseUrl.concat(cell.row.original.id)
                                );
                                setShowEdit(true);
                            }}
                        >
                            {t("edit")}
                        </Button>
                    </ButtonGroup>
                );
            }
        }
    ];

    return (
        <>
            <PageHeader
                title={t("users")}
                subtitle={t("administer_users")}
                commands={
                    <Button
                        onClick={() => {
                            setShowCreate(true);
                            managerListApi.get(
                                "api/organisation/users/handlers"
                            );
                            sitesListApi.get("api/organisation/sites");
                        }}
                        color="primary"
                    >
                        {t("add_user")}
                    </Button>
                }
            />
            <AdminNavigation />
            <div className="mt-2 mt-md-3">
                <DataTable
                    hideFilterButton={true}
                    columns={columns}
                    result={listResult}
                />
            </div>
            <Modal
                isOpen={showCreate}
                toggle={() => {
                    setShowCreate(!showCreate);
                }}
                onClosed={() => {
                    createApi.reset();
                }}
            >
                <ModalHeader> {t("add_user")}</ModalHeader>
                {(managerListResult.status === 1 ||
                    sitesListResult.status === 1) && (
                    <ModalBody>
                        <LoadingSpinner />
                    </ModalBody>
                )}
                {managerListResult.status === 2 &&
                    sitesListResult.status === 2 && (
                        <ModalBody>
                            <Row>
                                <Col xs={12}>
                                    <Formik
                                        initialValues={{
                                            name: "",
                                            email: "",
                                            mobilePhone: "",
                                            personalIdentityNumber: "",
                                            tempPassword: randPassword(
                                                3,
                                                2,
                                                2,
                                                1
                                            ),
                                            managerId: null,
                                            siteId: null,
                                            roles: [],
                                            companyId: match.params.id
                                        }}
                                        onSubmit={(values) => {
                                            // same shape as initial values

                                            createApi.post(apiBaseUrl, values);
                                        }}
                                        validationSchema={validationSchema}
                                    >
                                        {({
                                            errors,
                                            values,
                                            touched,
                                            handleChange,
                                            handleBlur,
                                            handleSubmit,
                                            setFieldValue
                                        }) => (
                                            <Form
                                                onSubmit={handleSubmit}
                                                noValidate
                                            >
                                                <ItemForm
                                                    values={values}
                                                    errors={errors}
                                                    touched={touched}
                                                    handleBlur={handleBlur}
                                                    handleChange={handleChange}
                                                    setFieldValue={
                                                        setFieldValue
                                                    }
                                                    managerList={
                                                        managerListResult.data
                                                    }
                                                    sitesList={
                                                        sitesListResult.data
                                                    }
                                                />
                                                <FormGroup>
                                                    <Row>
                                                        <Col xs={6}>
                                                            <Button
                                                                block
                                                                color="white"
                                                                onClick={() => {
                                                                    setShowCreate(
                                                                        false
                                                                    );
                                                                }}
                                                            >
                                                                {t("cancel")}
                                                            </Button>
                                                        </Col>
                                                        <Col xs={6}>
                                                            <Button
                                                                block
                                                                disabled={
                                                                    createResult.status ===
                                                                    1
                                                                }
                                                                color="primary"
                                                                type="submit"
                                                            >
                                                                {t("add")}
                                                            </Button>
                                                        </Col>
                                                    </Row>
                                                </FormGroup>
                                            </Form>
                                        )}
                                    </Formik>
                                </Col>
                            </Row>

                            <Row>
                                <Col xs={12}>
                                    <AlertResultFeedback
                                        result={createResult}
                                    />
                                </Col>
                            </Row>
                        </ModalBody>
                    )}
            </Modal>
            <Modal
                isOpen={showEdit}
                toggle={() => {
                    setShowEdit(!showEdit);
                }}
                onClosed={() => {
                    editApi.reset();
                }}
            >
                <ModalHeader>{t("add_user")}</ModalHeader>
                {(managerListResult.status === 1 ||
                    editItemResult.status === 1 ||
                    sitesListResult.status === 1) && (
                    <ModalBody>
                        <LoadingSpinner />
                    </ModalBody>
                )}
                {managerListResult.status === 2 &&
                    editItemResult.status === 2 &&
                    sitesListResult.status === 2 && (
                        <ModalBody>
                            <Row>
                                <Col xs={12}>
                                    <Formik
                                        initialValues={{
                                            ...editItemResult.data
                                        }}
                                        onSubmit={(values) => {
                                            // same shape as initial values

                                            editApi.put(apiBaseUrl, values);
                                        }}
                                        validationSchema={validationSchema}
                                    >
                                        {({
                                            errors,
                                            values,
                                            touched,
                                            handleChange,
                                            handleBlur,
                                            handleSubmit,
                                            setFieldValue
                                        }) => (
                                            <Form
                                                onSubmit={handleSubmit}
                                                noValidate
                                            >
                                                <FormGroup>
                                                    <Row>
                                                        <Col>
                                                            <Button
                                                                disabled={
                                                                    editResult.status ===
                                                                    1
                                                                }
                                                                onClick={() => {
                                                                    setDeleteId(
                                                                        values.id
                                                                    );
                                                                    setShowDelete(
                                                                        true
                                                                    );
                                                                }}
                                                                className="float-end mt-2"
                                                                color="danger"
                                                                type="button"
                                                            >
                                                                {t("delete")}
                                                            </Button>
                                                        </Col>
                                                    </Row>
                                                </FormGroup>
                                                <ItemForm
                                                    isEdit={true}
                                                    handleSubmit={handleSubmit}
                                                    handleBlur={handleBlur}
                                                    values={values}
                                                    errors={errors}
                                                    touched={touched}
                                                    handleChange={handleChange}
                                                    setFieldValue={
                                                        setFieldValue
                                                    }
                                                    managerList={
                                                        managerListResult.data
                                                    }
                                                    sitesList={
                                                        sitesListResult.data
                                                    }
                                                />
                                                <FormGroup>
                                                    <Row>
                                                        <Col xs={6}>
                                                            <Button
                                                                block
                                                                color="white"
                                                                onClick={() => {
                                                                    setShowEdit(
                                                                        false
                                                                    );
                                                                }}
                                                            >
                                                                {t("cancel")}
                                                            </Button>
                                                        </Col>
                                                        <Col xs={6}>
                                                            <Button
                                                                block
                                                                disabled={
                                                                    editResult.status ===
                                                                    1
                                                                }
                                                                color="primary"
                                                                type="submit"
                                                            >
                                                                {t("save")}
                                                            </Button>
                                                        </Col>
                                                    </Row>
                                                </FormGroup>
                                            </Form>
                                        )}
                                    </Formik>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={12}>
                                    <AlertResultFeedback result={editResult} />
                                </Col>
                            </Row>
                        </ModalBody>
                    )}
            </Modal>
            <Modal
                isOpen={showDelete}
                toggle={() => {
                    setShowDelete(!showDelete);
                }}
                onClosed={() => {
                    deleteApi.reset();
                }}
            >
                <ModalHeader>
                    {" "}
                    {t("delete")} {t("user")}
                </ModalHeader>
                {deleteId && (
                    <ModalBody>
                        <h4>
                            {" "}
                            {t("are_you_sure_you_want_to_delete")} {t("user")}?
                        </h4>
                        <Row>
                            <Col xs={6}>
                                <Button
                                    block
                                    color="white"
                                    onClick={() => {
                                        setShowDelete(false);
                                        setDeleteId(null);
                                    }}
                                >
                                    {t("cancel")}
                                </Button>
                            </Col>
                            <Col xs={6}>
                                <Button
                                    disabled={deleteResult.status === 1}
                                    block
                                    color="danger"
                                    onClick={() => {
                                        deleteApi.del(
                                            apiBaseUrl.concat(deleteId)
                                        );
                                    }}
                                >
                                    {t("delete")}
                                </Button>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12}>
                                <AlertResultFeedback result={deleteResult} />
                            </Col>
                        </Row>
                    </ModalBody>
                )}
            </Modal>
        </>
    );
};
export default CrudPage;
