import { t } from "i18next";
import * as React from "react";
const SvgTrailerFront = (props) => (
	<svg
		width={227}
		height={312}
		viewBox="0 0 227 312"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
		className="svg-component"
	>
		<g clipPath="url(#clip0_1811_3478)">
			<path
				d="M146.09 217.84H80.6797V236.67H146.09V217.84Z"
				fill="#1A1A1A"
			/>
			<path
				d="M31.1499 217.84H80.6899V236.67H31.1499C30.3199 236.67 29.6499 236 29.6499 235.17V219.34C29.6499 218.51 30.3199 217.84 31.1499 217.84Z"
				fill="#262626"
			/>
			<path
				d="M44.0198 227.75H38.0698V236.67H44.0198V227.75Z"
				fill="#767676"
			/>
			<path d="M76.72 227.75H70.77V236.67H76.72V227.75Z" fill="#767676" />
			<path
				d="M65.8196 227.75H59.8696V236.67H65.8196V227.75Z"
				fill="#767676"
			/>
			<path
				d="M54.9197 227.75H48.9697V236.67H54.9197V227.75Z"
				fill="#767676"
			/>
			<path
				d="M195.62 236.67H146.08V217.84H195.62C196.45 217.84 197.12 218.51 197.12 219.34V235.17C197.12 236 196.45 236.67 195.62 236.67Z"
				fill="#262626"
			/>
			<path
				d="M182.75 236.67H188.7V227.75H182.75V236.67Z"
				fill="#767676"
			/>
			<path d="M150.05 236.67H156V227.75H150.05V236.67Z" fill="#767676" />
			<path
				d="M160.95 236.67H166.9V227.75H160.95V236.67Z"
				fill="#767676"
			/>
			<path
				d="M171.86 236.67H177.81V227.75H171.86V236.67Z"
				fill="#767676"
			/>
			<path
				d="M31.1499 259.46H83.6599V266.4H31.1499C30.3199 266.4 29.6499 265.73 29.6499 264.9V260.96C29.6499 260.13 30.3199 259.46 31.1499 259.46Z"
				fill="#262626"
			/>
			<path
				d="M44.8501 259.46H38.5601V262.75H44.8501V259.46Z"
				fill="#767676"
			/>
			<path
				d="M79.4599 259.46H73.1699V262.75H79.4599V259.46Z"
				fill="#767676"
			/>
			<path
				d="M67.9199 259.46H61.6299V262.75H67.9199V259.46Z"
				fill="#767676"
			/>
			<path
				d="M56.3901 259.46H50.1001V262.75H56.3901V259.46Z"
				fill="#767676"
			/>
			<path
				d="M195.13 266.4H142.62V259.46H195.13C195.96 259.46 196.63 260.13 196.63 260.96V264.9C196.63 265.73 195.96 266.4 195.13 266.4Z"
				fill="#262626"
			/>
			<path
				d="M181.43 262.75H187.72V259.46H181.43V262.75Z"
				fill="#767676"
			/>
			<path
				d="M146.81 262.75H153.1V259.46H146.81V262.75Z"
				fill="#767676"
			/>
			<path
				d="M158.35 262.75H164.64V259.46H158.35V262.75Z"
				fill="#767676"
			/>
			<path
				d="M169.89 262.75H176.18V259.46H169.89V262.75Z"
				fill="#767676"
			/>
			<path
				d="M196.63 236.67H30.1401V238.65H196.63V236.67Z"
				fill="#979797"
			/>
			<path
				d="M196.63 257.48H30.1401V259.46H196.63V257.48Z"
				fill="#979797"
			/>
			<path
				d="M196.63 238.65H30.1401V257.48H196.63V238.65Z"
				fill="#262626"
			/>
			<path
				d="M54.7599 295.65C54.7599 295.65 51.3499 298.37 45.2699 298.37C39.1899 298.37 35.7799 295.65 35.7799 295.65C35.0999 295.46 34.6299 294.83 34.6299 294.12V266.39H55.9199V294.12C55.9199 294.83 55.4499 295.45 54.7699 295.65H54.7599Z"
				fill="#767676"
			/>
			<path
				d="M77.5199 295.65C77.5199 295.65 74.0299 298.37 67.7999 298.37C61.5699 298.37 58.0799 295.65 58.0799 295.65C57.3799 295.46 56.8999 294.83 56.8999 294.12V266.39H78.6999V294.12C78.6999 294.83 78.2199 295.45 77.5199 295.65Z"
				fill="#767676"
			/>
			<path
				d="M171.05 295.65C171.05 295.65 174.54 298.37 180.77 298.37C187 298.37 190.49 295.65 190.49 295.65C191.19 295.46 191.67 294.83 191.67 294.12V266.39H169.87V294.12C169.87 294.83 170.35 295.45 171.05 295.65Z"
				fill="#767676"
			/>
			<path
				d="M148.26 295.65C148.26 295.65 151.75 298.37 157.98 298.37C164.21 298.37 167.7 295.65 167.7 295.65C168.4 295.46 168.88 294.83 168.88 294.12V266.39H147.08V294.12C147.08 294.83 147.56 295.45 148.26 295.65Z"
				fill="#767676"
			/>
			<path
				d="M194.33 16.9399H32.4301C30.7069 16.9399 29.3101 18.3368 29.3101 20.0599V210.9C29.3101 212.623 30.7069 214.02 32.4301 214.02H194.33C196.053 214.02 197.45 212.623 197.45 210.9V20.0599C197.45 18.3368 196.053 16.9399 194.33 16.9399Z"
				fill="white"
			/>
			<path
				d="M193.96 20.4399V210.52H32.8101V20.4399H193.95M194.34 13.4399H32.4301C28.7801 13.4399 25.8101 16.3999 25.8101 20.0599V210.91C25.8101 214.56 28.7701 217.53 32.4301 217.53H194.34C197.99 217.53 200.96 214.57 200.96 210.91V20.0599C200.96 16.4099 198 13.4399 194.34 13.4399Z"
				fill="#E0E0E0"
			/>
			<path
				d="M195.63 218.34H31.1499C30.3199 218.34 29.6499 219.01 29.6499 219.84V235.67C29.6499 236.5 30.3199 237.17 31.1499 237.17H30.1499V259.96H31.1499C30.3199 259.96 29.6499 260.63 29.6499 261.46V265.4C29.6499 266.23 30.3199 266.9 31.1499 266.9H34.6299V293.63C34.6299 294.34 35.0999 294.96 35.7799 295.16C35.7799 295.16 39.1899 297.88 45.2699 297.88C51.3499 297.88 54.7599 295.16 54.7599 295.16C55.4399 294.97 55.9099 294.34 55.9099 293.63V266.9H56.8999V293.63C56.8999 294.34 57.3799 294.96 58.0799 295.16C58.0799 295.16 61.5699 297.88 67.7999 297.88C74.0299 297.88 77.5199 295.16 77.5199 295.16C78.2199 294.97 78.6999 294.34 78.6999 293.63V266.9H83.6499V259.96H142.61V266.9H147.07V293.63C147.07 294.34 147.55 294.96 148.25 295.16C148.25 295.16 151.74 297.88 157.97 297.88C164.2 297.88 167.69 295.16 167.69 295.16C168.39 294.97 168.87 294.34 168.87 293.63V266.9H169.86V293.63C169.86 294.34 170.34 294.96 171.04 295.16C171.04 295.16 174.53 297.88 180.76 297.88C186.99 297.88 190.48 295.16 190.48 295.16C191.18 294.97 191.66 294.34 191.66 293.63V266.9H195.11C195.94 266.9 196.61 266.23 196.61 265.4V261.46C196.61 260.63 195.94 259.96 195.11 259.96H196.61V237.17H195.61C196.44 237.17 197.11 236.5 197.11 235.67V219.84C197.11 219.01 196.44 218.34 195.61 218.34H195.63Z"
				stroke="black"
				strokeWidth={2}
				strokeMiterlimit={10}
			/>
			<path
				d="M194.34 13.9399H32.4301C28.7801 13.9399 25.8101 16.8999 25.8101 20.5599V211.41C25.8101 215.06 28.7701 218.03 32.4301 218.03H194.34C197.99 218.03 200.96 215.07 200.96 211.41V20.5599C200.96 16.9099 198 13.9399 194.34 13.9399Z"
				stroke="black"
				strokeWidth={2}
				strokeMiterlimit={10}
			/>
			<rect
				width={75.59}
				height={103.937}
				fill="white"
				fillOpacity={0.01}
				className="zone"
				onClick={() =>
					props.handlePathClick &&
					props.handlePathClick(
						86,
						t("Zone86", "Front fører-side top")
					)
				}
			/>
			{props.clickedZones.find((path) => path.zoneId === 86) && (
				<g filter="url(#filter0_d_1811_3478)">
					<rect
						x={22.7949}
						y={36.9683}
						width={30}
						height={30}
						rx={15}
						fill="#3c7749"
					/>
					<rect
						x={21.7949}
						y={35.9683}
						width={32}
						height={32}
						rx={16}
						stroke="white"
						strokeWidth={2}
					/>
					<path
						d="M45.7949 45.9683L34.7949 56.9683L29.7949 51.9683"
						stroke="white"
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
				</g>
			)}
			<rect
				width={75.59}
				height={103.937}
				transform="translate(75.5898)"
				fill="white"
				fillOpacity={0.01}
				className="zone"
				onClick={() =>
					props.handlePathClick &&
					props.handlePathClick(87, t("Zone87", "Front center top"))
				}
			/>
			{props.clickedZones.find((path) => path.zoneId === 87) && (
				<g filter="url(#filter1_d_1811_3478)">
					<rect
						x={98.3848}
						y={36.9683}
						width={30}
						height={30}
						rx={15}
						fill="#3c7749"
					/>
					<rect
						x={97.3848}
						y={35.9683}
						width={32}
						height={32}
						rx={16}
						stroke="white"
						strokeWidth={2}
					/>
					<path
						d="M121.385 45.9683L110.385 56.9683L105.385 51.9683"
						stroke="white"
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
				</g>
			)}
			<rect
				width={75.59}
				height={103.937}
				transform="translate(151.18)"
				fill="white"
				fillOpacity={0.01}
				className="zone"
				onClick={() =>
					props.handlePathClick &&
					props.handlePathClick(
						88,
						t("Zone88", "Front passager-side top")
					)
				}
			/>
			{props.clickedZones.find((path) => path.zoneId === 88) && (
				<g filter="url(#filter2_d_1811_3478)">
					<rect
						x={173.975}
						y={36.9683}
						width={30}
						height={30}
						rx={15}
						fill="#3c7749"
					/>
					<rect
						x={172.975}
						y={35.9683}
						width={32}
						height={32}
						rx={16}
						stroke="white"
						strokeWidth={2}
					/>
					<path
						d="M196.975 45.9683L185.975 56.9683L180.975 51.9683"
						stroke="white"
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
				</g>
			)}
			<rect
				width={75.59}
				height={103.937}
				transform="translate(0 103.937)"
				fill="white"
				fillOpacity={0.01}
				className="zone"
				onClick={() =>
					props.handlePathClick &&
					props.handlePathClick(
						89,
						t("Zone89", "Front fører-side midt")
					)
				}
			/>
			{props.clickedZones.find((path) => path.zoneId === 89) && (
				<g filter="url(#filter3_d_1811_3478)">
					<rect
						x={22.7949}
						y={140.905}
						width={30}
						height={30}
						rx={15}
						fill="#3c7749"
					/>
					<rect
						x={21.7949}
						y={139.905}
						width={32}
						height={32}
						rx={16}
						stroke="white"
						strokeWidth={2}
					/>
					<path
						d="M45.7949 149.905L34.7949 160.905L29.7949 155.905"
						stroke="white"
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
				</g>
			)}
			<rect
				width={75.59}
				height={103.937}
				transform="translate(75.5898 103.937)"
				fill="white"
				fillOpacity={0.01}
				className="zone"
				onClick={() =>
					props.handlePathClick &&
					props.handlePathClick(90, t("Zone90", "Front center midt "))
				}
			/>
			{props.clickedZones.find((path) => path.zoneId === 90) && (
				<g filter="url(#filter4_d_1811_3478)">
					<rect
						x={98.3848}
						y={140.905}
						width={30}
						height={30}
						rx={15}
						fill="#3c7749"
					/>
					<rect
						x={97.3848}
						y={139.905}
						width={32}
						height={32}
						rx={16}
						stroke="white"
						strokeWidth={2}
					/>
					<path
						d="M121.385 149.905L110.385 160.905L105.385 155.905"
						stroke="white"
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
				</g>
			)}
			<rect
				width={75.59}
				height={103.937}
				transform="translate(151.18 103.937)"
				fill="white"
				fillOpacity={0.01}
				className="zone"
				onClick={() =>
					props.handlePathClick &&
					props.handlePathClick(
						91,
						t("Zone91", "Front passager-side midt")
					)
				}
			/>
			{props.clickedZones.find((path) => path.zoneId === 91) && (
				<g filter="url(#filter5_d_1811_3478)">
					<rect
						x={173.975}
						y={140.905}
						width={30}
						height={30}
						rx={15}
						fill="#3c7749"
					/>
					<rect
						x={172.975}
						y={139.905}
						width={32}
						height={32}
						rx={16}
						stroke="white"
						strokeWidth={2}
					/>
					<path
						d="M196.975 149.905L185.975 160.905L180.975 155.905"
						stroke="white"
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
				</g>
			)}
			<rect
				width={75.59}
				height={103.937}
				transform="translate(0 207.873)"
				fill="white"
				fillOpacity={0.01}
				className="zone"
				onClick={() =>
					props.handlePathClick &&
					props.handlePathClick(
						92,
						t("Zone92", "Front fører-side bund")
					)
				}
			/>
			{props.clickedZones.find((path) => path.zoneId === 92) && (
				<g filter="url(#filter6_d_1811_3478)">
					<rect
						x={22.7949}
						y={244.842}
						width={30}
						height={30}
						rx={15}
						fill="#3c7749"
					/>
					<rect
						x={21.7949}
						y={243.842}
						width={32}
						height={32}
						rx={16}
						stroke="white"
						strokeWidth={2}
					/>
					<path
						d="M45.7949 253.842L34.7949 264.842L29.7949 259.842"
						stroke="white"
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
				</g>
			)}
			<rect
				width={75.59}
				height={103.937}
				transform="translate(75.5898 207.873)"
				fill="white"
				fillOpacity={0.01}
				className="zone"
				onClick={() =>
					props.handlePathClick &&
					props.handlePathClick(93, t("Zone93", "Front center bund"))
				}
			/>
			{props.clickedZones.find((path) => path.zoneId === 93) && (
				<g filter="url(#filter7_d_1811_3478)">
					<rect
						x={98.3848}
						y={244.842}
						width={30}
						height={30}
						rx={15}
						fill="#3c7749"
					/>
					<rect
						x={97.3848}
						y={243.842}
						width={32}
						height={32}
						rx={16}
						stroke="white"
						strokeWidth={2}
					/>
					<path
						d="M121.385 253.842L110.385 264.842L105.385 259.842"
						stroke="white"
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
				</g>
			)}
			<rect
				width={75.59}
				height={103.937}
				transform="translate(151.18 207.873)"
				fill="white"
				fillOpacity={0.01}
				className="zone"
				onClick={() =>
					props.handlePathClick &&
					props.handlePathClick(
						94,
						t("Zone94", "Front passager-side bund")
					)
				}
			/>
			{props.clickedZones.find((path) => path.zoneId === 94) && (
				<g filter="url(#filter8_d_1811_3478)">
					<rect
						x={173.975}
						y={244.842}
						width={30}
						height={30}
						rx={15}
						fill="#3c7749"
					/>
					<rect
						x={172.975}
						y={243.842}
						width={32}
						height={32}
						rx={16}
						stroke="white"
						strokeWidth={2}
					/>
					<path
						d="M196.975 253.842L185.975 264.842L180.975 259.842"
						stroke="white"
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
				</g>
			)}
		</g>
		<defs>
			<filter
				id="filter0_d_1811_3478"
				x={0.794922}
				y={16.9683}
				width={74}
				height={74}
				filterUnits="userSpaceOnUse"
				colorInterpolationFilters="sRGB"
			>
				<feFlood floodOpacity={0} result="BackgroundImageFix" />
				<feColorMatrix
					in="SourceAlpha"
					type="matrix"
					values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
					result="hardAlpha"
				/>
				<feOffset dy={2} />
				<feGaussianBlur stdDeviation={10} />
				<feComposite in2="hardAlpha" operator="out" />
				<feColorMatrix
					type="matrix"
					values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
				/>
				<feBlend
					mode="normal"
					in2="BackgroundImageFix"
					result="effect1_dropShadow_1811_3478"
				/>
				<feBlend
					mode="normal"
					in="SourceGraphic"
					in2="effect1_dropShadow_1811_3478"
					result="shape"
				/>
			</filter>
			<filter
				id="filter1_d_1811_3478"
				x={76.3848}
				y={16.9683}
				width={74}
				height={74}
				filterUnits="userSpaceOnUse"
				colorInterpolationFilters="sRGB"
			>
				<feFlood floodOpacity={0} result="BackgroundImageFix" />
				<feColorMatrix
					in="SourceAlpha"
					type="matrix"
					values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
					result="hardAlpha"
				/>
				<feOffset dy={2} />
				<feGaussianBlur stdDeviation={10} />
				<feComposite in2="hardAlpha" operator="out" />
				<feColorMatrix
					type="matrix"
					values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
				/>
				<feBlend
					mode="normal"
					in2="BackgroundImageFix"
					result="effect1_dropShadow_1811_3478"
				/>
				<feBlend
					mode="normal"
					in="SourceGraphic"
					in2="effect1_dropShadow_1811_3478"
					result="shape"
				/>
			</filter>
			<filter
				id="filter2_d_1811_3478"
				x={151.975}
				y={16.9683}
				width={74}
				height={74}
				filterUnits="userSpaceOnUse"
				colorInterpolationFilters="sRGB"
			>
				<feFlood floodOpacity={0} result="BackgroundImageFix" />
				<feColorMatrix
					in="SourceAlpha"
					type="matrix"
					values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
					result="hardAlpha"
				/>
				<feOffset dy={2} />
				<feGaussianBlur stdDeviation={10} />
				<feComposite in2="hardAlpha" operator="out" />
				<feColorMatrix
					type="matrix"
					values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
				/>
				<feBlend
					mode="normal"
					in2="BackgroundImageFix"
					result="effect1_dropShadow_1811_3478"
				/>
				<feBlend
					mode="normal"
					in="SourceGraphic"
					in2="effect1_dropShadow_1811_3478"
					result="shape"
				/>
			</filter>
			<filter
				id="filter3_d_1811_3478"
				x={0.794922}
				y={120.905}
				width={74}
				height={74}
				filterUnits="userSpaceOnUse"
				colorInterpolationFilters="sRGB"
			>
				<feFlood floodOpacity={0} result="BackgroundImageFix" />
				<feColorMatrix
					in="SourceAlpha"
					type="matrix"
					values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
					result="hardAlpha"
				/>
				<feOffset dy={2} />
				<feGaussianBlur stdDeviation={10} />
				<feComposite in2="hardAlpha" operator="out" />
				<feColorMatrix
					type="matrix"
					values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
				/>
				<feBlend
					mode="normal"
					in2="BackgroundImageFix"
					result="effect1_dropShadow_1811_3478"
				/>
				<feBlend
					mode="normal"
					in="SourceGraphic"
					in2="effect1_dropShadow_1811_3478"
					result="shape"
				/>
			</filter>
			<filter
				id="filter4_d_1811_3478"
				x={76.3848}
				y={120.905}
				width={74}
				height={74}
				filterUnits="userSpaceOnUse"
				colorInterpolationFilters="sRGB"
			>
				<feFlood floodOpacity={0} result="BackgroundImageFix" />
				<feColorMatrix
					in="SourceAlpha"
					type="matrix"
					values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
					result="hardAlpha"
				/>
				<feOffset dy={2} />
				<feGaussianBlur stdDeviation={10} />
				<feComposite in2="hardAlpha" operator="out" />
				<feColorMatrix
					type="matrix"
					values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
				/>
				<feBlend
					mode="normal"
					in2="BackgroundImageFix"
					result="effect1_dropShadow_1811_3478"
				/>
				<feBlend
					mode="normal"
					in="SourceGraphic"
					in2="effect1_dropShadow_1811_3478"
					result="shape"
				/>
			</filter>
			<filter
				id="filter5_d_1811_3478"
				x={151.975}
				y={120.905}
				width={74}
				height={74}
				filterUnits="userSpaceOnUse"
				colorInterpolationFilters="sRGB"
			>
				<feFlood floodOpacity={0} result="BackgroundImageFix" />
				<feColorMatrix
					in="SourceAlpha"
					type="matrix"
					values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
					result="hardAlpha"
				/>
				<feOffset dy={2} />
				<feGaussianBlur stdDeviation={10} />
				<feComposite in2="hardAlpha" operator="out" />
				<feColorMatrix
					type="matrix"
					values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
				/>
				<feBlend
					mode="normal"
					in2="BackgroundImageFix"
					result="effect1_dropShadow_1811_3478"
				/>
				<feBlend
					mode="normal"
					in="SourceGraphic"
					in2="effect1_dropShadow_1811_3478"
					result="shape"
				/>
			</filter>
			<filter
				id="filter6_d_1811_3478"
				x={0.794922}
				y={224.842}
				width={74}
				height={74}
				filterUnits="userSpaceOnUse"
				colorInterpolationFilters="sRGB"
			>
				<feFlood floodOpacity={0} result="BackgroundImageFix" />
				<feColorMatrix
					in="SourceAlpha"
					type="matrix"
					values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
					result="hardAlpha"
				/>
				<feOffset dy={2} />
				<feGaussianBlur stdDeviation={10} />
				<feComposite in2="hardAlpha" operator="out" />
				<feColorMatrix
					type="matrix"
					values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
				/>
				<feBlend
					mode="normal"
					in2="BackgroundImageFix"
					result="effect1_dropShadow_1811_3478"
				/>
				<feBlend
					mode="normal"
					in="SourceGraphic"
					in2="effect1_dropShadow_1811_3478"
					result="shape"
				/>
			</filter>
			<filter
				id="filter7_d_1811_3478"
				x={76.3848}
				y={224.842}
				width={74}
				height={74}
				filterUnits="userSpaceOnUse"
				colorInterpolationFilters="sRGB"
			>
				<feFlood floodOpacity={0} result="BackgroundImageFix" />
				<feColorMatrix
					in="SourceAlpha"
					type="matrix"
					values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
					result="hardAlpha"
				/>
				<feOffset dy={2} />
				<feGaussianBlur stdDeviation={10} />
				<feComposite in2="hardAlpha" operator="out" />
				<feColorMatrix
					type="matrix"
					values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
				/>
				<feBlend
					mode="normal"
					in2="BackgroundImageFix"
					result="effect1_dropShadow_1811_3478"
				/>
				<feBlend
					mode="normal"
					in="SourceGraphic"
					in2="effect1_dropShadow_1811_3478"
					result="shape"
				/>
			</filter>
			<filter
				id="filter8_d_1811_3478"
				x={151.975}
				y={224.842}
				width={74}
				height={74}
				filterUnits="userSpaceOnUse"
				colorInterpolationFilters="sRGB"
			>
				<feFlood floodOpacity={0} result="BackgroundImageFix" />
				<feColorMatrix
					in="SourceAlpha"
					type="matrix"
					values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
					result="hardAlpha"
				/>
				<feOffset dy={2} />
				<feGaussianBlur stdDeviation={10} />
				<feComposite in2="hardAlpha" operator="out" />
				<feColorMatrix
					type="matrix"
					values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
				/>
				<feBlend
					mode="normal"
					in2="BackgroundImageFix"
					result="effect1_dropShadow_1811_3478"
				/>
				<feBlend
					mode="normal"
					in="SourceGraphic"
					in2="effect1_dropShadow_1811_3478"
					result="shape"
				/>
			</filter>
			<clipPath id="clip0_1811_3478">
				<rect width={226.77} height={311.81} fill="white" />
			</clipPath>
		</defs>
	</svg>
);
export default SvgTrailerFront;
