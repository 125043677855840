import React, { useState, useRef, useEffect } from "react";
import {
    Button,
    ButtonGroup,
    Alert,
    ListGroup,
    ListGroupItem
} from "reactstrap";
import { useApi } from "components/useApi3";
import { useTranslation } from "react-i18next";

const FileUpload = ({
    attachments,
    onSuccessUpload,
    onRemoveImageItem,
    apiEndpoint,
    downloadApiEndpoint
}) => {
    const [uploadResult, uploadApi] = useApi();
    const [downloadResult, downloadApi] = useApi();
    const { t } = useTranslation();
    const [file, setFile] = useState(null);

    const fileInput = useRef();

    const onFileUploadChange = (e) => {
        // console.log(e);
        e.preventDefault();
        setFile(e.target.files[0]);
    };

    const triggerInputFile = (e) => {
        fileInput.current.click();
    };

    useEffect(() => {
        if (file) {
            uploadApi.file(apiEndpoint, file);
        }
    }, [file, uploadApi]);

    useEffect(() => {
        if (uploadResult.status === 2) {
            console.log(uploadResult.data);
            onSuccessUpload(uploadResult.data);
            setFile(null);
            uploadApi.reset();
        }
        if (uploadResult.status > 2) {
            setFile(null);
        }
    }, [uploadResult, onSuccessUpload, uploadApi]);

    const downloadFile = (name) => {
        downloadApi.getBlob(downloadApiEndpoint.concat(name), name);
    };

    return (
        <ListGroup>
            <ListGroupItem>
                <input
                    className="d-none"
                    type="file"
                    name="file"
                    id="exampleFile"
                    ref={fileInput}
                    onChange={onFileUploadChange}
                ></input>
                <ButtonGroup className="mb-2">
                    <Button
                        color="white"
                        onClick={triggerInputFile}
                        disabled={uploadResult.status === 1}
                    >
                        {t("choose_file")}
                        {file ? (
                            <span>
                                {" "}
                                {uploadResult.status === 1 ? (
                                    <span>
                                        ({t("adds")} {file.name})
                                    </span>
                                ) : null}
                            </span>
                        ) : null}
                    </Button>
                </ButtonGroup>
                {uploadResult.status > 2 ? (
                    <Alert color="warning">
                        {t("the_file_could_not_be_added")}
                    </Alert>
                ) : null}
            </ListGroupItem>
            {attachments?.length === 0 ? (
                <ListGroupItem>
                    <i>{t("to_upload_photos_click_vhoose_file")}</i>
                </ListGroupItem>
            ) : null}
            {attachments !== null &&
                attachments.length > 0 &&
                attachments?.map((item, key) => {
                    return (
                        <ListGroupItem key={key}>
                            <ButtonGroup>
                                <Button
                                    color="white"
                                    onClick={() => {
                                        onRemoveImageItem(item.name);
                                    }}
                                >
                                    {t("delete")}
                                </Button>
                                <Button
                                    disabled={downloadResult.status === 1}
                                    color="white"
                                    onClick={() => {
                                        downloadFile(item.name);
                                    }}
                                >
                                    {t("download")}
                                </Button>
                            </ButtonGroup>{" "}
                            {decodeURI(item.shortName).replace("+", " ")}{" "}
                        </ListGroupItem>
                    );
                })}
        </ListGroup>
    );
};

export default FileUpload;
