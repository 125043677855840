import { Formik } from "formik";
import { StepOneFormItems } from "pages/incidents/components/IncidentForm/StepOneFormItems";
import { Alert, Button, Col, Form, FormGroup, Row } from "reactstrap";
import { step1ValidationSchema } from "../../../../components/form/TryggHansa/schema";
import { useTranslation } from "react-i18next";

const StepOne = ({ initialValues, onSubmit }) => {
    const { t } = useTranslation();
    return (
        <Formik
            initialValues={initialValues}
            validateOnBlur={false}
            validateOnChange={false}
            onSubmit={(values) => {
                // same shape as initial values
                // gå vidare till steptwo
                onSubmit(values);
            }}
            validationSchema={step1ValidationSchema}
        >
            {({
                errors,
                values,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                setFieldValue
            }) => (
                <Form onSubmit={handleSubmit} noValidate>
                    {Object.keys(errors).length > 0 && (
                        <Alert color="danger">
                            {t(
                                "you_have_not_filled_in_all_required_fields._Questions_marked_with_red_text_must_be_completed"
                            )}
                        </Alert>
                    )}

                    <StepOneFormItems
                        values={values}
                        errors={errors}
                        touched={touched}
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                        setFieldValue={setFieldValue}
                    />
                    <FormGroup>
                        <Row>
                            <Col xs={12} className="d-flex justify-content-end">
                                <Button color="primary" type="submit">
                                    {t("next_step")}
                                </Button>
                            </Col>
                        </Row>
                    </FormGroup>
                </Form>
            )}
        </Formik>
    );
};
export default StepOne;
