// Skapa, steg1 och steg2, och hantera Skadeinformation
import { FormGroup, Label, Input, Col, Row } from "reactstrap";
import Switch from "components/InputSwitch";
import HelpDialog from "components/HelpDialog";
import SelectVehicle from "../SelectVehicle";
import FileUpload from "../FileUpload";
import CounterPartFormList from "../CounterPartFormList";
import { useFormikContext } from "formik";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

export const StepTwoFormItems = ({
    insuranceCategory,
    vehicles,
    vehiclesStatus,
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    setFieldValue
}) => {
    const { submitCount, isValid } = useFormikContext();
    const { t } = useTranslation();
    useEffect(() => {
        if (!isValid) {
            const errorElement = document.querySelector("#topOfForm");

            if (errorElement) {
                //console.log("scrolling to element", errorElement);
                errorElement.scrollIntoView({ behavior: "smooth" });
            }
        }
    }, [submitCount, isValid]);

    return (
        <>
            <Row>
                <Col sm={8}>
                    <FormGroup>
                        <Label>{t("vehicle")}</Label>
                        <SelectVehicle
                            vehiclesStatus={vehiclesStatus}
                            vehicles={vehicles}
                            vehicleId={values.vehicleId}
                            className={
                                errors.vehicleId
                                    ? "form-control is-invalid"
                                    : "form-control"
                            }
                            setVehicleId={(vehicleId, vehicleFullName) => {
                                setFieldValue("vehicleId", vehicleId);
                                setFieldValue(
                                    "vehicleFullName",
                                    vehicleFullName
                                );
                            }}
                        />
                        {errors.vehicleId ? (
                            <div className="invalid-feedback d-block">
                                {errors.vehicleId}
                            </div>
                        ) : null}
                    </FormGroup>
                </Col>
                <Col sm={4}>
                    <FormGroup>
                        <Label>{t("odometer")} </Label>
                        <Input
                            className={errors.mileage ? "is-invalid" : ""}
                            autoComplete="off"
                            name="mileage"
                            value={values.mileage}
                            onChange={handleChange}
                            onBlur={handleBlur}
                        ></Input>
                        {errors.mileage ? (
                            <div className="invalid-feedback d-block">
                                {errors.mileage}
                            </div>
                        ) : null}
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col sm={8}>
                    <FormGroup>
                        <Label>{t("damage_location")}</Label>
                        <Input
                            className={errors.location ? "is-invalid" : ""}
                            autoComplete="off"
                            name="location"
                            value={values.location}
                            onChange={handleChange}
                            onBlur={handleBlur}
                        ></Input>
                        {errors.location ? (
                            <div className="invalid-feedback d-block">
                                {errors.location}
                            </div>
                        ) : null}
                    </FormGroup>
                </Col>
                <Col sm={4}>
                    <FormGroup>
                        <Label>{t("number_of_passengers")}</Label>
                        <Input
                            className={
                                errors.numberOfPassengers ? "is-invalid" : ""
                            }
                            autoComplete="off"
                            name="numberOfPassengers"
                            value={values.numberOfPassengers}
                            onChange={handleChange}
                            onBlur={handleBlur}
                        ></Input>
                        {errors.numberOfPassengers ? (
                            <div className="invalid-feedback d-block">
                                {errors.numberOfPassengers}
                            </div>
                        ) : null}
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col xs={12}>
                    <FormGroup>
                        <Label>{t("incident_description")}</Label>
                        <HelpDialog
                            title={t("witnesses")}
                            message={
                                <>
                                    <p>
                                        {t(
                                            "describe_the_incident_based_on_what_happened_and_what_caused_the_incident"
                                        )}
                                    </p>
                                    <p>
                                        {t(
                                            "vehicle_a_is_coming_on_the_main_road_or_vehicle_b_is_coming_from_the_left"
                                        )}
                                    </p>
                                    <p>
                                        {t(
                                            "remember_that_the_insurance_company_must_be_able_to_understand_what_happened"
                                        )}
                                    </p>
                                </>
                            }
                        />
                        <Input
                            className={
                                errors.descriptionOfEvents ? "is-invalid" : ""
                            }
                            autoComplete="off"
                            name="descriptionOfEvents"
                            value={values.descriptionOfEvents}
                            type="textarea"
                            style={{ height: "200px" }}
                            onChange={handleChange}
                            onBlur={handleBlur}
                        ></Input>
                        {errors.descriptionOfEvents ? (
                            <div className="invalid-feedback d-block">
                                {errors.descriptionOfEvents}
                            </div>
                        ) : null}
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col xs={12} lg={4}>
                    <FormGroup className="mt-2">
                        <Switch
                            onChange={() =>
                                setFieldValue(
                                    "replacementVehicleHired",
                                    !values.replacementVehicleHired
                                )
                            }
                            checked={values.replacementVehicleHired}
                            label={t("hired_replacement_vehicles")}
                        />
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col xs={12} lg={4}>
                    <FormGroup className="mt-2">
                        <Switch
                            onChange={() =>
                                setFieldValue(
                                    "visibleDamages",
                                    !values.visibleDamages
                                )
                            }
                            checked={values.visibleDamages}
                            label={t(
                                "visible_damage_to_vehicles_or_other_objects"
                            )}
                        />
                    </FormGroup>
                </Col>
            </Row>
            {values.visibleDamages && (
                <Row>
                    <Col xs={12}>
                        <FormGroup>
                            <Label>{t("describe_visible_damage")}</Label>
                            <Input
                                className={
                                    errors.visibleDamagesDescription
                                        ? "is-invalid"
                                        : ""
                                }
                                autoComplete="off"
                                name="visibleDamagesDescription"
                                value={values.visibleDamagesDescription}
                                type="textarea"
                                onChange={handleChange}
                                onBlur={handleBlur}
                            ></Input>
                        </FormGroup>
                    </Col>
                </Row>
            )}
            <Row>
                <Col xs={12} lg={4}>
                    <FormGroup className="mt-2">
                        <Switch
                            onChange={() =>
                                setFieldValue(
                                    "personalDamages",
                                    !values.personalDamages
                                )
                            }
                            checked={values.personalDamages}
                            label={t("personal_injury")}
                        />
                    </FormGroup>
                </Col>
            </Row>
            {values.personalDamages && (
                <Row>
                    <Col xs={12}>
                        <FormGroup>
                            <Label>
                                Beskriv personskador: Personnummer, Namn,
                                Adress, Telefon (om möjligt)
                            </Label>
                            <Input
                                className={
                                    errors.personalDamagesDescription
                                        ? "is-invalid"
                                        : ""
                                }
                                autoComplete="off"
                                name="personalDamagesDescription"
                                value={values.personalDamagesDescription}
                                type="textarea"
                                onChange={handleChange}
                                onBlur={handleBlur}
                            ></Input>
                        </FormGroup>
                    </Col>
                </Row>
            )}
            <Row className="mb-3">
                <Col xs={12} lg={4}>
                    <FormGroup>
                        <Label>{t("photos")}</Label>
                        <FileUpload
                            attachments={values.images}
                            onSuccessUpload={(uploadedImage) => {
                                const newArray = [
                                    ...values.images,
                                    uploadedImage
                                ];

                                setFieldValue("images", newArray);
                            }}
                            onRemoveImageItem={(name) => {
                                const newArray = values.images.filter(
                                    (item) => item.name !== name
                                );
                                setFieldValue("images", newArray);
                            }}
                            apiEndpoint="api/incident/uploadimage"
                            downloadApiEndpoint="api/incident/downloadimage?name="
                        />
                    </FormGroup>
                </Col>
            </Row>

            {(insuranceCategory === "CRASH" ||
                insuranceCategory === "THEFT") && (
                <Row>
                    <Col xs={12}>
                        <CounterPartFormList
                            items={values.counterPartContactInformations}
                            onAdd={(item) => {
                                const newArray = [
                                    ...values.counterPartContactInformations,
                                    item
                                ];
                                setFieldValue(
                                    "counterPartContactInformations",
                                    newArray
                                );
                            }}
                            onRemove={(name) => {
                                const newArray =
                                    values.counterPartContactInformations.filter(
                                        (item) => item.name !== name
                                    );
                                setFieldValue(
                                    "counterPartContactInformations",
                                    newArray
                                );
                            }}
                        />
                    </Col>
                </Row>
            )}
            <Row>
                <Col xs={12}>
                    <FormGroup>
                        <Label>{t("witnesses")}</Label>
                        <HelpDialog
                            title={t("witnesses")}
                            message={
                                <>
                                    <p>
                                        {t(
                                            "if_witnesses_are_specified_they_must_if_possible_contain_the_following_information"
                                        )}
                                    </p>
                                    <ul>
                                        <li>{t("name")}</li>
                                        <li>{t("email")}</li>
                                        <li>{t("address")}</li>
                                        <li>
                                            {t(
                                                "postal_code_and_postal_address"
                                            )}
                                        </li>
                                        <li>{t("phone_office_hours")}</li>
                                    </ul>
                                </>
                            }
                        />
                        <Input
                            className={errors.witnesses ? "is-invalid" : ""}
                            autoComplete="off"
                            name="witnesses"
                            value={values.witnesses}
                            type="textarea"
                            onChange={handleChange}
                            onBlur={handleBlur}
                        ></Input>
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col xs={12}>
                    <Label>{t("other")}</Label>
                </Col>
                <Col xs={12}>
                    <FormGroup className="mt-2">
                        <Switch
                            onChange={() =>
                                setFieldValue(
                                    "policeContacted",
                                    !values.policeContacted
                                )
                            }
                            checked={values.policeContacted}
                            label={t("police_involved")}
                        />
                    </FormGroup>
                    <FormGroup className="mt-2">
                        <Switch
                            onChange={() =>
                                setFieldValue(
                                    "emergencyServiceContacted",
                                    !values.emergencyServiceContacted
                                )
                            }
                            checked={values.emergencyServiceContacted}
                            label={t("emergency_services_connected")}
                        />
                    </FormGroup>
                    <FormGroup className="mt-2">
                        <Switch
                            onChange={() =>
                                setFieldValue("bloodTest", !values.bloodTest)
                            }
                            checked={values.bloodTest}
                            label={t("have_blood_samples_been_taken")}
                        />
                    </FormGroup>
                    <FormGroup className="mt-2">
                        <Switch
                            onChange={() =>
                                setFieldValue("breathTest", !values.breathTest)
                            }
                            checked={values.breathTest}
                            label={t("has_a_breath_sample_been_taken")}
                        />
                    </FormGroup>
                    <FormGroup className="mt-2">
                        <Switch
                            onChange={() =>
                                setFieldValue(
                                    "isVehicleTowed",
                                    !values.isVehicleTowed
                                )
                            }
                            checked={values.isVehicleTowed}
                            label={t("has_the_vehicle_been_salvaged")}
                        />
                    </FormGroup>
                </Col>
            </Row>
            {values.isVehicleTowed && (
                <Row>
                    <Col sm={6}>
                        <FormGroup>
                            <Label>{t("where_was_the_vehicle_salvaged")}</Label>
                            <Input
                                autoComplete="off"
                                name="vehicleTowedToLocation"
                                value={values.vehicleTowedToLocation}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            ></Input>
                        </FormGroup>
                    </Col>
                </Row>
            )}
        </>
    );
};
