import { AppContext } from "AppContext";
import { useContext } from "react";
import TryggHansaIncidentForm from "../../../components/form/TryggHansa/TryggHansaIncidentForm";
import TrygIncident from "../../../components/form/Tryg/TrygIncident";

const IncidentView = ({ match }) => {
    const appContext = useContext(AppContext);

    return appContext.customer === "Tryg" ? (
        <TrygIncident match={match} editable={true} />
    ) : (
        <TryggHansaIncidentForm match={match} editable={true} />
    );
};
export default IncidentView;
