import { AppContext } from "AppContext";
import { t } from "i18next";
import { useContext } from "react";
import { FormGroup, Label, Input } from "reactstrap";

const Form = ({
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    setFieldValue
}) => {
    const authContext = useContext(AppContext);
    return (
        <>
            <FormGroup>
                <Label for="name">{t("name")}</Label>
                <Input
                    className={errors.name ? "is-invalid" : ""}
                    autoComplete="off"
                    name="name"
                    value={values.name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                ></Input>
                {errors.name && touched.name && (
                    <span className="text-danger">{errors.name}</span>
                )}
            </FormGroup>
            <FormGroup>
                <Label for="name">
                    {t("email_on_primary_company_contact")}
                </Label>
                <Input
                    className={errors.replyEmail ? "is-invalid" : ""}
                    autoComplete="off"
                    name="replyEmail"
                    value={values.replyEmail}
                    onChange={handleChange}
                    onBlur={handleBlur}
                ></Input>
                {errors.replyEmail && touched.replyEmail && (
                    <span className="text-danger">{errors.replyEmail}</span>
                )}
            </FormGroup>
            {authContext.userRoles &&
                authContext.userRoles.indexOf("SuperAdmin") !== -1 && (
                    <FormGroup tag="fieldset">
                        <Label for="customer">{t("customer")}</Label>

                        <FormGroup check>
                            <Input
                                name="customer"
                                value={0}
                                type="radio"
                                checked={values.customer === 0 ? true : false}
                                onChange={() => setFieldValue("customer", 0)}
                            />
                            <Label>Trygg Hansa</Label>
                        </FormGroup>
                        <FormGroup check>
                            <Input
                                type="radio"
                                name="customer"
                                value={1}
                                checked={values.customer === 1}
                                onChange={() => setFieldValue("customer", 1)}
                            />
                            <Label>Tryg</Label>
                        </FormGroup>
                    </FormGroup>
                )}
        </>
    );
};
export default Form;
