import React from "react";
import Datetime from "react-datetime";
import moment from "moment";
import { Row, Col } from "reactstrap";
import "./SplitDatetime.css";
import { useTranslation } from "react-i18next";
import i18next from "i18next";

const SplitDatetime = (props) => {
    const { onChange, errors, value } = props;
    const { t } = useTranslation();
    const [dateValue, setDateValue] = React.useState(value);
    const [timeValue, setTimeValue] = React.useState(value);
    const onChangeDate = (e) => {
        setDateValue(e);
        onChange(
            moment(
                moment(e, "YYYY-MM-DD").format("L") +
                    " " +
                    moment(timeValue, "HH:mm").format("HH:mm")
            )
        );
    };

    const onChangeTime = (e) => {
        setTimeValue(e);
        onChange(
            moment(
                moment(dateValue, "YYYY-MM-DD").format("L") +
                    " " +
                    moment(e, "HH:mm").format("HH:mm")
            )
        );
    };

    return (
        <Row>
            <Col lg={8} xs={7}>
                <Datetime
                    locale={i18next.resolvedLanguage}
                    className="incidentDate-datePicker"
                    onChange={onChangeDate}
                    timeFormat={false}
                    inputProps={{
                        placeholder: t("date"),
                        className: errors.incidentDate
                            ? "form-control is-invalid"
                            : "form-control"
                    }}
                    value={dateValue}
                />
            </Col>
            <Col lg={4} xs={5}>
                <Datetime
                    locale={i18next.resolvedLanguage}
                    className="incidentDate-timePicker"
                    onChange={onChangeTime}
                    timeFormat={true}
                    dateFormat={false}
                    inputProps={{
                        placeholder: t("time"),
                        className: errors.incidentDate
                            ? "form-control is-invalid"
                            : "form-control"
                    }}
                    value={timeValue}
                />
            </Col>
        </Row>
    );
};
export default SplitDatetime;
