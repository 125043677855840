import { FormGroup, Label, Input } from "reactstrap";

const Form = ({ values, errors, touched, handleBlur, handleChange }) => {
    return (
        <>
            <FormGroup>
                <Label for="name">Namn</Label>
                <Input
                    className={errors.name ? "is-invalid" : ""}
                    autoComplete="off"
                    name="name"
                    value={values.name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                ></Input>
                {errors.name && touched.name && (
                    <span className="text-danger">{errors.name}</span>
                )}
            </FormGroup>
        </>
    );
};
export default Form;
