import React, { useEffect, useState, useContext } from "react";
import { useJsApiLoader } from "@react-google-maps/api";
import { getGeocode } from "use-places-autocomplete";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { AppContext } from "AppContext";

const libraries = ["places"];

const AutoSelectLocationButton = ({
	field,
	setShowValidationModal,
	setValidateContent,
	setFieldValue,
	name,
	showValidationModal,
	validateContent,
	formGroup,
	handleSubmit
}) => {
	const todaysDate = new Date();
	const appContext = useContext(AppContext);
	const [formattedDate, setFormattedDate] = useState("");

	const [myPositionLatLng, setMyPositionLatLng] = useState();

	const { t, i18n } = useTranslation();
	const { isLoaded } = useJsApiLoader({
		id: "google-map-script",
		googleMapsApiKey: "AIzaSyC7x_PUYwT2eqhGzaCe0OuymwFr5_Kvo8s",
		libraries
	});

	useEffect(() => {
		moment.locale(i18n.language);
		const todaysDate = new Date();
		const newFormattedDate = moment(todaysDate).format(
			"dddd, DD/MM/YYYY, H:mm"
		);
		setFormattedDate(newFormattedDate);
	}, [i18n.language]);

	useEffect(() => {
		if (navigator.geolocation) {
			navigator.geolocation.getCurrentPosition(
				(position) => {
					const userLat = position.coords.latitude;
					const userLng = position.coords.longitude;
					setMyPositionLatLng({ lat: userLat, lng: userLng });
				},
				(error) => {
					console.error("Error getting geolocation:", error);
				},
				{ enableHighAccuracy: true }
			);
		} else {
			console.error("Geolocation is not supported by your browser");
		}
	}, []);

	const getLocationFromLatLng = async () => {
		if (isLoaded) {
			const lat = myPositionLatLng.lat;
			const lng = myPositionLatLng.lng;

			const fetchAddress = async () => {
				try {
					const location = { lat, lng };
					const results = await getGeocode({ location });
					const address = results[0]?.formatted_address;
					const message = (
						<div>
							{t("your_position_is", "Your selected position is")}{" "}
							<strong>{address && address}</strong>,<br></br>
							{t("your_choosen_time", "Your selected date")}{" "}
							<strong>{formattedDate && formattedDate}</strong>
						</div>
					);

					setValidateContent({
						title: t(
							"accept_your_position",
							"Accept your position"
						),
						message: message
					});
					setShowValidationModal(true);
				} catch (error) {
					console.error("Error fetching address: ", error);
					console.log("Error fetching address");
					setValidateContent({
						title: t(
							"accept_your_position",
							"Accept your position"
						),
						message: t(
							"cannot_find_your_position",
							"Right now it doesn't seem like we can find your location"
						)
					});
				}
			};

			return await fetchAddress();
		}
	};

	return (
		<div>
			{showValidationModal && validateContent && (
				<Modal
					isOpen={showValidationModal}
					toggle={() => setShowValidationModal(false)}
					style={{ top: "25%" }}
					zIndex="1000"
					fade={false}
				>
					<ModalHeader>{validateContent?.title}</ModalHeader>
					<ModalBody>{validateContent?.message}</ModalBody>
					<ModalFooter className="justify-content-between">
						<Button
							color="secondary"
							outline
							onClick={() => {
								appContext.setFormSettings((prevState) => {
									return {
										...prevState,
										nextQuestionId: formGroup.fields.find(
											(x) => x.labelKey === "no"
										).nextFormGroupId
									};
								});
								setFieldValue(formGroup.id, field.labelKey);
								handleSubmit();
								setShowValidationModal(false);
								setValidateContent(null);
							}}
						>
							{t("no", "No")}
						</Button>

						<Button
							color="primary"
							onClick={() => {
								appContext.setFormSettings((prevState) => {
									return {
										...prevState,
										nextQuestionId: field.nextFormGroupId
									};
								});

								setFieldValue("incidentDate", todaysDate);
								setFieldValue(
									"location",
									JSON.stringify(myPositionLatLng)
								);
								handleSubmit();
								setShowValidationModal(false);
								setValidateContent(null);
							}}
							className="my-2 my-md-3"
						>
							{t("confirm", "Confirm")}
						</Button>
					</ModalFooter>
				</Modal>
			)}
			<div className="mb-2 mb-md-4 form-group">
				<Button
					className="mb-0 w-100"
					color="black"
					onClick={getLocationFromLatLng}
				>
					{t(
						field.labelKey,
						field.translateDK ? field.translateDK : field.labelKey
					)}
				</Button>
			</div>
		</div>
	);
};

export default AutoSelectLocationButton;
