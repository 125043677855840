import { t } from "i18next";
import * as React from "react";
const SvgCarSideLeft = (props) => (
	<svg
		width={468}
		height={171}
		viewBox="0 0 468 171"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
		className="svg-component"
	>
		<g clipPath="url(#clip0_1811_2713)">
			<path
				d="M22.3101 89.4602C22.3101 89.4602 24.4101 92.1602 20.3101 97.4602H22.3101L25.3101 89.4602H22.3101Z"
				fill="#989898"
			/>
			<path
				d="M22.31 89.4602C22.31 89.4602 19.31 92.4602 20.31 97.4602C24.41 92.1602 22.31 89.4602 22.31 89.4602Z"
				fill="white"
			/>
			<path
				d="M390.31 51.86L399.91 50.06C399.91 50.06 383.91 34.76 342.31 24.26L338.31 27.86C338.31 27.86 368.37 35.32 390.31 51.86Z"
				fill="#6F7B93"
				stroke="black"
				strokeMiterlimit={10}
			/>
			<path
				d="M152.31 60.8601C186.21 33.1301 210.31 27.8601 210.31 27.8601L204.51 25.3601C186.31 32.0601 170.31 42.8601 138.91 60.0601C138.91 60.0601 141.31 60.8601 145.31 61.8601C149.31 62.8601 152.31 60.8601 152.31 60.8601Z"
				fill="#6F7B93"
				stroke="black"
				strokeMiterlimit={10}
			/>
			<path
				d="M433.81 53.0601L399.81 50.0601L390.21 51.8601L427.21 55.8601C427.21 55.8601 427.31 55.9601 427.51 56.0601C430.41 54.5601 432.81 53.7601 434.61 53.3601C434.41 53.0601 434.11 52.9601 433.81 53.0601Z"
				fill="#EDEDED"
			/>
			<path
				d="M441.81 65.0601C441.81 65.0601 437.61 55.0601 434.61 53.3601C432.81 53.7601 430.41 54.6601 427.51 56.0601C428.61 56.7601 432.71 59.7601 435.51 64.8601L439.51 65.3601L441.81 65.0601Z"
				fill="white"
			/>
			<path
				d="M439.61 65.3601L435.61 64.8601C437.01 67.3601 438.01 70.3601 438.31 73.8601C439.31 85.8601 443.41 89.8601 443.41 89.8601V81.9601C443.31 81.8601 439.61 65.3601 439.61 65.3601Z"
				fill="#EDEDED"
			/>
			<path
				d="M424.81 77.26L438.71 77.76C438.51 76.56 438.31 75.26 438.21 73.86V73.76H424.31L424.81 77.26Z"
				fill="white"
			/>
			<path
				d="M262.31 18.8601C306.31 19.8601 338.31 27.8601 338.31 27.8601L342.31 24.2601C319.41 18.5601 292.61 14.2601 264.91 15.0601C226.31 16.0601 222.31 18.7601 204.51 25.3601L210.31 27.8601C210.31 27.8601 218.31 17.8601 262.31 18.8601Z"
				fill="#EDEDED"
			/>
			<path
				d="M443.31 89.7602C443.31 89.7602 440.91 87.3602 439.31 80.6602C434.01 80.4602 419.71 79.7602 417.21 79.7602C415.91 79.7602 415.21 79.3602 414.71 78.9602C414.71 78.9602 422.21 94.7602 405.21 97.7602C394.81 99.5602 397.11 101.06 390.31 101.86C390.61 102.26 390.91 102.76 391.21 103.26L449.71 96.9602L443.31 89.7602Z"
				fill="#EDEDED"
			/>
			<path
				d="M391.31 103.36C393.71 107.06 395.71 111.56 396.81 117.06V131.26L432.71 124.46C432.71 124.46 445.91 125.06 448.31 111.06L449.81 97.0601L391.31 103.36Z"
				fill="#D1D1D1"
			/>
			<path
				d="M160.31 63.86L177.31 63.26L171.81 50.76C163.41 56.96 160.31 63.86 160.31 63.86Z"
				fill="#262626"
			/>
			<path
				d="M171.81 50.7601L177.31 63.2601L239.01 60.6601L251.81 22.8601C203.61 23.6601 187.61 38.8601 171.81 50.7601Z"
				fill="#6F7B93"
			/>
			<path
				d="M318.31 29.1602L333.11 57.6602L341.31 57.3602L325.11 30.7602C322.91 30.1602 320.61 29.6602 318.31 29.1602Z"
				fill="black"
			/>
			<path
				d="M378.71 51.86C378.71 51.86 360.81 39.26 325.21 30.76L341.41 57.36L380.71 56.06C380.61 56.06 387.11 55.86 378.71 51.86Z"
				fill="#6F7B93"
			/>
			<path
				d="M259.11 22.8601L254.71 60.1601L333.11 57.5601L318.31 29.0601C301.91 25.6601 282.11 23.1601 259.11 22.8601Z"
				fill="#6F7B93"
			/>
			<path
				d="M255.31 22.8601H251.81L239.01 60.6601L254.81 60.1601L259.21 22.8601H255.31Z"
				fill="black"
			/>
			<path
				d="M186.21 59.7602V52.7602C186.21 52.7602 184.71 47.8602 177.41 49.3602C170.21 50.7602 170.21 58.2602 170.21 58.2602L171.11 60.1602C173.31 59.5602 177.51 58.6602 183.41 58.2602L186.21 59.7602Z"
				fill="white"
			/>
			<path
				d="M183.41 58.2603C177.51 58.5603 173.31 59.5603 171.11 60.1603L172.61 63.3603C172.61 63.3603 181.11 62.9603 186.21 60.3603V59.7603C186.21 59.7603 183.41 58.3603 183.41 58.2603Z"
				fill="#E1E1E1"
			/>
			<path
				d="M399.81 130.36L318.81 133.36V82.3601H397.81L390.91 99.2601L397.91 112.06L399.81 130.36Z"
				fill="#262626"
			/>
			<path
				d="M152.31 82.8601H67.3101V133.86H152.31V82.8601Z"
				fill="#262626"
			/>
			<path
				d="M255.31 23.8601C297.51 23.8601 328.61 31.6601 347.31 38.2601C367.61 45.3601 378.01 52.5601 378.11 52.6601L378.21 52.7601H378.31C380.61 53.8601 381.51 54.5601 381.81 54.8601C381.51 54.9601 381.01 55.0601 380.61 55.0601L341.31 56.3601L339.61 56.4601L340.51 57.9601L342.61 61.3601V61.4601L342.81 61.5601C342.91 61.7601 343.21 62.3601 342.71 63.9601C342.31 65.1601 341.61 66.5601 340.61 68.1601C336.61 74.3601 317.31 109.06 316.51 110.46L316.41 110.56V110.66C316.31 111.16 313.41 123.96 299.31 124.86C285.11 125.76 170.21 127.66 159.11 127.86C157.91 123.86 152.01 102.86 155.01 72.4601C155.11 71.9601 156.41 65.7601 160.61 64.8601L160.91 64.7601L161.11 64.4601C161.21 64.3601 167.41 50.8601 185.31 40.8601C201.81 31.6601 216.31 23.8601 255.31 23.8601ZM255.31 22.8601C179.31 22.8601 160.31 63.8601 160.31 63.8601C155.31 64.8601 153.91 72.2601 153.91 72.2601C150.51 106.66 158.31 128.86 158.31 128.86C158.31 128.86 284.31 126.86 299.31 125.86C314.31 124.86 317.31 110.86 317.31 110.86C317.31 110.86 337.31 74.8601 341.41 68.5601C342.61 66.6601 343.31 65.2601 343.61 64.1601C344.41 61.5601 343.41 60.7601 343.41 60.7601L341.31 57.3601L380.61 56.0601C380.61 56.0601 387.01 55.8601 378.61 51.8601C378.71 51.8601 337.31 22.8601 255.31 22.8601Z"
				fill="black"
			/>
			<path
				d="M434.91 63.6602C432.11 59.2602 428.61 56.6602 427.61 55.9602L411.41 64.7602L434.91 63.6602Z"
				fill="#E1E1E1"
			/>
			<path
				d="M38.71 89.5601C36.51 89.7601 34.71 90.0601 33.21 90.2601C34.71 89.9601 36.51 89.7601 38.71 89.5601Z"
				fill="black"
			/>
			<path
				d="M17.8101 128.86C18.0101 129.26 18.3101 129.56 18.7101 129.76L29.3101 134.56L43.3101 133.96C41.6101 132.26 37.7101 128.86 33.2101 128.86H17.8101Z"
				fill="#989898"
			/>
			<path
				d="M411.31 64.7602L427.51 55.9602H380.61L341.31 57.2602L343.41 60.6602C343.41 60.6602 344.41 61.4602 343.61 64.0602C413.91 65.3602 410.31 66.7602 410.31 66.7602C409.31 64.8602 411.31 64.7602 411.31 64.7602Z"
				fill="#EDEDED"
			/>
			<path
				d="M65.3102 81.8601H39.6103C39.6103 81.8601 34.1103 86.6601 31.5103 90.4601C31.7103 90.4601 32.0103 90.3601 32.3103 90.3601C32.4103 90.3601 32.5103 90.3601 32.6103 90.2601C32.8103 90.2601 32.9103 90.1601 33.1103 90.1601C34.6103 89.9601 36.4103 89.7601 38.6103 89.4601C39.7103 89.3601 40.8103 89.2601 42.1103 89.0601C42.3103 89.0601 42.5103 89.0601 42.7103 88.9601C43.2103 88.8601 43.7103 88.8601 44.2103 88.8601C44.6103 88.8601 44.9103 88.7601 45.3103 88.7601C45.6103 88.7601 45.9103 88.6601 46.2103 88.6601C46.6103 88.6601 47.0103 88.5601 47.5103 88.5601C47.7103 88.5601 47.9103 88.5601 48.2103 88.4601C48.8103 88.3601 49.5103 88.3601 50.1103 88.3601C50.3103 88.3601 50.6103 88.3601 50.8103 88.2601C51.4103 88.2601 52.0103 88.1601 52.6103 88.1601H53.0103C53.8103 88.1601 54.5103 88.0601 55.3103 88.0601H56.0103C56.8103 88.0601 57.6103 87.9601 58.4103 87.9601H58.5103C59.3103 87.9601 60.2103 87.8601 61.1103 87.8601H61.8103C62.7103 87.8601 63.6103 87.7601 64.5103 87.7601C65.5103 86.0601 66.5102 84.4601 67.3102 83.6601C69.2102 81.8601 65.2102 81.8601 65.3102 81.8601Z"
				fill="#F7F7F7"
			/>
			<path
				d="M38.7101 89.5601C36.5101 89.7601 34.7101 90.0601 33.2101 90.2601C33.0101 90.2601 32.9101 90.3601 32.7101 90.3601C32.6101 90.3601 32.5101 90.3601 32.3101 90.4601C32.0101 90.4601 31.7101 90.5601 31.5101 90.5601C30.7101 91.7601 30.1101 92.8601 30.2101 93.6601C30.2101 96.9601 33.2101 95.3601 33.2101 95.3601C33.2101 95.3601 34.1101 95.1601 36.5101 94.9601C37.0101 93.0601 37.8101 91.2601 38.7101 89.5601Z"
				fill="#7F7F7F"
			/>
			<path
				d="M61.1102 87.9602C60.2102 87.9602 59.4102 88.0602 58.5102 88.0602H58.4102C57.6102 88.0602 56.8102 88.1602 56.0102 88.1602H55.3102C54.5102 88.1602 53.8102 88.2602 53.0102 88.2602H52.6102C52.0102 88.2602 51.4102 88.3602 50.8102 88.3602C50.6102 88.3602 50.3102 88.3602 50.1102 88.4602C49.4102 88.4602 48.8102 88.5602 48.2102 88.5602C48.0102 88.5602 47.7102 88.5602 47.5102 88.6602C47.1102 88.6602 46.7102 88.7602 46.2102 88.7602C45.9102 88.7602 45.6102 88.8602 45.3102 88.8602C44.9102 88.8602 44.6102 88.9602 44.2102 88.9602C43.7102 88.9602 43.2102 89.0602 42.7102 89.0602C42.5102 89.0602 42.3102 89.0602 42.1102 89.1602C40.9102 89.2602 39.7102 89.3602 38.6102 89.5602C37.6102 91.3602 36.8102 93.1602 36.4102 94.7602C40.2102 94.3602 47.6102 93.8602 61.1102 93.8602C61.1102 93.8602 62.6102 90.7602 64.3102 87.9602C63.4102 87.9602 62.5102 87.9602 61.6102 88.0602C61.5102 87.9602 61.3102 87.9602 61.1102 87.9602Z"
				fill="#989898"
			/>
			<path
				d="M20.71 116.66L26.11 116.46L53.31 115.26L54.01 115.56L54.81 112.76L21.01 114.26C20.91 115.06 20.81 115.86 20.71 116.66Z"
				fill="#E1E1E1"
			/>
			<path
				d="M26.2101 116.36V124.66C26.2101 124.66 25.8101 126.26 27.2101 127.36C32.8101 126.96 40.9101 126.36 44.9101 126.16C51.4101 125.76 51.9101 123.46 51.9101 123.46L52.3101 121.86H43.3101V117.86H53.4101L54.1101 115.46L53.4101 115.16L26.2101 116.36Z"
				fill="#767676"
			/>
			<path
				d="M43.3101 121.86H52.3101L53.4101 117.86H43.3101V121.86Z"
				fill="#989898"
			/>
			<path
				d="M26.2103 124.66V116.36L20.8103 116.56C20.2103 122.46 21.1103 127.76 21.1103 127.76C21.1103 127.76 23.7103 127.56 27.2103 127.26C25.7103 126.26 26.2103 124.66 26.2103 124.66Z"
				fill="#989898"
			/>
			<path
				d="M242.61 119.16C197.41 121.16 168.91 122.06 156.41 122.26C157.41 126.56 158.21 128.86 158.21 128.86C158.21 128.86 198.91 128.06 244.51 127.26L242.81 120.26C242.71 119.86 242.71 119.46 242.61 119.16Z"
				fill="url(#paint0_linear_1811_2713)"
			/>
			<path
				d="M245.21 68.0601L153.91 72.3601C151.71 95.6601 154.51 113.26 156.51 122.26C168.91 122.06 197.41 121.16 242.71 119.16C240.71 103.16 244.71 71.7601 245.21 68.0601Z"
				fill="url(#paint1_linear_1811_2713)"
			/>
			<path
				d="M245.211 67.6601L247.911 60.3601L160.211 63.7601C155.211 64.7601 153.811 72.1601 153.811 72.1601V72.3601L245.111 68.0601C245.211 67.8601 245.211 67.7601 245.211 67.6601Z"
				fill="url(#paint2_linear_1811_2713)"
			/>
			<path
				d="M343.41 60.66L341.31 57.26L247.91 60.36L245.21 67.66V68.06L343.61 64.06C344.41 61.46 343.41 60.66 343.41 60.66Z"
				fill="url(#paint3_linear_1811_2713)"
			/>
			<path
				d="M242.81 120.26L244.51 127.26C271.01 126.76 293.71 126.26 299.21 125.86C309.21 125.16 313.81 118.76 315.81 114.56C295.21 116.56 268.81 118.06 242.51 119.16C242.71 119.56 242.71 119.86 242.81 120.26Z"
				fill="url(#paint4_linear_1811_2713)"
			/>
			<path
				d="M242.611 119.16C268.811 118.06 295.311 116.46 315.911 114.56C316.911 112.46 317.311 110.86 317.311 110.86C317.311 110.86 337.311 74.8599 341.411 68.5599C342.611 66.6599 343.311 65.2599 343.611 64.1599L245.211 68.1599C244.711 71.7599 240.611 103.16 242.611 119.16Z"
				fill="url(#paint5_linear_1811_2713)"
			/>
			<path
				d="M149.31 73.8601H143.31V77.8601H149.31V73.8601Z"
				fill="#FB8E36"
			/>
			<path
				d="M46.8101 92.8601C47.6385 92.8601 48.3101 90.8454 48.3101 88.3601C48.3101 85.8748 47.6385 83.8601 46.8101 83.8601C45.9816 83.8601 45.3101 85.8748 45.3101 88.3601C45.3101 90.8454 45.9816 92.8601 46.8101 92.8601Z"
				fill="white"
			/>
			<path
				d="M53.8101 92.8601C54.6385 92.8601 55.3101 90.8454 55.3101 88.3601C55.3101 85.8748 54.6385 83.8601 53.8101 83.8601C52.9816 83.8601 52.3101 85.8748 52.3101 88.3601C52.3101 90.8454 52.9816 92.8601 53.8101 92.8601Z"
				fill="white"
			/>
			<path
				d="M40.8101 125.86C43.2953 125.86 45.3101 123.845 45.3101 121.36C45.3101 118.875 43.2953 116.86 40.8101 116.86C38.3248 116.86 36.3101 118.875 36.3101 121.36C36.3101 123.845 38.3248 125.86 40.8101 125.86Z"
				fill="#989898"
			/>
			<path
				d="M35.8101 119.86H22.3101V120.86H35.8101V119.86Z"
				fill="#E1E1E1"
			/>
			<path
				d="M35.8101 122.86H22.3101V123.86H35.8101V122.86Z"
				fill="#E1E1E1"
			/>
			<path
				d="M38.8101 125.86C41.2953 125.86 43.3101 123.845 43.3101 121.36C43.3101 118.875 41.2953 116.86 38.8101 116.86C36.3248 116.86 34.3101 118.875 34.3101 121.36C34.3101 123.845 36.3248 125.86 38.8101 125.86Z"
				fill="#989898"
			/>
			<path
				d="M37.3101 121.36C37.3101 119.76 38.4101 118.46 39.8101 118.06C39.5101 117.96 39.1101 117.86 38.8101 117.86C36.9101 117.86 35.3101 119.46 35.3101 121.36C35.3101 123.26 36.9101 124.86 38.8101 124.86C39.1101 124.86 39.5101 124.76 39.8101 124.66C38.3101 124.26 37.3101 122.96 37.3101 121.36Z"
				fill="#EDEDED"
			/>
			<path
				d="M42.3101 121.36C42.3101 119.76 41.2101 118.46 39.8101 118.06C38.4101 118.46 37.3101 119.86 37.3101 121.36C37.3101 122.86 38.4101 124.26 39.8101 124.66C41.2101 124.26 42.3101 122.96 42.3101 121.36Z"
				fill="white"
			/>
			<path
				d="M105.81 155.86C123.759 155.86 138.31 141.309 138.31 123.36C138.31 105.411 123.759 90.8601 105.81 90.8601C87.8608 90.8601 73.3101 105.411 73.3101 123.36C73.3101 141.309 87.8608 155.86 105.81 155.86Z"
				fill="#414141"
			/>
			<path
				d="M106.31 102.86C107.91 102.86 109.51 103.06 111.01 103.36L111.61 100.46C109.91 100.06 108.11 99.8601 106.31 99.8601C105.01 99.8601 103.71 99.9601 102.41 100.16C102.51 100.96 102.61 101.96 102.71 103.16C103.81 102.96 105.01 102.86 106.31 102.86Z"
				fill="#E4E4E4"
			/>
			<path
				d="M102.61 103.16C102.51 101.96 102.41 100.96 102.31 100.16C98.11 100.86 94.41 102.56 91.21 105.06L93.11 107.36C95.91 105.26 99.11 103.76 102.61 103.16Z"
				fill="#CDCDCD"
			/>
			<path
				d="M93.2101 107.46L91.3101 105.16C88.9101 107.06 86.9101 109.46 85.3101 112.16C85.9101 112.56 86.8101 113.16 87.8101 113.86C89.2101 111.26 91.0101 109.16 93.2101 107.46Z"
				fill="#E4E4E4"
			/>
			<path
				d="M125.21 114.76L127.91 113.36C126.51 110.56 124.71 108.06 122.41 105.96C121.81 106.46 121.11 107.26 120.21 108.06C122.31 109.96 124.01 112.16 125.21 114.76Z"
				fill="#E4E4E4"
			/>
			<path
				d="M87.81 113.76C86.81 113.06 85.91 112.56 85.31 112.06C83.41 115.56 82.21 119.56 82.21 123.76H85.21C85.31 120.16 86.21 116.76 87.81 113.76Z"
				fill="#CDCDCD"
			/>
			<path
				d="M120.11 108.16C121.01 107.36 121.71 106.56 122.31 106.06C119.31 103.36 115.61 101.36 111.51 100.46L110.91 103.36C114.41 104.16 117.61 105.86 120.11 108.16Z"
				fill="#CDCDCD"
			/>
			<path
				d="M127.31 123.86C127.31 124.26 127.31 124.56 127.21 124.96C128.41 125.16 129.41 125.26 130.21 125.36C130.21 124.86 130.31 124.36 130.31 123.86C130.31 120.16 129.41 116.56 127.91 113.46L125.21 114.86C126.51 117.46 127.31 120.56 127.31 123.86Z"
				fill="#CDCDCD"
			/>
			<path
				d="M127.21 124.86C127.11 127.76 126.31 130.46 125.11 132.96L127.81 134.26C129.11 131.56 129.91 128.56 130.11 125.36C129.41 125.16 128.41 125.06 127.21 124.86Z"
				fill="#E4E4E4"
			/>
			<path
				d="M118.51 140.86C119.11 141.86 119.61 142.76 120.01 143.46C123.41 141.06 126.11 137.96 127.91 134.26L125.21 132.96C123.61 136.16 121.31 138.86 118.51 140.86Z"
				fill="#CDCDCD"
			/>
			<path
				d="M110.91 144.26L111.61 147.16C114.71 146.46 117.51 145.16 120.01 143.46C119.61 142.76 119.11 141.86 118.51 140.86C116.31 142.46 113.71 143.66 110.91 144.26Z"
				fill="#E4E4E4"
			/>
			<path
				d="M85.3101 123.86H82.3101C82.3101 127.06 82.9101 130.06 84.1101 132.86C84.8101 132.66 85.8101 132.26 87.0101 131.96C85.8101 129.46 85.3101 126.76 85.3101 123.86Z"
				fill="#E4E4E4"
			/>
			<path
				d="M86.9098 131.96C85.8098 132.36 84.8098 132.66 84.0098 132.86C85.6098 136.66 88.1098 140.06 91.3098 142.56L93.1098 140.16C90.4098 138.06 88.3098 135.26 86.9098 131.96Z"
				fill="#CDCDCD"
			/>
			<path
				d="M106.31 144.86C104.31 144.86 102.41 144.56 100.61 144.06C100.21 145.16 99.8098 146.16 99.5098 146.86C101.71 147.46 103.91 147.86 106.31 147.86C108.11 147.86 109.91 147.66 111.71 147.26L111.01 144.36C109.41 144.66 107.91 144.86 106.31 144.86Z"
				fill="#CDCDCD"
			/>
			<path
				d="M93.2102 140.26L91.4102 142.66C93.8102 144.56 96.6102 146.06 99.6102 146.86C99.9102 146.16 100.31 145.16 100.71 144.06C97.8102 143.26 95.3102 141.96 93.2102 140.26Z"
				fill="#E4E4E4"
			/>
			<path
				d="M97.51 121.96C97.51 121.96 98.11 121.16 95.81 119.36C94.41 118.26 90.71 115.76 87.81 113.76C86.21 116.76 85.21 120.16 85.21 123.76L94.81 123.66C94.91 123.66 97.71 123.36 97.51 121.96Z"
				fill="#808080"
			/>
			<path
				d="M99.3101 129.46C99.3101 129.46 99.1101 128.46 96.1101 129.16C94.4101 129.56 90.1101 130.86 86.8101 131.96C88.2101 135.16 90.3101 138.06 93.1101 140.26L99.0101 132.66C99.0101 132.66 100.51 130.26 99.3101 129.46Z"
				fill="#808080"
			/>
			<path
				d="M110.01 115.76C110.01 115.76 110.61 116.56 113.01 114.66C114.41 113.56 117.71 110.46 120.21 108.16C117.61 105.86 114.51 104.26 111.01 103.46L109.01 112.86C108.91 112.76 108.61 115.56 110.01 115.76Z"
				fill="#808080"
			/>
			<path
				d="M102.31 115.86C102.31 115.86 103.31 115.86 103.31 112.86C103.31 111.06 103.01 106.66 102.71 103.16C99.2101 103.76 95.9101 105.26 93.3101 107.36L99.4101 114.76C99.3101 114.86 101.31 116.86 102.31 115.86Z"
				fill="#808080"
			/>
			<path
				d="M106.41 132.76C106.41 132.76 105.51 132.36 104.21 135.06C103.41 136.66 101.81 140.86 100.61 144.06C102.41 144.56 104.31 144.86 106.31 144.86C107.91 144.86 109.51 144.66 111.01 144.36L108.81 135.06C108.71 134.96 107.71 132.26 106.41 132.76Z"
				fill="#808080"
			/>
			<path
				d="M114.91 121.76C114.11 120.56 116.51 119.06 116.51 119.06L125.11 114.76C123.91 112.26 122.21 109.96 120.11 108.16C117.61 110.56 114.31 113.56 112.91 114.66C110.61 116.56 109.91 115.76 109.91 115.76C108.51 115.56 108.81 112.76 108.81 112.76L110.81 103.36C109.31 103.06 107.71 102.86 106.11 102.86C104.91 102.86 103.71 102.96 102.51 103.16C102.81 106.56 103.11 111.06 103.11 112.86C103.11 115.86 102.11 115.86 102.11 115.86C101.11 116.86 99.1098 114.86 99.1098 114.86L93.0098 107.46C90.8098 109.16 89.0098 111.36 87.6098 113.86C90.5098 115.76 94.2098 118.26 95.6098 119.46C97.9098 121.36 97.3098 122.06 97.3098 122.06C97.5098 123.46 94.6098 123.76 94.6098 123.76L85.0098 123.86C85.0098 126.76 85.6098 129.46 86.6098 131.96C89.9098 130.96 94.2098 129.56 95.9098 129.16C98.8098 128.46 99.1098 129.46 99.1098 129.46C100.31 130.26 98.8098 132.56 98.8098 132.56L92.9098 140.16C95.1098 141.86 97.6098 143.16 100.31 143.96C101.51 140.76 103.21 136.56 103.91 134.96C105.21 132.26 106.11 132.66 106.11 132.66C107.41 132.16 108.41 134.86 108.41 134.86L110.61 144.16C113.41 143.56 115.91 142.36 118.21 140.76C116.51 137.76 114.21 133.86 113.41 132.36C112.11 129.66 113.01 129.26 113.01 129.26C113.51 127.96 116.11 128.86 116.11 128.86L124.81 132.96C126.01 130.46 126.71 127.76 126.91 124.86C123.51 124.36 119.11 123.76 117.31 123.36C114.71 122.66 115.01 121.76 114.91 121.76Z"
				fill="#CDCDCD"
			/>
			<path
				d="M125.21 114.76L116.61 119.06C116.61 119.06 114.21 120.56 115.01 121.76C115.01 121.76 114.81 122.76 117.71 123.36C119.41 123.76 123.81 124.46 127.31 124.86C127.31 124.46 127.41 124.16 127.41 123.76C127.31 120.56 126.51 117.46 125.21 114.76Z"
				fill="#808080"
			/>
			<path
				d="M113.31 129.26C113.31 129.26 112.41 129.66 113.71 132.36C114.51 133.96 116.71 137.86 118.51 140.76C121.31 138.76 123.61 135.96 125.21 132.86L116.51 128.76C116.51 128.86 113.81 127.96 113.31 129.26Z"
				fill="#808080"
			/>
			<path
				d="M105.81 118.86C106.638 118.86 107.31 118.189 107.31 117.36C107.31 116.532 106.638 115.86 105.81 115.86C104.982 115.86 104.31 116.532 104.31 117.36C104.31 118.189 104.982 118.86 105.81 118.86Z"
				fill="#767676"
			/>
			<path
				d="M100.31 123.36C101.138 123.36 101.81 122.689 101.81 121.86C101.81 121.032 101.138 120.36 100.31 120.36C99.4816 120.36 98.8101 121.032 98.8101 121.86C98.8101 122.689 99.4816 123.36 100.31 123.36Z"
				fill="#767676"
			/>
			<path
				d="M102.31 129.36C103.138 129.36 103.81 128.689 103.81 127.86C103.81 127.032 103.138 126.36 102.31 126.36C101.482 126.36 100.81 127.032 100.81 127.86C100.81 128.689 101.482 129.36 102.31 129.36Z"
				fill="#767676"
			/>
			<path
				d="M109.31 129.36C110.138 129.36 110.81 128.689 110.81 127.86C110.81 127.032 110.138 126.36 109.31 126.36C108.482 126.36 107.81 127.032 107.81 127.86C107.81 128.689 108.482 129.36 109.31 129.36Z"
				fill="#767676"
			/>
			<path
				d="M111.61 123.76C112.438 123.76 113.11 123.088 113.11 122.26C113.11 121.432 112.438 120.76 111.61 120.76C110.781 120.76 110.11 121.432 110.11 122.26C110.11 123.088 110.781 123.76 111.61 123.76Z"
				fill="#767676"
			/>
			<path
				d="M105.81 126.86C107.743 126.86 109.31 125.293 109.31 123.36C109.31 121.427 107.743 119.86 105.81 119.86C103.877 119.86 102.31 121.427 102.31 123.36C102.31 125.293 103.877 126.86 105.81 126.86Z"
				fill="#767676"
			/>
			<path
				d="M359.81 155.86C377.759 155.86 392.31 141.309 392.31 123.36C392.31 105.411 377.759 90.8601 359.81 90.8601C341.861 90.8601 327.31 105.411 327.31 123.36C327.31 141.309 341.861 155.86 359.81 155.86Z"
				fill="#414141"
			/>
			<path
				d="M360.31 102.86C361.91 102.86 363.51 103.06 365.01 103.36L365.61 100.46C363.91 100.06 362.11 99.8601 360.31 99.8601C359.01 99.8601 357.71 99.9601 356.41 100.16C356.51 100.96 356.61 101.96 356.71 103.16C357.81 102.96 359.01 102.86 360.31 102.86Z"
				fill="#E4E4E4"
			/>
			<path
				d="M356.61 103.16C356.51 101.96 356.41 100.96 356.31 100.16C352.21 100.86 348.31 102.56 345.21 105.06L347.11 107.36C349.91 105.26 353.11 103.76 356.61 103.16Z"
				fill="#CDCDCD"
			/>
			<path
				d="M347.21 107.46L345.31 105.16C342.91 107.06 340.91 109.46 339.31 112.16C339.91 112.56 340.81 113.16 341.81 113.86C343.21 111.26 345.01 109.16 347.21 107.46Z"
				fill="#E4E4E4"
			/>
			<path
				d="M379.21 114.76L381.91 113.36C380.51 110.56 378.71 108.06 376.41 105.96C375.81 106.46 375.11 107.26 374.21 108.06C376.31 109.96 378.01 112.16 379.21 114.76Z"
				fill="#E4E4E4"
			/>
			<path
				d="M341.81 113.76C340.81 113.06 339.91 112.56 339.31 112.06C337.31 115.56 336.21 119.56 336.21 123.76H339.21C339.31 120.16 340.21 116.76 341.81 113.76Z"
				fill="#CDCDCD"
			/>
			<path
				d="M374.11 108.16C375.01 107.36 375.71 106.56 376.31 106.06C373.31 103.36 369.61 101.36 365.51 100.46L364.91 103.36C368.41 104.16 371.61 105.86 374.11 108.16Z"
				fill="#CDCDCD"
			/>
			<path
				d="M381.31 123.86V124.96C382.51 125.16 383.51 125.26 384.31 125.36V123.86C384.31 120.16 383.41 116.56 381.91 113.46L379.21 114.86C380.51 117.46 381.31 120.56 381.31 123.86Z"
				fill="#CDCDCD"
			/>
			<path
				d="M381.21 124.86C381.11 127.76 380.31 130.46 379.21 132.96L381.91 134.26C383.21 131.56 384.01 128.56 384.21 125.36C383.41 125.16 382.41 125.06 381.21 124.86Z"
				fill="#E4E4E4"
			/>
			<path
				d="M372.51 140.86C373.11 141.86 373.61 142.76 374.01 143.46C377.41 141.06 380.11 137.96 381.91 134.26L379.21 132.96C377.61 136.16 375.31 138.86 372.51 140.86Z"
				fill="#CDCDCD"
			/>
			<path
				d="M364.91 144.26L365.61 147.16C368.71 146.46 371.51 145.16 374.01 143.46C373.61 142.76 373.11 141.86 372.51 140.86C370.31 142.46 367.71 143.66 364.91 144.26Z"
				fill="#E4E4E4"
			/>
			<path
				d="M339.31 123.86H336.31C336.31 127.06 336.91 130.06 338.11 132.86C338.81 132.66 339.81 132.26 341.01 131.96C339.81 129.46 339.21 126.76 339.31 123.86Z"
				fill="#E4E4E4"
			/>
			<path
				d="M340.91 131.96C339.81 132.36 338.81 132.66 338.01 132.86C339.61 136.66 342.11 140.06 345.31 142.56L347.21 140.16C344.41 138.06 342.31 135.26 340.91 131.96Z"
				fill="#CDCDCD"
			/>
			<path
				d="M360.31 144.86C358.31 144.86 356.41 144.56 354.61 144.06C354.21 145.16 353.81 146.16 353.51 146.86C355.61 147.46 357.91 147.86 360.31 147.86C362.11 147.86 363.91 147.66 365.71 147.26L365.01 144.36C363.41 144.66 361.91 144.86 360.31 144.86Z"
				fill="#CDCDCD"
			/>
			<path
				d="M347.21 140.26L345.31 142.66C347.71 144.56 350.51 146.06 353.51 146.86C353.81 146.16 354.11 145.16 354.61 144.06C351.81 143.26 349.31 141.96 347.21 140.26Z"
				fill="#E4E4E4"
			/>
			<path
				d="M351.51 121.96C351.51 121.96 352.11 121.16 349.81 119.36C348.41 118.26 344.71 115.76 341.81 113.76C340.21 116.76 339.21 120.16 339.21 123.76L348.81 123.66C348.91 123.66 351.71 123.36 351.51 121.96Z"
				fill="#808080"
			/>
			<path
				d="M353.41 129.46C353.41 129.46 353.21 128.46 350.31 129.16C348.61 129.56 344.31 130.86 341.01 131.96C342.41 135.16 344.51 138.06 347.31 140.26L353.21 132.66C353.01 132.66 354.51 130.26 353.41 129.46Z"
				fill="#808080"
			/>
			<path
				d="M364.01 115.76C364.01 115.76 364.61 116.56 367.01 114.66C368.41 113.56 371.71 110.46 374.21 108.16C371.61 105.86 368.51 104.26 365.01 103.46L363.01 112.86C362.91 112.76 362.61 115.56 364.01 115.76Z"
				fill="#808080"
			/>
			<path
				d="M356.31 115.86C356.31 115.86 357.31 115.86 357.31 112.86C357.31 111.06 357.01 106.66 356.71 103.16C353.21 103.76 349.91 105.26 347.31 107.36L353.41 114.76C353.31 114.86 355.31 116.86 356.31 115.86Z"
				fill="#808080"
			/>
			<path
				d="M360.41 132.76C360.41 132.76 359.51 132.36 358.21 135.06C357.41 136.66 355.81 140.86 354.61 144.06C356.41 144.56 358.31 144.86 360.31 144.86C361.91 144.86 363.41 144.66 364.91 144.36L362.71 135.06C362.71 134.96 361.71 132.26 360.41 132.76Z"
				fill="#808080"
			/>
			<path
				d="M369.01 121.76C368.21 120.56 370.61 119.06 370.61 119.06L379.21 114.76C378.01 112.26 376.31 109.96 374.21 108.16C371.71 110.56 368.41 113.56 367.01 114.66C364.61 116.56 364.01 115.76 364.01 115.76C362.61 115.56 362.91 112.76 362.91 112.76L364.91 103.36C363.41 103.06 361.81 102.86 360.21 102.86C359.01 102.86 357.71 102.96 356.61 103.16C356.91 106.56 357.21 111.06 357.21 112.86C357.21 115.86 356.21 115.86 356.21 115.86C355.21 116.86 353.21 114.86 353.21 114.86L347.11 107.46C344.91 109.16 343.11 111.36 341.71 113.86C344.61 115.76 348.31 118.26 349.71 119.46C352.11 121.36 351.41 122.06 351.41 122.06C351.61 123.46 348.81 123.76 348.81 123.76L339.21 123.86C339.21 126.76 339.81 129.46 340.81 131.96C344.11 130.96 348.41 129.56 350.11 129.16C353.01 128.46 353.21 129.46 353.21 129.46C354.41 130.26 352.91 132.56 352.91 132.56L347.01 140.16C349.21 141.86 351.71 143.16 354.41 143.96C355.61 140.76 357.31 136.56 358.01 134.96C359.31 132.26 360.21 132.66 360.21 132.66C361.51 132.16 362.51 134.86 362.51 134.86L364.71 144.16C367.51 143.56 370.01 142.36 372.31 140.76C370.61 137.76 368.31 133.86 367.51 132.36C366.21 129.66 367.11 129.26 367.11 129.26C367.61 127.96 370.21 128.86 370.21 128.86L378.91 132.96C380.11 130.46 380.81 127.76 380.91 124.86C377.51 124.36 373.01 123.76 371.41 123.36C368.71 122.66 369.01 121.76 369.01 121.76Z"
				fill="#CDCDCD"
			/>
			<path
				d="M379.21 114.76L370.61 119.06C370.61 119.06 368.21 120.56 369.01 121.76C369.01 121.76 368.81 122.76 371.71 123.36C373.41 123.76 377.81 124.46 381.21 124.86V123.76C381.31 120.56 380.51 117.46 379.21 114.76Z"
				fill="#808080"
			/>
			<path
				d="M367.31 129.26C367.31 129.26 366.41 129.66 367.71 132.36C368.51 133.96 370.71 137.86 372.51 140.76C375.31 138.76 377.61 135.96 379.21 132.86L370.51 128.76C370.51 128.86 367.81 127.96 367.31 129.26Z"
				fill="#808080"
			/>
			<path
				d="M359.81 118.86C360.638 118.86 361.31 118.189 361.31 117.36C361.31 116.532 360.638 115.86 359.81 115.86C358.982 115.86 358.31 116.532 358.31 117.36C358.31 118.189 358.982 118.86 359.81 118.86Z"
				fill="#767676"
			/>
			<path
				d="M354.31 123.36C355.138 123.36 355.81 122.689 355.81 121.86C355.81 121.032 355.138 120.36 354.31 120.36C353.482 120.36 352.81 121.032 352.81 121.86C352.81 122.689 353.482 123.36 354.31 123.36Z"
				fill="#767676"
			/>
			<path
				d="M356.31 129.36C357.138 129.36 357.81 128.689 357.81 127.86C357.81 127.032 357.138 126.36 356.31 126.36C355.482 126.36 354.81 127.032 354.81 127.86C354.81 128.689 355.482 129.36 356.31 129.36Z"
				fill="#767676"
			/>
			<path
				d="M363.31 129.36C364.138 129.36 364.81 128.689 364.81 127.86C364.81 127.032 364.138 126.36 363.31 126.36C362.482 126.36 361.81 127.032 361.81 127.86C361.81 128.689 362.482 129.36 363.31 129.36Z"
				fill="#767676"
			/>
			<path
				d="M365.61 123.76C366.438 123.76 367.11 123.088 367.11 122.26C367.11 121.432 366.438 120.76 365.61 120.76C364.781 120.76 364.11 121.432 364.11 122.26C364.11 123.088 364.781 123.76 365.61 123.76Z"
				fill="#767676"
			/>
			<path
				d="M359.81 126.86C361.743 126.86 363.31 125.293 363.31 123.36C363.31 121.427 361.743 119.86 359.81 119.86C357.877 119.86 356.31 121.427 356.31 123.36C356.31 125.293 357.877 126.86 359.81 126.86Z"
				fill="#767676"
			/>
			<path
				d="M238.81 71.8601H222.81V74.6601H238.81V71.8601Z"
				fill="white"
			/>
			<path
				d="M222.81 75.3602C222.81 78.3602 226.81 78.3602 226.81 78.3602H233.81C237.81 78.3602 238.81 74.7602 238.81 74.7602V74.6602H222.81V75.3602Z"
				fill="#767676"
			/>
			<path
				d="M238.81 71.3601C238.81 71.3601 238.81 70.3601 230.81 70.3601C222.81 70.3601 222.81 71.3601 222.81 71.3601V71.8601H238.81V71.3601Z"
				fill="#767676"
			/>
			<path
				d="M334.81 68.8601H318.81V71.6601H334.81V68.8601Z"
				fill="white"
			/>
			<path
				d="M318.81 72.3602C318.81 75.3602 322.81 75.3602 322.81 75.3602H329.81C333.81 75.3602 334.81 71.7602 334.81 71.7602V71.6602H318.81V72.3602Z"
				fill="#767676"
			/>
			<path
				d="M334.81 68.3601C334.81 68.3601 334.81 67.3601 326.81 67.3601C318.81 67.3601 318.81 68.3601 318.81 68.3601V68.8601H334.81V68.3601Z"
				fill="#767676"
			/>
			<path
				d="M69.0102 105.86C41.8102 106.06 27.7102 107.26 22.0102 107.56C21.1102 111.46 21.0102 114.16 21.0102 114.16L54.8102 112.66L54.0102 115.46L53.3102 117.86L52.2102 121.86L51.8102 123.46C51.8102 123.46 51.3102 125.76 44.8102 126.16C40.8102 126.36 32.7102 126.96 27.1102 127.36C23.6102 127.66 21.0102 127.86 21.0102 127.86C21.0102 127.86 20.0102 122.56 20.7102 116.66C20.8102 115.86 20.9102 115.06 21.0102 114.26C21.0102 114.26 21.1102 111.46 22.0102 107.66C21.5102 107.66 21.3102 107.76 21.3102 107.76C21.3102 107.76 21.5102 107.76 22.0102 107.66C23.3102 102.06 26.2102 94.0601 32.9102 88.7601C35.7102 85.3601 39.6102 81.8601 39.6102 81.8601C39.6102 81.8601 44.6102 80.5601 53.4102 78.7601C72.7102 73.0601 144.81 62.3601 144.81 62.3601L145.31 61.8601C141.31 60.8601 138.91 60.0601 138.91 60.0601H130.91C130.91 60.0601 42.9102 67.0601 29.9102 82.0601L25.4102 89.5601L22.4102 97.5601L20.2102 102.26C20.2102 102.26 17.5102 108.26 18.4102 115.56L19.4102 124.56H17.4102L17.7102 128.36C17.7102 128.56 17.8102 128.76 17.8102 128.86H33.3102C37.8102 128.86 41.7102 132.26 43.4102 133.96H44.0102L65.2102 135.96V118.16C65.3102 117.96 65.9102 112.46 69.0102 105.86Z"
				fill="#EDEDED"
			/>
			<path
				d="M39.6102 81.8602H65.2102C65.2102 81.8602 69.2102 81.8602 67.2102 83.8602C66.4102 84.6602 65.4102 86.2602 64.4102 87.9602C62.7102 90.7602 61.2102 93.8602 61.2102 93.8602C47.7102 93.8602 40.3102 94.3602 36.5102 94.7602C34.1102 94.9602 33.2102 95.1602 33.2102 95.1602C33.2102 95.1602 30.2102 96.7602 30.2102 93.4602C30.2102 92.6602 30.7102 91.5602 31.5102 90.3602C31.9102 89.7602 32.3102 89.2602 32.8102 88.6602C26.1102 93.9602 23.2102 101.86 21.9102 107.56C27.5102 107.16 41.7102 105.96 68.9102 105.86C72.6102 98.0602 79.6102 88.6602 93.2102 84.8602C118.21 77.8602 134.21 96.8602 134.21 96.8602C134.21 96.8602 141.61 104.16 144.41 118.06V122.36H156.51C154.41 113.36 151.61 95.6602 153.91 72.2602C153.91 72.2602 154.71 67.9602 157.31 65.4602C110.51 66.5602 74.5102 74.3602 53.4102 78.7602C44.7102 80.5602 39.7102 81.7602 39.6102 81.8602Z"
				fill="white"
			/>
			<path
				d="M299.31 125.86C284.31 126.86 158.31 128.86 158.31 128.86C158.31 128.86 157.51 126.56 156.51 122.36H144.51V136.06L322.11 133.96L323.71 115.36H315.51C313.31 119.46 308.61 125.26 299.31 125.86Z"
				fill="#D1D1D1"
			/>
			<path
				d="M414.31 77.8602C414.31 77.8602 411.31 68.8602 410.31 66.8602C410.31 66.8602 413.91 65.4602 343.61 64.1602C343.21 65.2602 342.61 66.7602 341.41 68.5602C337.31 74.8602 317.31 110.86 317.31 110.86C317.31 110.86 316.91 112.86 315.61 115.36H323.81L323.91 114.06C323.91 114.06 329.51 87.0602 361.71 86.0602C361.71 86.0602 379.81 87.2601 390.51 102.06C397.31 101.16 395.01 99.7602 405.41 97.9602C422.41 94.9602 414.91 79.1602 414.91 79.1602C414.31 78.4602 414.31 77.8602 414.31 77.8602Z"
				fill="white"
			/>
			<path
				d="M143.31 122.26H144.51V117.96C141.71 104.16 134.31 96.7601 134.31 96.7601C134.31 96.7601 118.31 77.7601 93.3101 84.7601C79.8101 88.5601 72.7101 97.9601 69.0101 105.76C65.9101 112.36 65.3101 117.86 65.3101 117.86V135.66L67.9101 135.86V118.96C67.9101 118.96 68.3101 112.76 72.0101 105.66C76.5101 96.9601 86.0101 86.9601 105.91 85.8601C105.91 85.8601 136.91 86.8601 141.91 118.86V135.86H144.51V122.16L143.31 122.26Z"
				fill="#989898"
			/>
			<path
				d="M53.4102 78.7601C74.5102 74.3601 110.51 66.5601 157.31 65.4601C158.11 64.6601 159.11 64.1601 160.21 63.8601C160.21 63.8601 179.21 22.8601 255.21 22.8601C337.21 22.8601 378.61 51.8601 378.61 51.8601C387.01 55.8601 380.61 56.0601 380.61 56.0601H427.51C427.31 55.9601 427.21 55.8601 427.21 55.8601L390.21 51.8601C370.61 36.3601 338.21 27.8601 338.21 27.8601C338.21 27.8601 306.21 19.8601 262.21 18.8601C218.21 17.8601 210.21 27.8601 210.21 27.8601C210.21 27.8601 182.91 35.5601 152.21 60.8601C152.21 60.8601 149.21 62.8601 145.21 61.8601L144.71 62.3601C144.81 62.3601 72.7102 73.0601 53.4102 78.7601Z"
				fill="#E1E1E1"
			/>
			<path
				d="M400.21 121.36V117.06C397.41 103.26 390.01 95.8602 390.01 95.8602C390.01 95.8602 374.01 76.8602 349.01 83.8602C335.51 87.6602 328.41 97.0602 324.71 104.86C321.61 111.46 321.01 116.96 321.01 116.96V134.76L323.61 134.96V118.06C323.61 118.06 324.01 111.86 327.71 104.76C332.21 96.0602 341.71 86.0602 361.61 84.9602C361.61 84.9602 392.61 85.9602 397.61 117.96V130.96H400.21V121.36Z"
				fill="#989898"
			/>
			<path
				d="M411.31 64.7601C411.31 64.7601 409.21 64.7601 410.21 66.7601C411.21 68.7601 414.21 77.7601 414.21 77.7601C414.21 77.7601 414.21 79.7601 417.21 79.7601C419.61 79.7601 434.01 80.4601 439.31 80.6601C439.11 79.7601 438.91 78.7601 438.71 77.6601L424.81 77.1601L424.41 73.5601H438.31C437.91 69.5601 436.61 66.1601 434.91 63.5601L411.31 64.7601Z"
				fill="#DC0000"
				stroke="black"
				strokeWidth={0.5}
				strokeMiterlimit={10}
			/>
			<path
				d="M65.2998 81.26H39.5998C39.5998 81.26 34.0998 86.06 31.4998 89.86C31.5298 89.86 30.0998 92.26 30.1998 93.06C30.1998 96.36 33.1998 94.76 33.1998 94.76C33.1998 94.76 34.0998 94.56 36.4998 94.36C36.5198 94.29 47.7898 93.26 61.0998 93.26C61.0998 93.26 61.8998 91.6 62.9998 89.62C63.2498 89.46 63.4198 89.05 63.5698 88.61C63.8098 88.19 64.0498 87.77 64.3098 87.36C64.2498 87.36 64.3598 87.16 64.5098 87.16C65.5098 85.46 66.5098 83.86 67.3098 83.06C69.2098 81.26 65.2098 81.26 65.3098 81.26H65.2998Z"
				stroke="#231F20"
				strokeWidth={0.5}
				strokeMiterlimit={10}
			/>
			<path
				d="M153.9 71.76C151.7 95.06 154.5 112.66 156.5 121.66C156.47 121.66 156.43 121.66 156.4 121.66C157.4 125.96 158.2 128.26 158.2 128.26C158.2 128.26 198.9 127.46 244.5 126.66L242.8 119.66C242.7 119.26 242.7 118.86 242.6 118.56C242.63 118.56 242.67 118.56 242.7 118.56C240.7 102.56 244.7 71.16 245.2 67.46L153.9 71.76Z"
				stroke="#231F20"
				strokeWidth={0.5}
				strokeMiterlimit={10}
			/>
			<path
				d="M160.2 63.16C155.2 64.16 153.8 71.56 153.8 71.56V71.76L245.1 67.46C245.2 67.26 245.2 67.16 245.2 67.06L247.9 59.76L160.2 63.16Z"
				stroke="#231F20"
				strokeWidth={0.5}
				strokeMiterlimit={10}
			/>
			<path
				d="M343.4 60.0599L341.3 56.6599L247.9 59.7599L245.2 67.0599V67.4599L343.6 63.4599C344.4 60.8599 343.4 60.0599 343.4 60.0599Z"
				stroke="#231F20"
				strokeWidth={0.5}
				strokeMiterlimit={10}
			/>
			<path
				d="M242.6 118.55C242.6 118.55 242.53 118.55 242.5 118.55C242.7 118.95 242.7 119.25 242.8 119.65L244.5 126.65C271 126.15 293.7 125.65 299.2 125.25C309.19 124.55 313.79 118.16 315.8 113.96C315.83 113.96 315.87 113.96 315.9 113.96C316.9 111.86 317.3 110.26 317.3 110.26C317.3 110.26 337.3 74.2598 341.4 67.9598C342.6 66.0598 343.3 64.6598 343.6 63.5598L245.2 67.5598C244.7 71.1598 240.6 102.55 242.6 118.56V118.55Z"
				stroke="#231F20"
				strokeWidth={0.5}
				strokeMiterlimit={10}
			/>
			<path
				d="M22.81 96.7202L25.81 88.7202H22.81C22.81 88.7202 19.81 91.7202 20.81 96.7202H22.81Z"
				fill="white"
				stroke="black"
				strokeMiterlimit={10}
			/>
			<path
				d="M450.21 96.2201L443.81 89.0201L443.91 89.1201V81.2201C443.81 81.1201 440.11 64.6201 440.11 64.6201H440.06L442.31 64.3201C442.31 64.3201 438.11 54.3201 435.11 52.6201M435.11 52.6201C433.93 52.8801 433.93 52.8801 435.11 52.6201ZM435.11 52.6201C434.91 52.3201 434.61 52.2201 434.31 52.3201L400.38 49.3301H400.41C400.41 49.3301 384.41 34.0201 342.81 23.5201C319.91 17.8201 293.11 13.5201 265.41 14.3201C226.81 15.3201 222.81 18.0201 205.01 24.6201C186.81 31.3201 170.81 42.1201 139.41 59.3201H131.41C131.41 59.3201 43.4102 66.3201 30.4102 81.3201L25.9102 88.8201L22.9102 96.8201L20.7102 101.52C20.7102 101.52 18.0102 107.52 18.9102 114.82L19.9102 123.82H17.9102L18.2102 127.62C18.2102 127.82 18.3102 128.02 18.3102 128.12C18.5102 128.52 18.8102 128.82 19.2102 129.02L29.8102 133.82L43.8102 133.22H44.5102L65.7102 135.22L65.8102 134.92L68.4102 135.12V133.12H75.5502C79.9202 145.91 92.0302 155.12 106.31 155.12C120.59 155.12 132.7 145.91 137.07 133.12H142.41V135.12H145.01V135.32L321.51 133.23V134.02L324.11 134.22V132.44L329.26 132.25C333.36 145.5 345.71 155.12 360.31 155.12C375.74 155.12 388.66 144.36 391.97 129.93L397.31 129.73V130.52L398.89 130.22H400.71V129.88L433.21 123.72C433.21 123.72 446.41 124.32 448.81 110.32L450.31 96.3201"
				stroke="black"
				strokeWidth={2}
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M434.96 53.3601C434.76 53.0601 434.46 52.9601 434.16 53.0601L400.16 50.0601L390.56 51.8601L427.53 55.8601C429.05 56.9001 433.29 60.1801 435.86 64.8601L439.86 65.3601L442.16 65.0601C442.16 65.0601 437.96 55.0601 434.96 53.3601Z"
				fill="white"
				stroke="black"
				strokeWidth={0.5}
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<rect
				width={155.907}
				height={56.6933}
				transform="translate(0 1)"
				fill="white"
				fillOpacity={0.01}
				className="zone"
				onClick={() =>
					props.handlePathClick &&
					props.handlePathClick(
						10,
						t("Zone10", "Forparti fører-side top")
					)
				}
			/>
			{props.clickedZones.find((path) => path.zoneId === 10) && (
				<g filter="url(#filter0_d_1811_2713)">
					<rect
						x={62.9531}
						y={14.3467}
						width={30}
						height={30}
						rx={15}
						fill="#3c7749"
					/>
					<rect
						x={61.9531}
						y={13.3467}
						width={32}
						height={32}
						rx={16}
						stroke="white"
						strokeWidth={2}
					/>
					<path
						d="M85.9531 23.3467L74.9531 34.3467L69.9531 29.3467"
						stroke="white"
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
				</g>
			)}
			<rect
				width={155.907}
				height={56.6933}
				transform="translate(155.907 1)"
				fill="white"
				fillOpacity={0.01}
				className="zone"
				onClick={() =>
					props.handlePathClick &&
					props.handlePathClick(
						11,
						t("Zone11", "Midt fører-side top")
					)
				}
			/>
			{props.clickedZones.find((path) => path.zoneId === 11) && (
				<g filter="url(#filter1_d_1811_2713)">
					<rect
						x={218.86}
						y={14.3467}
						width={30}
						height={30}
						rx={15}
						fill="#3c7749"
					/>
					<rect
						x={217.86}
						y={13.3467}
						width={32}
						height={32}
						rx={16}
						stroke="white"
						strokeWidth={2}
					/>
					<path
						d="M241.86 23.3467L230.86 34.3467L225.86 29.3467"
						stroke="white"
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
				</g>
			)}
			<rect
				width={155.907}
				height={56.6933}
				transform="translate(311.813 1)"
				fill="white"
				fillOpacity={0.01}
				className="zone"
				onClick={() =>
					props.handlePathClick &&
					props.handlePathClick(
						12,
						t("Zone12", "Bagparti fører-side top")
					)
				}
			/>
			{props.clickedZones.find((path) => path.zoneId === 12) && (
				<g filter="url(#filter2_d_1811_2713)">
					<rect
						x={374.767}
						y={14.3467}
						width={30}
						height={30}
						rx={15}
						fill="#3c7749"
					/>
					<rect
						x={373.767}
						y={13.3467}
						width={32}
						height={32}
						rx={16}
						stroke="white"
						strokeWidth={2}
					/>
					<path
						d="M397.767 23.3467L386.767 34.3467L381.767 29.3467"
						stroke="white"
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
				</g>
			)}
			<rect
				width={155.907}
				height={56.6933}
				transform="translate(0 57.6934)"
				fill="white"
				fillOpacity={0.01}
				className="zone"
				onClick={() =>
					props.handlePathClick &&
					props.handlePathClick(
						13,
						t("Zone13", "Forparti fører-side midt")
					)
				}
			/>
			{props.clickedZones.find((path) => path.zoneId === 13) && (
				<g filter="url(#filter3_d_1811_2713)">
					<rect
						x={62.9531}
						y={71.04}
						width={30}
						height={30}
						rx={15}
						fill="#3c7749"
					/>
					<rect
						x={61.9531}
						y={70.04}
						width={32}
						height={32}
						rx={16}
						stroke="white"
						strokeWidth={2}
					/>
					<path
						d="M85.9531 80.04L74.9531 91.04L69.9531 86.04"
						stroke="white"
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
				</g>
			)}
			<rect
				width={155.907}
				height={56.6933}
				transform="translate(155.907 57.6934)"
				fill="white"
				fillOpacity={0.01}
				className="zone"
				onClick={() =>
					props.handlePathClick &&
					props.handlePathClick(
						14,
						t("Zone14", "Midt fører-side midt")
					)
				}
			/>
			{props.clickedZones.find((path) => path.zoneId === 14) && (
				<g filter="url(#filter4_d_1811_2713)">
					<rect
						x={218.86}
						y={71.04}
						width={30}
						height={30}
						rx={15}
						fill="#3c7749"
					/>
					<rect
						x={217.86}
						y={70.04}
						width={32}
						height={32}
						rx={16}
						stroke="white"
						strokeWidth={2}
					/>
					<path
						d="M241.86 80.04L230.86 91.04L225.86 86.04"
						stroke="white"
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
				</g>
			)}
			<rect
				width={155.907}
				height={56.6933}
				transform="translate(311.813 57.6934)"
				fill="white"
				fillOpacity={0.01}
				className="zone"
				onClick={() =>
					props.handlePathClick &&
					props.handlePathClick(
						15,
						t("Zone15", "Bagparti fører-side midt")
					)
				}
			/>
			{props.clickedZones.find((path) => path.zoneId === 15) && (
				<g filter="url(#filter5_d_1811_2713)">
					<rect
						x={374.767}
						y={71.04}
						width={30}
						height={30}
						rx={15}
						fill="#3c7749"
					/>
					<rect
						x={373.767}
						y={70.04}
						width={32}
						height={32}
						rx={16}
						stroke="white"
						strokeWidth={2}
					/>
					<path
						d="M397.767 80.04L386.767 91.04L381.767 86.04"
						stroke="white"
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
				</g>
			)}
			<rect
				width={155.907}
				height={56.6933}
				transform="translate(0 114.387)"
				fill="white"
				fillOpacity={0.01}
				className="zone"
				onClick={() =>
					props.handlePathClick &&
					props.handlePathClick(
						16,
						t("Zone16", "Forparti fører-side bund")
					)
				}
			/>
			{props.clickedZones.find((path) => path.zoneId === 16) && (
				<g filter="url(#filter6_d_1811_2713)">
					<rect
						x={62.9531}
						y={127.733}
						width={30}
						height={30}
						rx={15}
						fill="#3c7749"
					/>
					<rect
						x={61.9531}
						y={126.733}
						width={32}
						height={32}
						rx={16}
						stroke="white"
						strokeWidth={2}
					/>
					<path
						d="M85.9531 136.733L74.9531 147.733L69.9531 142.733"
						stroke="white"
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
				</g>
			)}
			<rect
				width={155.907}
				height={56.6933}
				transform="translate(155.907 114.387)"
				fill="white"
				fillOpacity={0.01}
				className="zone"
				onClick={() =>
					props.handlePathClick &&
					props.handlePathClick(
						17,
						t("Zone17", "Midt fører-side bund")
					)
				}
			/>
			{props.clickedZones.find((path) => path.zoneId === 17) && (
				<g filter="url(#filter7_d_1811_2713)">
					<rect
						x={218.86}
						y={127.733}
						width={30}
						height={30}
						rx={15}
						fill="#3c7749"
					/>
					<rect
						x={217.86}
						y={126.733}
						width={32}
						height={32}
						rx={16}
						stroke="white"
						strokeWidth={2}
					/>
					<path
						d="M241.86 136.733L230.86 147.733L225.86 142.733"
						stroke="white"
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
				</g>
			)}
			<rect
				width={155.907}
				height={56.6933}
				transform="translate(311.813 114.387)"
				fill="white"
				fillOpacity={0.01}
				className="zone"
				onClick={() =>
					props.handlePathClick &&
					props.handlePathClick(
						18,
						t("Zone18", "Bagparti fører-side bund")
					)
				}
			/>
			{props.clickedZones.find((path) => path.zoneId === 18) && (
				<g filter="url(#filter8_d_1811_2713)">
					<rect
						x={374.767}
						y={127.733}
						width={30}
						height={30}
						rx={15}
						fill="#3c7749"
					/>
					<rect
						x={373.767}
						y={126.733}
						width={32}
						height={32}
						rx={16}
						stroke="white"
						strokeWidth={2}
					/>
					<path
						d="M397.767 136.733L386.767 147.733L381.767 142.733"
						stroke="white"
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
				</g>
			)}
		</g>
		<defs>
			<filter
				id="filter0_d_1811_2713"
				x={40.9531}
				y={-5.65332}
				width={74}
				height={74}
				filterUnits="userSpaceOnUse"
				colorInterpolationFilters="sRGB"
			>
				<feFlood floodOpacity={0} result="BackgroundImageFix" />
				<feColorMatrix
					in="SourceAlpha"
					type="matrix"
					values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
					result="hardAlpha"
				/>
				<feOffset dy={2} />
				<feGaussianBlur stdDeviation={10} />
				<feComposite in2="hardAlpha" operator="out" />
				<feColorMatrix
					type="matrix"
					values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
				/>
				<feBlend
					mode="normal"
					in2="BackgroundImageFix"
					result="effect1_dropShadow_1811_2713"
				/>
				<feBlend
					mode="normal"
					in="SourceGraphic"
					in2="effect1_dropShadow_1811_2713"
					result="shape"
				/>
			</filter>
			<filter
				id="filter1_d_1811_2713"
				x={196.86}
				y={-5.65332}
				width={74}
				height={74}
				filterUnits="userSpaceOnUse"
				colorInterpolationFilters="sRGB"
			>
				<feFlood floodOpacity={0} result="BackgroundImageFix" />
				<feColorMatrix
					in="SourceAlpha"
					type="matrix"
					values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
					result="hardAlpha"
				/>
				<feOffset dy={2} />
				<feGaussianBlur stdDeviation={10} />
				<feComposite in2="hardAlpha" operator="out" />
				<feColorMatrix
					type="matrix"
					values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
				/>
				<feBlend
					mode="normal"
					in2="BackgroundImageFix"
					result="effect1_dropShadow_1811_2713"
				/>
				<feBlend
					mode="normal"
					in="SourceGraphic"
					in2="effect1_dropShadow_1811_2713"
					result="shape"
				/>
			</filter>
			<filter
				id="filter2_d_1811_2713"
				x={352.767}
				y={-5.65332}
				width={74}
				height={74}
				filterUnits="userSpaceOnUse"
				colorInterpolationFilters="sRGB"
			>
				<feFlood floodOpacity={0} result="BackgroundImageFix" />
				<feColorMatrix
					in="SourceAlpha"
					type="matrix"
					values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
					result="hardAlpha"
				/>
				<feOffset dy={2} />
				<feGaussianBlur stdDeviation={10} />
				<feComposite in2="hardAlpha" operator="out" />
				<feColorMatrix
					type="matrix"
					values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
				/>
				<feBlend
					mode="normal"
					in2="BackgroundImageFix"
					result="effect1_dropShadow_1811_2713"
				/>
				<feBlend
					mode="normal"
					in="SourceGraphic"
					in2="effect1_dropShadow_1811_2713"
					result="shape"
				/>
			</filter>
			<filter
				id="filter3_d_1811_2713"
				x={40.9531}
				y={51.04}
				width={74}
				height={74}
				filterUnits="userSpaceOnUse"
				colorInterpolationFilters="sRGB"
			>
				<feFlood floodOpacity={0} result="BackgroundImageFix" />
				<feColorMatrix
					in="SourceAlpha"
					type="matrix"
					values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
					result="hardAlpha"
				/>
				<feOffset dy={2} />
				<feGaussianBlur stdDeviation={10} />
				<feComposite in2="hardAlpha" operator="out" />
				<feColorMatrix
					type="matrix"
					values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
				/>
				<feBlend
					mode="normal"
					in2="BackgroundImageFix"
					result="effect1_dropShadow_1811_2713"
				/>
				<feBlend
					mode="normal"
					in="SourceGraphic"
					in2="effect1_dropShadow_1811_2713"
					result="shape"
				/>
			</filter>
			<filter
				id="filter4_d_1811_2713"
				x={196.86}
				y={51.04}
				width={74}
				height={74}
				filterUnits="userSpaceOnUse"
				colorInterpolationFilters="sRGB"
			>
				<feFlood floodOpacity={0} result="BackgroundImageFix" />
				<feColorMatrix
					in="SourceAlpha"
					type="matrix"
					values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
					result="hardAlpha"
				/>
				<feOffset dy={2} />
				<feGaussianBlur stdDeviation={10} />
				<feComposite in2="hardAlpha" operator="out" />
				<feColorMatrix
					type="matrix"
					values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
				/>
				<feBlend
					mode="normal"
					in2="BackgroundImageFix"
					result="effect1_dropShadow_1811_2713"
				/>
				<feBlend
					mode="normal"
					in="SourceGraphic"
					in2="effect1_dropShadow_1811_2713"
					result="shape"
				/>
			</filter>
			<filter
				id="filter5_d_1811_2713"
				x={352.767}
				y={51.04}
				width={74}
				height={74}
				filterUnits="userSpaceOnUse"
				colorInterpolationFilters="sRGB"
			>
				<feFlood floodOpacity={0} result="BackgroundImageFix" />
				<feColorMatrix
					in="SourceAlpha"
					type="matrix"
					values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
					result="hardAlpha"
				/>
				<feOffset dy={2} />
				<feGaussianBlur stdDeviation={10} />
				<feComposite in2="hardAlpha" operator="out" />
				<feColorMatrix
					type="matrix"
					values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
				/>
				<feBlend
					mode="normal"
					in2="BackgroundImageFix"
					result="effect1_dropShadow_1811_2713"
				/>
				<feBlend
					mode="normal"
					in="SourceGraphic"
					in2="effect1_dropShadow_1811_2713"
					result="shape"
				/>
			</filter>
			<filter
				id="filter6_d_1811_2713"
				x={40.9531}
				y={107.733}
				width={74}
				height={74}
				filterUnits="userSpaceOnUse"
				colorInterpolationFilters="sRGB"
			>
				<feFlood floodOpacity={0} result="BackgroundImageFix" />
				<feColorMatrix
					in="SourceAlpha"
					type="matrix"
					values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
					result="hardAlpha"
				/>
				<feOffset dy={2} />
				<feGaussianBlur stdDeviation={10} />
				<feComposite in2="hardAlpha" operator="out" />
				<feColorMatrix
					type="matrix"
					values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
				/>
				<feBlend
					mode="normal"
					in2="BackgroundImageFix"
					result="effect1_dropShadow_1811_2713"
				/>
				<feBlend
					mode="normal"
					in="SourceGraphic"
					in2="effect1_dropShadow_1811_2713"
					result="shape"
				/>
			</filter>
			<filter
				id="filter7_d_1811_2713"
				x={196.86}
				y={107.733}
				width={74}
				height={74}
				filterUnits="userSpaceOnUse"
				colorInterpolationFilters="sRGB"
			>
				<feFlood floodOpacity={0} result="BackgroundImageFix" />
				<feColorMatrix
					in="SourceAlpha"
					type="matrix"
					values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
					result="hardAlpha"
				/>
				<feOffset dy={2} />
				<feGaussianBlur stdDeviation={10} />
				<feComposite in2="hardAlpha" operator="out" />
				<feColorMatrix
					type="matrix"
					values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
				/>
				<feBlend
					mode="normal"
					in2="BackgroundImageFix"
					result="effect1_dropShadow_1811_2713"
				/>
				<feBlend
					mode="normal"
					in="SourceGraphic"
					in2="effect1_dropShadow_1811_2713"
					result="shape"
				/>
			</filter>
			<filter
				id="filter8_d_1811_2713"
				x={352.767}
				y={107.733}
				width={74}
				height={74}
				filterUnits="userSpaceOnUse"
				colorInterpolationFilters="sRGB"
			>
				<feFlood floodOpacity={0} result="BackgroundImageFix" />
				<feColorMatrix
					in="SourceAlpha"
					type="matrix"
					values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
					result="hardAlpha"
				/>
				<feOffset dy={2} />
				<feGaussianBlur stdDeviation={10} />
				<feComposite in2="hardAlpha" operator="out" />
				<feColorMatrix
					type="matrix"
					values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
				/>
				<feBlend
					mode="normal"
					in2="BackgroundImageFix"
					result="effect1_dropShadow_1811_2713"
				/>
				<feBlend
					mode="normal"
					in="SourceGraphic"
					in2="effect1_dropShadow_1811_2713"
					result="shape"
				/>
			</filter>
			<linearGradient
				id="paint0_linear_1811_2713"
				x1={156.41}
				y1={124}
				x2={244.51}
				y2={124}
				gradientUnits="userSpaceOnUse"
			>
				<stop offset={0.76} stopColor="#EDEDED" />
				<stop offset={1} stopColor="#CCCCCC" />
			</linearGradient>
			<linearGradient
				id="paint1_linear_1811_2713"
				x1={153.1}
				y1={95.1501}
				x2={245.21}
				y2={95.1501}
				gradientUnits="userSpaceOnUse"
			>
				<stop offset={0.76} stopColor="white" />
				<stop offset={1} stopColor="#E1E1E1" />
			</linearGradient>
			<linearGradient
				id="paint2_linear_1811_2713"
				x1={153.811}
				y1={66.3501}
				x2={247.911}
				y2={66.3501}
				gradientUnits="userSpaceOnUse"
			>
				<stop offset={0.76} stopColor="#EDEDED" />
				<stop offset={1} stopColor="#CCCCCC" />
			</linearGradient>
			<linearGradient
				id="paint3_linear_1811_2713"
				x1={245.21}
				y1={62.65}
				x2={343.92}
				y2={62.65}
				gradientUnits="userSpaceOnUse"
			>
				<stop offset={0.76} stopColor="#EDEDED" />
				<stop offset={1} stopColor="#CCCCCC" />
			</linearGradient>
			<linearGradient
				id="paint4_linear_1811_2713"
				x1={242.66}
				y1={120.9}
				x2={315.96}
				y2={120.9}
				gradientUnits="userSpaceOnUse"
			>
				<stop offset={0.76} stopColor="#EDEDED" />
				<stop offset={1} stopColor="#CCCCCC" />
			</linearGradient>
			<linearGradient
				id="paint5_linear_1811_2713"
				x1={242.061}
				y1={91.6499}
				x2={343.611}
				y2={91.6499}
				gradientUnits="userSpaceOnUse"
			>
				<stop offset={0.76} stopColor="white" />
				<stop offset={1} stopColor="#E1E1E1" />
			</linearGradient>
			<clipPath id="clip0_1811_2713">
				<rect width={467.72} height={170.08} fill="white" />
			</clipPath>
		</defs>
	</svg>
);
export default SvgCarSideLeft;
