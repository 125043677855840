import { useTranslation } from "react-i18next";
import {
    Button,
    Card,
    CardBody,
    CardText,
    Col,
    Form,
    FormGroup,
    Input,
    Label,
    Row
} from "reactstrap";

import { AppContext } from "AppContext";
import { useContext, useEffect, useState } from "react";
import Datetime from "react-datetime";
import moment from "moment";
import FileUpload from "../../FileUpload";
import { Field } from "formik";
import IncidentMap from "../../IncidentMap";
import SelectVehicle from "../../SelectVehicle";
import { useApi } from "components/useApi3";
import ImageSelector from "../../ImageSelector/ImageSelector";
import checkIcon from "assets/Marker2.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle } from "@fortawesome/pro-light-svg-icons";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import AutoSelectLocationButton from "./AutoSelectLocationButton";

var today = moment();
var valid = function (current) {
    return current.isBefore(today);
};

function isJson(str) {
    try {
        return JSON.parse(str) && !!str;
    } catch (e) {
        return false;
    }
}

function getValue(component, value, dataType) {
    if (component === "Upload") {
        return value !== null && isJson(value) ? JSON.parse(value) : [];
    } else if (dataType === 5) {
        return null;
    }
    return value;
}
export const Component = (props) => {
    const appContext = useContext(AppContext);

    const [dateTimePickerLang, setDateTimePickerLang] = useState("");
    const { t, i18n } = useTranslation();

    useEffect(() => {
        const languageParts = i18n.language.split("-");
        const mainLanguage = languageParts[0];
        setDateTimePickerLang(mainLanguage);
    }, [i18n.language]);

    const {
        index,
        setFieldValue,
        handleChange,
        handleBlur,
        errors,
        field,
        formGroup,
        values,
        options,
        arrayHelpers,
        clickedZones,
        setClickedZones,
        setShowValidationModal,
        showValidationModal,
        validateContent,
        setValidateContent,
        addAnswerResult,
        handleSubmit
    } = props;

    const [vehiclesResult, vehiclesApi] = useApi();
    const [value, setValue] = useState(
        getValue(field.component, values[field.id], field.dataType)
    );
    const [dateValue, setDateValue] = useState(moment().toDate());
    const [timeValue, setTimeValue] = useState(moment().toDate());

    useEffect(() => {
        if (field.component === "VehiclePicker") {
            vehiclesApi.get("api/system/vehicles/select");
        }
    }, [vehiclesApi, field]);

    useEffect(() => {
        if (field.component === "Select" && value !== null) {
            const previousSelectedValue = field?.options.find(
                (option) => option.labelKey === value
            );

            if (previousSelectedValue) {
                appContext.setFormSettings((prevState) => ({
                    ...prevState,
                    nextQuestionId: previousSelectedValue.nextQuestionId
                }));
            }
        }
    }, [vehiclesApi, field]);

    useEffect(() => {
        if (
            field.component === "IncidentDateTimePicker" &&
            field.value !== null &&
            moment(field.value).isValid()
        ) {
            setDateValue(moment(field.value).format("YYYY-MM-DD"));
            setTimeValue(moment(field.value).format("HH:mm"));
        }
    }, [vehiclesApi, field]);

    switch (field?.component) {
        case "Button":
            return (
                <div className="mb-2 mb-md-4 form-group">
                    <p className="small">
                        {t(field.helpTextLabelKey, field.helpTextTranslateDK)}
                    </p>
                    <Button
                        className="mb-0 w-100"
                        color="black"
                        type="submit"
                        onClick={() => {
                            appContext.setFormSettings((prevState) => {
                                return {
                                    ...prevState,
                                    nextQuestionId: field.nextFormGroupId
                                };
                            });
                            setFieldValue(formGroup.id, field.labelKey);
                        }}
                    >
                        {t(field.labelKey, field.labelKey)}
                    </Button>
                </div>
            );
        case "SkipButton":
            return (
                <div className="mb-2 mb-md-4 form-group">
                    <Button
                        className="mb-0 w-100"
                        color="black"
                        type="submit"
                        onClick={(e) => {
                            if (formGroup.isFieldArray) {
                                const emptyValue = [{ [formGroup.id]: null }];
                                setFieldValue(formGroup.id, emptyValue);
                            } else {
                                const uploadField = formGroup?.fields?.find(
                                    (x) => x.component === "Upload"
                                );

                                if (uploadField !== undefined) {
                                    setFieldValue(uploadField?.id, "[]");
                                } else {
                                    formGroup.fields
                                        .filter(
                                            (x) =>
                                                x.component !== field.component
                                        )
                                        .map((y) => {
                                            return setFieldValue(y.id, "");
                                        });

                                    setFieldValue(
                                        formGroup.id,
                                        t(field.labelKey, "")
                                    );
                                }
                            }
                            appContext.setFormSettings((prevState) => {
                                return {
                                    ...prevState,
                                    nextQuestionId: field.nextFormGroupId
                                };
                            });
                        }}
                    >
                        {t(field.labelKey, field.labelKey)}
                    </Button>
                </div>
            );
        case "AutoSelectLocationButton":
            return (
                <AutoSelectLocationButton
                    handleSubmit={handleSubmit}
                    name={
                        formGroup.isFieldArray
                            ? `${formGroup.id}.${index}.${field.id}`
                            : `${field.id}`
                    }
                    setValue={setValue}
                    setFieldValue={setFieldValue}
                    setValidateContent={setValidateContent}
                    validateContent={validateContent}
                    showValidationModal={showValidationModal}
                    setShowValidationModal={setShowValidationModal}
                    formGroup={formGroup}
                    addAnswerResult={addAnswerResult}
                    field={field}
                />
            );
        case "AddButton":
            return (
                <FormGroup className="mb-4">
                    <Button
                        className="mb-2 w-100"
                        color="primary"
                        type="button"
                        onClick={() => {
                            let newItem = {};
                            formGroup.fields.map((field) => {
                                return (newItem[field.id] = "");
                            });
                            arrayHelpers.push(newItem);
                        }}
                    >
                        {t(field.labelKey, field.labelKey)}
                    </Button>
                </FormGroup>
            );
        case "Input":
            return (
                <>
                    <FormGroup className="mb-4">
                        <Label>
                            {t(
                                field.labelKey,
                                field.translateDK
                                    ? field.translateDK
                                    : field.labelKey
                            )}
                        </Label>
                        <p className="small">
                            {t(
                                field.helpTextLabelKey,
                                field.helpTextTranslateDK
                            )}
                        </p>
                        <Field
                            name={
                                formGroup.isFieldArray
                                    ? `${formGroup.id}.${index}.${field.id}`
                                    : `${field.id}`
                            }
                        >
                            {({
                                inputField,
                                form: { touched, errors },
                                meta
                            }) => (
                                <Input
                                    {...inputField}
                                    className={
                                        errors &&
                                        errors[
                                            formGroup.isFieldArray
                                                ? `${formGroup.id}.${index}.${field?.id}`
                                                : field.id
                                        ]
                                            ? "is-invalid"
                                            : ""
                                    }
                                    autoComplete="off"
                                    name={
                                        formGroup.isFieldArray
                                            ? `${formGroup.id}.${index}.${field?.id}`
                                            : field.id
                                    }
                                    defaultValue={
                                        formGroup.isFieldArray
                                            ? values[formGroup.id][index][
                                                  field.id
                                              ]
                                            : field.value
                                    }
                                    onChange={(e) => {
                                        handleChange(e);
                                        if (formGroup.isFieldArray) {
                                            setFieldValue(
                                                `intepretedValues.${index}.${field?.labelKey}`,
                                                e.target.value
                                            );
                                        }
                                    }}
                                    onBlur={handleBlur}
                                />
                            )}
                        </Field>
                    </FormGroup>
                </>
            );
        case "IncidentDateTimePicker":
            return (
                <>
                    <FormGroup className="mb-4">
                        <Datetime
                            locale={dateTimePickerLang}
                            isValidDate={valid}
                            input={false}
                            id={field.id}
                            className="incidentDate-datePicker"
                            onChange={(e) => {
                                setDateValue(moment(e).format("YYYY-MM-DD"));

                                const newDate = moment(
                                    moment(e).format("YYYY-MM-DD") +
                                        " " +
                                        moment(timeValue).format("HH:mm")
                                );
                                setFieldValue(field.id, newDate);
                                setFieldValue("incidentDate", newDate);
                                appContext.setFormSettings((prevState) => {
                                    return {
                                        ...prevState,
                                        nextQuestionId: field.nextFormGroupId
                                    };
                                });
                            }}
                            timeFormat={false}
                            inputProps={{
                                placeholder: t("date"),
                                className:
                                    errors && errors[field.id]
                                        ? "form-control is-invalid"
                                        : "form-control"
                            }}
                            value={moment(dateValue)}
                            initialValue={dateValue}
                        />

                        <Datetime
                            className="incidentDate-timePicker mt-3"
                            onChange={(e) => {
                                setTimeValue(e);
                                const newDate = new Date(
                                    dateValue + " " + moment(e).format("HH:mm")
                                );
                                setFieldValue(field.id, newDate);
                                setFieldValue("incidentDate", newDate);
                            }}
                            timeFormat={true}
                            dateFormat={false}
                            inputProps={{
                                placeholder: t("time"),
                                className:
                                    errors && errors[field.id]
                                        ? "form-control is-invalid"
                                        : "form-control"
                            }}
                            value={timeValue}
                            initialValue={timeValue}
                        />
                    </FormGroup>
                </>
            );
        case "DatePicker":
            return (
                <>
                    <FormGroup className="mb-4">
                        <Datetime
                            locale={dateTimePickerLang}
                            isValidDate={valid}
                            input={false}
                            id={field.id}
                            className="incidentDate-datePicker"
                            onChange={(e) => {
                                setDateValue(e);
                                setFieldValue(field.id, e);

                                appContext.setFormSettings((prevState) => {
                                    return {
                                        ...prevState,
                                        nextQuestionId: field.nextFormGroupId
                                    };
                                });
                            }}
                            timeFormat={false}
                            inputProps={{
                                placeholder: t("date"),
                                className:
                                    errors && errors[field.id]
                                        ? "form-control is-invalid"
                                        : "form-control"
                            }}
                            value={dateValue}
                            initialValue={dateValue}
                        />
                    </FormGroup>
                </>
            );
        case "Checkbox":
            return (
                <>
                    <FormGroup check>
                        <Label check>
                            <Input
                                type="checkbox"
                                name={field.labelKey}
                                checked={field.value}
                                onChange={(e) => {
                                    const isChecked = e.target.checked;
                                    setFieldValue(field.id, isChecked);
                                }}
                            />
                            {t(field.labelKey, field.translateDK)}
                        </Label>
                    </FormGroup>
                </>
            );
        case "MultiSelect":
            return (
                <>
                    <p>
                        {t(field.helpTextLabelKey, field.helpTextTranslateDK)}
                    </p>
                    <FormGroup className="multi-select">
                        {options &&
                            options?.map((option, index) => (
                                <Field
                                    name={
                                        formGroup.isFieldArray
                                            ? `${formGroup.id}.${index}.${field.id}`
                                            : `${field.id}`
                                    }
                                >
                                    {({
                                        inputField,
                                        form: { touched, errors },
                                        meta
                                    }) => {
                                        const parsedValue = values[field.id]
                                            ? JSON.parse(values[field.id])
                                            : [];
                                        const parsedNextQuestions = values[
                                            field.id
                                        ]
                                            ? JSON.parse(values[field.id]).map(
                                                  (x) => x.NextFormGroupId
                                              )
                                            : [];
                                        return (
                                            <FormGroup
                                                {...inputField}
                                                key={index}
                                                className={`multi-select__item d-flex align-items-center rounded-3 ${
                                                    parsedValue.length > 0 &&
                                                    parsedValue.findIndex(
                                                        (value) =>
                                                            value.LabelKey ===
                                                            option.labelKey
                                                    ) !== -1 &&
                                                    "border-success text-success"
                                                } `}
                                                onClick={(e) => {
                                                    let newValues = parsedValue;
                                                    let newQuestionsIds =
                                                        parsedNextQuestions
                                                            ? parsedNextQuestions
                                                            : [];
                                                    const removeItem =
                                                        newValues.findIndex(
                                                            (x) =>
                                                                x.LabelKey ===
                                                                option.labelKey
                                                        );
                                                    if (removeItem !== -1) {
                                                        newValues.splice(
                                                            removeItem,
                                                            1
                                                        );
                                                        newQuestionsIds.splice(
                                                            removeItem,
                                                            1
                                                        );
                                                    } else {
                                                        newValues.push({
                                                            LabelKey:
                                                                option.labelKey,
                                                            NextFormGroupId:
                                                                option.nextQuestionId,
                                                            OptionId: option.id
                                                        });
                                                        newQuestionsIds.push(
                                                            option.nextQuestionId
                                                        );
                                                    }

                                                    setFieldValue(
                                                        field.id,
                                                        JSON.stringify(
                                                            newValues
                                                        )
                                                    );

                                                    const selectedOptions =
                                                        options &&
                                                        newQuestionsIds.length >
                                                            0
                                                            ? options?.find(
                                                                  (x) =>
                                                                      newQuestionsIds?.find(
                                                                          (y) =>
                                                                              y ===
                                                                              x.nextQuestionId
                                                                      )
                                                              )
                                                            : null;

                                                    appContext.setFormSettings(
                                                        (prevState) => {
                                                            return {
                                                                ...prevState,
                                                                nextQuestionId:
                                                                    selectedOptions
                                                                        ? selectedOptions.nextQuestionId
                                                                        : formGroup.nextFormGroupId
                                                            };
                                                        }
                                                    );
                                                }}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                            >
                                                {parsedValue.length > 0 &&
                                                parsedValue.findIndex(
                                                    (value) =>
                                                        value.LabelKey ===
                                                        option.labelKey
                                                ) !== -1 ? (
                                                    <FontAwesomeIcon
                                                        icon={faCheckCircle}
                                                        className="multi-select__success-icon text-success"
                                                    />
                                                ) : (
                                                    <FontAwesomeIcon
                                                        icon={faCircle}
                                                        className="multi-select__default-icon text-black-50"
                                                    />
                                                )}
                                                <Label className="multi-select__label ms-2 mb-0">
                                                    {t(
                                                        option.labelKey,
                                                        option.translateDK
                                                    )}
                                                </Label>
                                                {errors &&
                                                errors[
                                                    formGroup.isFieldArray
                                                        ? `${formGroup.id}.${index}.${field?.id}`
                                                        : field.id
                                                ]
                                                    ? console.log(errors)
                                                    : ""}
                                            </FormGroup>
                                        );
                                    }}
                                </Field>
                            ))}
                    </FormGroup>
                </>
            );

        case "Select":
            return (
                <Form>
                    <FormGroup row className="mb-4">
                        <Col md={12} xs={12}>
                            <Input
                                type="select"
                                style={{ cursor: "pointer" }}
                                className={
                                    errors && errors[field.id]
                                        ? "is-invalid"
                                        : ""
                                }
                                id={field.id}
                                defaultValue={options.findIndex(
                                    (x) => x.labelKey === values[field.id]
                                )}
                                value={
                                    options
                                        ? options.findIndex(
                                              (x) =>
                                                  x.labelKey ===
                                                  values[field.id]
                                          )
                                        : null
                                }
                                onChange={(e) => {
                                    handleChange(e);

                                    setFieldValue(
                                        field.id,
                                        e.target.value !== ""
                                            ? options[e.target.value].labelKey
                                            : null
                                    );
                                    appContext.setFormSettings((prevState) => {
                                        return {
                                            ...prevState,
                                            nextQuestionId:
                                                options.length > 0
                                                    ? options[e.target.value]
                                                          .nextQuestionId
                                                    : null
                                        };
                                    });
                                }}
                                onBlur={handleBlur}
                            >
                                <option value="">
                                    -- {t(field.labelKey, field.translateDK)} --
                                </option>
                                {options &&
                                    options?.map((x, index) => (
                                        <option value={index} key={index}>
                                            {t(x.labelKey, x.translateDK)}
                                        </option>
                                    ))}
                            </Input>
                        </Col>
                    </FormGroup>
                </Form>
            );

        case "Text":
            return (
                <>
                    <FormGroup className="mb-4">
                        {t(field.labelKey, field.labelKey)}
                    </FormGroup>
                </>
            );
        case "LocationPicker":
            return (
                <>
                    <FormGroup inline={false}>
                        <Field
                            name={
                                formGroup.isFieldArray
                                    ? `${formGroup.id}.${index}.${field.id}`
                                    : `${field.id}`
                            }
                        >
                            {({
                                inputField,
                                form: { touched, errors },
                                meta
                            }) => (
                                <IncidentMap
                                    {...inputField}
                                    className={
                                        errors &&
                                        errors[
                                            formGroup.isFieldArray
                                                ? `${formGroup.id}.${index}.${field?.id}`
                                                : field.id
                                        ]
                                            ? "is-invalid"
                                            : ""
                                    }
                                    autoComplete="off"
                                    name={
                                        formGroup.isFieldArray
                                            ? `${formGroup.id}.${index}.${field?.id}`
                                            : field.id
                                    }
                                    value={
                                        formGroup.location
                                            ? formGroup.location
                                            : ""
                                    }
                                    values={values}
                                    field={field}
                                    formGroup={formGroup}
                                    setFieldValue={setFieldValue}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                />
                            )}
                        </Field>
                    </FormGroup>
                </>
            );
        case "ImageSelector":
            return (
                <>
                    <FormGroup inline={false}>
                        <Label>
                            {field.translateDK &&
                                t(field.labelKey, field.translateDK)}
                        </Label>
                        <Field
                            name={
                                formGroup.isFieldArray
                                    ? `${formGroup.id}.${index}.${field.id}`
                                    : `${field.id}`
                            }
                        >
                            {({
                                inputField,
                                form: { touched, errors },
                                meta
                            }) => (
                                <ImageSelector
                                    errors={errors}
                                    field={field}
                                    value={field.value}
                                    setFieldValue={setFieldValue}
                                    options={options}
                                    clickedZones={clickedZones}
                                    setClickedZones={setClickedZones}
                                />
                            )}
                        </Field>
                    </FormGroup>
                </>
            );
        case "ImageCard":
            return (
                <>
                    <FormGroup inline={false} className="mb-4">
                        <Row className="g-3 g-md-4">
                            {options &&
                                options?.map((x, index) => (
                                    <Col xs="12" md="6" className="image-card">
                                        <Card
                                            className={`${
                                                x.id.toString() ===
                                                    values[field.id] &&
                                                "border-success"
                                            } image-card__card p-2 p-md-3 bg-white rounded-3`}
                                            role="button"
                                            onClick={() => {
                                                appContext.setFormSettings(
                                                    (prevState) => {
                                                        return {
                                                            ...prevState,
                                                            nextQuestionId:
                                                                x.nextQuestionId
                                                        };
                                                    }
                                                );

                                                setFieldValue(
                                                    field.id,
                                                    x.id.toString()
                                                );
                                            }}
                                        >
                                            <div
                                                style={{ position: "relative" }}
                                            >
                                                {x.id.toString() ===
                                                    values[field.id] && (
                                                    <img
                                                        className="image-card__icon"
                                                        src={checkIcon}
                                                        alt="check-icon"
                                                    />
                                                )}
                                                <img
                                                    className="rounded-2 mw-100"
                                                    alt={t(
                                                        x.labelKey,
                                                        x.translateDK
                                                    )}
                                                    src={`data:image/png;base64,${x.value}`}
                                                />
                                            </div>

                                            <CardBody className="p-0 mt-2 mt-md-3">
                                                <CardText>
                                                    {t(
                                                        x.labelKey,
                                                        x.translateDK
                                                    )}
                                                </CardText>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                ))}
                        </Row>
                    </FormGroup>
                </>
            );
        case "VehiclePicker": {
            return (
                <>
                    <FormGroup className="mb-4">
                        {vehiclesResult.status === 2 && (
                            <SelectVehicle
                                formGroup={formGroup}
                                vehiclesStatus={vehiclesResult.status}
                                vehicles={vehiclesResult.data}
                                vehicleId={formGroup.vehicleId}
                                className={
                                    errors.vehicleId
                                        ? "form-control is-invalid"
                                        : "form-control"
                                }
                                setVehicleId={(vehicleId, vehicleFullName) => {
                                    appContext.setFormSettings((prevState) => {
                                        return {
                                            ...prevState,
                                            nextQuestionId:
                                                field.nextFormGroupId
                                        };
                                    });
                                    setFieldValue("vehicleId", vehicleId);
                                    setFieldValue(
                                        field.formGroupId,
                                        vehicleFullName
                                    );
                                }}
                            />
                        )}
                    </FormGroup>
                </>
            );
        }

        case "FormTemplateButton":
            return (
                <>
                    <FormGroup className="mb-4">
                        <Button
                            className="mb-2 w-100"
                            color="primary"
                            type="submit"
                            onClick={() => {
                                appContext.setFormSettings((prevState) => {
                                    return {
                                        ...prevState,
                                        nextQuestionId: field.nextFormGroupId
                                    };
                                });
                                setFieldValue(
                                    field.id,
                                    t(
                                        field.labelKey,
                                        field.translateDK
                                            ? field.translateDK
                                            : field.labelKey
                                    )
                                );
                                setFieldValue("formTemplateId", field.sort);
                            }}
                        >
                            {t(
                                field.labelKey,
                                field.translateDK
                                    ? field.translateDK
                                    : field.labelKey
                            )}
                        </Button>
                    </FormGroup>
                </>
            );
        case "Upload":
            return (
                <>
                    <FormGroup inline={false}>
                        {field.translateDK && (
                            <Label className="form-label">
                                {t(field.labelKey, field.translateDK)}
                            </Label>
                        )}
                        <FileUpload
                            attachments={
                                isJson(values[field.id])
                                    ? JSON.parse(values[field.id])
                                    : null
                            }
                            onSuccessUpload={(uploadedImage) => {
                                const newArray =
                                    value && value.length > 0
                                        ? [...value, uploadedImage]
                                        : [uploadedImage];

                                setFieldValue(
                                    field.id,
                                    JSON.stringify(newArray)
                                );
                                setValue(newArray);
                            }}
                            onRemoveImageItem={(name) => {
                                const newArray = value.filter(
                                    (item) => item.name !== name
                                );
                                setFieldValue(
                                    field.id,
                                    JSON.stringify(newArray)
                                );
                                setValue(newArray);
                            }}
                            apiEndpoint="api/incident/uploadtrygimage"
                            downloadApiEndpoint="api/incident/downloadtrygimage?name="
                        />
                    </FormGroup>
                </>
            );

        case "Review":
            return (
                <>
                    <div
                        className="d-flex flex-column align-content-end "
                        style={{ minHeight: 400 }}
                    >
                        {field.formGroups &&
                            field.formGroups.length > 0 &&
                            field.formGroups.map((formGroup) => {
                                return (
                                    <div>
                                        <h5 className="mb-0">
                                            {t(
                                                formGroup.labelKey,
                                                formGroup.translateDK
                                            )}
                                        </h5>

                                        {formGroup.fields?.map((x, index) => (
                                            <p>{x.interpretedValue}</p>
                                        ))}
                                    </div>
                                );
                            })}
                    </div>
                </>
            );
        default:
            return (
                <>
                    <FormGroup className="mb-4">
                        <Field
                            name={
                                formGroup.isFieldArray
                                    ? `${formGroup.id}.${index}.${field.id}`
                                    : `${field.id}`
                            }
                        >
                            {({
                                inputField,
                                form: { touched, errors },
                                meta
                            }) => (
                                <Input
                                    {...inputField}
                                    className={
                                        errors &&
                                        errors[
                                            formGroup.isFieldArray
                                                ? `${formGroup.id}.${index}.${field?.id}`
                                                : field.id
                                        ]
                                            ? "is-invalid"
                                            : ""
                                    }
                                    autoComplete="off"
                                    name={
                                        formGroup.isFieldArray
                                            ? `${formGroup.id}.${index}.${field?.id}`
                                            : field.id
                                    }
                                    defaultValue={field.value}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                />
                            )}
                        </Field>
                    </FormGroup>
                </>
            );
    }
};
