import FormatCurrency from "components/FormatCurrency";
import HilightCard from "components/HilightCard";
import { useTranslation } from "react-i18next";
import { Row, Col } from "reactstrap";

const InfoCardSite = ({ result }) => {
    const { t } = useTranslation();
    const finished = result.status === 2;
    const data = result.data;

    return (
        <Row>
            <Col sm={6} className="p-4">
                <div className="d-flex justify-content-between mb-2">
                    <div>
                        <label>{t("preliminary_cost")}</label>
                    </div>
                    <div className="d-flex justify-content-end">
                        <span className="small-value">
                            {!finished ? (
                                "-"
                            ) : (
                                <FormatCurrency value={data.preliminaryCost} />
                            )}
                        </span>
                    </div>
                </div>
                <div className="d-flex justify-content-between mb-2">
                    <div>
                        <label>{t("counterpart_cost")}</label>
                    </div>
                    <div className="d-flex justify-content-end">
                        <span className="small-value">
                            {!finished ? (
                                "-"
                            ) : (
                                <FormatCurrency value={data.counterpartCost} />
                            )}
                        </span>
                    </div>
                </div>
                <div className="d-flex justify-content-between mb-2">
                    <div>
                        <label>{t("total_cost")}</label>
                    </div>
                    <div className="d-flex justify-content-end">
                        <span className="small-value">
                            {!finished ? (
                                "-"
                            ) : (
                                <FormatCurrency value={data.totalCost} />
                            )}
                        </span>
                    </div>
                </div>
            </Col>
            <Col sm={6}>
                <HilightCard>
                    {data?.categoriesSummary?.map((category) => (
                        <div className="d-flex justify-content-between mb-2">
                            <div>
                                <label>
                                    {t(category.labelKey, category.translateDK)}
                                </label>
                            </div>
                            <div className="d-flex justify-content-end">
                                <span className="small-value">
                                    {!finished ? "-" : category.cost}
                                </span>
                            </div>
                        </div>
                    ))}
                </HilightCard>
            </Col>
        </Row>
    );
};

export default InfoCardSite;
