import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import { Nav, NavItem } from "reactstrap";

const AdminNavigation = () => {
    const { t } = useTranslation();
    return (
        <Nav className="nav-tabs">
            <NavItem>
                <NavLink exact to="/admin/company">
                    {t("company")}
                </NavLink>
            </NavItem>
            <NavItem>
                <NavLink exact to="/admin/company/users">
                    {t("users")}
                </NavLink>
            </NavItem>
            <NavItem>
                <NavLink exact to="/admin/company/sites">
                    {t("cities")}
                </NavLink>
            </NavItem>
            <NavItem>
                <NavLink exact to="/admin/company/vehicles">
                    {t("vehicles")}
                </NavLink>
            </NavItem>
        </Nav>
    );
};
export default AdminNavigation;
