// hantera ärende formulär-inputs
import Switch from "components/InputSwitch";
import NumberField from "components/NumberField";
import { FormGroup, Label, Input, Col, Row } from "reactstrap";
import Datetime from "react-datetime";
import { useTranslation } from "react-i18next";
import i18next from "i18next";

export const HandleTryggHansaIncidentFormInputs = ({
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    setFieldValue
}) => {
    const { t } = useTranslation();
    return (
        <>
            <Row>
                <Col xs={12}>
                    <FormGroup>
                        <Switch
                            onChange={() =>
                                setFieldValue(
                                    "isInsuranceMatter",
                                    !values.isInsuranceMatter
                                )
                            }
                            checked={values.isInsuranceMatter}
                            label={t("insurance_case")}
                        />
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col xs={12}>
                    <FormGroup>
                        <Label> {t("salvage_company")}</Label>
                        <Input
                            className={errors.towingCompany ? "is-invalid" : ""}
                            autoComplete="off"
                            name="towingCompany"
                            value={values.towingCompany}
                            type="text"
                            onChange={handleChange}
                            onBlur={handleBlur}
                        ></Input>
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col xs={12}>
                    <FormGroup>
                        <Label>{t("estimated_cost_of_damage_caused")}</Label>
                        <NumberField
                            value={values.estimatedCostForInjury}
                            onChange={handleChange}
                            name="estimatedCostForInjury"
                            onBlur={handleBlur}
                            className={
                                errors.estimatedCostForInjury
                                    ? "is-invalid"
                                    : ""
                            }
                        />
                        {errors.estimatedCostForInjury ? (
                            <div className="invalid-feedback d-block">
                                {errors.estimatedCostForInjury}
                            </div>
                        ) : null}
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col xs={12}>
                    <FormGroup>
                        <Label> {t("counterpart_cost")}</Label>
                        <NumberField
                            value={values.counterpartCost}
                            onChange={handleChange}
                            name="counterpartCost"
                            onBlur={handleBlur}
                            className={
                                errors.counterpartCost ? "is-invalid" : ""
                            }
                        />
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col xs={12}>
                    <FormGroup>
                        <Label>
                            {t("material_damage_on_other_than_vehicle")}
                        </Label>
                        <Input
                            className={
                                errors.materialDamages ? "is-invalid" : ""
                            }
                            autoComplete="off"
                            name="materialDamages"
                            value={values.materialDamages}
                            type="textarea"
                            onChange={handleChange}
                            onBlur={handleBlur}
                        ></Input>
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col xs={12}>
                    <FormGroup>
                        <Label>{t("date_of_follow-up_call")}</Label>
                        <Datetime
                            locale={i18next.resolvedLanguage}
                            onChange={(e) => {
                                setFieldValue("followUpTalkDate", e);
                            }}
                            timeFormat={false}
                            inputProps={{
                                placeholder: t("date_of_follow-up_call"),
                                className: "form-control"
                            }}
                            value={values.followUpTalkDate}
                        />
                        {errors.followUpTalkDate ? (
                            <div className="invalid-feedback d-block">
                                {errors.followUpTalkDate}
                            </div>
                        ) : null}
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col xs={12}>
                    <FormGroup>
                        <Label> {t("measure")}</Label>
                        <Input
                            className={errors.action ? "is-invalid" : ""}
                            autoComplete="off"
                            name="action"
                            value={values.action}
                            type="textarea"
                            onChange={handleChange}
                            onBlur={handleBlur}
                        ></Input>
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col xs={12}>
                    <FormGroup>
                        <Label> {t("other_notes")}</Label>
                        <Input
                            className={
                                errors.additionalNotes ? "is-invalid" : ""
                            }
                            autoComplete="off"
                            name="additionalNotes"
                            value={values.additionalNotes}
                            type="textarea"
                            onChange={handleChange}
                            onBlur={handleBlur}
                        ></Input>
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col xs={12}>
                    <FormGroup>
                        <Label> {t("status")}</Label>
                        <Input
                            type="select"
                            name="status"
                            id="status"
                            value={values.status}
                            className={
                                errors.status
                                    ? "form-control is-invalid"
                                    : "form-control"
                            }
                            onChange={handleChange}
                            onBlur={handleBlur}
                        >
                            <option value="Open"> {t("open")}</option>
                            <option value="Sent"> {t("sent")}</option>
                            <option value="Completed"> {t("completed")}</option>
                        </Input>
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col xs={12}>
                    <FormGroup>
                        <Label> {t("damage_number")}</Label>
                        <Input
                            className={errors.damageNumber ? "is-invalid" : ""}
                            autoComplete="off"
                            name="damageNumber"
                            value={values.damageNumber}
                            type="text"
                            onChange={handleChange}
                            onBlur={handleBlur}
                        ></Input>
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col xs={12}>
                    <FormGroup>
                        <Switch
                            onChange={() =>
                                setFieldValue(
                                    "supplementedForInsuranceCompany",
                                    !values.supplementedForInsuranceCompany
                                )
                            }
                            checked={values.supplementedForInsuranceCompany}
                            label={t("completed_for_insurance_companies")}
                        />
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col xs={12}>
                    <FormGroup>
                        <Label> {t("result")}</Label>
                        <Input
                            className={errors.result ? "is-invalid" : ""}
                            autoComplete="off"
                            name="result"
                            value={values.result}
                            type="textarea"
                            onChange={handleChange}
                            onBlur={handleBlur}
                        ></Input>
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col xs={12}>
                    <FormGroup>
                        <Label> {t("regulation_ready_date")}</Label>
                        <Datetime
                            locale={i18next.resolvedLanguage}
                            onChange={(e) => {
                                setFieldValue("handlingDoneDate", e);
                            }}
                            timeFormat={false}
                            inputProps={{
                                placeholder: t("regulation_ready_date"),
                                className: "form-control"
                            }}
                            value={values.handlingDoneDate}
                        />
                        {errors.handlingDoneDate ? (
                            <div className="invalid-feedback d-block">
                                {errors.handlingDoneDate}
                            </div>
                        ) : null}
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col xs={12}>
                    <FormGroup>
                        <Label> {t("compensation_amount")}</Label>
                        <NumberField
                            value={values.compensationAmount}
                            onChange={handleChange}
                            name="compensationAmount"
                            onBlur={handleBlur}
                            className={
                                errors.compensationAmount ? "is-invalid" : ""
                            }
                        />
                    </FormGroup>
                </Col>
            </Row>
        </>
    );
};
