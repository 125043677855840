import React, { useEffect, useMemo, useState } from "react";
import { useApi } from "components/useApi3";
import DataTable from "components/DataTable";

import PageHeader from "components/PageHeader";
import Panel from "components/Panel";
import { Button, ButtonGroup, Col, FormGroup } from "reactstrap";
import { useHistory } from "react-router-dom";
import moment from "moment";
import incidentCategory from "components/IncidentCatogory";
import IncidentStatus from "components/IncidentStatus";
import Switch from "components/InputSwitch";
import { useTranslation } from "react-i18next";
import { useContext } from "react";
import { AppContext } from "AppContext";
import {
	incidentStatusById,
	incidentStatusByStatusName
} from "helpers/incidentStatusById";
import TableFiltrationButton from "components/TableFiltrationButton";

const IncidentsList = () => {
	const history = useHistory();
	const { t } = useTranslation();
	const appContext = useContext(AppContext);
	const [listResult, listApi] = useApi();

	const [filters, setFilters] = useState({
		statusOpen: true,
		statusSent: true,
		statusCompleted: true
	});

	useEffect(() => {
		listApi.get("api/handlecases/handle");
	}, [listApi, filters]);
	const statusFilters =
		appContext.customer === "Tryg"
			? [
					{
						name: t("incoming", "Incoming"),
						value: "Open"
					},
					{
						name: t("new", "New"),
						value: "New"
					},
					{
						name: t("reviewed", "reviewed"),
						value: "Recieved"
					},
					{
						name: t("tryg_damage", "Tryg damage"),
						value: "SentToTryg"
					},
					{
						name: t("internal_damage", "Internal damage"),
						value: "SavedLocally"
					},
					{
						name: t("completed", "Completed"),
						value: "Completed"
					},
					{
						name: t("removed", "Removed"),
						value: "Removed"
					}
			  ]
			: [
					{
						name: t("incoming", "Incoming"),
						value: "Open"
					},
					{
						name: t("sent", "Sent"),
						value: "Sent"
					},
					{
						name: t("completed", "Completed"),
						value: "Completed"
					}
			  ];
	const initialState =
		appContext.customer === "Tryg"
			? {
					filters: [
						{
							id: "status",
							value: [
								"New",
								"SentToTryg",
								"SavedLocally",
								"Recieved",
								"Completed"
							]
						}
					]
			  }
			: {
					filters: [
						{
							id: "status",
							value: ["Open", "Sent", "Completed"]
						}
					]
			  };
	const columns = [
		{
			accessorFn: (row) =>
				row.vehicleIdentifier &&
				row.vehicleIdentifier
					.concat(" - ")
					.concat(row.vehicleRegistrationNumber)
					.concat(" - ")
					.concat(row.vehicleModel),
			enableColumnFilter: true,
			header: t("vehicle")
		},

		{
			enableColumnFilter: true,
			accessorKey: "status",
			accessorFn: (row) =>
				incidentStatusByStatusName(row.status, t, appContext.customer)
					.label,
			header: t("status"),
			cell: (cell) => {
				return (
					<IncidentStatus
						status={cell.row.original.status}
						customer={appContext.customer}
					/>
				);
			},
			Filter: TableFiltrationButton,
			hiddenHeaderFilter: true,
			filterFn: "multiFilter",
			FilterOptions: statusFilters
		},
		{
			accessorFn: (row) => moment(row.incidentDate).format("L"),
			enableColumnFilter: true,
			header: t("incident_date"),
			cell: (cell) => {
				return moment(cell.row.original.incidentDate).format("L");
			}
		},
		{
			accessorFn: (row) => t(row.category, row.category),
			enableColumnFilter: true,
			header: t("incident")
		},

		{
			accessorKey: "createdByName",
			enableColumnFilter: true,
			header: t("created_by")
		},
		{
			accessorKey: "createdDate",
			enableColumnFilter: true,

			header: t("created"),
			cell: (cell) => {
				return moment(cell.row.original.createdDate).format("L HH:mm");
			}
		},
		{
			enableColumnFilter: true,
			accessorKey: "responsibleUserName",
			header: t("responsible")
		},

		{
			accessorKey: "commands",
			header: "",
			cell: (cell) => {
				return (
					<ButtonGroup className="float-end">
						<Button
							size="sm"
							color="white"
							onClick={() => {
								history.push(
									"/incidents/edit/".concat(
										cell.row.original.id
									)
								);
							}}
						>
							{t("manage")}
						</Button>
					</ButtonGroup>
				);
			}
		}
	];

	return (
		<>
			<PageHeader
				title={t("manage_cases")}
				subtitle={t("handle_incoming_cases")}
			/>
			<div className="py-3 ">
				<DataTable
					columns={columns}
					result={listResult}
					initialState={initialState}
				/>
			</div>
		</>
	);
};
export default IncidentsList;
