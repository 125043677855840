import React from "react";
import {
	useReactTable,
	getCoreRowModel,
	getFilteredRowModel,
	getFacetedRowModel,
	getFacetedUniqueValues,
	getFacetedMinMaxValues,
	getPaginationRowModel,
	getSortedRowModel,
	flexRender
} from "@tanstack/react-table";

import {
	RankingInfo,
	rankItem,
	compareItems
} from "@tanstack/match-sorter-utils";
import { useMemo } from "react";
import useTableExport from "./useTableExport";
import { Button, Row, Col, Container } from "reactstrap";
import { t } from "i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faArrowDown,
	faArrowUp,
	faFilter,
	faSearch
} from "@fortawesome/pro-light-svg-icons";
import {
	ArrowLeftIcon,
	ArrowRightIcon,
	DoubleArrowLeftIcon,
	DoubleArrowRightIcon,
	LoadingIcon
} from "./Icons";
const ReactTable = ({
	columns,
	result,
	rowData,
	initialState,
	hideExport,
	hideFilterButton
}) => {
	const [columnFilters, setColumnFilters] = React.useState(
		initialState?.filters ? initialState.filters : []
	);
	const [globalFilter, setGlobalFilter] = React.useState("");
	const [exportTableState, exportTable] = useTableExport();
	const [showColumnFilter, setShowColumnFilter] = React.useState(false);

	const data = useMemo(
		() =>
			rowData
				? result.status === 2
					? rowData
					: []
				: result.status === 2
				? result.data
				: [],

		[result.status, result.data, rowData]
	);

	const table = useReactTable({
		data: data,
		columns,
		filterFns: {
			multiFilter: (row, columnId, value) => {
				return value !== null
					? value.includes(row.original[columnId])
					: row.original[columnId].includes(value);
			}
		},
		state: {
			columnFilters,
			globalFilter
		},
		onColumnFiltersChange: setColumnFilters,
		onGlobalFilterChange: setGlobalFilter,
		getCoreRowModel: getCoreRowModel(),
		getFilteredRowModel: getFilteredRowModel(),
		getSortedRowModel: getSortedRowModel(),
		getPaginationRowModel: getPaginationRowModel(),
		getFacetedRowModel: getFacetedRowModel(),
		getFacetedUniqueValues: getFacetedUniqueValues(),
		getFacetedMinMaxValues: getFacetedMinMaxValues(),
		debugTable: true,
		debugHeaders: true,
		debugColumns: false
	});

	return (
		<div className="bg-white rounded-3 p-0">
			<div className="d-flex flex-column p-2 p-md-3 gap-3">
				<div className="d-flex flex-column flex-md-row align-items-center justify-content-between ">
					{!globalFilter && (
						<DebouncedInput
							style={{ minWidth: "300px" }}
							value={globalFilter ?? ""}
							onChange={(value) => setGlobalFilter(String(value))}
							placeholder={t("search", "Search...")}
						/>
					)}

					<div className="d-flex justify-content-start react-table-nav-buttons">
						{!hideFilterButton && (
							<Button
								onClick={() =>
									setShowColumnFilter((prev) => !prev)
								}
								className="d-none d-lg-block btn-form-input"
								color="white"
							>
								<FontAwesomeIcon
									className="me-2"
									icon={faFilter}
								/>
								{t(
									"more_filter_options",
									"More filter options"
								)}
							</Button>
						)}

						{!hideExport && (
							<Button
								className="link btn-form-input ms-0 ms-md-2 m-0"
								color="primary"
								disabled={
									result.status !== 2 ||
									exportTableState === 1
								}
								onClick={() => {
									exportTable(
										columns,
										table.getRowModel().rows,
										table.getFilteredRowModel()
									);
								}}
							>
								{exportTableState === 1 ? (
									<>
										<LoadingIcon /> {t("exporting")}
										..
									</>
								) : (
									t("export")
								)}
							</Button>
						)}
					</div>
				</div>
				<div className="d-flex flex-column">
					<div className="d-flex flex-lg-row flex-column align-md-items-center align-items-start justify-content-between">
						<div>
							{table.getHeaderGroups().map((headerGroup) =>
								headerGroup.headers.map((header) => {
									return (
										header.column.getCanFilter() && (
											<div
												key={header.id}
												className="d-flex flex-column flex-md-row"
											>
												{flexRender(
													header.column.columnDef
														.Filter,
													{
														column: header.column
													}
												)}
											</div>
										)
									);
								})
							)}
						</div>
						<div className="d-flex align-items-center mt-lg-0 mt-3">
							{t("show", "Show")}:
							<select
								className="ms-1 rounded-3 react-table-page-select"
								value={table.getState().pagination.pageSize}
								onChange={(e) => {
									table.setPageSize(Number(e.target.value));
								}}
							>
								{[10, 20, 30, 40, 50].map((pageSize) => (
									<option key={pageSize} value={pageSize}>
										{pageSize}
									</option>
								))}
							</select>
						</div>
					</div>
				</div>
			</div>

			<div className="react-table d-flex d-lg-none">
				<div className="react-table__body">
					{table.getRowModel().rows.map((row) => {
						return (
							<Row className="react-table__body-row px-2 px-md-3 flex-wrap flex-row">
								{row.getVisibleCells().map((cell, index) => {
									return (
										<Col
											xs={
												cell.column.columnDef
													.fullWidthMobile
													? 12
													: 6
											}
											key={cell.id}
											className={` ${
												cell.column.columnDef
													.fullWidthMobile &&
												"react-table__full-width fw-bolder"
											} react-table__body-cell mb-2 mb-lg-0 ${
												cell.column.columnDef.hideMobile
													? "d-none d-md-flex"
													: "d-flex"
											} ${
												cell.column.columnDef
													.orderMobile !== undefined
													? `order-${cell.column.columnDef.orderMobile}`
													: "order-5"
											}
                                            
                                            `}
										>
											<div className="d-flex flex-column flex-wrap">
												{!cell.column.columnDef
													.hideHeaderMobile && (
													<small className="d-block d-lg-none text-black-50">
														{
															cell.column
																.columnDef
																.header
														}
													</small>
												)}

												{flexRender(
													cell.column.columnDef.cell,
													cell.getContext()
												)}
											</div>
										</Col>
									);
								})}
							</Row>
						);
					})}
				</div>
			</div>

			<div className="table-responsive d-lg-flex d-none">
				<table className="table table-grid mb-0">
					{table.getHeaderGroups().map((headerGroup) => (
						<thead className="" key={headerGroup.id}>
							<tr>
								{headerGroup.headers.map((header, index) => {
									return header.isPlaceholder ? null : (
										<>
											<th
												style={{
													verticalAlign:
														showColumnFilter &&
														header.column.columnDef
															.enableColumnFilter &&
														header.column.columnDef
															.Filter &&
														"text-top"
												}}
											>
												<div
													{...{
														onClick:
															header.column.getToggleSortingHandler()
													}}
													className={`d-flex align-items-center w-100 ${
														showColumnFilter &&
														"mb-2"
													}`}
													style={{
														cursor: "pointer"
													}}
												>
													{header.column.columnDef
														.header ? (
														flexRender(
															header.column
																.columnDef
																.header,
															header.getContext()
														)
													) : (
														<span className="mt-3"></span>
													)}
													{{
														asc: (
															<FontAwesomeIcon
																className="react-table__icon"
																icon={faArrowUp}
															/>
														),
														desc: (
															<FontAwesomeIcon
																className="react-table__icon"
																icon={
																	faArrowDown
																}
															/>
														)
													}[
														header.column.getIsSorted()
													] ?? null}
												</div>

												{showColumnFilter ? (
													header.column.columnDef
														.enableColumnFilter &&
													!header.column.columnDef
														.Filter ? (
														<Filter
															placeholder={
																header.column
																	.columnDef
																	.header
															}
															column={
																header.column
															}
															table={table}
														/>
													) : (
														<span className="mb-3"></span>
													)
												) : null}
											</th>
										</>
									);
								})}
							</tr>
						</thead>
					))}
					<tbody className="">
						{table.getRowModel().rows.map((row) => {
							return (
								<tr className="px-2 px-md-3">
									{row
										.getVisibleCells()
										.map((cell, index) => {
											return (
												<td key={cell.id}>
													{flexRender(
														cell.column.columnDef
															.cell,
														cell.getContext()
													)}
												</td>
											);
										})}
								</tr>
							);
						})}
					</tbody>
				</table>
			</div>

			<div className="h-2" />
			<div className="items-center d-flex justify-content-end gap-2 react-table-pagination">
				<nav aria-label="...">
					<ul className="pagination d-flex align-items-center m-0 p-md-3">
						<li className="page-item disabled">
							<button
								className="p-1 react-table-pagination__button"
								onClick={() => table.setPageIndex(0)}
								disabled={!table.getCanPreviousPage()}
							>
								<DoubleArrowLeftIcon />
							</button>
						</li>
						<li className="page-item">
							{" "}
							<button
								className="p-1 react-table-pagination__button"
								onClick={() => table.previousPage()}
								disabled={!table.getCanPreviousPage()}
							>
								<ArrowLeftIcon />
							</button>
						</li>
						<li className="react-table-pagination__index px-2">
							{table.getState().pagination.pageIndex + 1}{" "}
							{t("of", "of")} {table.getPageCount()}
						</li>
						<li className="page-item active">
							<button
								className="p-1 react-table-pagination__button"
								onClick={() => table.nextPage()}
								disabled={!table.getCanNextPage()}
							>
								<ArrowRightIcon />
							</button>
						</li>
						<li className="page-item">
							<button
								className="p-1 react-table-pagination__button"
								onClick={() =>
									table.setPageIndex(table.getPageCount() - 1)
								}
								disabled={!table.getCanNextPage()}
							>
								<DoubleArrowRightIcon />
							</button>
						</li>
					</ul>
				</nav>
			</div>
		</div>
	);
};

export default ReactTable;

function Filter({ column, table, placeholder }) {
	const firstValue = table
		.getPreFilteredRowModel()
		.flatRows[0]?.getValue(column.id);

	const columnFilterValue = column.getFilterValue();

	const sortedUniqueValues = React.useMemo(
		() =>
			typeof firstValue === "number"
				? []
				: Array.from(column.getFacetedUniqueValues().keys()).sort(),
		[column.getFacetedUniqueValues()]
	);

	return typeof firstValue === "number" ? (
		<div>
			<div className="flex space-x-2 bg-white">
				<DebouncedInput
					type="number"
					min={Number(column.getFacetedMinMaxValues()?.[0] ?? "")}
					max={Number(column.getFacetedMinMaxValues()?.[1] ?? "")}
					value={columnFilterValue?.[0] ?? ""}
					onChange={(value) =>
						column.setFilterValue((old) => [value, old?.[1]])
					}
					placeholder={`Min ${
						column.getFacetedMinMaxValues()?.[0]
							? `(${column.getFacetedMinMaxValues()?.[0]})`
							: ""
					}`}
					className="w-24 border rounded"
				/>
				<DebouncedInput
					type="number"
					min={Number(column.getFacetedMinMaxValues()?.[0] ?? "")}
					max={Number(column.getFacetedMinMaxValues()?.[1] ?? "")}
					value={columnFilterValue?.[1] ?? ""}
					onChange={(value) =>
						column.setFilterValue((old) => [old?.[0], value])
					}
					placeholder={`Max ${
						column.getFacetedMinMaxValues()?.[1]
							? `(${column.getFacetedMinMaxValues()?.[1]})`
							: ""
					}`}
					className="w-24 border rounded"
				/>
			</div>
			<div className="h-1" />
		</div>
	) : (
		<>
			<datalist id={column.id + "list"}>
				{sortedUniqueValues.slice(0, 5000).map((value) => (
					<option value={value} key={value} />
				))}
			</datalist>
			<DebouncedInput
				style={{ minHeight: "34px" }}
				type="text"
				value={columnFilterValue ?? ""}
				onChange={(value) => column.setFilterValue(value)}
				placeholder={t("search", "Search...")}
				list={column.id + "list"}
			/>
			<div className="h-1" />
		</>
	);
}

// A debounced input react component
function DebouncedInput({
	value: initialValue,
	onChange,
	debounce = 500,
	...props
}) {
	const [value, setValue] = React.useState(initialValue);

	React.useEffect(() => {
		setValue(initialValue);
	}, [initialValue]);

	React.useEffect(() => {
		const timeout = setTimeout(() => {
			onChange(value);
		}, debounce);

		return () => clearTimeout(timeout);
	}, [value]);

	return (
		<div className="d-none d-md-flex table-filtration-search ">
			<input
				{...props}
				value={value}
				onChange={(e) => setValue(e.target.value)}
				className="form-control mb-3 mb-md-0 mt-2 mt-md-0 bg-white"
			/>
		</div>
	);
}
