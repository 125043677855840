import Panel from "components/Panel";

import { Col, FormGroup, Row } from "reactstrap";
import DateFormat from "components/DateFormat";
import FormatCurrency from "components/FormatCurrency";
import { useTranslation } from "react-i18next";

const LabelValue = ({ label, value }) => {
    return (
        <FormGroup>
            <label>{label}</label>
            <div>{value ? value : "-"}</div>
        </FormGroup>
    );
};
const CounterPartList = ({ items }) => {
    const { t } = useTranslation();
    return items && items.length > 0 ? (
        <Row>
            {items.map((item, key) => {
                return (
                    <Col key={key} sm={4}>
                        <Panel>
                            <div className="small-label">{t("name")}</div>
                            <h1>{item.name}</h1>
                            <div className="small-label">
                                {t("license_plate_number")}
                            </div>
                            <div>{item.registrationNumber}</div>
                            <div className="small-label">
                                {t("personal_or_organization_number")}
                            </div>
                            <div>{item.personalOrOrganisationNumber}</div>
                            <div className="small-label"> {t("phone")}</div>
                            <div>{item.telephoneNumber}</div>
                        </Panel>
                    </Col>
                );
            })}
        </Row>
    ) : (
        "-"
    );
};

const TryggHansaDetails = ({ item }) => {
    const insuranceLabel =
        item.insuranceCategory === "CRASH"
            ? "Krock, trafikolycka eller skada på fordon"
            : item.insuranceCategory === "THEFT"
            ? "Stöld eller inbrott"
            : item.insuranceCategory === "FIRE"
            ? "Brand"
            : null;
    const { t } = useTranslation();
    return (
        <>
            <Row>
                <Col sm={4}>
                    <LabelValue label={t("incident")} value={insuranceLabel} />
                </Col>
                <Col sm={4}>
                    <LabelValue label={t("place")} value={item.location} />
                </Col>
                <Col sm={4}>
                    <LabelValue
                        label={t("incident_date")}
                        value={<DateFormat>{item.incidentDate}</DateFormat>}
                    />
                </Col>
            </Row>
            <Row>
                <Col sm={4}>
                    <LabelValue
                        label={t("type_of_damage")}
                        value={item.crashCategory}
                    />
                </Col>
                <Col sm={4}>
                    <LabelValue
                        label={t("vehicle")}
                        value={item.vehicleDisplayName}
                    />
                </Col>
                <Col sm={4}>
                    <LabelValue label={t("odometer")} value={item.mileage} />
                </Col>
            </Row>
            <Row>
                <Col sm={12}>
                    <LabelValue
                        label={t("damage_location")}
                        value={item.damageLocationCategory.join(", ")}
                    />
                </Col>
            </Row>
            {item.crashCategory === "Kollision" && (
                <>
                    <Row>
                        <Col sm={12}>
                            <LabelValue
                                label={t("meeting_collision")}
                                value={
                                    item.isTrafficCollision ? t("yes") : t("no")
                                }
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12}>
                            <LabelValue
                                label={t("the_approximate_width_of_the_road")}
                                value={item.estimatedRoadWidth}
                            />
                        </Col>
                    </Row>
                </>
            )}
            <hr />
            <Row>
                <Col sm={4}>
                    <LabelValue
                        label={t("date_of_follow-up_call")}
                        value={<DateFormat>{item.followUpTalkDate}</DateFormat>}
                    />
                </Col>
                <Col sm={4}>
                    <LabelValue
                        label={t("regulation_ready_date")}
                        value={<DateFormat>{item.handlingDoneDate}</DateFormat>}
                    />
                </Col>
                <Col sm={4}>
                    <LabelValue
                        label={t("damage_number")}
                        value={item.damageNumber}
                    />
                </Col>
            </Row>
            <Row>
                <Col sm={4}>
                    <LabelValue
                        label={t("salvage")}
                        value={item.towingCompany}
                    />
                </Col>
            </Row>
            <hr />
            <Row>
                <Col sm={12}>
                    <LabelValue
                        label={t("incident_description")}
                        value={item.descriptionOfEvents}
                    />
                </Col>
            </Row>
            <Row>
                <Col sm={12}>
                    <LabelValue
                        label={t("number_of_passengers")}
                        value={item.numberOfPassengers}
                    />
                </Col>
            </Row>
            <Row>
                <Col sm={12}>
                    <LabelValue
                        label={t("contact_details_counterparty")}
                        value={
                            <CounterPartList
                                items={item.counterPartContactInformations}
                            />
                        }
                    />
                </Col>
            </Row>
            <Row>
                <Col sm={12}>
                    <LabelValue label={t("witnesses")} value={item.witnesses} />
                </Col>
            </Row>
            <hr />
            <Row>
                <Col sm={12}>
                    <LabelValue
                        label={t("other")}
                        value={
                            <ul>
                                {item.policeContacted ? (
                                    <li className="value">
                                        Polis inkopplad: Ja
                                    </li>
                                ) : null}
                                {item.emergencyServiceContacted ? (
                                    <li className="value">
                                        Räddningstjänst inkopplad: Ja
                                    </li>
                                ) : null}
                                {item.bloodTest ? (
                                    <li className="value">
                                        Blodprov har tagits: Ja
                                    </li>
                                ) : null}
                                {item.breathTest ? (
                                    <li className="value">
                                        Utandningsprov har tagits: Ja
                                    </li>
                                ) : null}
                                {item.replacementVehicleHired ? (
                                    <li className="value">
                                        Ersättningsfordon har anlitats: Ja
                                    </li>
                                ) : null}
                                {item.supplementedForInsuranceCompany ? (
                                    <li className="value">
                                        Kompletterad till försäkringsbolag: Ja
                                    </li>
                                ) : null}
                            </ul>
                        }
                    />
                </Col>
            </Row>
            <hr />
            <Row>
                <Col sm={4}>
                    <LabelValue
                        label={t("estimated_cost_of_damage_caused")}
                        value={
                            <FormatCurrency
                                value={item.estimatedCostForInjury}
                            />
                        }
                    />
                </Col>
                <Col sm={4}>
                    <LabelValue
                        label={t("compensation_amount")}
                        alue={
                            <FormatCurrency value={item.compensationAmount} />
                        }
                    />
                </Col>
                <Col sm={4}>
                    <LabelValue
                        label={t("counterpart_cost")}
                        value={<FormatCurrency value={item.counterpartCost} />}
                    />
                </Col>
            </Row>
            <hr />
            <Row>
                <Col sm={12}>
                    <LabelValue
                        label={t("material_damage_on_other_than_vehicle")}
                        value={item.materialDamages}
                    />
                </Col>
            </Row>
            <Row>
                <Col sm={12}>
                    <LabelValue
                        label={t("visible_damage_to_vehicles_or_other_objects")}
                        value={item.visibleDamages ? t("yes") : t("no")}
                    />
                </Col>
            </Row>
            <Row>
                <Col sm={12}>
                    <LabelValue
                        label={t("description_visible_damage")}
                        value={item.visibleDamagesDescription}
                    />
                </Col>
            </Row>
            <Row>
                <Col sm={12}>
                    <LabelValue
                        label={t("personal_injury")}
                        value={item.personalDamages ? t("yes") : t("no")}
                    />
                </Col>
            </Row>
            <Row>
                <Col sm={12}>
                    <LabelValue
                        label={t("description_visible_damage")}
                        value={item.personalDamagesDescription}
                    />
                </Col>
            </Row>
            <Row>
                <Col sm={12}>
                    <LabelValue
                        label={t("is_the_vehicle_salvaged")}
                        value={item.isVehicleTowed ? t("yes") : t("no")}
                    />
                </Col>
            </Row>
            {item.isVehicleTowed && (
                <Row>
                    <Col sm={12}>
                        <LabelValue
                            label={t("the_vehicle_was_towed_to")}
                            value={item.materialDamages}
                        />
                    </Col>
                </Row>
            )}
        </>
    );
};

export default TryggHansaDetails;
