import { useTranslation } from "react-i18next";
import { FormGroup, Label, Input } from "reactstrap";

const Form = ({ values, errors, touched, handleBlur, handleChange }) => {
	const { t } = useTranslation();
	return (
		<div className="p-2 p-md-0">
			<FormGroup>
				<Label for="name">{t("name")}</Label>
				<Input
					className={errors.name ? "is-invalid" : ""}
					autoComplete="off"
					name="name"
					value={values.name}
					onChange={handleChange}
					onBlur={handleBlur}
				></Input>
				{errors.name && touched.name && (
					<span className="text-danger">{errors.name}</span>
				)}
			</FormGroup>
			<FormGroup>
				<Label for="name">
					{t("email_on_primary_company_contact")}
				</Label>
				<Input
					className={errors.replyEmail ? "is-invalid" : ""}
					autoComplete="off"
					name="replyEmail"
					value={values.replyEmail}
					onChange={handleChange}
					onBlur={handleBlur}
				></Input>
				{errors.replyEmail && touched.replyEmail && (
					<span className="text-danger">{errors.replyEmail}</span>
				)}
			</FormGroup>
		</div>
	);
};
export default Form;
