import { useApi } from "components/useApi3";
import { t } from "i18next";
import React, { useContext, useEffect, useRef, useState } from "react";
import {
    Button,
    Form,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Spinner
} from "reactstrap";
import * as Yup from "yup";

import { AppContext } from "AppContext";
import { Formik } from "formik";
import { getValidation } from "./Helpers/validation";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { FormGroupContainer } from "./FormGroups/FormGroupContainer";
import { TrygFormButtons } from "./FormGroups/TrygFormButtons";
import LoadingSpinner from "components/LoadingSpinner";
import { isJson } from "components/form/Tryg/components/helpers/convertIncidentValues";

const filterOutComponentFromAnswers = (component) => {
    return (
        component !== "FormTemplateButton" &&
        component !== "VehiclePicker" &&
        component !== "Button" &&
        component !== "SkipButton" &&
        component !== "IncidentDateTimePicker" &&
        component !== "Review"
    );
};

const filterOutVariableFromAnswers = (variableName) => {
    return (
        variableName !== "incidentDate" &&
        variableName !== "vehicleId" &&
        variableName !== "formTemplateId"
    );
};

const getValue = (
    isFieldArray,
    values,
    key,
    component,
    repeatable,
    formGroupId
) => {
    return isFieldArray
        ? JSON.stringify(values[key])
        : repeatable
        ? JSON.stringify(values)
        : isJson(values[key]) && component === "LocationPicker"
        ? JSON.stringify(JSON.parse(values[key]).value)
        : values[key].toString();
};

const getIntepretedValue = (
    isFieldArray,
    values,
    key,
    component,
    repeatable
) => {
    return isFieldArray
        ? JSON.stringify(values.intepretedValues)
        : repeatable
        ? JSON.stringify(values)
        : isJson(values[key]) && component === "LocationPicker"
        ? JSON.parse(values[key]).interpretedValue
        : t(values[key].toString(), {
              lng: "da-DK"
          }) !== null
        ? t(values[key].toString(), {
              lng: "da-DK"
          })
        : values[key].toString();
};

export function FAQTree({
    isHandler,
    defaultIncidentId,
    setGetIncident,
    setHideAdminQuestion
}) {
    const appContext = useContext(AppContext);
    const [clickedZones, setClickedZones] = useState([]);
    const [loading, setLoading] = useState(true);
    const [loadingFirst, setLoadingFirstQuestion] = useState(true);
    const [openModal, setOpenModal] = useState(false);
    const [incidentId, setIncidentId] = useState(
        defaultIncidentId ? defaultIncidentId : 0
    );
    const [steps, setSteps] = useState([]);
    const [values, setValues] = useState({});
    const [questionResult, dataApi] = useApi();
    const [addAnswerResult, answerDataApi] = useApi();
    const [updateIncident, updateIncidentApi] = useApi();
    const [removeIncidentResult, removeIncidentApi] = useApi();
    const [showValidationModal, setShowValidationModal] = useState(false);
    const [validateContent, setValidateContent] = useState(null);
    const history = useHistory();
    const formRef = useRef();

    const [validationSteps, setValidationSteps] = useState({});

    useEffect(() => {
        if (addAnswerResult.status === 2) {
            setIncidentId(addAnswerResult.data);
        }
    }, [addAnswerResult]);

    useEffect(() => {
        dataApi.get(
            "api/incident/incidents/firstquestion/"
                .concat(`${isHandler ? "true" : "false"}`)
                .concat("/")
                .concat(incidentId)
        );
        setLoadingFirstQuestion(true);
    }, [dataApi]);
    useEffect(() => {
        if (updateIncident.status === 2) {
            history.push("/incidents/mine");
        }
    }, [updateIncident.status]);
    useEffect(() => {
        if (removeIncidentResult.status === 2) {
            dataApi.get(
                "api/incident/incidents/firstquestion/"
                    .concat(`${isHandler ? "true" : "false"}`)
                    .concat("/")
                    .concat(incidentId)
            );
        }
    }, [removeIncidentResult.status]);
    const clearForm = () => {
        removeIncidentApi.put(
            "api/incident/incidents/"
                .concat(questionResult.data?.incidentId)
                .concat("/removeincident")
        );
        setOpenModal(false);
    };

    const handleChoice = (values) => {
        const uploadField = questionResult.data?.fields?.find(
            (x) => x.component === "Upload"
        );
        if (uploadField !== undefined) {
            const uploadKey = Object.keys(values).find(
                (x) => x !== uploadField.id
            );

            if (uploadKey !== null && values[uploadKey] === null) {
                values = { ...values, [uploadField.id]: "[]" };
            }
        }

        setLoading(true);
        if (questionResult.data?.incident?.isReview) {
            updateIncidentApi.put(
                "api/incident/incidents/"
                    .concat(questionResult?.data?.incident?.id)
                    .concat("/sendtryg")
            );
        } else {
            const newValues = {
                id: questionResult.data?.incidentId
                    ? questionResult.data?.incidentId
                    : 0,
                vehicleId:
                    values["vehicleId"] !== undefined
                        ? values["vehicleId"]
                        : questionResult.data?.vehicleId
                        ? questionResult.data?.vehicleId
                        : 0,
                incidentDate:
                    values["incidentDate"] !== undefined
                        ? values["incidentDate"]
                        : questionResult.data?.incidentDate
                        ? questionResult.data?.incidentDate
                        : null,
                location:
                    values["location"] !== undefined
                        ? values["location"]
                        : questionResult.data?.location
                        ? questionResult.data?.location
                        : null,
                formTemplateId:
                    values["formTemplateId"] !== undefined
                        ? values["formTemplateId"]
                        : questionResult.data?.formTemplateId
                        ? questionResult.data?.formTemplateId
                        : 0,
                isHandlerForm: isHandler,
                previousFormGroupId: addAnswerResult.data?.selectedFormGroupId
                    ? addAnswerResult.data?.selectedFormGroupId
                    : questionResult.data?.previousFormGroupId,
                answers:
                    values[questionResult.data?.fields[0].id] !== null &&
                    !questionResult.data?.repeatable
                        ? Object.keys(values)
                              .filter(
                                  (x) =>
                                      values[x] !== "" &&
                                      values[x] !== null &&
                                      x !== "intepretedValues"
                              )
                              .map((key) => {
                                  return {
                                      id: questionResult.data?.isFieldArray
                                          ? questionResult.data?.answerId
                                          : questionResult.data?.fields[key]
                                                ?.answerId,
                                      formGroupId: questionResult.data?.id,
                                      answerId: questionResult.data
                                          ?.isFieldArray
                                          ? questionResult.data?.answerId
                                          : questionResult.data?.fields[key]
                                                ?.answerId,
                                      fieldId: questionResult.data?.isFieldArray
                                          ? null
                                          : Number.isInteger(parseInt(key)) &&
                                            questionResult.data?.id !==
                                                parseInt(key)
                                          ? key
                                          : null,
                                      component:
                                          questionResult.data?.fields[key]
                                              ?.id !== null
                                              ? questionResult.data?.fields[key]
                                                    ?.component
                                              : null,
                                      value: getValue(
                                          questionResult.data?.isFieldArray,
                                          values,
                                          key,
                                          questionResult.data?.fields[key]
                                              ?.component,
                                          questionResult.data?.repeatable,
                                          questionResult.data?.id
                                      ),
                                      interpretedValue: getIntepretedValue(
                                          questionResult.data?.isFieldArray,
                                          values,
                                          key,
                                          questionResult.data?.fields[key]
                                              ?.component
                                      ),
                                      selectedFormGroupId: appContext
                                          .formSettings.nextQuestionId
                                          ? appContext.formSettings
                                                .nextQuestionId
                                          : questionResult.data
                                                ?.nextFormGroupId,
                                      previousFormGroupId: addAnswerResult.data
                                          ?.selectedAnswerId
                                          ? questionResult.data
                                                ?.previousFormGroupId
                                          : questionResult.data?.id,
                                      sort: questionResult.data?.sort
                                  };
                              })
                        : questionResult.data?.repeatable
                        ? [
                              {
                                  id: questionResult.data?.answerId,
                                  formGroupId: questionResult.data?.id,
                                  answerId:
                                      questionResult.data?.answerId?.answerId,
                                  fieldId: null,
                                  component: null,
                                  value: getValue(
                                      questionResult.data?.isFieldArray,
                                      values,
                                      questionResult.data?.id,
                                      null,
                                      questionResult.data?.repeatable,
                                      questionResult.data?.id
                                  ),
                                  interpretedValue: getIntepretedValue(
                                      questionResult.data?.isFieldArray,
                                      values,
                                      null,
                                      null,
                                      questionResult.data?.repeatable,
                                      questionResult.data?.id
                                  ),
                                  selectedFormGroupId: appContext.formSettings
                                      .nextQuestionId
                                      ? appContext.formSettings.nextQuestionId
                                      : questionResult.data?.nextFormGroupId,
                                  previousFormGroupId: addAnswerResult.data
                                      ?.selectedAnswerId
                                      ? addAnswerResult.data?.selectedAnswerId
                                      : questionResult.data
                                            ?.previousFormGroupId,
                                  sort: questionResult.data?.sort
                              }
                          ]
                        : [
                              {
                                  id: questionResult.data?.isFieldArray
                                      ? questionResult.data?.answerId
                                      : questionResult.data?.fields[0]
                                            ?.answerId,
                                  formGroupId: questionResult.data?.id,
                                  answerId: questionResult.data?.isFieldArray
                                      ? questionResult.data?.answerId
                                      : questionResult.data?.fields[0]
                                            ?.answerId,
                                  fieldId:
                                      questionResult.data?.fields[0]?.fieldId,
                                  component:
                                      questionResult.data?.fields[0]?.component,
                                  value: null,
                                  interpretedValue: null,
                                  selectedFormGroupId: appContext.formSettings
                                      .nextQuestionId
                                      ? appContext.formSettings.nextQuestionId
                                      : questionResult.data?.nextFormGroupId,
                                  previousFormGroupId: addAnswerResult.data
                                      ?.selectedAnswerId
                                      ? addAnswerResult.data?.selectedAnswerId
                                      : questionResult.data?.id
                              }
                          ]
            };
            answerDataApi.put("api/incident/incidents/addquestion/", newValues);
        }
    };

    const goToPreviousPage = () => {
        setLoading(true);
        dataApi.get(
            "api/incident/incidents/nextquestion/"
                .concat(questionResult.data?.previousFormGroupId)
                .concat("/")
                .concat(questionResult.data?.incidentId)
                .concat("/")
                .concat(
                    questionResult.data?.sort > 1
                        ? questionResult.data?.sort - 1
                        : 0
                )
                .concat("/")
                .concat(isHandler ? isHandler : false)
        );
    };

    useEffect(() => {
        if (addAnswerResult.status === 2 && incidentId > 0) {
            if (
                appContext.formSettings.nextQuestionId > 0 ||
                questionResult.data?.nextFormGroupId > 0
            ) {
                dataApi.get(
                    "api/incident/incidents/nextquestion/"
                        .concat(
                            appContext.formSettings.nextQuestionId
                                ? appContext.formSettings.nextQuestionId
                                : questionResult.data?.nextFormGroupId
                        )
                        .concat("/")
                        .concat(incidentId)
                        .concat("/")
                        .concat(
                            questionResult.data?.nextSort
                                ? questionResult.data?.nextSort
                                : 0
                        )
                        .concat("/")
                        .concat(isHandler ? isHandler : false)
                );
            }
        }
    }, [addAnswerResult, incidentId]);

    useEffect(() => {
        setValues({});
        setValidationSteps({});
        if (questionResult.status === 2) {
            if (questionResult.data.answerId !== null && loadingFirst) {
                setOpenModal(true);
                setLoadingFirstQuestion(false);
            }

            setSteps((prev) => [...prev, questionResult.data.id]);
            let validationStep = Yup.object().shape({});
            let emptyArrayValue = {};
            questionResult.data?.fields?.length > 0 &&
                questionResult.data?.fields
                    .filter((x) => filterOutComponentFromAnswers(x.component))
                    .map((field) => {
                        const newObj = {
                            ...values,
                            [questionResult.data?.isFieldArray
                                ? questionResult.data?.id
                                : field.id]:
                                field.component !== "Button" ||
                                field.upload !== "Button" ||
                                field.component !== "Select"
                                    ? questionResult.data?.isFieldArray
                                        ? JSON.parse(questionResult.data?.value)
                                            ? JSON.parse(
                                                  questionResult.data?.value
                                              )
                                            : [
                                                  Object.assign(
                                                      emptyArrayValue,
                                                      field.id
                                                  )
                                              ]
                                        : field.value
                                    : null
                        };
                        setValues(Object.assign(values, newObj));

                        return Object.assign(
                            validationStep,
                            Yup.object().shape({
                                [field.id]: getValidation(
                                    field.dataType,
                                    field.optional,
                                    field.labelKey
                                )
                            })
                        );
                    });
            setValidationSteps(validationStep);

            appContext.setFormSettings(() => ({
                formGroupId: questionResult.data.id,
                currentAnswerId: questionResult.data.answerId
                    ? questionResult.data.answerId
                    : 0
            }));
        }
    }, [questionResult.status]);

    useEffect(() => {
        questionResult.status === 1 ? setLoading(true) : setLoading(false);

        if (questionResult.status === 2) {
            setHideAdminQuestion &&
                setHideAdminQuestion(questionResult.data?.fields === null);
            const adminForm =
                questionResult.data?.adminFormGroups &&
                questionResult.data?.adminFormGroups
                    .find((x) => x.id === 140)
                    ?.fields?.find((x) => x.value === "true") !== null;

            setGetIncident && setGetIncident(adminForm);
        }
    }, [questionResult.status]);

    return (
        <div className="faq-container">
            {questionResult.status === 2 && openModal && !isHandler && (
                <Modal isOpen={openModal} toggle={() => setOpenModal(false)}>
                    <ModalHeader>
                        {t(
                            "tryg_form_continue_modal_header",
                            "Du har en åben sag"
                        )}
                    </ModalHeader>
                    <ModalBody>
                        {t(
                            "tryg_form_continue_modal_body",
                            "Vil du fortsætte eller oprette en ny sag?"
                        )}
                    </ModalBody>
                    <ModalFooter className="justify-content-between">
                        <Button
                            color="secondary"
                            outline
                            onClick={() => clearForm()}
                        >
                            {t("create_new_incident", "Opret ny sag")}
                        </Button>
                        <Button
                            color="primary"
                            onClick={() => setOpenModal(false)}
                        >
                            {t("continue", "Fortsætte")}
                        </Button>
                    </ModalFooter>
                </Modal>
            )}

            {loading && (
                <div className="faq-container-loading-icon">
                    <LoadingSpinner />
                </div>
            )}

            {!loading && questionResult.status === 2 && (
                <Formik
                    innerRef={formRef}
                    validationSchema={validationSteps}
                    initialValues={values}
                    validateOnBlur={false}
                    validateOnChange={false}
                    enableReinitialize={true}
                    onSubmit={(values) => {
                        handleChoice(values);
                    }}
                >
                    {({
                        errors,
                        values,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        setFieldValue,
                        setErrors
                    }) => (
                        <Form onSubmit={handleSubmit} noValidate>
                            <div
                                className="d-flex flex-column align-content-end justify-content-center"
                                style={{ minHeight: 400 }}
                            >
                                <FormGroupContainer
                                    validateContent={validateContent}
                                    setValidateContent={setValidateContent}
                                    setShowValidationModal={
                                        setShowValidationModal
                                    }
                                    showValidationModal={setShowValidationModal}
                                    questionResult={
                                        questionResult?.data?.incident
                                            ? questionResult?.data.incident
                                            : questionResult?.data
                                    }
                                    errors={errors}
                                    values={values}
                                    handleSubmit={handleSubmit}
                                    clickedZones={clickedZones}
                                    setClickedZones={setClickedZones}
                                    setFieldValue={setFieldValue}
                                    handleChange={handleChange}
                                    handleBlur={handleBlur}
                                    addAnswerResult={addAnswerResult}
                                    isHandler={isHandler}
                                />
                                {!questionResult?.data?.incident?.isReview && (
                                    <TrygFormButtons
                                        loading={loading}
                                        setLoading={setLoading}
                                        questionResult={questionResult}
                                        setErrors={setErrors}
                                        goToPreviousPage={goToPreviousPage}
                                        addAnswerResult={addAnswerResult}
                                    />
                                )}
                            </div>
                        </Form>
                    )}
                </Formik>
            )}
        </div>
    );
}
