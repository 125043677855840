import React, { useEffect, useMemo } from "react";
import { useApi } from "components/useApi3";
import DataTable from "components/DataTable";

import { Button, ButtonGroup } from "reactstrap";
import incidentCategory from "components/IncidentCatogory";
import IncidentStatus from "components/IncidentStatus";
import IncidentProgress from "components/IncidentProgress";
import { ArrowRightIcon } from "components/Icons";
import { Link, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useContext } from "react";
import { AppContext } from "AppContext";
import TableFiltrationButton from "components/TableFiltrationButton";
import moment from "moment";

const filters = {
	statusOpen: true,
	statusSent: true,
	statusCompleted: true
};

const HandlerIncidentList = () => {
	const history = useHistory();
	const { t } = useTranslation();
	const [listResult, listApi] = useApi();
	const appContext = useContext(AppContext);
	useEffect(() => {
		listApi.get("api/handlecases/handle");
	}, [listApi]);
	const statusFilters =
		appContext.customer === "Tryg"
			? [
					{
						name: t("incoming", "Incoming"),
						value: "Open"
					},
					{
						name: t("new", "New"),
						value: "New"
					},
					{
						name: t("reviewed", "reviewed"),
						value: "Recieved"
					},
					{
						name: t("tryg_damage", "Tryg damage"),
						value: "SentToTryg"
					},
					{
						name: t("internal_damage", "Internal damage"),
						value: "SavedLocally"
					},
					{
						name: t("completed", "Completed"),
						value: "Completed"
					},
					{
						name: t("removed", "Removed"),
						value: "Removed"
					}
			  ]
			: [
					{
						name: t("incoming", "Incoming"),
						value: "Open"
					},
					{
						name: t("sent", "Sent"),
						value: "Sent"
					},
					{
						name: t("completed", "Completed"),
						value: "Completed"
					}
			  ];
	const initialState =
		appContext.customer === "Tryg"
			? {
					filters: [
						{
							id: "status",
							value: [
								"New",
								"SentToTryg",
								"SavedLocally",
								"Recieved",
								"Completed"
							]
						}
					]
			  }
			: {
					filters: [
						{
							id: "status",
							value: ["Open", "Sent", "Completed"]
						}
					]
			  };
	const columns = [
		{
			accessorKey: "vehicle",
			accessorFn: (row) =>
				`${row.vehicleIdentifier} - ${row.vehicleRegistrationNumber} - ${row.vehicleModel}`,
			header: t("vehicle")
		},
		{
			accessorKey: "status",
			header: t("status"),
			cell: (cell) => {
				return (
					<IncidentStatus
						status={cell.row.original.status}
						customer={appContext.customer}
					/>
				);
			},
			Filter: TableFiltrationButton,
			hiddenHeaderFilter: true,
			filterFn: "multiFilter",
			FilterOptions: statusFilters
		},
		{
			enableColumnFilter: true,
			accessorKey: "incidentDate",
			header: t("incident_date"),
			cell: (cell) => {
				return moment(cell.row.original.incidentDate).format("L");
			}
		},
		{
			header: t("incident"),
			accessorFn: (row) => {
				return `${t(row.category, row.category)}`;
			}
		},
		{
			accessorKey: "commands",
			header: "",
			cell: (cell) => {
				return (
					<ButtonGroup className="float-end">
						<Button
							size="sm"
							color="white"
							onClick={() => {
								history.push(
									"/incidents/edit/".concat(
										cell.row.original.id
									)
								);
							}}
						>
							{t("manage")}
						</Button>
					</ButtonGroup>
				);
			}
		}
	];

	return (
		<>
			<div className="d-flex justify-content-between pt-2 pt-md-3 px-2 px-md-3">
				<h1 className="">{t("incidents")}</h1>
				<Link className="large-link" to="/incidents/list">
					{t("showallincidents")} <ArrowRightIcon />
				</Link>
			</div>
			<DataTable
				columns={columns}
				result={listResult}
				initialState={initialState}
				hideFilterButton={true}
				hideSearch
				hideExport
			/>
		</>
	);
};
export default HandlerIncidentList;
