import {
    Page,
    Text,
    View,
    Document,
    StyleSheet,
    Image as PdfImage,
    Svg
} from "@react-pdf/renderer";
import { t } from "i18next";

import moment from "moment";

const styles = StyleSheet.create({
    page: {
        backgroundColor: "#ffffff",
        display: "flex",
        justifyContent: "flex-start",
        fontSize: "12px",
        paddingTop: 85,
        paddingBottom: 65,
        paddingHorizontal: 35
    },
    container: { justifyContent: "flex-start", top: 0 },

    section: {
        flexDirection: "row",
        borderBottom: "1px solid #dee2e6",
        paddingVertical: "5px"
    },
    row: {
        flexDirection: "row"
    },
    flexGrow: {
        flexGrow: 0,
        flexShrink: 1,
        flexBasis: 200,
        paddingRight: 5,
        maxWidth: "50%"
    },
    left: {
        paddingRight: 8,
        width: "50%"
    },

    right: {
        paddingLeft: 8,
        width: "50%"
    },

    header: {
        position: "absolute",
        fontSize: 12,
        top: 30,
        left: 35,
        right: 35,
        paddingBottom: 8,
        borderBottom: "3px solid #dee2e6"
    },
    headerRow: {
        flexDirection: "row",
        justifyContent: "space-between"
    },
    headerLeft: {
        paddingRight: 15,
        paddingVertical: 5,
        width: "50%",
        fontSize: 14
    },
    headerRight: {
        display: "flex",
        textAlign: "right",
        justifyContent: "flex-end",
        paddingLeft: 5,
        width: "50%",
        right: 0
    },
    pageNumber: {
        position: "absolute",
        fontSize: 12,
        bottom: 30,
        left: 0,
        right: 0,
        textAlign: "center",
        color: "grey"
    },
    h1: {
        fontSize: "16px",
        marginBottom: 5
    }
});

const ComponentRender = ({ item }) => {
    switch (item.component) {
        case "LocationPicker": {
            let selectedValue;
            if (item && item.value) {
                selectedValue = JSON.parse(item.value)?.interpretedValue;
            }
            return (
                <View>
                    <Text>{selectedValue}</Text>
                </View>
            );
        }

        case "ImageSelector":
            return (
                <View style={{ flexDirection: "column", width: 400 }}>
                    {item.value.length > 0 && Array.isArray(item.value) ? (
                        item.value
                            ?.filter((x) => x.labelKey === "text")
                            .map((x) => (
                                <View
                                    style={{
                                        flexDirection: "row",
                                        marginBottom: "8px"
                                    }}
                                >
                                    <Text style={{ marginRight: 8 }}>•</Text>
                                    <Text>{x.value}</Text>
                                </View>
                            ))
                    ) : (
                        <View style={{ flexDirection: "row", marginBottom: 4 }}>
                            <Text style={{ marginHorizontal: 8 }}>•</Text>
                            <Text>{item.value}</Text>
                        </View>
                    )}
                </View>
            );
        case "Upload": {
            return (
                <View style={styles.row}>
                    {item.value?.map((x) => (
                        <PdfImage
                            style={styles.flexGrow}
                            src={`data:image/jpeg;base64, ${x}`}
                        />
                    ))}
                </View>
            );
        }
        case "ImageCard": {
            return (
                <View style={styles.row}>
                    {item.value?.map((x) => {
                        return (
                            <PdfImage
                                style={styles.flexGrow}
                                src={`data:image/png;base64, ${x}`}
                            />
                        );
                    })}
                </View>
            );
        }
        case "MultiSelect":
            return (
                <p className="m-0">
                    {item.value.length > 0 && Array.isArray(item.value) ? (
                        item.value
                            ?.filter((x) => x.labelKey === "LabelKey")
                            .map((x) => <Text>{t(x.value)}</Text>)
                    ) : (
                        <Text>{item.value}</Text>
                    )}
                </p>
            );
        case "IncidentDateTimePicker":
            return (
                <View>
                    <Text>{item.value}</Text>
                </View>
            );
        case "Select":
            return (
                <View>
                    <Text>{item.value}</Text>
                </View>
            );
        case "FormTemplateButton":
            return (
                <View>
                    <Text>{item.translateDK}</Text>
                </View>
            );
        default:
            return (
                <View>
                    {item.value.length > 0 && Array.isArray(item.value) ? (
                        item.value?.map((x) => <Text>{x?.value}</Text>)
                    ) : (
                        <Text>{item.value}</Text>
                    )}
                </View>
            );
    }
};

export default function PdfDocument({ content }) {
    return (
        <Document title={content.header?.subtitle}>
            <Page size="A4" style={styles.page}>
                <View style={styles.header} fixed>
                    <View style={styles.headerRow} wrap>
                        <View>
                            <Text style={styles.h1}>
                                {content.header?.title}
                            </Text>
                            <Text>{content.header?.subtitle}</Text>
                        </View>
                        <View>
                            <Text>{content.header?.rightColumn.label}</Text>
                            <Text>{content.header?.rightColumn.value}</Text>
                        </View>
                    </View>
                </View>
                <View style={styles.container}>
                    {content.formGroups?.map((formGroup) => {
                        return (
                            <View style={styles.section} wrap>
                                <View style={styles.left}>
                                    <Text>{formGroup.label}</Text>
                                </View>
                                <View style={styles.right}>
                                    {formGroup.fields.map((x) => {
                                        return <ComponentRender item={x} />;
                                    })}
                                </View>
                            </View>
                        );
                    })}
                </View>

                <Text
                    style={styles.pageNumber}
                    render={({ pageNumber, totalPages }) =>
                        `${pageNumber} / ${totalPages}`
                    }
                    fixed
                />
            </Page>
        </Document>
    );
}
